import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import { JsonUrl } from 'api/apiList'
import FlexBox from 'components/FlexBox'
import Box from '@mui/material/Box/Box'
import { partyplanetFun } from 'api/request'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { resetRoute } from 'utils'
interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: any
  progress?: Number
}
const Video: React.FC<PreloaderProps> = ({ imageUrls, videoUrls }) => {
  let [btn_1, btn_2, btn_3, btn_4, btn_5, di_yinliang, di_youtube, di_yinliang_kai, logo_pp, logo_sky, logo_xian, btn_a, btn_w, VideoBg, btn_Android, btn_GooglePlay] = imageUrls
  const [index] = videoUrls

  // const videoSource = `${JsonUrl}/mp4/index.mp4`
  const [isLoaded, setIsLoaded] = useState(false)

  const [WinInfo, setWinInfo] = useState<any>([])
  const [MacInfo, setMacInfo] = useState<any>([])
  const [AndroidInfo, setAndroidInfo] = useState<any>([])
  const [IosInfo, setIosInfo] = useState<any>([])

  const [Mute, setMute] = useState<any>(true)

  const toggleMute = () => {
    setMute(!Mute)
  }
  useEffect(() => {
    const getdownloadInfo = () => {
      partyplanetFun()
        .then((res: any) => {
          if (res.data.length > 0) {
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index]
              switch (element.platform) {
                case 'pc':
                  setWinInfo(res.data[index])
                  break
                case 'mac':
                  setMacInfo(res.data[index])
                  break
                case 'android':
                  setAndroidInfo(res.data[index])
                  break
                case 'ios':
                  setIosInfo(res.data[index])
                  break
              }
            }
          }
        })
        .catch((err: any) => {})
    }
    getdownloadInfo()
  }, [])
  return (
    <Wrap style={{ backgroundImage: `url(${VideoBg})` }}>
      <video
        playsInline
        muted={Mute}
        autoPlay
        loop
        className="back-video-style"
        onCanPlayThrough={() => {
          setIsLoaded(true)
        }}
      >
        <source src={index} type="video/mp4"></source>
      </video>
      <HeadBox>
        <FlexBox>
          <Logo onClick={resetRoute} src={logo_sky} alt="Logo" />
          <Line src={logo_xian} alt="Line"></Line>
          <GameLogo onClick={() => window.location.reload()} src={logo_pp} alt="GameLogo"></GameLogo>
        </FlexBox>

        <FlexBox>
          <HeadA href="https://discord.com/invite/UC9ud4QzP7" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_1} alt="" />}
          </HeadA>
          <HeadA href="https://twitter.com/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_2} alt="" />}
          </HeadA>
          <HeadA href="https://medium.com/@SkylandNFTs" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_3} alt="" />}
          </HeadA>
          <HeadA href="https://linktr.ee/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_4} alt="" />}
          </HeadA>
          <HeadLink to={'/'}>{<HeadRight src={btn_5} alt="" />}</HeadLink>
        </FlexBox>
      </HeadBox>

      <FootBox>
        <FlexBox>
          <YoutobeA href="https://www.youtube.com/watch?v=4dlorfwG4P4" target="_blank" rel="noopener noreferrer">
            <Youtobe src={di_youtube} alt="" />
          </YoutobeA>

          {Mute ? <Volume onClick={toggleMute} src={di_yinliang} alt="" /> : <Volume onClick={toggleMute} src={di_yinliang_kai} alt="" />}
        </FlexBox>

        {
        <DownLoadBox>
          <LogoBox href={'https://play.google.com/store/apps/details?id=com.Skytopia.PartyPlanet'} target="_blank" rel="noopener noreferrer">
            <SaveButton>
              <LogoBtn src={btn_GooglePlay} alt=""></LogoBtn>
            </SaveButton>
          </LogoBox>
          <LogoBox href={AndroidInfo?.download} target="_blank" rel="noopener noreferrer">
            <SaveButton>
              <LogoBtn src={btn_Android} alt=""></LogoBtn>
            </SaveButton>
          </LogoBox>
          <LogoBox href={MacInfo?.download} target="_blank" rel="noopener noreferrer">
            <SaveButton>
              <LogoBtn src={btn_a} alt=""></LogoBtn>
            </SaveButton>
          </LogoBox>
          <LogoBox href={WinInfo?.download} target="_blank" rel="noopener noreferrer">
            <SaveButton>
              <LogoBtn src={btn_w} alt=""></LogoBtn>
            </SaveButton>
          </LogoBox>
        </DownLoadBox>
        }
      </FootBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #000;
  background-size: 100% 100%;
  .back-video-style {
    margin: 0 auto;
    width: auto;
    height: 100vh;
    display: block;
  }
`
const HeadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94.01vw;
  height: 4.583vw;
  position: absolute;
  left: 2.969vw;
  top: 2.604vw;
`
const Logo = styled.img`
  width: 8.021vw;
  height: 3.333vw;
`
const Line = styled.img`
  width: 0.521vw;
  height: 3.125vw;
  margin: 0 0.938vw 0 1.615vw;
`
const GameLogo = styled.img`
  width: 7.448vw;
  height: 4.583vw;
`

const HeadA = styled.a`
  width: 3.333vw;
  height: 3.333vw;
  display: block;
  position: relative;
  margin-right: 1.042vw;
`
const HeadLink = styled(Link)`
  width: 3.333vw;
  height: 3.333vw;
  display: block;
  position: relative;
  margin-right: 1.042vw;
`

const HeadRight = styled.img`
  width: 3.333vw;
  display: block;
  position: absolute;
`
const FootBox = styled.div`
  display: flex;
  align-items: self-end;
  justify-content: space-between;
  width: 94.792vw;
  height: 9.219vw;
  position: absolute;
  left: 2.969vw;
  bottom: 3.177vw;
  z-index: 1;
`
const YoutobeA = styled.a`
  width: 11.979vw;
  height: 5.104vw;
  display: block;
  position: relative;
  margin-right: 1.771vw;
`
const Youtobe = styled.img`
  width: 11.979vw;
  height: auto;
`
const Volume = styled.img`
  width: 6.979vw;
  height: auto;
`

// 下载按钮
const DownLoadBox = styled.div`
  width: 12.813vw;
  /* height: 9.219vw; */
  height: 19.271vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

const LogoBox = styled.a`
  width: 12.813vw;
  height: 4.271vw;
  cursor: pointer;
`
const SaveButton = styled(Button)`
  width: 12.813vw;
  height: 4.271vw;
  background-color: rgba(92, 127, 255, 0);
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`
const LogoBtn = styled.img`
  width: 12.813vw;
  height: auto;
  position: absolute;
`
export default Video
