import Image from "components/Image"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { orderListFun } from "api/request"
import FlexBox from "components/FlexBox"
import Media from "react-media"
import OrderH5 from "./OrderH5"

const Order = () => {
  const [OrderListInfo, setOrderList] = useState<any>([])

  useEffect(() => {
    const getproductList = () => {
      orderListFun()
        .then((res: any) => {
          console.log(res)

          setOrderList(res.data.list)
        })
        .catch((err: any) => {})
    }
    getproductList()
  }, [])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="product-wrap">
            <Title>ORDER</Title>
            <ProductList>
              <FlexBox sx={{ marginBottom: "0.677vw" }}>
                <TableTitle style={{ marginRight: "14.323vw" }}>Product Information</TableTitle>
                <TableTitle style={{ marginRight: "6.719vw" }}>Unit Price</TableTitle>
                <TableTitle style={{ marginRight: "6.875vw" }}>Number</TableTitle>
                <TableTitle style={{ marginRight: "6.458vw" }}>Payments</TableTitle>
                <TableTitle>Transaction Status</TableTitle>
              </FlexBox>

              {OrderListInfo?.length > 0 ? (
                OrderListInfo?.map((item: any, i: any) => (
                  <TableBodyBox key={i}>
                    <TableBody
                      style={{
                        width: "22.396vw",
                        boxSizing: "border-box",
                      }}
                    >
                      <FlexBox
                        sx={{
                          width: "22.396vw",
                          boxSizing: "border-box",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <div style={{ marginRight: "0.5vw" }}>{item?.updated?.split("T")[0]}</div>
                        <div>Order Number : {item?.id}</div>
                      </FlexBox>
                      <FlexBox>
                        <OrderImage src={item?.image_url}></OrderImage>
                        <OrderName>{item?.name}</OrderName>
                      </FlexBox>
                    </TableBody>
                    <TableBody style={{ width: "14.5vw", textAlign: "center" }}>
                      <FlexBox center>
                        {item?.price}
                        <ProductPriceImage folder="setting" src="CMP.png"></ProductPriceImage>
                      </FlexBox>
                    </TableBody>
                    <TableBody style={{ width: "10.5vw", textAlign: "center" }}> {item?.amount}</TableBody>
                    <TableBody style={{ width: "15vw", textAlign: "center" }}>
                      <FlexBox center>
                        {item?.cost}
                        <ProductPriceImage folder="setting" src="CMP.png"></ProductPriceImage>
                      </FlexBox>
                    </TableBody>
                    <TableBody style={{ width: "11vw", textAlign: "right" }}>Complete</TableBody>
                  </TableBodyBox>
                ))
              ) : (
                <FlexBox column center sx={{ width: "100%" }}>
                  <NoDataImg src="NoData.png" folder="setting"></NoDataImg>
                  <Tips>No Data</Tips>
                </FlexBox>
              )}
            </ProductList>
          </Wrap>
        ) : (
          <OrderH5></OrderH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh - 3.594vw);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
`
const Title = styled.div<{ marginVal?: string }>`
  width: 7.083vw;
  height: 1.719vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 2.604vw;
  margin: 11.25vw 0 2.083vw 9.635vw;
`
const ProductList = styled.div`
  width: 79.167vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  min-height: 10vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 10.469vw;
  flex-wrap: wrap;
  box-sizing: border-box;
  padding: 1.042vw;
`

const TableTitle = styled.div`
  font-size: 1.302vw;
  color: #95add4;
  font-family: Albert Sans-Bold, Albert Sans;
`
const TableBodyBox = styled.div`
  width: 77.031vw;
  height: 6.458vw;
  border-radius: 0.781vw;
  margin-bottom: 1.042vw;
  background: rgba(139, 164, 255, 0.5);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0.521vw;
`
const TableBody = styled.div`
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
`
const NoDataImg = styled(Image)`
  width: 7vw;
  height: 7vw;
  margin: 4vw auto 0.5vw;
`
const Tips = styled.div`
  /* height: 1.615vw; */
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b2c1dd;
  text-align: center;
`
const OrderImage = styled.img`
  width: 3.958vw;
  height: 3.958vw;
  border-radius: 0.521vw;
  margin-right: 0.208vw;
`
const OrderName = styled.div`
  width: 16.042vw;
  height: 3vw;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`

const ProductPriceImage = styled(Image)`
  width: 1.979vw;
  height: 1.979vw;
  margin-left: 0.469vw;
`
export default Order
