import styled from '@emotion/styled'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { ImgInfo } from '../config/Roadmap'
import { useEffect, useRef, useState } from 'react'
import Media from 'react-media'
import RoadmapH5 from './RoadmapH5'
import { S3Url } from "api/apiList";

const UnCompleteBig = `${S3Url}/images/index2/RoadMap/UnCompleteBig.png`
const UnCompleteSmall = `${S3Url}/images/index2/RoadMap/UnCompleteSmall.png.png`
const CompleteSmall = `${S3Url}/images/index2/RoadMap/CompleteSmall.png.png`
const CompleteBig = `${S3Url}/images/index2/RoadMap/CompleteBig.png`


const Roadmap = () => {
  const scrollContainerRef: any = useRef(null)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startX, setStartX] = useState<any>()
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e: { pageX: number }) => {
    setIsMouseDown(true)
    console.log(e.pageX)

    setStartX(e.pageX - scrollContainerRef.current.offsetLeft)
    setScrollLeft(scrollContainerRef.current.scrollLeft)
    scrollContainerRef.current.style.cursor = 'grabbing'
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
    scrollContainerRef.current.style.cursor = 'grab'
  }

  const handleMouseMove = (e: { preventDefault: () => void; pageX: number }) => {
    if (!isMouseDown) return
    e.preventDefault()
    const x = e.pageX - scrollContainerRef.current.offsetLeft
    const scrollX = (x - startX) * 2
    scrollContainerRef.current.scrollLeft = scrollLeft - scrollX
  }

  // useEffect(() => {
  //   scrollContainerRef.current.scrollLeft = 680
  // }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.fromTo(entry.target, { opacity: 0, y: 100 }, { opacity: 1, y: 0, duration: 1 })
        }
      })
    })

    const elements = document.querySelectorAll('.roadmap')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap>
            <RoadmapText className="roadmap">ROADMAP</RoadmapText>

            <RoadMapBox className="roadmap" ref={scrollContainerRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp}>
              <RoadMap>
                {ImgInfo.map((item, key) => {
                  return (
                    <StageBox className={item.class} key={key} CompleteStatus={item?.CompleteStatus} CompleteIdx={key}>
                      {(item.lineClass === 'Q2Line' || item.lineClass === 'Q4Line' || item.lineClass === 'Q6Line' || item.lineClass === 'Q8Line') && <ArrowLine1 folder="index2/Roadmap" src="ArrowBottom.png" />}
                      {(item.lineClass === 'Q2Line' || item.lineClass === 'Q4Line' || item.lineClass === 'Q6Line' || item.lineClass === 'Q8Line') && <ArrowLine2 folder="index2/Roadmap" src="ArrowUp.png" />}

                      {item.CurrentProgress && <CurrentProgress folder="index2/Roadmap" src="CurrentProgress.png" />}
                      <StageName>{item.name}</StageName>

                      <StageContent>
                        <StageVal>{item.content1}</StageVal>
                        <StageVal>{item?.content2}</StageVal>
                        {item?.content3 !== '' && <StageVal>{item?.content3}</StageVal>}
                        {item?.content4 !== '' && <StageVal>{item?.content4}</StageVal>}
                        {item?.content5 !== '' && <StageVal>{item?.content5}</StageVal>}
                        {item?.content6 !== '' && <StageVal>{item?.content6}</StageVal>}
                      </StageContent>
                    </StageBox>
                  )
                })}
              </RoadMap>
            </RoadMapBox>
          </Wrap>
        ) : (
          <RoadmapH5></RoadmapH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;

  .Q1,
  .Q3,
  .Q5,
  .Q7,
  .Q9 {
    height: 21.146vw;
  }
  .Q2,
  .Q4,
  .Q6,
  .Q8 {
    height: 18.021vw;
  }
`

const RoadMapBox = styled.div`
  width: 100%;
  height: 31.25vw;
  position: absolute;
  left: 0;
  top: 15.573vw;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
const RoadMap = styled.div`
  width: fit-content;
  height: 31.25vw;
  margin: 0 7.813vw;
  display: flex;
  align-items: center;
  position: relative;
`
const RoadmapText = styled.div`
  width: 13.385vw;
  height: 1.563vw;
  position: absolute;
  top: 9.375vw;
  left: 13.75vw;
  font-size: 2.083vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #000000;
  line-height: 1.563vw;
`

const StageBox = styled.div<{ CompleteStatus?: boolean; CompleteIdx: number }>`
  width: 15.521vw;
  background-image: url(${({ CompleteStatus, CompleteIdx }) => (CompleteStatus && CompleteIdx % 2 === 0 ? UnCompleteBig : CompleteStatus && CompleteIdx % 2 === 1 ? UnCompleteSmall : !CompleteStatus && CompleteIdx % 2 === 0 ? CompleteBig : CompleteSmall)});
  background-size: 100% 100%;
  border-radius: 1.458vw;
  margin-left: 1.667vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
`
const CurrentProgress = styled(Image)`
  width: 1.146vw;
  height: 1.354vw;
  position: absolute;
  left: 1.771vw;
  top: 2vw;
  /* top: 2.865vw; */
`
const StageName = styled.div`
  width: 11.979vw;
  height: 1.563vw;
  font-size: 1.146vw;
  font-family: Onest-Medium, Onest;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.458vw;
`

const StageContent = styled.ul`
  width: 11.979vw;
  min-height: 2vw;
  font-size: 0.833vw;
  font-family: Onest-Regular, Onest;
  color: #ecf1f6;
  line-height: 1.563vw;
  margin-top: 0.521vw;
`
const StageVal = styled.li`
  list-style-position: outside; /* 将标志放置在内容外部 */
`
const ArrowLine1 = styled(Image)`
  width: 17.813vw;
  height: 3.854vw;
  position: absolute;
  bottom: -4.115vw;
  right: 7.76vw;
`
const ArrowLine2 = styled(Image)`
  width: 17.813vw;
  height: 3.854vw;
  position: absolute;
  top: -4.219vw;
  left: 7.448vw;
`
export default Roadmap
