import React, { useCallback, useContext } from 'react'
import { OpenHandler, CloseHandler, ModalContext } from 'context/ModalProvider'

const useModal = (modal: React.ReactNode): [OpenHandler, CloseHandler] => {
  const { onOpen, onClose } = useContext(ModalContext)
  const openHandler = useCallback(() => {
    onOpen(modal)
  }, [modal, onOpen])
  return [openHandler, onClose]
}

export default useModal