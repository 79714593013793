import Image from 'components/Image'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect, useRef, useState } from 'react'
import { Box, Button } from '@mui/material'
import { progressFun, getproofFun, checkWhiteListFun } from 'api/request'
import message from 'utils/message'
import { useWeb3React } from '@web3-react/core'
import { buyLandWithETH } from 'utils/BuyNFT'
import { useLandSaleWithETHAndDAI } from 'hooks/useContract'
import { S3Url } from "api/apiList";

const Mint = () => {
  const a1 = `${S3Url}/images/skytopia/a1.png`
  const a2 = `${S3Url}/images/skytopia/a2.png`
  const a3 = `${S3Url}/images/skytopia/a3.png`

  const [loading, setLoading] = useState(false)

  const [MintList, setMintList] = useState<any>([])
  const [isWhiteList, setIsWhiteList] = useState<any>()

  const images = [a1, a2, a3] // eslint-disable-line
  var { account } = useWeb3React()

  const LandSaleWithETHAndDAIContract = useLandSaleWithETHAndDAI(localStorage.getItem('MarketContract'))

  // 获取mint进度
  useEffect(() => {
    const getProgressFun = () => {
      progressFun({ contract_address: localStorage.getItem('NftContract') })
        .then((res: any) => {
          setMintList({ ...res.data })
        })
        .catch((err: any) => {})
    }
    const interval = setInterval(async () => {
      await getProgressFun()
    }, 6000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (account === '' || account === null || account === undefined) {
      return
    }
    const getCheckWhiteListFun = () => {
      checkWhiteListFun({ contract_address: localStorage.getItem('NftContract'), wallet_address: account })
        .then((res: any) => {
          if (res.data) {
            setIsWhiteList(res.data.is_white_list)
          }
        })
        .catch((err: any) => {})
    }
    getCheckWhiteListFun()
  }, [account])

  // useEffect(() => {
  //   if (pcSize) {
  //     var tl = gsap.timeline({ scrollTrigger: { trigger: '.Mint-wrap', start: 'top-=700' } })
  //     tl.fromTo('.Mint-img-Life', { opacity: 0, x: 60 }, { x: 0, opacity: 1, duration: 0.5 })
  //     tl.fromTo('.Mint-title-Life', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.5 })
  //     tl.fromTo('.Mint-countDowns', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.5 })
  //   }
  // }, [pcSize])

  // 图片无缝播放
  const marqueeRef = useRef<any>(null)
  const [totalWidth, setTotalWidth] = useState(0)
  let animationFrameId: number | null = null

  useEffect(() => {
    const marquee = marqueeRef.current
    let totalWidth = 0
    for (const child of marquee.children) {
      totalWidth += (child as HTMLElement).offsetWidth
    }
    setTotalWidth(totalWidth)
  }, [images]) // eslint-disable-line

  const step = () => {
    const marquee = marqueeRef.current
    const firstChild = marquee.firstChild as HTMLElement
    marquee.scrollLeft += 1

    if (marquee.scrollLeft >= firstChild.offsetWidth) {
      marquee.appendChild(marquee.firstChild)
      marquee.scrollLeft -= firstChild.offsetWidth
    }

    animationFrameId = requestAnimationFrame(step)
  }

  useEffect(() => {
    step()
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
    }
  }, [totalWidth]) // eslint-disable-line

  return (
    <Wrap className="Mint-wrap">
      <MintBox>
        <MintTextBox className="Minttextbox">
          <BigTitle className="Minttitie">Skytopian Residents</BigTitle>
          <SmallTitle className="Minttitie">This series is designed by the Skytopia team. It's made up of 10,000 unique 3D avatar NFTs.</SmallTitle>
          <Progress>
            {MintList?.nft_minted_amount || 0}/{MintList?.nft_total || 0}
          </Progress>
          <TipBtn className="Minttitie">Third round</TipBtn>
          <Whitelisted>
            <Box>Coming Soon</Box>
          </Whitelisted>
          {/* <MintPrice>Mint price: {isWhiteList ? 'Free' : '500CMP'}</MintPrice>
          <Whitelisted>
            <Box>Whitelisted：</Box>
            {isWhiteList ? <IsWhitelisted folder="skytopia" src="yes.png" /> : <IsWhitelisted folder="skytopia" src="no.png" />}
          </Whitelisted>

          <MintBtn
            onClick={async () => {
              if (account === '' || account === null || account === undefined) {
                message.warn('Please connect your wallet. ')
                return false
              } else if (account?.toLocaleLowerCase() !== localStorage.getItem('account')?.toLocaleLowerCase()) {
                message.warn('Connected address does not match. Please switch to your binding address.')
                return false
              }
              var list: any = []
              try {
                await getproofFun({ contract_address: localStorage.getItem('NftContract') }).then(async (res: any) => {
                  if (res.code === 0) {
                    list = res.data
                  }
                })
              } catch (error) {
                return false
              }

              try {
                if (list.length === 0) {
                  return false
                }
                setLoading(true)
                await buyLandWithETH(LandSaleWithETHAndDAIContract, account, list.token_id, list.price, list.salt, list.proofs)
                message.success('Mint Success')
                setLoading(false)
              } catch (error) {
                message.error('Mint Error.')
                setLoading(false)
              }
            }}
            disabled={loading}
          >
            <MintImg folder="skytopia" src="Mint.png" />

            {loading ? <Loading className="RotationBack" folder="components" src="rotate.png" /> : <ArrowImg folder="skytopia" src="arrow.png" />}
          </MintBtn> */}
        </MintTextBox>

        <StoryBox ref={marqueeRef}>
          {images.map((image, index) => (
            <img key={index} src={image} alt="" />
          ))}
        </StoryBox>
      </MintBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const MintBox = styled.div`
  width: 92.396vw;
  height: 32.083vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

const MintTextBox = styled.div`
  width: 24.583vw;
  min-height: 32.083vw;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
`

const BigTitle = styled.div`
  width: 24.583vw;
  height: 9.323vw;
  font-size: 4.167vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 4.583vw;
  margin-bottom: 1.927vw;
`

const SmallTitle = styled.div`
  width: 24.583vw;
  height: 3.229vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #3d3d3d;
  line-height: 1.563vw;
  margin-bottom: 0.417vw;
`
const TipBtn = styled.div`
  width: 24.583vw;
  height: 2.24vw;
  background: #7e8084;
  border-radius: 0.417vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #ffffff;
  line-height: 2.24vw;
  margin-bottom: 0.781vw;
  text-align: center;
`
const Progress = styled.div`
  width: fit-content;
  height: 4.167vw;
  font-size: 4.375vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  line-height: 3.958vw;
  background: linear-gradient(90deg, #ff58a9 26%, #26a9f0 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`
const MintPrice = styled.div`
  height: 30px;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 30pzx;
`
const Whitelisted = styled.div`
  width: 7.917vw;
  height: 1.563vw;
  font-size: 1.042vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 1.563vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const IsWhitelisted = styled(Image)`
  width: 1.458vw;
  height: 1.458vw;
`
const MintBtn = styled(Button)`
  width: 16.25vw;
  height: 3.438vw;
  background: #383938;
  border-radius: 3.438vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.75vw 0 4.167vw;
  box-sizing: border-box;
  margin-top: 3.177vw;
  :hover {
    background: #383938;
  }
  @keyframes rotationback {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 3s linear infinite;
  }
`
const MintImg = styled(Image)`
  width: 4.792vw;
  height: 1.458vw;
`

const ArrowImg = styled(Image)`
  width: 1.563vw;
  height: 0.677vw;
`
const Loading = styled(Image)`
  width: 2.5vw;
  height: 2.5vw;
`

const StoryBox = styled.div`
  width: 61.146vw;
  height: 32.083vw;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  img {
    height: 32.083vw;
    width: 101.25vw;
  }
`
export default Mint
