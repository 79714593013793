import Image from "components/Image"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import useSize from "hooks/useSize"
import FlexBox from "components/FlexBox"
import { Box, Button } from "@mui/material"
import lottie from "lottie-web"
// import Iceland from "assets/json/dance.json"
import CountDowns from "./CountDowns"
import { JsonUrl } from "api/apiList"
import { checkWhiteListFun, getCountdownFun, progressFun } from "api/request"
import message from "utils/message"

const FirstH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (!pcSize) {
      lottie.loadAnimation({
        container: document.getElementsByClassName("first-img-community")[0],
        renderer: "svg",
        loop: true,
        autoplay: true,
        // animationData: Iceland,
        path: `${JsonUrl}/json/dance.json`,
      })
    }
  }, [pcSize])

  const [isShow, setIsshow] = useState(false)
  const [timeStamp, setTimeStamp] = useState(0)
  const [countdownType, setCountdownType] = useState(0)
  const [mintPlanType, setMintPlanType] = useState(0)
  const [nftList, setNftList] = useState<any>([])
  const [wallet_address, setwalletAddress] = useState("")

  const getCountdown = () => {
    getCountdownFun({ contract_address: localStorage.getItem("NftContract") })
      .then((res: any) => {
        setTimeStamp(res.data.countdown_end_time_stamp)
        setCountdownType(res.data.countdown_type) // 0:  wait 1: satrt
        setMintPlanType(res.data.mint_plan_type) //1: Whitelist 2：Public
        setIsshow(res.data.en_show)
      })
      .catch((err: any) => {})
  }
  const getProgressFun = () => {
    progressFun({ contract_address: localStorage.getItem("NftContract") })
      .then((res: any) => {
        setNftList({ ...res.data })
      })
      .catch((err: any) => {})
  }

  const getCheckWhiteListFun = () => {
    if (wallet_address === "") {
      message.error("you are not eligible. ")
      return
    }
    checkWhiteListFun({ contract_address: localStorage.getItem("NftContract"), wallet_address: wallet_address })
      .then((res: any) => {
        if (res.data.is_white_list === true) {
          message.success("you are eligible.")
        } else {
          message.error("you are not eligible. ")
        }
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    getCountdown()
    const interval = setInterval(async () => {
      await getProgressFun()
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Wrap className="first-wrap">
      <Title marginVal="16.267vw auto 3.6vw">Skytopian Residents is coming</Title>
      <Tips>
        This series is designed by the CryptoSkyland team. It’s made up of 10,000 unique 3D avatar NFTs will be <span>free</span> to community.
      </Tips>
      <FlexBox
        between
        sx={{
          width: "70vw",
          height: "9.067vw",
          fontSize: "3.467vw",
          lineHeight: "1.25vw",
          color: "#7893BE",
          marginLeft: "15.867vw",
          fontFamily: "Albert Sans-Bold, Albert Sans",
          marginTop: "4vw",
        }}
      >
        <div>
          <div> first Round ······························· Completed</div>
          <div style={{ marginTop: "2.667vw" }}>
            Second Round ························ <span style={{ color: "#23ca8f" }}>Minting</span>
          </div>
        </div>
      </FlexBox>
      {isShow && (
        <InputBox>
          <input
            type="text"
            value={wallet_address}
            placeholder="Enter your CMP address to see if you are eligible"
            onChange={(evt: any) => {
              setwalletAddress(evt.target.value || "")
            }}
          />
          <SaveButton>
            <SearchImage
              folder="avatar"
              src="search.png"
              onClick={() => {
                getCheckWhiteListFun()
              }}
            />
          </SaveButton>
        </InputBox>
      )}

      <CountNum>
        {nftList?.nft_minted_amount || 0}/{nftList?.nft_total || 0}
      </CountNum>
      <LifeImage marginval={isShow ? "4vw auto 5.467vw" : "4vw auto 16.133vw"} folder="avatar" src="H501@2x.png" className="first-img-Life" />
      {isShow && (
        <Box sx={{ margin: "0 0 10.417vw 4.896vw", position: "relative", zIndex: 1 }} className="first-countDowns">
          <CountDowns timeStamp={timeStamp} getCountdown={getCountdown} countdownType={countdownType} mintPlanType={mintPlanType} />
        </Box>
      )}

      <Title marginVal="0 auto 3.867vw">Another me in another universe</Title>
      <Tips>Experience the virtual world with your own avatar! Customize your unique 3D avatar and meet friends from all over the world, anywhere you want.</Tips>
      <div className="first-img-community"></div>

      <Title marginVal="0 auto 4vw">become an avatar creator</Title>
      <Tips>Create your individual avatar NFTs, show your creativity to the whole metaverse and receive generous rewards.</Tips>
      <MetaworldImage folder="avatar" src="H503@2x.png" className="first-img-metaworld" />
      <Soon>Coming soon...</Soon>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: 300vw;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 35.84vw;
  .first-img-community {
    width: 38.4vw;
    height: 32vw;
    margin: 4.667vw auto 15.2vw;
  }
`
const LifeImage = styled(Image)<{ marginval?: string }>`
  width: 75.6vw;
  height: 62.267vw;
  margin: ${({ marginval }) => marginval};
`
const MetaworldImage = styled(Image)`
  width: 90.667vw;
  height: 54.8vw;
  margin: 3.067vw auto 6.267vw;
`

const Title = styled.div<{ marginVal?: string }>`
  width: 88vw;
  text-align: center;
  min-height: 10.8vw;
  font-size: 6.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 6.4vw;
  margin: ${({ marginVal }) => marginVal};
`
const Tips = styled.div`
  width: 82.267vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  line-height: 4vw;
  margin: 0 auto;
  span {
    color: rgba(35, 202, 143, 1);
    font-size: 3.733vw;
  }
`

const CountNum = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  color: #36cd9a;
  min-width: 22.267vw;
  height: 3.733vw;
  font-size: 4.267vw;
  margin: 4.4vw auto;
  text-align: center;
`
const Soon = styled.div`
  width: 40vw;
  height: 3.733vw;
  font-size: 5.333vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 3.467vw;
  margin: 0 auto;
`
const InputBox = styled.div`
  width: 80.933vw;
  height: 7.067vw;
  border-radius: 2.133vw;
  position: relative;
  margin: 3.771vw 0 0 8.533vw;
  input {
    width: 80.933vw;
    height: 7.067vw;
    border-radius: 2.133vw;
    outline: none;
    border: 0;
    display: block;
    box-sizing: border-box;
    padding-left: 2.667vw;
    background: rgba(161, 186, 227, 0.6);
    font-size: 3.2vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
`
const SaveButton = styled(Button)`
  width: 7.867vw;
  height: 7.2vw;
  min-width: 7.867vw;
  border-radius: 2.133vw;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(92, 127, 255, 0);
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`
const SearchImage = styled(Image)`
  width: 7.867vw;
  height: 7.2vw;
`
export default FirstH5
