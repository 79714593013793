export const TeamInfo = [
  {
    name: 'Edwin',
    Title: 'COFOUNDER | Head of BusinessDevelopment ',
    url: 'a9.png',
    content: 'An early Blockchain adopter  excellent insights into blockchain. Experinced in Incubating blockchain projects. ',
    borderColor: '#A796D4',
    linkedin: '',
    twitter: '',
  },
  {
    name: 'Kathy',
    Title: 'Head of  Partnerships',
    // url: 'Kathy.png',
    url: 'a7.png',
    content: 'An experienced sales account manager, client onboarding, and relationship executive.',
    borderColor: '#DFB570',
    linkedin: '',
    twitter: 'https://twitter.com/KathyxMeta',
  },
  {
    name: 'Lennie',
    Title: '3D Art Director',
    // url: 'Lennie.png',
    url: 'a4.png',
    content: 'Independent 3d Artist produced 3d assets for animation, gaming and film . She has worked on several independent games as a 3d art director.',
    borderColor: '#848CD8',
    linkedin: '',
    twitter: '',
  },
  {
    name: 'Mason',
    Title: 'COFOUNDER | Head of Technology',
    // url: 'Mason.png',
    url: 'a5.png',
    content: 'Specialist in computer graphics and game development. Senior Tech Developer for over 10years. Previously working in Nvidia.',
    borderColor: '#DD993E',
    linkedin: '',
    twitter: 'https://twitter.com/0xMasonQ',
  },
  {
    name: 'Liz',
    Title: 'Concept Art Director',
    url: 'a8.png',
    content: 'Working in the film and game industry for over 10 years. Good at environment concept design, digital scenic painting and illustration for graphics.',
    borderColor: '#DCAAA4',
    linkedin: '',
    twitter: '',
  },
  {
    name: 'Philp', 
    Title: 'Head of DApps Development',
    url: 'a6.png',
    content: 'Senior Software Developer. Early dapp developer of many famous blockchains like Etherum , Difinity and Solana.',
    borderColor: '#AEA749',
    linkedin: '',
    twitter: '',
  },
  {
    name: 'Alex',
    Title: 'COFOUNDER | Head of Operations',
    url: 'a3.png',
    content: 'A Web 3 community specialist and an avid gamer for over 20 years with an in-depth knowledge of multiple platforms & games. ',
    borderColor: '#DB9D7B',
    linkedin: '',
    twitter: 'https://twitter.com/AlexSkyland',
  },
  {
    name: 'Jimmy',
    Title: 'Game producer',
    url: 'a1',
    content: 'Former game producer at Netease and Funplus, with extensive experience in research and development, Involved in numerous games generating monthly revenue of up to 100 million or even 1 billion.',
    borderColor: '#DEB066',
    linkedin: '',
    twitter: '',
  },
]
