import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect } from "react"
import useSize from "hooks/useSize"
import FlexBox from "components/FlexBox"
import { Box } from "@mui/material"

const CharacteristicH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".characteristic-wrap", start: "top-=700" } })
      tl.fromTo(".characteristic-img-Life", { opacity: 0, x: 60 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".characteristic-title-Life", { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.5 })

      var t2 = gsap.timeline({ scrollTrigger: { trigger: ".characteristic-wrap", start: "top-=100" } })
      t2.fromTo(".characteristic-img-community", { opacity: 0, x: -60 }, { x: 0, opacity: 1, duration: 0.5 })
      t2.fromTo(".characteristic-title-community", { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.5 })

      var t3 = gsap.timeline({ scrollTrigger: { trigger: ".characteristic-wrap", start: "top+=300" } })
      t3.fromTo(".characteristic-img-metaworld", { opacity: 0, x: 60 }, { x: 0, opacity: 1, duration: 0.5 })
      t3.fromTo(".characteristic-title-metaworld", { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])

  return (
    <Wrap className="characteristic-wrap">
      <Title>Experience a second Life</Title>
      <Tips>
        Travel through different Metaworlds, meet new civilizations, play immersive games and participate in virtual activities. New experience-new
        life.
      </Tips>
      <LifeImage folder="about_h5" src="02-01@2x.png" />

      <Title>Join the community</Title>
      <Tips>
        Make new friends and build strong connections with each other in the virtual world. Join groups and establish your amazing civilizations.
      </Tips>
      <CommunityImage className="characteristic-img-community" folder="about_h5" src="02-03@2x.png" />

      <Title>Create your Metaworld</Title>
      <Tips>
        CryptoSkyland is working on tools, SDKs and services for creators, developers and publishers to get the most out of connecting Metaworlds to
        CryptoSkyland.
      </Tips>
      <MetaworldImage folder="about_h5" src="02-04@2x.png" className="characteristic-img-metaworld" />
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 285.867vw;
  width: 100%;
  position: relative;
  overflow: hidden;
`
const LifeImage = styled(Image)`
  width: 85.2vw;
  height: 54.933vw;
  margin: 7.733vw auto 11.733vw;
`

const CommunityImage = styled(Image)`
  width: 100%;
  height: 34.533vw;
  margin: 5.6vw auto 15.867vw;
`
const MetaworldImage = styled(Image)`
  width: 100%;
  height: 34.533vw;
  margin: 6.533vw auto 13.467vw;
`

const Title = styled.div<{ marginVal?: string }>`
  width: 90vw;
  height: 5.2vw;
  font-size: 7.467vw;
  color: #7893be;
  line-height: 43px;
  position: relative;
  top: 0;
  left: 50%;
  margin: 0 0 9.733vw -45vw;
`
const Tips = styled.div`
  width: 84vw;
  min-height: 13.867vw;
  font-size: 3.2vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  line-height: 5.333vw;
  margin: 0 auto;
`
export default CharacteristicH5
