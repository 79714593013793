/* eslint-disable */
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import Image from "components/Image"
import FlexBox from "components/FlexBox"
import BasicSelect from "./DropDownSelection"
import { NfTlistFun } from "api/request"
import { getAuthCookie } from "utils/user"
import { Box } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"
import Media from "react-media"
import InventoryH5 from "./InventoryH5"

const Inventory = () => {
  const [totalPage, setTotalPage] = useState(0)

  const [contractAddress, setContractAddress] = useState<any>("all")
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(1)
  const handleClose = () => {
    setOpen(false)
  }

  const [getAllDataList, setAllDataList] = useState<any>([])
  const token = getAuthCookie()

  const selectSendDataFn = (pageIndex?: number) => {
    setAllDataList([])
    setTotalPage(0)
    const param = {
      contract_address: contractAddress,
    }
    setLoading(true)
    NfTlistFun(param)
      .then((res: any) => {
        setTotalPage(res?.data?.length || 0)
        setAllDataList([...res?.data])
      })
      .catch((e) => {
        setTotalPage(0)
        setAllDataList([])
      })
  }

  useEffect(() => {
    if (token) {
      selectSendDataFn()
    }
  }, [token, contractAddress])
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap>
            <Title>Inventory</Title>
            <FlexBox startCenter sx={{ margin: "0 0 1.042vw 17.083vw" }}>
              <Total>{totalPage || 0}</Total>
              <BasicSelect setContractAddress={setContractAddress}></BasicSelect>
            </FlexBox>
            {getAllDataList?.length > 0 ? (
              <>
                <InventoryBox>
                  {getAllDataList?.map((item: any, i: any) => (
                    <ImgBox
                      key={i}
                      onClick={() => {
                        setOpen(true)
                        setShow(i + 1)
                      }}
                    >
                      <NFTImgBox>
                        {item?.meta_info.animation_url === "" ? (
                          <NFTImg
                            src={item.meta_info.image}
                            alt="NFT"
                            style={{ display: loading ? "none" : "block" }}
                            onLoad={() => {
                              setLoading(false)
                            }}
                          />
                        ) : (
                          <NFTVideo
                            playsInline
                            muted
                            autoPlay
                            loop
                            onCanPlayThrough={() => {
                              setLoading(false)
                            }}
                          >
                            <source src={item.meta_info.animation_url} type="video/mp4"></source>
                          </NFTVideo>
                        )}
                      </NFTImgBox>
                      {loading && <Loading className="RotationBack" folder="components" src="rotate.png"></Loading>}
                      <Box
                        sx={{
                          width: "10.938vw",
                          height: "3.75vw",
                          background: "#7D99FF",
                          borderRadius: "0 0 0 0",
                          position: "absolute",
                          bottom: "0",
                          left: "0",
                          zIndex: 3,
                        }}
                      >
                        <NFTID>#{item.token_id}</NFTID>
                        <NFTName>{item.meta_info.name}</NFTName>
                      </Box>
                    </ImgBox>
                  ))}
                </InventoryBox>
              </>
            ) : (
              <NoData>
                <NoDataImg src="NoData.png" folder="setting"></NoDataImg>
                <Tips>You don’t have nft yet</Tips>
              </NoData>
            )}

            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
              {getAllDataList.map((item: any, key: any) =>
                item?.meta_info.animation_url === ""
                  ? show === key + 1 && <img src={item.meta_info.image} alt="NFT" className="img-style-big" key={key + item} />
                  : show === key + 1 && (
                      <video playsInline muted autoPlay loop className="video-style-big"  key={key + item}>
                        <source src={item.meta_info.animation_url} type="video/mp4"></source>
                      </video>
                    )
              )}
            </Backdrop>
          </Wrap>
        ) : (
          <InventoryH5></InventoryH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: 94vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .img-style-big {
    width: 41.667vw;
    height: auto;
  }
  .video-style-big {
    max-width: 80vw;
    max-height: 60vh;
    width: auto;
    height: auto;
  }
`
const Title = styled.div`
  width: 30vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.8vw;
  text-align: center;
  margin: 6.927vw auto 2.083vw;
`
const Total = styled.div`
  min-width: 2vw;
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin-right: 1.646vw;
`
const NoData = styled.div`
  width: 79vw;
  height: 26.771vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  margin-left: 16.563vw;
  overflow: hidden;
  position: relative;
`
const NoDataImg = styled(Image)`
  width: 14.688vw;
  height: 16.667vw;
  margin: 4.323vw 34.896vw 1.979vw 28.125vw;
`
const Tips = styled.div`
  height: 1.615vw;
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b2c1dd;
  text-align: center;
  position: absolute;
  bottom: 2.188vw;
  left: 24.948vw;
`
const InventoryBox = styled.div`
  width: 79vw;
  overflow-y: auto;
  min-height: 26.771vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  margin-left: 16.563vw;
  overflow: hidden;
  box-sizing: border-box;
  padding: 1.927vw 3.75vw 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`

const ImgBox = styled.div`
  width: 10.938vw;
  height: 14.063vw;
  overflow: hidden;
  position: relative;
  margin-bottom: 2.083vw;
  border-radius: 0.781vw;
  border: 0.417vw solid rgba(255, 246, 0, 0);

  @keyframes rotationback {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 3s linear infinite;
  }
  &:hover {
    border: 0.417vw solid rgba(255, 246, 0, 1);
  }
`

const NFTImgBox = styled.div`
  width: 10.938vw;
  height: 10.938vw;
  background-color: rgba(120, 147, 190, 0.5);

  position: absolute;
  left: 0;
  top: 0;
`
const NFTImg = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`
const NFTVideo = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`

const NFTID = styled.div`
  width: 10.938vw;
  height: 1.354vw;
  background: rgba(255, 255, 255, 0.3);
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const NFTName = styled.div`
  width: 10.938vw;
  height: 2.396vw;
  padding: 0 1.042vw;
  text-align: center;
  box-sizing: border-box;
  line-height: 2.396vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const Loading = styled(Image)`
  position: absolute;
  left: 50%;
  top: 40%;
  margin-top: -1.25vw;
  margin-left: -1.25vw;
  width: 2.5vw;
  height: 2.5vw;
`
export default Inventory
/* eslint-disable */
