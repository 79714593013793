import React from 'react'
import styled from '@emotion/styled'
interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress?: Number
}
const MarqueeH5: React.FC<PreloaderProps> = ({ imageUrls }) => {
  const [di_huangsedi] = imageUrls
  const text = 'join US BEFORE THE PARTY STARTS'
  return (
    <Marquee style={{ backgroundImage: `url(${di_huangsedi})` }}>
      <div>{text}</div>
      <div>{text}</div>
    </Marquee>
  )
}

const Marquee = styled.div<{}>`
  display: flex;
  width: 200%;
  height: 8vw;
  overflow: hidden;
  background-size: 50% 100%;
  line-height: 8vw;
  font-size: 4vw;
  font-family: Milky Nice-Regular, Milky Nice;
  color: #a04604;

  & > div {
    animation: div 10s linear infinite;
    width: 100%;
    white-space: nowrap;
    text-align: center;
  }

  @keyframes div {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

export default MarqueeH5
