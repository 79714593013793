import styled from '@emotion/styled'
import FlexBox from 'components/FlexBox'

interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress?: Number
}
const PartyCity: React.FC<PreloaderProps> = ({ imageUrls }) => {
  const [CityBgH5, CityImgH5] = imageUrls

  return (
    <Warp style={{ backgroundImage: `url(${CityBgH5})` }}>
      <Title>THE PARTY OPEN CITY</Title>
      <FlexBox start>
        <Tips>
          Skycity is a visually immersive digital metropolis, where users, as digital citizens, can freely enjoy entertainment, make transactions, and engage in social interactions. <br />
          Party Planet is the very first Skycity in Skytopia – join in and dive into the fun!
        </Tips>
        <CityImg src={CityImgH5} alt=""></CityImg>
      </FlexBox>
    </Warp>
  )
}

const Warp = styled.div`
  width: 100%;
  height: 43vw;
  background-size: 100% auto;
  overflow: hidden;
`
const Title = styled.div`
  width: fit-content;
  font-size: 4.8vw;
  font-family: Milky Nice;
  font-weight: 900;
  color: #fff;
  margin: 2.5vw 0 1.867vw 5vw;
`

const CityImg = styled.img`
  width: 55vw;
  height: auto;
`
const Tips = styled.div`
  width: 34vw;
  height: 21.067vw;
  font-size: 2.083vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #fff;
  line-height: 3.5vw;
  margin: 0 6vw 0 5vw;
`
export default PartyCity
