import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import Image from 'components/Image'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import HunbsVideo from 'components/HunbsVideo'
import HunbsImg from 'components/HunbsImg'
import FlexBox from 'components/FlexBox'
import { useNavigate } from 'react-router-dom'
import { HubsInfo } from '../config/hubsInfo'

const IndexH5: React.FC<any> = () => {
  gsap.registerPlugin(ScrollTrigger)
  const [heightLength, setHeightLength] = useState<any>(1)

  window.onscroll = function () {
    var heightTop = document.documentElement.scrollTop || document.body.scrollTop
    const heightLength = ((document.body.scrollWidth + 17) / 750) * 340
    setHeightLength((heightLength - heightTop) / 100)
  }
  useEffect(() => {
    gsap.to('.bg-text', {
      y: 0.1 * -ScrollTrigger.maxScroll(window),
      ease: 'none',
      scrollTrigger: {
        // trigger: ".skytopiawarp",
        scrub: true,
      },
    })
  }, [])

  const [submenu, setSubmenus] = useState<any>(1)

  useEffect(() => {
    if (window.location.href.includes('metaworld')) {
      setSubmenus(1)
    } else if (window.location.href.includes('village')) {
      setSubmenus(2)
    } else {
      setSubmenus(3)
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [])
  const navigate = useNavigate()
  return (
    <Warp className="metaworld-warp">
      <Image folder="index" src="H5Bg@2x" className="bg-text" />
      <Title>metaworld hub</Title>
      <Contentbg>
        {window.location.href.includes('/hubs/metaworld') && (
          <HeadBox className="metaworld-headbox" style={{ opacity: heightLength > 0 ? heightLength : 0 }}>
            <HeadImg folder="hunbs" src="c1@2x"></HeadImg>
            <LeftImg folder="hunbs" src="001@2x" onClick={() => navigate(-1)}></LeftImg>

            <FlexBox sx={{ height: '11.733vw', position: 'absolute', margin: '2.533vw 0 2.533vw 0' }}>
              <UserImg folder="hunbs" src="game2" />
              <FlexBox startBetween column sx={{ height: '7.8vw' }}>
                <UserName>Skytopia</UserName>
                <IssuingTime>Last updated Aug 18 </IssuingTime>
              </FlexBox>
            </FlexBox>
          </HeadBox>
        )}
        {window.location.href.includes('/hubs/metaworld') && (
          <SlidingRearTop
            style={{
              opacity: heightLength < 0 ? 1 : 1 - heightLength,
              background: '#94cded',
            }}
          >
            <LeftImg folder="hunbs" src="001@2x" onClick={() => navigate(-1)}></LeftImg>
            <UserImg folder="hunbs" src="game2" />
            <UserName>Skytopia</UserName>
          </SlidingRearTop>
        )}

        {window.location.href.includes('/hubs/village') && (
          <HeadBox className="metaworld-headbox" style={{ opacity: heightLength > 0 ? heightLength : 0 }}>
            <HeadImg folder="hunbs" src="c2@2x"></HeadImg>
            <LeftImg folder="hunbs" src="001@2x" onClick={() => navigate(-1)}></LeftImg>
            <FlexBox sx={{ height: '11.733vw', position: 'absolute', margin: '2.533vw 0 2.533vw 0' }}>
              <UserImg folder="hunbs" src="game3" />
              <FlexBox startBetween column sx={{ height: '7.8vw' }}>
                <UserName>Mysterious Village</UserName>
                <IssuingTime>Last updated Aug 3 </IssuingTime>
              </FlexBox>
            </FlexBox>
          </HeadBox>
        )}
        {window.location.href.includes('/hubs/village') && (
          <SlidingRearTop
            style={{
              opacity: heightLength < 0 ? 1 : 1 - heightLength,
              background: '#9B7CC9',
            }}
          >
            <LeftImg folder="hunbs" src="001@2x" onClick={() => navigate(-1)}></LeftImg>
            <UserImg folder="hunbs" src="game3" />
            <UserName>Mysterious Village</UserName>
          </SlidingRearTop>
        )}

        {window.location.href.includes('/hubs/party') && (
          <HeadBox className="metaworld-headbox" style={{ opacity: heightLength > 0 ? heightLength : 0 }}>
            <HeadImg folder="hunbs" src="c3@2x"></HeadImg>
            <LeftImg folder="hunbs" src="001@2x" onClick={() => navigate(-1)}></LeftImg>
            <FlexBox sx={{ height: '11.733vw', position: 'absolute', margin: '2.533vw 0 2.533vw 0' }}>
              <UserImg folder="hunbs" src="game1" />
              <FlexBox startBetween column sx={{ height: '7.8vw' }}>
                <UserName>Party Planet</UserName>
                <IssuingTime>Last updated Aug 22 </IssuingTime>
              </FlexBox>
            </FlexBox>
          </HeadBox>
        )}
        {window.location.href.includes('/hubs/party') && (
          <SlidingRearTop
            style={{
              opacity: heightLength < 0 ? 1 : 1 - heightLength,
              background: '#FDC2AB',
            }}
          >
            <LeftImg folder="hunbs" src="001@2x" onClick={() => navigate(-1)}></LeftImg>
            <UserImg folder="hunbs" src="game1" />
            <UserName>Mysterious Village</UserName>
          </SlidingRearTop>
        )}

        {HubsInfo.map((item, key) => (
          <Content key={item.id}>
            {item.type === 1 && submenu === item.state && <HunbsVideo VideoInfo={item}></HunbsVideo>}
            {item.type === 2 && submenu === item.state && <HunbsImg ImgInfo={item} />}
            {/* {item.type === 3 && <HunbsHyperlinks />} */}
          </Content>
        ))}
      </Contentbg>
    </Warp>
  )
}
const Contentbg = styled.div`
  background: #e4e1f6;
`
const Warp = styled.div`
  position: relative;
  padding-bottom: 22.604vw;
  .bg-text {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0vw;
    left: 0;
    z-index: 0;
    display: block !important;
  }
`
const Title = styled.div`
  height: 5.2vw;
  font-size: 7.467vw;
  color: #7893be;
  line-height: 5.733vw;
  margin: 16.267vw 0 7.867vw 3.867vw;
  position: relative;
`
const Content = styled.div``
const HeadBox = styled.div`
  width: 97.067vw;
  height: 16.8vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  position: relative;
  z-index: 10;
  margin-left: 1.467vw;
`
const HeadImg = styled(Image)`
  width: 97.067vw;
  height: 20.8vw;
  position: absolute;
  z-index: 0;
`
const LeftImg = styled(Image)`
  width: 4.533vw;
  height: 5.867vw;
  position: absolute;
  left: 2.4vw;
  top: 50%;
  z-index: 10;
  margin-top: -2.9335vw;
`

const UserImg = styled(Image)`
  width: 11.733vw;
  height: 11.733vw;
  margin-right: 1.333vw;
  margin-left: 10.667vw;
`
const UserName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
`
const IssuingTime = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 3.2vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
`

const SlidingRearTop = styled.div`
  width: 100%;
  height: 13.333vw;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #94cded;
  /* padding: 0.8vw 4.133vw; */
  box-sizing: border-box;
  position: fixed;
  left: 0vw;
  top: 0vw;
`

export default IndexH5
