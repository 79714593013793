export const SkytopiaInfo = [
  {
    url: "03-01@2x",
    leftUrl:"03-01left@2x",
    class: "Linked-img-style-01",
  },
  {
    url: "03-02@2x",
    leftUrl:"03-02left@2x",
    class: "Linked-img-style-02",
  },
  {
    url: "03-03@2x",
    leftUrl:"03-03left@2x",
    class: "Linked-img-style-03",
  },
  {
    url: "03-04@2x",
    leftUrl:"03-04left@2x",
    class: "Linked-img-style-04",
  },
]
