import Image from 'components/Image'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect } from 'react'
import useSize from 'hooks/useSize'
import { Box } from '@mui/material'
import { ImgInfo } from '../config/NFTHeader'
import { mouseEnter, mouseEnterTopMove, mouseLeave, mouseLeaveTopMove } from 'utils/gsap'
import Media from 'react-media'
import NFTHeaderH5 from './NFTHeaderH5'

const NFTHeader = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: '.friends-wrap', start: 'top-=800' } })
      tl.fromTo('.friends-title-Tips', { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo('.friends-title-style', { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="friends-wrap">
            <Tips className="friends-title-Tips">Become the owner of a unique</Tips>
            <Title className="friends-title-style">SKYLAND NFT </Title>
            <Box sx={{ margin: '0 auto', width: '63.333vw', height: '28.958vw', position: 'relative' }}>
              {ImgInfo.map((item, keys) => (
                <Box
                  key={item.url + keys}
                  className={`${item.class}-box img-box`}
                  onMouseEnter={() => {
                    if (keys === 1) {
                      mouseEnterTopMove(`.${item.class}`, 1.15)
                    } else {
                      mouseEnter(`.${item.class}`, 1.15)
                    }
                  }}
                  onMouseLeave={() => {
                    if (keys === 1) {
                      mouseLeaveTopMove(`.${item.class}`)
                    } else {
                      mouseLeave(`.${item.class}`)
                    }
                  }}
                >
                  <Image folder="nft" src={item.urlBg}></Image>
                  <Image folder="nft" src={item.url} className={`${item.class} ImgBox`}></Image>
                </Box>
              ))}
            </Box>
          </Wrap>
        ) : (
          <NFTHeaderH5></NFTHeaderH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  height: 57.552vw;
  width: 100%;
  position: relative;
  overflow: hidden;
  .img-box {
    position: absolute;
    img {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .small-01-box {
    width: 14.115vw;
    height: 14.063vw;
    left: 0;
    top: 0;
    img {
      width: 14.115vw;
      height: 14.063vw;
    }
  }
  .small-02-box {
    width: 19.271vw;
    height: 18.802vw;
    left: 14.531vw;
    top: 0;
    img {
      width: 19.271vw;
      height: 18.802vw;
    }
  }
  .small-03-box {
    width: 9.427vw;
    height: 9.427vw;
    left: 34.219vw;
    top: 0;
    img {
      width: 9.427vw;
      height: 9.427vw;
    }
  }
  .small-04-box {
    width: 9.427vw;
    height: 9.427vw;
    left: 44.063vw;
    top: 0;
    img {
      width: 9.427vw;
      height: 9.427vw;
    }
  }
  .small-05-box {
    width: 9.427vw;
    height: 9.427vw;
    left: 53.906vw;
    top: 0;
    img {
      width: 9.427vw;
      height: 9.427vw;
    }
  }

  .small-06-box {
    width: 14.115vw;
    height: 14.063vw;
    left: 0;
    top: 14.844vw;
    img {
      width: 14.115vw;
      height: 14.063vw;
    }
  }
  .small-07-box {
    width: 9.427vw;
    height: 9.427vw;
    left: 14.531vw;
    top: 19.688vw;
    img {
      width: 9.427vw;
      height: 9.427vw;
    }
  }
  .small-08-box {
    width: 9.427vw;
    height: 9.427vw;
    left: 24.271vw;
    top: 19.688vw;
    img {
      width: 9.427vw;
      height: 9.427vw;
    }
  }
  .small-09-box {
    width: 14.323vw;
    height: 18.802vw;
    left: 34.219vw;
    top: 10.313vw;
    img {
      width: 14.323vw;
      height: 18.802vw;
    }
  }
  .small-10-box {
    width: 14.323vw;
    height: 18.802vw;
    left: 48.958vw;
    top: 10.313vw;
    img {
      width: 14.323vw;
      height: 18.802vw;
    }
  }
  .ImgBox {
    z-index: 2;
  }
`
const Title = styled.div`
  text-align: center;
  color: #7893be;
  z-index: 1;
  line-height: 1.8vw;
  font-size: 2.5vw;
  margin: 0 auto 4.01vw;
`
const Tips = styled.div`
  text-align: center;
  font-size: 1.615vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin: 9.323vw auto 0.365vw;
`
export default NFTHeader
