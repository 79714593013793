import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect } from "react"
import useSize from "hooks/useSize"

const PersonalSpaceH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".personal-space-wrap", start: "top-=800" } })
      tl.fromTo(".personal-space-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".personal-space-title-Tips", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".personal-space-banner", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])

  return (
    <Wrap className="personal-space-wrap">
      <Title className="personal-space-title-style">your personal space in the metaverse</Title>
      <Tips className="personal-space-title-Tips">Customize space, raise digital pets and party with your friends in your skyland.</Tips>
      <Banner className="personal-space-banner" folder="nft_h5" src="banner" />
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 98.267vw;
  width: 100%;
  position: relative;
`
const Title = styled.div`
  width: 81vw;
  height: 13.467vw;
  text-align: center;
  color: #7893be;
  line-height: 7vw;
  font-size: 7.733vw;
  margin: 0 auto 5.067vw;
`
const Tips = styled.div`
  text-align: center;
  color: #95add4;
  margin: 0 auto 3.867vw;
  width: 82.467vw;
  height: 10.8vw;
  font-size: 4.133vw;
  font-family: Albert Sans-Bold, Albert Sans;
  line-height: 5.8vw;
  span {
    color: #b0d474;
  }
  p {
    color: #38e2b3;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  div {
    color: #32e5ef;
    display: inline-block;
  }
`
const Banner = styled(Image)`
  height: 49.467vw;
  width: 87.867vw;
  margin: 0 auto;
`
export default PersonalSpaceH5
