/* eslint-disable */
import React, { useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { getErc20Contract } from 'utils/web3Utils'
import useWeb3 from './useWeb3'
import useRefresh from './useRefresh'


export const useTokenBalance = (tokenAddress: string) => {
  const [balance, setBalance] = useState(new BigNumber(0))
  const { account } = useWeb3React()
  const web3 = useWeb3()
  const { fastRefresh } = useRefresh()
  useEffect(() => {
    const fetchBalance = async () => {
      const contract = getErc20Contract(tokenAddress, web3)
      const res = await contract.methods.balanceOf(account).call()
      setBalance(new BigNumber(res))
    }
    if (account && tokenAddress !== '') {
      try {
        fetchBalance()
      } catch (err) {}
    } else {
      setBalance(balance)
    }
  }, [account, tokenAddress, fastRefresh, web3])

  return balance
}
/* eslint-disable */