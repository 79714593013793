// 涛哥本地

// export const baseURL = "http://192.168.1.11:8080/api"

// test address
// export const baseURL = "https://offical.pre.skytopia.io/api"

//dev address
export const baseURL = 'https://www.marsxyz.com/api'
// export const JsonUrl = 'https://cryptoskyland.com'

export const JsonUrl = 'https://www.marsxyz.com/OfficialWeb'

// export const S3Url = `${S3Url}'
export const S3Url = 'https://www.marsxyz.com/OfficialWeb'

// google 的id
// export const Google_Client_id = '639037494074-lht6utmtu91287isjljee3simot7v47u.apps.googleusercontent.com' // test
export const Google_Client_id = '531846212481-9n8dtol33g3pabdffdfllbleur7rp4k2.apps.googleusercontent.com' // 正式

// facebook 的id
// export const facebook_Client_id = '278303704784679' // test
export const facebook_Client_id = '662215842442304' // 正式

// Twitter 的id
// export const Twitter_consume_key = '2eBL01lV4xRJ9H8DwPbN9ZDLx'  // test
export const Twitter_consume_key = 'ZPgQEXDdxzycjOuQGD39a3YTj' // 正式


const ACCOUNT_URL = baseURL + '/v1'

export const createAccessToken = ACCOUNT_URL + '/createAccessToken'

// 获取随机数
export const createRandomAPI = ACCOUNT_URL + '/web/get_nonce'
// 钱包登录
export const createAccessTokenWithSignature = ACCOUNT_URL + '/common/login_by_wallet'

// 设置个人信息
export const personalAPI = ACCOUNT_URL + '/web/user/personal'

// 获取个人信息
export const getPersonalAPI = ACCOUNT_URL + '/web/user/personal'

// 获取NFT倒计时
export const getCountdownAPI = ACCOUNT_URL + '/common/nft/countdown'

// 邮箱登录
export const login_by_emailAPI = ACCOUNT_URL + '/common/login_by_email'

// 绑定邮箱
export const band_emailAPI = ACCOUNT_URL + '/web/user/band_email'

// 获取邮箱验证码
export const send_smsAPI = ACCOUNT_URL + '/web/user/verify/send_sms'

// 修改登录密码
export const change_pwdAPI = ACCOUNT_URL + '/web/user/change_pwd'

// 重置密码
export const reset_pwdAPI = ACCOUNT_URL + '/web/reset_pwd'

// 获取NFT proof
export const proofAPI = ACCOUNT_URL + '/web/user/nft/proof'

// 修改邮箱
export const modify_emailAPI = ACCOUNT_URL + '/web/user/modify_email'

// 上传图片
export const NfTlistAPI = ACCOUNT_URL + '/web/user/nft/list'

// 上传图片
export const recaptchaAPI = ACCOUNT_URL + '/common/recaptcha'

// 获取NFT合约
export const contractsAPI = ACCOUNT_URL + '/common/nft/contracts'

// 获取NFTmint进度
export const progressAPI = ACCOUNT_URL + '/common/nft/progress'

// 获取TOKEN CMP价格
export const priceAPI = ACCOUNT_URL + '/common/token/price'

// 获取NFT合约列表
export const contractListAPI = ACCOUNT_URL + '/common/nft/contract/list'

// 提币
export const withdrawAPI = ACCOUNT_URL + '/web/user/asset/withdraw'

// 获取用户资产列表
export const assetListAPI = ACCOUNT_URL + '/web/user/asset/list'

// 提币信息
export const withdraw_infoAPI = ACCOUNT_URL + '/common/asset/withdraw_info'

// 商城
// 商品列表
export const productListAPI = ACCOUNT_URL + '/common/store/product/list'

// 用户下单
export const storeOrderAPI = ACCOUNT_URL + '/web/user/store/order'

// 获取客户端版本号以及下载路径
export const partyplanetAPI = ACCOUNT_URL + '/common/version?client_name=partyplanet'

// 邀请list查询
export const inviteRewardAPI = ACCOUNT_URL + '/web/user/invite_reward'

// 邀请list查询
export const inviteSwitchAPI = ACCOUNT_URL + '/web/user/invite/switch'

// 检查是否白名单
export const checkWhiteListAPI = ACCOUNT_URL + '/common/nft/check_white_list'

// 用户订单
export const orderListAPI = ACCOUNT_URL + '/web/user/store/order/list'

// 谷歌登录
export const googleLoginAPI = ACCOUNT_URL + '/common/share_login/google_web'

// Twitter_request_token
export const requestTokenAPI = ACCOUNT_URL + '/common/share_login/twitter/request_token'

// Twitter登录
export const twitterLoginAPI = ACCOUNT_URL + '/common/share_login/twitter'


// facebook_code 获取code
export const facebookCodeAPI = ACCOUNT_URL + '/common/share_login/facebook/code?state=dadaddasdasd'

// facebook_login
export const facebookLoginAPI = ACCOUNT_URL + '/common/share_login/facebook'

// 官网邮箱注册
export const registerAPI = ACCOUNT_URL + '/common/register'

// 未注册用户获取验证码
export const UnLoginsend_smsAPI = ACCOUNT_URL + '/common/send_sms'


// 绑定钱包连接
export const BandWalletAPI = ACCOUNT_URL + '/web/user/band_wallet'
