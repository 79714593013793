import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import Header from 'components/Header'
import Media from 'react-media'

const PrivacyAgreement: React.FC<any> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Header />
      <Media query={{ maxWidth: 1000 }}>
        {(matches) =>
          !matches ? (
            <Warp>
              <h1>Privacy Policy</h1>
              <div>
                <p>This Privacy Policy is intended to inform you about how we collect, use, process, and protect your personal information. Please make sure to read and understand our Terms of Service before reading this Privacy Policy.</p>

                <h2>1. Information Collection</h2>
                <p>We collect information that includes but is not limited to:</p>
                <ul>
                  <li>Personal information that you provide, such as name, email address, and contact information.</li>
                  <li>Information that is automatically collected when you use our services, such as IP address, browser type, and operating system.</li>
                  <li>Information that is collected through cookies and other technologies, such as your browsing history and search queries.</li>
                </ul>

                <h2>2. Information Use</h2>
                <p>We use the information we collect to:</p>
                <ul>
                  <li>Provide, personalize, and improve our services.</li>
                  <li>Send you information about our services and products.</li>
                  <li>Identify and correct errors or malfunctions in our services.</li>
                  <li>Maintain, protect, and improve our services and technologies.</li>
                  <li>Analyze and monitor user behavior to improve our services and marketing activities.</li>
                </ul>
                <p>We do not sell your personal information to third parties.</p>

                <h2>3. Information Sharing</h2>
                <p>We may share your personal information with:</p>
                <ul>
                  <li>Our affiliates and partners to provide services.</li>
                  <li>Our service providers and contractors to assist us in providing, maintaining, and improving our services.</li>
                  <li>Legal requirements or government agencies.</li>
                </ul>

                <h2>4. Information Protection</h2>
                <p>We take appropriate technical and organizational measures to protect the security and confidentiality of your personal information. We take necessary measures to prevent unauthorized access, use, disclosure, or damage when storing and processing your personal information.</p>

                <h2>5. Cookies and Other Technologies</h2>
                <p>We use cookies and similar technologies to collect and store information to provide better services and marketing. You can manage these technologies on your device, such as disabling cookies or clearing browser cache.</p>

                <h2>6. Data Transfers</h2>
                <p>
                  We may transfer your personal information to other countries or regions to provide services and support our business. We will take necessary measures to protect your personal information and comply with applicable laws and industry standards before transferring your personal
                  information to other countries or regions.
                </p>
                <h2>7. User Choices</h2>
                <p>You can access, correct, update, or delete your personal information at any time. If you wish to access, correct, update, or delete your personal information, please contact our customer service.</p>

                <h2>8. Legal Compliance</h2>
                <p>We will comply with applicable laws and regulations to ensure that our processing and use of your personal information comply with legal requirements. We may disclose your personal information as required by law.</p>

                <h2>9. Privacy Policy Updates</h2>
                <p>We may update this Privacy Policy periodically to reflect changes in our services and business. If we make any material changes to this Privacy Policy, we will notify you through appropriate means and request that you review and agree to the policy again.</p>

                <h2>10. Data Deletion Instructions</h2>
                <p>If you wish to delete the personal information we have collected, please send an email to our customer service. We will respond to your request within a reasonable time frame and take necessary steps to delete your personal information.</p>
                <p>Please note that we may need to retain certain information for legal requirements, security maintenance, fraud prevention, or fulfilling contractual obligations.</p>

                <p>If you have any questions or comments, please contact our customer service.</p>
              </div>
            </Warp>
          ) : (
            <Warp>
              <h1>Privacy Policy</h1>
              <div>
                <p>This Privacy Policy is intended to inform you about how we collect, use, process, and protect your personal information. Please make sure to read and understand our Terms of Service before reading this Privacy Policy.</p>

                <h2>1. Information Collection</h2>
                <p>We collect information that includes but is not limited to:</p>
                <ul>
                  <li>Personal information that you provide, such as name, email address, and contact information.</li>
                  <li>Information that is automatically collected when you use our services, such as IP address, browser type, and operating system.</li>
                  <li>Information that is collected through cookies and other technologies, such as your browsing history and search queries.</li>
                </ul>

                <h2>2. Information Use</h2>
                <p>We use the information we collect to:</p>
                <ul>
                  <li>Provide, personalize, and improve our services.</li>
                  <li>Send you information about our services and products.</li>
                  <li>Identify and correct errors or malfunctions in our services.</li>
                  <li>Maintain, protect, and improve our services and technologies.</li>
                  <li>Analyze and monitor user behavior to improve our services and marketing activities.</li>
                </ul>
                <p>We do not sell your personal information to third parties.</p>

                <h2>3. Information Sharing</h2>
                <p>We may share your personal information with:</p>
                <ul>
                  <li>Our affiliates and partners to provide services.</li>
                  <li>Our service providers and contractors to assist us in providing, maintaining, and improving our services.</li>
                  <li>Legal requirements or government agencies.</li>
                </ul>

                <h2>4. Information Protection</h2>
                <p>We take appropriate technical and organizational measures to protect the security and confidentiality of your personal information. We take necessary measures to prevent unauthorized access, use, disclosure, or damage when storing and processing your personal information.</p>

                <h2>5. Cookies and Other Technologies</h2>
                <p>We use cookies and similar technologies to collect and store information to provide better services and marketing. You can manage these technologies on your device, such as disabling cookies or clearing browser cache.</p>

                <h2>6. Data Transfers</h2>
                <p>
                  We may transfer your personal information to other countries or regions to provide services and support our business. We will take necessary measures to protect your personal information and comply with applicable laws and industry standards before transferring your personal
                  information to other countries or regions.
                </p>
                <h2>7. User Choices</h2>
                <p>You can access, correct, update, or delete your personal information at any time. If you wish to access, correct, update, or delete your personal information, please contact our customer service.</p>

                <h2>8. Legal Compliance</h2>
                <p>We will comply with applicable laws and regulations to ensure that our processing and use of your personal information comply with legal requirements. We may disclose your personal information as required by law.</p>

                <h2>9. Privacy Policy Updates</h2>
                <p>We may update this Privacy Policy periodically to reflect changes in our services and business. If we make any material changes to this Privacy Policy, we will notify you through appropriate means and request that you review and agree to the policy again.</p>

                <h2>10. Data Deletion Instructions</h2>
                <p>If you wish to delete the personal information we have collected, please send an email to our customer service. We will respond to your request within a reasonable time frame and take necessary steps to delete your personal information.</p>
                <p>Please note that we may need to retain certain information for legal requirements, security maintenance, fraud prevention, or fulfilling contractual obligations.</p>

                <p>If you have any questions or comments, please contact our customer service.</p>
              </div>
            </Warp>
          )
        }
      </Media>
    </>
  )
}

const Warp = styled.div`
  width: 1280px;
  margin: 120px auto;
  color: #000;
  font-size: 28px;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 4vw;
    box-sizing: border-box;
    font-size: 3.2vw;
  }
  h1 {
    padding: 10px;
    margin-bottom: 20px;
    @media (max-width: 1000px) {
      padding: 1.333vw;
      margin-bottom: 2.667vw;
    }
  }

  /* ul {
    margin-left: 20px;
  } */
`

export default PrivacyAgreement
