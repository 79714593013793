import React, { useContext, useEffect, useState } from 'react'
import Modal from 'components/Modal/NewModal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Button } from '@mui/material'
import FlexBox from 'components/FlexBox'
import Btn from './CodeCountdown'
import { registerFun } from 'api/request'
import message from 'utils/message'
import { emailReg, resetRoute } from 'utils'
import Media from 'react-media'
import { setAuthCookie } from 'utils/user'
import LoginModal from 'components/LoginModal'
import useModal from 'hooks/useModal'

const NewRegisterModel: React.FC<any> = () => {
  const [emailAddress, setEmailAddress] = useState('')
  const [Emailcode, setEmailcode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [InvitorId, setInvitorId] = useState<any>('')

  useEffect(() => {
    if (sessionStorage.getItem('InvitationCode') + '' !== 'null') {
      let registerCode = sessionStorage.getItem('InvitationCode')
      setInvitorId(registerCode)
    } else {
      setInvitorId('')
    }
  }, [])

  var reg = emailReg()

  const [loginModalHandler] = useModal(<LoginModal />)

  const { onClose } = useContext(ModalContext)

  const title = 'Register'

  const ChangePsd = () => {
    if (newPassword.length < 8) {
      message.error('Passwords must have at least 8 characters.')
      return false
    }

    if (!reg.test(emailAddress)) {
      message.error('Invalid email address.')
      return false
    }

    registerFun({ password: newPassword, mobile_or_email: emailAddress, sms_code: Emailcode, register_code: InvitorId, platform: 'web' })
      .then((res: any) => {
        if (res.code === 0) {
          onClose()
          setAuthCookie(res.data)
          resetRoute()
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const content = (
    <Warp>
      <input
        type="text"
        value={emailAddress}
        onChange={(evt: any) => {
          setEmailAddress(evt.target.value || '')
        }}
        placeholder="Enter your email"
      />

      <FlexBox startBetween sx={{ width: '100%' }}>
        <input
          type="text"
          value={Emailcode}
          onChange={(evt: any) => {
            setEmailcode(evt.target.value || '')
          }}
          maxLength={6}
          style={{
            width: '20.625vw',
          }}
          placeholder="Enter your verification code"
        />
        <Btn isable={emailAddress} isAuthenticated={false}></Btn>
      </FlexBox>

      <input
        type="password"
        value={newPassword}
        onChange={(evt: any) => {
          setNewPassword(evt.target.value || '')
        }}
        placeholder="Enter your password"
      />

      <input
        type="text"
        value={InvitorId}
        onChange={(evt: any) => {
          setInvitorId(evt.target.value || '')
        }}
        placeholder="Enter your Invitation Code"
        style={{
          marginBottom: 0,
        }}
      />
      <Tips>· 8+ characters, must contain at least one letter or one number</Tips>

      <FlexBox between style={{ width: '27.396vw' }}>
        <Signin onClick={loginModalHandler}>Cancel</Signin>
        <Signin
          disabled={emailAddress.length === 0 || Emailcode.length !== 6 || newPassword.length === 0}
          onClick={() => {
            ChangePsd()
          }}
        >
          Sign up
        </Signin>
      </FlexBox>
    </Warp>
  )
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Modal
            title={title}
            content={content}
            sx={{
              width: '32.708vw',
              minHeight: '20vw',
            }}
          />
        ) : (
          <div>123</div>
          // <EditSettingModelH5 />
        )
      }
    </Media>
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2.656vw;
  position: relative;
  input {
    width: 27.396vw;
    height: 2.604vw;
    background: #d8d8d8;
    border-radius: 0.938vw;
    font-size: 1.042vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-weight: 700;
    color: #3d3d3d;
    line-height: 1.25vw;
    outline: none;
    border: 0;
    display: block;
    /* margin-bottom: 1.875vw; */
    box-sizing: border-box;
    padding-left: 1.198vw;
    margin: 0 auto 1.875vw;
  }
  input::-webkit-input-placeholder {
    color: #b0b0b0;
    font-size: 1.198vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: #c2c6cd;
  }
`
const Signin = styled(Button)`
  width: 11.979vw;
  height: 2.813vw;
  background: #383938;
  border-radius: 2.188vw;
  font-size: 1.042vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #ffffff;
  line-height: 1.563vw;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #383938;
  }
`
const Tips = styled.div`
  width: 21.51vw;
  height: 0.729vw;
  font-size: 0.729vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #46a2ff;
  line-height: 0.729vw;
  margin: 0.26vw 0 2.5vw;
`

export default NewRegisterModel
