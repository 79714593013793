export const ImgInfo = [
  {
    url: 'CircleTree1',
    urlBig: 'tree1@2x',
    class: 'circletree1',
    Title: 'The Tree of Creation',
    content1: 'In the distant past, the world was a void, and a lifeless piece of land floated in the sky.',
    content2: 'Later, the "Tree of Creation" appeared, which nurtured all life.',
  },
  {
    url: 'CircleTree2',
    urlBig: 'tree2@2x',
    class: 'circletree2',
    Title: 'The Great Disaster',
    content1: 'One day, black flames fell from the sky, and ignited the Tree of Creation.',
    content2: 'The withered tree collapsed, causing the entire land of Skytopia to crumble and split apart. ',
  },
  {
    url: 'CircleTree3',
    urlBig: 'tree3@2x',
    class: 'circletree3',
    Title: 'Metamorphosis and Rebirth',
    content1: 'The remaining roots of the Tree of Creation turned into seeds that sprouted into the World Tree.',
    content2: 'Survivors established their main cities and civilizations under its protection.',
  },
  {
    url: 'CircleTree4',
    urlBig: 'tree4@2x',
    class: 'circletree4',
    Title: 'Bad omen, disaster is coming',
    content1: 'The prosperous main city civilizations became aware of the abnormality of the "World Tree."',
    content2: 'The terrifying great disaster from ancient times is about to come again!',
  },
  {
    url: 'CircleTree5',
    urlBig: 'tree5@2x',
    class: 'circletree5',
    Title: ' Embark on a journey',
    content1: "Let's embark on a journey, the descendants of the Tree of Creation, ",
    content2: "to explore the unknown world, find the tree's fragments, and save everyone from the next Great Disaster!",
  },
]
