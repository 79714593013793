import styled from '@emotion/styled'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { useEffect, useRef, useState } from 'react'
import { ImgInfo } from '../config/StoryName'

const OurWorldH5 = () => {
  const [ClickStatus, setClickStatus] = useState<number>(0)
  const [isMove, setIsMove] = useState<boolean>(false)

  useEffect(() => {
    if (!isMove) {
      return
    }
    const interval = setInterval(() => {
      ClickEnter((ClickStatus + 1) % 5)
      var tl = gsap.timeline({ scrollTrigger: { trigger: '.ourWorld-wrap', start: 'top-=600' } })
      tl.fromTo('.titiebox', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.3 })
      tl.fromTo('.smalltitle', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.3 })
      tl.fromTo('.ourWorld-tips', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.3 })
    }, 5000)

    return () => clearInterval(interval)
  }, [ClickStatus, isMove])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsMove(true)
        } else {
          setIsMove(false)
        }
      })
    })

    const elements = document.querySelectorAll('.ourWorld-wrap')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  // 点击按钮
  const ClickEnter = (key: number) => {
    setClickStatus(key)
    gsap.fromTo(`.circletree${key + 1}`, { opacity: 1, scale: 1.25 }, { opacity: 1, scale: 1, duration: 3 })
  }

  return (
    <Wrap className="ourWorld-wrap">
      {ImgInfo.map((item, key) => (
        <OurWorldBox key={key}>
          <StoryNameBox className={`${ClickStatus === key ? 'show' : ''}`}>
            <TreeBox>
              <TreeImg folder="index2/StoryName" className={`${item.class}`} src={item.urlBig} />
            </TreeBox>
          </StoryNameBox>

          <OurWorldTextBox className={`${ClickStatus === key ? 'show' : ''}`}>
            <BigTitle>Story</BigTitle>
            <SmallTitle className="smalltitle">{item.Title}</SmallTitle>
            <Tips className="ourWorld-tips">{item.content1}</Tips>
            <Tips className="ourWorld-tips">{item.content2}</Tips>
          </OurWorldTextBox>
        </OurWorldBox>
      ))}

      <Pagination>
        <PaginationLine></PaginationLine>
        {ImgInfo.map((item, key) => (
          <UnSelectBox
            key={key}
            onClick={() => {
              ClickEnter(key)
            }}
          >
            {<SelectBox style={{ opacity: ClickStatus === key ? 1 : 0 }} />}
          </UnSelectBox>
        ))}
      </Pagination>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 136.933vw;
  position: relative;
  .show {
    z-index: 1;
    opacity: 1;
  }
`

const OurWorldBox = styled.div`
  width: 84.533vw;
  position: absolute;
  top: 0;
  left: 7.733vw;
`
const OurWorldTextBox = styled.div`
  width: 84.533vw;
  min-height: 28.75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
`

const BigTitle = styled.div`
  width: 84.533vw;
  height: 13.867vw;
  font-size: 10.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 13.867vw;
  white-space: nowrap;
`

const SmallTitle = styled.div`
  width: 84.533vw;
  height: 8.533vw;
  font-size: 5.6vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 5.6vw;
`
const Tips = styled.div`
  width: 84.533vw;
  min-height: 10vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #7b7b7b;
  line-height: 5.067vw;
`

const StoryNameBox = styled.div`
  width: 84.533vw;
  height: 45.333vw;
  position: relative;
  opacity: 0;
  margin: 14vw auto 12.8vw;
`
const TreeBox = styled.div`
  width: 84.533vw;
  height: 45.333vw;
  overflow: hidden;
  border-radius: 6.133vw;
`
const TreeImg = styled(Image)`
  width: 84.533vw;
  height: 45.333vw;
`

const Pagination = styled.div`
  width: 17.733vw;
  height: 1.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 64.933vw;
  left: 50%;
  transform: translateX(-50%);
`
const PaginationLine = styled.div`
  width: 17.5vw;
  height: 0.133vw;
  background: #000000;
  position: absolute;
`

const UnSelectBox = styled.div`
  width: 0.667vw;
  height: 0.667vw;
  background: #f3f3f3;
  position: relative;
  border-radius: 50%;
  border: 0.133vw solid #000000;
`
const SelectBox = styled.div`
  width: 2.667vw;
  height: 1.067vw;
  background: #000000;
  border-radius: 0.533vw;
  position: absolute;
  top: -0.2vw;
  left: -1vw;
`
export default OurWorldH5
