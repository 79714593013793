import React, { useContext, useEffect, useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Button } from '@mui/material'
import FlexBox from 'components/FlexBox'
import Btn from './CodeCountdown'
import { band_emailFun } from 'api/request'
import message from 'utils/message'
import { emailReg } from 'utils'
import Media from 'react-media'
import EditSettingModelH5 from './EditSettingModelH5'

const EditSettingModel: React.FC<any> = ({ setUpdate }) => {
  const [emailAddress, setEmailAddress] = useState('')
  const [Emailcode, setEmailcode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [InvitorId, setInvitorId] = useState<any>('')
  useEffect(() => {
    if (sessionStorage.getItem('InvitationCode') + '' !== 'null') {
      let registerCode = sessionStorage.getItem('InvitationCode')
      setInvitorId(registerCode)
    } else {
      setInvitorId('')
    }
  }, [])

  var reg = emailReg()
  const ChangePsd = () => {
    if (newPassword.length < 8) {
      message.error('Passwords must have at least 8 characters.')
      return false
    }
    if (newPassword !== repeatPassword) {
      message.error('Passwords do not match.')
      return false
    }

    if (!reg.test(emailAddress)) {
      message.error('Invalid email address.')
      return false
    }
        // if (!isValidPassword(newPassword)) {
    //   message.error('Support upper and lower case letters only.')
    //   return false
    // }

    band_emailFun({ password: newPassword, mobile_or_email: emailAddress, code: Emailcode, platform: 'web', register_code: Number(InvitorId) })
      .then((res: any) => {
        if (res.code === 0) {
          setUpdate(new Date())
          onClose()
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const { onClose } = useContext(ModalContext)

  const title = 'email setting'

  const content = (
    <Warp>
      <Title>Email Connection:</Title>
      <input
        type="text"
        value={emailAddress}
        onChange={(evt: any) => {
          setEmailAddress(evt.target.value || '')
        }}
        style={{
          background: emailAddress ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>Verification Code:</Title>
      <FlexBox startBetween sx={{ width: '100%' }}>
        <input
          type="text"
          value={Emailcode}
          onChange={(evt: any) => {
            setEmailcode(evt.target.value || '')
          }}
          maxLength={6}
          style={{
            background: Emailcode ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
            width: '17.865vw',
          }}
        />
        <Btn isable={emailAddress}></Btn>
      </FlexBox>

      <Title>Password:</Title>
      <input
        type="password"
        value={newPassword}
        onChange={(evt: any) => {
          setNewPassword(evt.target.value || '')
        }}
        style={{
          background: newPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>Confirm Password:</Title>
      <input
        type="password"
        value={repeatPassword}
        onChange={(evt: any) => {
          setRepeatPassword(evt.target.value || '')
        }}
        style={{
          background: repeatPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>Invitation Code:</Title>
      <input
        type="text"
        value={InvitorId}
        onChange={(evt: any) => {
          setInvitorId(evt.target.value || '')
        }}
        style={{
          background: InvitorId ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
          marginBottom: 0,
        }}
      />

      <Signin
        disabled={emailAddress.length === 0 || Emailcode.length !== 6 || newPassword.length === 0 || repeatPassword.length === 0}
        onClick={() => {
          ChangePsd()
        }}
      >
        save
      </Signin>
    </Warp>
  )
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Modal
            title={title}
            content={content}
            sx={{
              width: '28.646vw',
              minHeight: '40vw',
            }}
          />
        ) : (
          <EditSettingModelH5 setUpdate={setUpdate} />
        )
      }
    </Media>
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.563vw 1.979vw 1.563vw;
  position: relative;
  input {
    width: 24.635vw;
    height: 2.604vw;
    background: rgba(92, 127, 255, 0.5);
    border-radius: 1.042vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-size: 1.354vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    line-height: 1.406vw;
    display: block;
    margin-bottom: 2.604vw;
    box-sizing: border-box;
    padding-left: 0.781vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  width: 24.635vw;
  height: 3.438vw;
  background: #5c7fff;
  border-radius: 1.719vw;
  font-size: 1.354vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 1.042vw auto 0;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  font-size: 1.354vw;
  height: 1.094vw;
  line-height: 1vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-bottom: 1.042vw;
`

export default EditSettingModel
