import React, { useRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { TeamInfo } from '../config/Team'
import FlexBox from 'components/FlexBox'
import { openNewTab } from 'utils'

const CarouselH5: React.FC = () => {
  const [selectTeam, setSelectTeam] = useState(0)

  return (
    <Wrap className="exit-animation" id={'Team'}>
      <Title className="team">TEAM</Title>

      <Wrapper className="team">
        {TeamInfo.map((item, index) => (
          <HeadImg
            folder="index2/Team"
            onClick={() => {
              setSelectTeam(index)
            }}
            key={index}
            src={item?.url}
          />
        ))}
      </Wrapper>
      {TeamInfo.map(
        (item, i) =>
          selectTeam === i && (
            <TeamBox key={i}>
              <FlexBox>
                <HeadSmallImg folder="index2/Team" src={item?.url} />
                <HeadTitle>{item?.name}</HeadTitle>
                <FlexBox sx={{ position: 'absolute', right: ' 0px', top: '0px' }}>
                  {item?.linkedin !== '' && <LinkedinImg onClick={() => openNewTab(item?.linkedin)} folder="index2/Team" src={'in.png'} />}
                  {item?.twitter !== '' && <TwitterImg onClick={() => openNewTab(item?.twitter)} folder="index2/Team" src={'x.png'} />}
                </FlexBox>
              </FlexBox>
              <Headjob>{item?.Title}</Headjob>
              <Line></Line>
              <TeamContent>{item.content}</TeamContent>
            </TeamBox>
          )
      )}
    </Wrap>
  )
}

export default CarouselH5

const Wrap = styled.div`
  position: relative;
  width: 100vw;
  height: 185.067vw;
  margin: auto;

  .image {
    float: left;
    width: 308px;
    height: 524px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    visibility: hidden; // Initially set visibility to hidden
    transition: transform 0.23s ease-in-out, opacity 0.23s ease-in-out;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100vw;
`
const HeadImg = styled(Image)`
  width: 29.333vw;
  height: 29.333vw;
  margin: 5.333vw auto 0;
  cursor: pointer;
`

const Title = styled.div`
  width: 65.6vw;
  height: 9.333vw;
  font-size: 6.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 9.333vw;
  margin: 14.133vw 0 0 2.4vw;
`

const TeamBox = styled.div<{ border?: string }>`
  width: 89.867vw;
  height: 31.6vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #3d3d3d;
  line-height: 5.067vw;
  transition: transform 0.23s ease-in-out, opacity 0.23s ease-in-out;
  margin: 5.6vw auto 0;
  position: relative;
`
const TeamContent = styled.div`
  width: 89.867vw;
  min-height: 16.667vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #3d3d3d;
  line-height: 5.067vw;
`
const HeadSmallImg = styled(Image)`
  width: 8.267vw;
  height: 8.267vw;
  margin-right: 1.333vw;
  border-radius: 50%;
`
const HeadTitle = styled.div`
  width: 80.267vw;
  height: 5.733vw;
  font-size: 6.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 6.667vw;
  white-space: nowrap;
`

const Headjob = styled.div`
  width: 89.867vw;
  height: 4vw;
  font-size: 2.667vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #767676;
  line-height: 4vw;
  white-space: nowrap;
  margin: 0.533vw 0 0.8vw 0;
`
const LinkedinImg = styled(Image)`
  width: 8.267vw;
  height: 8.267vw;
  display: block;

  border-radius: 50%;
`
const TwitterImg = styled(Image)`
  width: 8.267vw;
  height: 8.267vw;
  display: block;
  margin-left: 6.667vw;
  border-radius: 50%;
`
const Line = styled.div`
  width: 89.867vw;
  height: 0px;
  opacity: 1;
  border: 1px solid #d8d8d8;
  margin-bottom: 1.333vw;
`
