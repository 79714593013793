import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect, useState } from "react"
import useSize from "hooks/useSize"
import FlexBox from "components/FlexBox"
import { Box, Button } from "@mui/material"
import lottie from "lottie-web"
import CountDowns from "./CountDowns"
import Media from "react-media"
import FirstH5 from "./FirstH5"
import { getCountdownFun, progressFun, checkWhiteListFun } from "api/request"
import { JsonUrl } from "api/apiList"
import message from "utils/message"

const First = () => {
  const { pcSize } = useSize()
  const [isShow, setIsshow] = useState(false)
  const [timeStamp, setTimeStamp] = useState(0)
  const [countdownType, setCountdownType] = useState(0)
  const [mintPlanType, setMintPlanType] = useState(0)
  const [nftList, setNftList] = useState<any>([])
  const [wallet_address, setwalletAddress] = useState("")

  const getCountdown = () => {
    getCountdownFun({ contract_address: localStorage.getItem("NftContract") })
      .then((res: any) => {
        setTimeStamp(res.data.countdown_end_time_stamp)
        setCountdownType(res.data.countdown_type) // 0:  wait 1: satrt
        setMintPlanType(res.data.mint_plan_type) //1: Whitelist 2：Public
        setIsshow(res.data.en_show)
      })
      .catch((err: any) => {})
  }
  const getProgressFun = () => {
    progressFun({ contract_address: localStorage.getItem("NftContract") })
      .then((res: any) => {
        setNftList({ ...res.data })
      })
      .catch((err: any) => {})
  }

  const getCheckWhiteListFun = () => {
    if (wallet_address === "") {
      message.error("you are not eligible. ")
      return
    }
    checkWhiteListFun({ contract_address: localStorage.getItem("NftContract"), wallet_address: wallet_address })
      .then((res: any) => {
        if (res.data.is_white_list === true) {
          message.success("you are eligible.")
        } else {
          message.error("you are not eligible. ")
        }
      })
      .catch((err: any) => {})
  }

  useEffect(() => {
    getCountdown()
    const interval = setInterval(async () => {
      await getProgressFun()
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".first-wrap", start: "top-=700" } })
      tl.fromTo(".first-img-Life", { opacity: 0, x: 60 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".first-title-Life", { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".first-countDowns", { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.5 })

      var t2 = gsap.timeline({ scrollTrigger: { trigger: ".first-wrap", start: "top+=100" } })
      t2.fromTo(".first-img-community", { opacity: 0, x: -60 }, { x: 0, opacity: 1, duration: 0.5 })
      t2.fromTo(".first-title-community", { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.5 })

      var t3 = gsap.timeline({ scrollTrigger: { trigger: ".first-wrap", start: "center-=400" } })
      t3.fromTo(".first-img-metaworld", { opacity: 0, x: 60 }, { x: 0, opacity: 1, duration: 0.5 })
      t3.fromTo(".first-title-metaworld", { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.5 })
      lottie.loadAnimation({
        container: document.getElementsByClassName("first-img-community")[0],
        renderer: "svg",
        loop: true,
        autoplay: true,
        // animationData: Iceland,
        path: `${JsonUrl}/json/dance.json`,
      })
    }
  }, [pcSize])
  console.log()

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="first-wrap">
            <ContentLife style={{ margin: isShow ? "11.302vw 0 0.885vw 7.604vw" : "20vw 0 0.885vw 7.604vw" }} className="first-title-Life">
              <Title marginVal="0 0 2.865vw 7.552vw">skytopian residents</Title>
              <FlexBox between wrap sx={{ width: "40vw", height: "4.688vw", marginBottom: "1.771vw" }}>
                <Tips>This series is designed by the CryptoSkyland team. It’s made up</Tips>
                <Tips>
                  of 10,000 unique 3D avatar NFTs will be <span>free</span> to community.
                </Tips>
              </FlexBox>
              <FlexBox
                between
                sx={{
                  width: "32.552vw",
                  height: "4.198vw",
                  fontSize: "1.25vw",
                  lineHeight: "1.25vw",
                  color: "#7893BE",
                  marginLeft: "3.177vw",
                  fontFamily: "Albert Sans-Bold, Albert Sans",
                }}
              >
                <div>
                  <div> first Round ·························································· Completed</div>
                  <div style={{ marginTop: "1.615vw" }}>
                    Second Round ··················································· <span style={{ color: "#23ca8f" }}>Minting</span>
                  </div>
                </div>
              </FlexBox>
            </ContentLife>
            {isShow && (
              <InputBox className="first-title-Life">
                <input
                  type="text"
                  value={wallet_address}
                  placeholder="Enter your CMP address to see if you are eligible"
                  onChange={(evt: any) => {
                    setwalletAddress(evt.target.value || "")
                  }}
                />
                {/* <SaveButton>
                  <SearchImage
                    folder="avatar"
                    src="search.png"
                    onClick={() => {
                      getCheckWhiteListFun()
                    }}
                  />
                </SaveButton> */}
              </InputBox>
            )}
            <CountNum style={{ marginBottom: isShow ? " 0.885vw " : "18.698vw" }} className="first-title-Life">
              {nftList?.nft_minted_amount || 0}/{nftList?.nft_total || 0}
            </CountNum>
            {isShow && (
              <Box sx={{ margin: "1.354vw 0 10.417vw 6.094vw", position: "relative", zIndex: 1 }} className="first-countDowns">
                <CountDowns timeStamp={timeStamp} getCountdown={getCountdown} countdownType={countdownType} mintPlanType={mintPlanType} />
              </Box>
            )}

            {/* <LifeImage folder="avatar" src="01@2x.png" className="first-img-Life" />
            <FlexBox startBetween sx={{ width: "79.375vw", height: "31.563vw", marginLeft: "13.802vw" }}>
              <div className="first-img-community"></div>
              <Box className="first-title-community" sx={{ width: "38.802vw" }}>
                <Title marginVal="7.344vw 0 1.979vw">Another me in another universe</Title>
                <Tips>
                  Experience the virtual world with your own avatar!
                  <br /> Customize your unique 3D avatar and meet friends from all over the world, anywhere you want.
                </Tips>
              </Box>
            </FlexBox> */}

            {/* <FlexBox startBetween sx={{ width: "78.958vw", height: "24.427vw", marginLeft: "14.323vw" }}>
              <Box sx={{ width: "33.854vw" }} className="first-title-metaworld">
                <Title marginVal="3.906vw 0 1.927vw">become an avatar creator</Title>
                <Tips>Create your individual avatar NFTs, show your creativity to the whole metaverse and receive generous rewards.</Tips>
                <Soon>Coming soon...</Soon>
              </Box>
              <MetaworldImage folder="avatar" src="03@2x.png"  className="first-img-metaworld" />
            </FlexBox> */}
          </Wrap>
        ) : (
          <FirstH5 />
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: 100vw;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
  .first-img-community {
    width: 25vw;
    height: 20.833vw;
  }
`
const LifeImage = styled(Image)`
  width: 46.146vw;
  height: 37.865vw;
  position: absolute;
  right: 1.406vw;
  top: 9.375vw;
`

const CountNum = styled.div`
  width: 48.958vw;
  height: 2.25vw;
  font-size: 2.344vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #36cd9a;
  margin: 1.094vw 0 0.885vw 2.8vw;
  text-align: center;
`
const ContentLife = styled.div`
  min-height: 9.01vw;
  width: 40.104vw;
`

const MetaworldImage = styled(Image)`
  width: 40.469vw;
  height: 24.427vw;
`

const Title = styled.div<{ marginVal?: string }>`
  height: 1.771vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin: ${({ marginVal }) => marginVal};
  line-height: 1.604vw;
`
const Tips = styled.div`
  font-size: 1.25vw;
  color: #95add4;
  font-family: Albert Sans-Bold, Albert Sans;
  span {
    color: rgba(35, 202, 143, 1);
    font-size: 1.875vw;
  }
`
const Soon = styled.div`
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(120, 147, 190, 1);
  line-height: 1.604vw;
  margin-top: 2.083vw;
`

const InputBox = styled.div`
  width: 35vw;
  height: 2.76vw;
  border-radius: 0.833vw;
  position: relative;
  margin: 1.771vw 0 0 9.4vw;
  input {
    width: 35vw;
    border-radius: 0.833vw;
    height: 2.76vw;
    outline: none;
    border: 0;
    display: block;
    box-sizing: border-box;
    padding-left: 1.563vw;
    background: rgba(161, 186, 227, 0.6);
    font-size: 1.25vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
`
const SaveButton = styled(Button)`
  width: 3.073vw;
  height: 2.813vw;
  min-width: 3.073vw;
  border-radius: 0.833vw;
  position: absolute;
  right: 0;
  top: 0;
  background-color: rgba(92, 127, 255, 0);
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`
const SearchImage = styled(Image)`
  width: 3.073vw;
  height: 2.813vw;
`

export default First
