import React, { useEffect, useState } from "react"
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar"
import Alert, { AlertColor } from "@mui/material/Alert"
import AlertTitle from "@mui/material/AlertTitle"
import message, { MessageContent } from "utils/message"

interface MessageProps {
  [key: Key]: any
}

type MessageType = AlertColor

const defaultDuration = 3000

const GlobalSnackbar: React.FC<MessageProps> = ({ props }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [type, setType] = useState<MessageType>("info")
  const [title, setTitle] = useState<string>("")
  const [content, setContent] = useState<string>("")
  const [duration, setDuration] = useState<number>(defaultDuration)

  const close = (_: any, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") return
    setOpen(false)
  }

  const handleMsg = (msg: MessageContent, type: MessageType, duration?: number) => {
    setOpen(true)
    setType(type)
    setTitle(typeof msg === "string" ? "" : msg.title)
    setContent(typeof msg === "string" ? msg : msg.content)
    setDuration(duration || defaultDuration)
  }

  useEffect(() => {
    message.info = (msg: MessageContent, duration?: number) => handleMsg(msg, "info", duration)
    message.warn = (msg: MessageContent, duration?: number) => handleMsg(msg, "warning", duration)
    message.success = (msg: MessageContent, duration?: number) => handleMsg(msg, "success", duration)
    message.error = (msg: MessageContent, duration?: number) => handleMsg(msg, "error", duration)
  }, [])

  return (
    <Snackbar open={open} autoHideDuration={duration} onClose={close} anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
      <Alert
        elevation={6}
        variant="filled"
        onClose={close}
        severity={type}
        sx={{ fontFamily: "Albert Sans-Bold, Albert Sans", display: "flex", alignItems: "center", fontSize: "0.729vw" }}
      >
        {/* {!!title && <AlertTitle>{title}</AlertTitle>} */}
        {content}
      </Alert>
    </Snackbar>
  )
}

export default GlobalSnackbar
