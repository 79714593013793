import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect, useRef, useState } from 'react'

interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: any
  progress?: Number
}
const GameMapH5: React.FC<PreloaderProps> = ({ imageUrls, videoUrls }) => {
  const [di_guanggao1H5, di_guanggao2H5, di_guanggao3H5, di_guanggao4H5, di_guanggao5H5, di_hongsediH5, videoStart, StayTuned, WordWarH5,ChaosCourseH5] = imageUrls
  const [FugitiveFinder, Mushroom, candyChaos, Football, WordWarVideo, ArcticAssault,ChaosCourse] = videoUrls
  const [autoPlay, setAutoPlay] = useState(999)

  const GameInfo = [
    {
      url: di_guanggao1H5,
      class: 'Game1',
      videoUrl: candyChaos,
      name: 'CANDY CHAOS',
    },
    {
      url: di_guanggao2H5,
      class: 'Game2',
      videoUrl: Mushroom,
      name: 'MUSHROOM MADNESS',
    },
    {
      url: di_guanggao3H5,
      class: 'Game3',
      videoUrl: Football,
      name: 'FOOTBALL FRENZY',
    },
    {
      url: ChaosCourseH5,
      class: 'Game7',
      videoUrl: ChaosCourse,
      name: 'CHAOS COURSE',
    },
    {
      url: di_guanggao4H5,
      class: 'Game4',
      videoUrl: FugitiveFinder,
      name: 'FUGITIVE  FINDER',
    },
    {
      url: WordWarH5,
      class: ' ',
      videoUrl: WordWarVideo,
      name: 'WORD WAR',
    },
    {
      url: di_guanggao5H5,
      class: 'Game6',
      videoUrl: ArcticAssault,
      name: 'ARCTIC ASSAULT',
    },
   
  ]

  const scrollContainerRef: any = useRef(null)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startX, setStartX] = useState<any>()
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e: { pageX: number }) => {
    setIsMouseDown(true)
    console.log(e.pageX)

    setStartX(e.pageX - scrollContainerRef.current.offsetLeft)
    setScrollLeft(scrollContainerRef.current.scrollLeft)
    scrollContainerRef.current.style.cursor = 'grabbing'
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
    scrollContainerRef.current.style.cursor = 'grab'
  }

  const handleMouseMove = (e: { preventDefault: () => void; pageX: number }) => {
    if (!isMouseDown) return
    e.preventDefault()
    const x = e.pageX - scrollContainerRef.current.offsetLeft
    const scrollX = (x - startX) * 2
    scrollContainerRef.current.scrollLeft = scrollLeft - scrollX
  }

  useEffect(() => {
    scrollContainerRef.current.scrollLeft = 0
  }, [])

  // useEffect(() => {
  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         gsap.fromTo(entry.target, { opacity: 0, x: 1000 }, { opacity: 1, x: 0, duration: 1 })
  //       }
  //     })
  //   })

  //   const elements = document.querySelectorAll('.roadmap')
  //   elements.forEach((el) => {
  //     observer.observe(el)
  //   })

  //   // 当组件卸载时停止观察
  //   return () => {
  //     elements.forEach((el) => {
  //       observer.unobserve(el)
  //     })
  //   }
  // }, [])

  return (
    <Wrap style={{ backgroundImage: `url(${di_hongsediH5})` }}>
      <RoadMapBox ref={scrollContainerRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp}>
        <RoadMap className="roadmap">
          {GameInfo.map((item, key) => (
            <GameBox
              key={key}
              onClick={() => {
                setAutoPlay(key + 1)
              }}
            >
              <GameName>{item.name}</GameName>
              <GameImg src={`${item.url}`} />
              {item.videoUrl && (
                <video
                  style={{
                    width: '100%',
                    height: 'auto',
                    position: 'absolute',
                    left: '0',
                    bottom: '0',
                    borderRadius: '8.267vw',
                    display: autoPlay === key + 1 ? 'block' : 'none',
                  }}
                  muted
                  playsInline
                  autoPlay
                  loop={true}
                  controls
                  src={item.videoUrl}
                ></video>
              )}
              <VideoStart style={{ display: autoPlay === key + 1 ? 'none' : 'block' }} src={`${videoStart}`}></VideoStart>
            </GameBox>
          ))}
          <div>
            <StayTunedImg src={`${StayTuned}`}></StayTunedImg>
          </div>
        </RoadMap>
      </RoadMapBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 153.867vw;
  position: relative;
  background-size: 100% 100%;
  video::-webkit-media-controls-enclosure {
    display: none;
  }
`

const RoadMapBox = styled.div`
  width: 100%;
  min-height: 122.133vw;
  position: absolute;
  left: 0;
  top: 9.2vw;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
const RoadMap = styled.div`
  width: 62.4vw;
  min-height: 122.133vw;
  display: flex;
  align-items: center;
`

const GameImg = styled.img`
  width: 62.4vw;
  height: 110.133vw;
  display: block;
`
const VideoStart = styled.img`
  width: 26.667vw;
  height: 26.667vw;
  display: block;
  position: absolute;
  bottom: 48.533vw;
  left: 18vw;
`
const StayTunedImg = styled.img`
  width: 64.533vw;
  height: 11.467vw;
  display: block;
  margin-left: 13.733vw;
`

const GameBox = styled.div`
  width: 62.4vw;
  height: 122.133vw;
  margin-left: 12.667vw;
  position: relative;
`
const GameName = styled.div`
  width: 62.4vw;
  text-align: center;
  height: 6.667vw;
  font-size: 6.667vw;
  font-family: Milky Nice-Regular, Milky Nice;
  color: #f2ffb9;
  line-height: 6.667vw;
  margin-bottom: 5.333vw;
  white-space: nowrap;
`

export default GameMapH5
