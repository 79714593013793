// @ts-nocheck
/* eslint-disable */
import Image from "components/Image"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import FlexBox from "components/FlexBox"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import BigNumber from "bignumber.js"
import { getBalanceNumber } from "utils/formatBalance"
import { useWeb3React } from "@web3-react/core"
import useWeb3 from "hooks/useWeb3"
import { useAssetsSaleWithCMP } from "hooks/useContract"
import message from "utils/message"
import { buyAssetsWithCMP } from "utils/BuyNFT"
import useModal from "hooks/useModal"
import NavigationH5 from "./NavigationH5"
import Loading from "./Loading"

const ProductDetailsH5 = () => {
  const [productListInfo, setProductList] = useState<any>()
  const [buyCount, setBuyCount] = useState<any>(1)
  const [ErrColose, setErrColose] = useState<any>(false)
  const [loadingState, setLoadingState] = useState<any>(true)
  const [CMPBanlance, setCMPBanlance] = useState<any>()

  var { account } = useWeb3React()
  const web3 = useWeb3()
  const [ChangeNavigation] = useModal(<NavigationH5 />)
  const [ChangeLoading] = useModal(<Loading loadingState={loadingState} />)
  // 交易合约
  const [requestedApproval, setRequestedApproval] = useState(false)

  useEffect(() => {
    const getBalance = async () => {
      const balance = await web3.eth.getBalance(`${account}`)
      setCMPBanlance(getBalanceNumber(new BigNumber(balance), 18))
    }
    account && getBalance()
  }, [account])

  const navigate = useNavigate()
  const handleToDetails = (e: any) => {
    e.stopPropagation()
    navigate("/store")
  }

  useEffect(() => {
    setProductList(JSON.parse(localStorage.getItem("productInfo")))
  }, [])
  const LandSaleWithETHAndDAIContract = useAssetsSaleWithCMP(localStorage.getItem("StoreContract"))

  return (
    <Wrap className="product-wrap">
      <GoBack onClick={handleToDetails}>GO BACK</GoBack>
      <Title>STORE</Title>
      {/* <FlexBox startBetween sx={{ width: "78.958vw", margin: "0 auto" }}> */}
      <ProductBox>
        <ProductTitle>{productListInfo?.name}</ProductTitle>
        <ProductImage src={productListInfo?.image_url} alt="NFT"></ProductImage>
        <ProductTip>{productListInfo?.description} </ProductTip>
      </ProductBox>
      <SelectCurrencyBox>
        <SelectCurrencyTitle>Select Currency:</SelectCurrencyTitle>
        <SelectWalletBox>
          <ProductPriceImage folder="setting" src={productListInfo?.pay_currency?.toUpperCase() || "CMP" + ".png"}></ProductPriceImage>
          <div>
            <WalletName>{productListInfo?.pay_currency}</WalletName>
            <WalletBalance>Balance:{CMPBanlance || 0}</WalletBalance>
          </div>
          <SelectWalletImage folder="store" src="blueRightH5.png" />
        </SelectWalletBox>
        <PriceTitle>Price:</PriceTitle>
        <FlexBox startCenter sx={{ margin: "0.8vw 0 3.733vw 0 " }}>
          <ProductPriceImage folder="setting" src={productListInfo?.pay_currency?.toUpperCase() || "CMP" + ".png"}></ProductPriceImage>
          <ProductPrice>{productListInfo?.price}</ProductPrice>
        </FlexBox>
        <SelectCurrencyTitle>Quantity:(up to ∞ )</SelectCurrencyTitle>
        <ProductInputBox>
          <ProductInputUpBtn
            folder="store"
            src="UP.png"
            onClick={() => {
              let val = buyCount
              setErrColose(false)
              setBuyCount(val + 1)
            }}
          />
          <input
            type="tel"
            value={buyCount}
            onChange={(evt: any) => {
              setBuyCount(evt.target.value || "")
              setErrColose(false)
            }}
            style={{
              background: buyCount ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
            }}
          />
          <ProductInputDownBtn
            folder="store"
            src="DOWN.png"
            onClick={() => {
              if (buyCount > 1) {
                let val = buyCount
                setErrColose(false)
                setBuyCount(val - 1)
              }
            }}
          />
        </ProductInputBox>
        {buyCount * productListInfo?.price > Number(CMPBanlance) && !ErrColose && (
          <ErrorBox>
            <ErrorBoxImage
              onClick={() => {
                setErrColose(true)
              }}
              folder="store"
              src="close.png"
            />
            <ErrorVal>Your balance is not enougth funds. Please deposit enough balance</ErrorVal>
          </ErrorBox>
        )}
        <Buy
          disabled={buyCount === 0 || requestedApproval || buyCount * productListInfo?.price > Number(CMPBanlance)}
          onClick={async () => {
            if (account?.toLocaleLowerCase() !== localStorage.getItem("account")?.toLocaleLowerCase()) {
              message.warn("Connected address does not match. Please switch to your binding address.")
              return false
            }

            try {
              setRequestedApproval(true)
              const hash = await buyAssetsWithCMP(LandSaleWithETHAndDAIContract, account, buyCount, productListInfo?.asset_name_byte32, productListInfo?.price)
              ChangeNavigation()
              setRequestedApproval(false)
            } catch (error) {
              console.log(error)
              message.error("Buy Error.")
              setRequestedApproval(false)
            }
          }}
        >
          BUY
        </Buy>
      </SelectCurrencyBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const GoBack = styled(Button)`
  width: 17.6vw;
  height: 4.667vw;
  background: rgba(92, 127, 255, 0.7);
  border-radius: 1.333vw;
  margin: 17vw 0 5.067vw 5.2vw;
  line-height: 4.667vw;
  font-size: 2.267vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  height: 5.2vw;
  font-size: 7.467vw;
  line-height: 5.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin: 0vw 0 7.6vw 5.2vw;
`
const ProductBox = styled.div`
  width: 87.333vw;
  height: 72.933vw;
  border-radius: 2.667vw;
  background: rgba(255, 255, 255, 0.4);
  margin-left: 6.267vw;
  padding: 2.667vw 5.333vw 3.467vw;
  box-sizing: border-box;
`
const ProductTitle = styled.div`
  width: 100%;
  font-size: 3.333vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ProductTip = styled.div`
  height: 6.667vw;
  font-size: 3.333vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  line-height: 1.979vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 0 auto;
`

const ProductImage = styled.img`
  width: 52.267vw;
  height: 52.267vw;
  display: block;
  margin: 2.667vw auto 2.667vw;
`

const SelectCurrencyBox = styled.div`
  width: 87.333vw;
  height: 72.933vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 2.667vw;
  padding: 4vw;
  box-sizing: border-box;
  margin: 2.933vw 0 4vw 6.267vw;
  position: relative;
`
const SelectCurrencyTitle = styled.div`
  width: 100%;
  font-size: 2.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 1.733vw;
`
const SelectWalletBox = styled.div`
  width: 79.067vw;
  height: 7.467vw;
  background: rgba(120, 147, 190, 0.5);
  border-radius: 2vw;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
  box-sizing: border-box;
  padding-left: 1.333vw;
`
const WalletName = styled.div`
  width: 30.4vw;
  height: 3.6vw;
  font-size: 3.333vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
`
const WalletBalance = styled.div`
  width: 23.467vw;
  font-size: 2.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
`
const SelectWalletImage = styled(Image)`
  width: 7.867vw;
  height: 7.467vw;
  position: absolute;
  right: 0;
  top: 0;
`
const PriceTitle = styled.div`
  font-size: 2.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin: 2.031vw 0 0.521vw 0;
`

const ProductPriceImage = styled(Image)`
  width: 4vw;
  height: 4vw;
  margin-right: 0.8vw;
`

const ProductPrice = styled.div`
  min-width: 2.24vw;
  font-size: 3.6vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b3c2dd;
`

const ProductInputBox = styled.div`
  width: 79.067vw;
  height: 7.333vw;
  border-radius: 2vw;
  margin-bottom: 3.733vw;
  position: relative;
  input {
    width: 79.067vw;
    height: 7.333vw;
    background: rgba(120, 147, 190, 0.5) !important;
    border-radius: 2vw;
    font-size: 3.333vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    line-height: 7.333vw;
    display: block;
    box-sizing: border-box;
    padding: 0 8vw 0 8vw;
    text-align: center;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.333vw;
  }
`
const ProductInputUpBtn = styled(Image)`
  width: 7.467vw;
  height: 7.467vw;
  position: absolute;
  left: 0;
  top: 0;
`
const ProductInputDownBtn = styled(Image)`
  width: 7.467vw;
  height: 7.467vw;
  position: absolute;
  right: 0;
  top: 0;
`
const ErrorBox = styled.div`
  width: 79.067vw;
  height: 8.667vw;
  background: rgba(246, 75, 75, 0.7);
  border-radius: 2vw;
  display: flex;
  align-items: center;
`
const ErrorBoxImage = styled(Image)`
  width: 4.133vw;
  height: 4.133vw;
  margin: auto 0.533vw auto 0.933vw;
`
const ErrorVal = styled.div`
  font-size: 2vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 8.667vw;
`
const Buy = styled(Button)`
  width: 79.067vw;
  height: 10.667vw;
  background: #5c7fff;
  border-radius: 5.333vw;
  font-size: 4.8vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  bottom: 4.4vw;
  left: 4.267vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
export default ProductDetailsH5
/* eslint-disable */
// @ts-nocheck
