import Image from 'components/Image'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect } from 'react'
import useSize from 'hooks/useSize'
import FlexBox from 'components/FlexBox'
import Media from 'react-media'
import BenefitsH5 from './BenefitsH5'

const Benefits = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: '.benefits-wrap', start: 'top-=800' } })
      tl.fromTo('.benefits-title-style', { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo('.benefits001', { opacity: 0 }, { opacity: 1, duration: 0.5 })
      tl.fromTo('.benefits002', { opacity: 0 }, { opacity: 1, duration: 0.5 })
      tl.fromTo('.benefits003', { opacity: 0 }, { opacity: 1, duration: 0.5 })
    }
  }, [pcSize])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="benefits-wrap">
            <Title className="benefits-title-style">Benefits</Title>
            <FlexBox between sx={{ margin: '0 21.458vw 0 18.854vw', width: '59.688vw' }}>
              <BenefitsBox className="benefits001">
                <BenefitsImg folder="nft" src="benefits001@2x" />
                <BenefitsText>NFT Royalties</BenefitsText>
              </BenefitsBox>
              <BenefitsBox className="benefits002">
                <BenefitsImg folder="nft" src="benefits002@2x" />
                <BenefitsText colors="#29886E">Airdrop Gifts</BenefitsText>
              </BenefitsBox>
              <BenefitsBox className="benefits003">
                <BenefitsImg folder="nft" src="benefits003@2x" />
                <BenefitsText colors="#0089BC">Metaverse Merch</BenefitsText>
              </BenefitsBox>
            </FlexBox>
            <Balloon folder="nft" src="benefits004@2x" />
          </Wrap>
        ) : (
          <BenefitsH5></BenefitsH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  height: 36.302vw;
  width: 100%;
  position: relative;
`
const Title = styled.div`
  text-align: center;
  color: #7893be;
  line-height: 1.8vw;
  font-size: 2.5vw;
  margin: 0 auto 3.229vw;
`
const BenefitsImg = styled(Image)`
  height: 18.906vw;
  width: 18.021vw;
`
const Balloon = styled(Image)`
  position: absolute;
  right: 4.063vw;
  top: 24.896vw;
  height: 20.677vw;
  width: 11.51vw;
`
const BenefitsBox = styled.div`
  position: relative;
  height: 18.906vw;
  width: 18.021vw;
`
const BenefitsText = styled.div<{ colors?: string }>`
  position: absolute;
  right: 0%;
  bottom: 0;
  height: 4.367vw;
  width: 15.53vw;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-around;
  flex-direction: column;
  font-size: 1.615vw;
  line-height: 1.615vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: ${({ colors }) => (colors?.includes('#') ? colors : '#754ba4')};
`

export default Benefits
