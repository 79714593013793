
/* eslint-disable */
import { useMemo } from "react"
import useWeb3 from "hooks/useWeb3"
import { getAssetsSaleWithCMP, getErc20Contract, getEthLandSaleWithETHAndDAI, getLandSaleWithETHAndDAI } from "utils/web3Utils"

export const useERC20 = (address: string) => {
  const web3 = useWeb3()
  return useMemo(() => getErc20Contract(address, web3), [address, web3])
}

// cmp
export const useLandSaleWithETHAndDAI = (contractAddress: any) => {
  const web3 = useWeb3()
  return useMemo(() => getLandSaleWithETHAndDAI(contractAddress, web3), [web3])
}
export const useAssetsSaleWithCMP = (contractAddress: any) => {
  const web3 = useWeb3()
  return useMemo(() => getAssetsSaleWithCMP(contractAddress, web3), [web3])
}

// eth
export const useEthLandSaleWithETHAndDAI = (contractAddress: any) => {
  const web3 = useWeb3()
  return useMemo(() => getEthLandSaleWithETHAndDAI(contractAddress, web3), [web3])
}




/* eslint-disable */

