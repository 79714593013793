export const ImgInfo = [
  {
    url: 'p1@2x',
    name: '2022',
    class: 'Q1',
    lineClass: 'Q1Line',
    CompleteStatus: true,
    CurrentProgress: false,
    content1: 'Project launch',
    content2: 'Gameplay design',
    content3: 'Concept art design',
    content4: 'Official website',
    content5: '',
    content6: '',
  },
  {
    url: 'p2@2x',
    name: '2023.H1',
    class: 'Q2',
    lineClass: 'Q2Line',
    CompleteStatus: true,
    CurrentProgress: false,
    content1: 'Skytopia: Party planet pivate demo',
    content2: 'Skytopian Residents launch',
    content3: 'Fundraising',
    content4: '',
    content5: '',
    content6: '',
  },
  {
    url: 'p3@2x',
    name: '2023.H2',
    class: 'Q3',
    lineClass: 'Q3Line',
    CompleteStatus: true,
    CurrentProgress: false,
    content1: 'Marketing Kickoff',
    content2: 'Skytopia: Party planet alpha',
    content3: '',
    content4: '',
    content5: '',
    content6: '',
  },
  {
    url: 'p4@2x',
    name: '2024.Q1',
    class: 'Q4',
    lineClass: 'Q4Line',
    CompleteStatus: false,
    CurrentProgress: true,
    content1: 'Party planet website',
    content2: 'Major Pass NFT launch',
    content3: 'Skytopia whitepaper',
    content4: '',
    content5: '',
    content6: '',
  },
  {
    url: 'p5@2x',
    name: '2024.Q2',
    class: 'Q5',
    lineClass: 'Q5Line',
    CompleteStatus: false,
    CurrentProgress: false,
    content1: 'Skytopia universe launch',
    content2: 'NFT staking',
    content3: 'Skytopia: Party planet beta',
    content4: 'Skyland NFT launch',
    content5: '',
    content6: '',
  },
  {
    url: 'p6@2x',
    name: '2024.Q3',
    class: 'Q6',
    lineClass: 'Q6Line',
    CompleteStatus: false,
    CurrentProgress: false,
    content1: 'Token launch',
    content2: 'Skycity system',
    content3: 'Governance system',
    content4: '',
    content5: '',
    content6: '',
  },
  {
    url: 'p6@2x',
    name: '2024.Q4',
    class: 'Q7',
    lineClass: 'Q7Line',
    CompleteStatus: false,
    CurrentProgress: false,
    content1: 'Skytopia: Mysterious village ptivate demo',
    content2: 'Skytopia SDK design',
    content3: '',
    content4: '',
    content5: '',
    content6: '',
  },
  {
    url: 'p6@2x',
    name: '2025.H1',
    class: 'Q8',
    lineClass: 'Q8Line',
    CompleteStatus: false,
    CurrentProgress: false,
    content1: 'Skytopia: Mysterious village aplha',
    content2: 'Skytopia SDK beta',
    content3: '',
    content4: '',
    content5: '',
    content6: '',
  },
  {
    url: 'p6@2x',
    name: '2025.H2',
    class: 'Q9',
    lineClass: 'Q9Line',
    CompleteStatus: false,
    CurrentProgress: false,
    content1: 'Skytopia: Mysterious village beta',
    content2: 'Skytopia builder plan',
    content3: 'Third party game integration',
    content4: '',
    content5: '',
    content6: '',
  },
]
