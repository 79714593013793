export const FAQInfo = [
  {
    title: "What is a Skyland?",
    content: "Skylands are the lands of CryptoSkyland metaverse, located in the MetaWorld, Skytopia.",
  },
  {
    title: "What can I do with a Skyland?",
    content:
      "The primary function of Skylands in CryptoSkyland is to be user's metaverse home. Users can participate in activities on their Skylands like customizing spaces, building communities with friends and hosting metaverse experiences including games, art galleries, interactive tours and so on.",
  },
  {
    title: "How many Skylands are there in CryptoSkyland?",
    content:
      "The total supply of Skyland NFTs is capped at 90,000. They will be brought to market in waves, starting with a limited supply of 6,000.",
  },
  {
    title: "Which blockchain?",
    content: "Caduceus, a new blockchain protocol dedicated to metaverse development.",
  },
  {
    title: "What is an Archipelago?",
    content:
      "An Archipelago is a group of Skylands that have been merged into a larger parcel. Archipelagos can be bought readily assembled or they can be created by building bridges between Skylands that are close by.",
  },
  {
    title: "What is the mint price?",
    content:
      "Coming soon. Please follow us on  <a href='https://discord.com/invite/et69qhsQAy' target='_blank' rel='noopener noreferrer'>Discord </a>and social media for updates.",
  },
  {
    title: "When will the first sale round begin?",
    content: "After the genesis avatars mint.",
  },
  {
    title: "Maximum mintable?",
    content: "5 NFT (including Archipelago) per wallet.",
  },
  {
    title: "How to get Whitelist and what are the benefits?",
    content:
      "Join the <a href='https://discord.com/invite/et69qhsQAy' target='_blank' rel='noopener noreferrer'>Discord</a>  and Finish the tasks. As a whitelist member, you will have the chance to mint Skyland NFTs at a exclusive price and before anyone else.",
  },
]
