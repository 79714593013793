import Image from 'components/Image'
import styled from '@emotion/styled'
import { useState } from 'react'
import { Box } from '@mui/material'
import { friendsInfo, selectInfo } from '../config/Residents'
import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'

const ResidentsH5 = () => {
  SwiperCore.use([Autoplay])
  const [Swipers, setSwiper] = useState<any>()
  const [big, setBig] = useState<any>(1)
  const [timer, setTimer] = useState<any>(1)
  return (
    <Wrap className="friends-wrap">
      {selectInfo.map((item, keys) => (
        <>
          <BigTeamBox key={item.name + keys} sx={{ display: keys + 1 === big ? 'block' : 'none', overflow: 'hidden' }}>
            <BigTitle style={{ color: `${item.color}` }}>{item.name}</BigTitle>
            <Image className="friends-img-select" key={keys + item.name} folder="skytopia" src={item.url}></Image>
          </BigTeamBox>
          <Tips style={{ display: keys + 1 === big ? 'block' : 'none' }}>{item.title}</Tips>
        </>
      ))}

      <Swiper
        slidesPerView={4}
        centeredSlides={true}
        loop
        freeMode={true}
        speed={250}
        initialSlide={0}
        grabCursor={true}
        loopFillGroupWithBlank={true}
        normalizeSlideIndex={true}
        onInit={(ev) => {
          setSwiper(ev)
        }}
        className="swiper-no-swiping"
        style={{
          width: '95.067vw',
          height: '26.667vw',
          boxSizing: 'border-box',
        }}
      >
        {friendsInfo.map((item, key) => (
          <SwiperSlide
            key={key}
            style={{ position: 'relative', width: '21.333vw', height: '23.467vw' }}
            onClick={() => {
              setBig(key + 1)
            }}
          >
            <Image src={item.url} folder="skytopia" className={`friends-img-style ${item.class}`} key={key + item.url}></Image>
            {key + 1 === big && <Image folder="skytopia" src="00@2x" className="skytopia-img-selected-style" />}
            <ImgName style={{ color: `${item.color}` }}>{item.name}</ImgName>
          </SwiperSlide>
        ))}
      </Swiper>

      <ArrowBox>
        <Arrow
          src="left@2x.png"
          folder="skytopia"
          onClick={() => {
            if (timer === 1) {
              setTimer(0)
              let selectIndex = big
              if (selectIndex === 1) {
                selectIndex = 11
              }
              setBig(selectIndex - 1)
              Swipers.slidePrev()
              setTimeout(function () {
                setTimer(1)
              }, 350)
            }
          }}
        ></Arrow>
        <span>
          {big}/{10}
        </span>
        <Arrow
          src="right@2x.png"
          folder="skytopia"
          onClick={() => {
            if (timer === 1) {
              setTimer(0)
              let selectIndex = big
              if (selectIndex === 10) {
                selectIndex = 0
              }
              setBig(selectIndex + 1)
              Swipers.slideNext()
              setTimeout(function () {
                setTimer(1)
              }, 350)
            }
          }}
        ></Arrow>
      </ArrowBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 138vw;
  position: relative;

  .friends-img-style {
    width: 21.333vw;
    height: 23.467vw;
    position: absolute;
    z-index: 1;
  }
  .swiper .swiper-wrapper {
    -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
    -moz-transition-timing-function: linear !important;
    -ms-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    padding-left: 0px;
  }
  /* .swiper {
    position: absolute;
    left: 9.067vw;
    top: 80vw;
  } */
  .skytopia-img-left-style {
    width: 3.2vw;
    height: 3.6vw;
    padding: 3vw;
  }
  .skytopia-img-right-style {
    width: 3.2vw;
    height: 3.6vw;
    padding: 3vw;
  }
  .skytopia-img-selected-style {
    width: 21.333vw;
    height: 23.467vw;
    position: absolute;
    top: 0;
    left: 0vw;
  }
`

const BigTeamBox = styled(Box)`
  width: 95.2vw;
  height: 73.733vw;
  margin: 0 auto;
  position: relative;
  .friends-img-select {
    width: 73.733vw;
    height: 73.733vw;
    position: absolute;
    left: 10.8vw;
    top: 0;
  }
`
const BigTitle = styled.div<{ ml?: boolean }>`
  width: 95.2vw;
  height: 34.133vw;
  font-size: 28.133vw;
  font-family: Insaniburger with Cheese;
  font-weight: 700;
  line-height: 28.133vw;
  margin-top: 24.133vw;
  text-align: center;
`
const Tips = styled.div`
  width: 81.467vw;
  height: 67.467vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #3d3d3d;
  line-height: 5.067vw;
  margin: 4.667vw auto 3.6vw;
`
const Title = styled.div`
  width: 60%;
  font-size: 7.467vw;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -30%;
  text-align: center;
  color: #7893be;
  z-index: 1;
`
const ImgName = styled.div`
  text-align: center;
  width: fit-content;
  font-size: 4.133vw;
  width: 60%;
  position: absolute;
  bottom: -2vw;
  left: 50%;
  margin-left: -30%;
`
const ArrowBox = styled.div`
  width: 69.733vw;
  height: 10.4vw;
  margin: 8.667vw auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3.2vw;
  font-family: Onest-Medium, Onest;
  font-weight: 500;
  color: #767676;
  line-height: 4vw;
`
const Arrow = styled(Image)`
  width: 8.8vw;
  height: 8.8vw;
`
export default ResidentsH5
