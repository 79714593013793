/* eslint-disable */
import React, { useContext } from 'react'
import Box from '@mui/system/Box'
import Modal from 'components/Modal/H5Modal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import Image from 'components/Image'

import { LeftList } from 'config/Nav'
import { useNavigate } from 'react-router'

const H5LoginModal: React.FC<any> = (props) => {
  const navigate = useNavigate()
  const { onClose } = useContext(ModalContext)
  const title = 'My account'

  const content = (
    <Box sx={{ marginTop: '6.133vw' }}>
      {LeftList.map(({ name, router }) => (
        <ListItem
          key={name}
          onClick={() => {
            navigate(router)
            onClose()
          }}
        >
          <ListIcon folder="components" src={`${name}Icon.png`} />
          <ListName>{name}</ListName>
        </ListItem>
      ))}
    </Box>
  )
  return (
    <Modal
      title={title}
      content={content}
      sx={{
        width: '100vw',
        minHeight: '105.867vw',
      }}
    />
  )
}

const ListItem = styled.div`
  display: flex;
  width: 100%;
  height: 15.467vw;
  padding: 0 3.733vw;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
`
const ListIcon = styled(Image)`
  width: 11.2vw;
  height: 11.2vw;
  margin-right: 4.8vw;
`
const ListName = styled.div`
  height: 5.067vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 5.067vw;
`
export default H5LoginModal
/* eslint-disable */
