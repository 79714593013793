import { getmultDisplayBalance } from './formatBalance'
import BigNumber from 'bignumber.js'
import Message from './message'

// buyNFT
export const buyLandWithETH = async (sousChefContract: any, account: any, tokenId: any, uAmount: any, salt: any, proofs: any) => {
  const proofsArray = proofs.split(',')
  return await sousChefContract.methods
    .buyNFTWithCMP(account, account, tokenId, getmultDisplayBalance(new BigNumber(uAmount), 18).toFixed(), salt, proofsArray)
    .send({ from: account, value: getmultDisplayBalance(new BigNumber(uAmount), 18) })
    .on('transactionHash', (tx: any) => {
      Message.warn('Confirming transaction, please wait for a second.')
      return tx.transactionHash
    })
}

// 转账
export const transferFPU = async (sousChefContract: any, account: any, CollectionAddress: any, uAmount: any) => {
  return await sousChefContract.methods
    .transfer(CollectionAddress, new BigNumber(uAmount).multipliedBy(new BigNumber(10).pow(18)).toFixed())
    .send({ from: account })
    .on('transactionHash', (tx: any) => {
      Message.warn('Confirming transaction, please wait for a second.')
      return tx.transactionHash
    })
}

// 商城
export const buyAssetsWithCMP = async (sousChefContract: any, account: any, buyCount: any, name: any,price:any) => {
  return await sousChefContract.methods
    .buyAssetsWithCMP(account, name, buyCount)
    .send({ from: account, value: getmultDisplayBalance(new BigNumber(buyCount).multipliedBy(price), 18) })
    .on('transactionHash', (tx: any) => {
      Message.warn('Confirming transaction, please wait for a second.')
      return tx.transactionHash
    })
}
