// @ts-nocheck
import React, { createContext, useState } from "react"

export type OpenHandler = () => void
export type CloseHandler = () => void

interface ModalContextType {
  onOpen: (node: React.ReactNode) => void
  onClose: CloseHandler
}

export const ModalContext = createContext<ModalContextType>({
  onOpen: () => null,
  onClose: () => null,
})

const ModalProvider: React.FC = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false)
  const [ModalNode, setModalNode] = useState<React.ReactNode>()
  const handleOpen = (modal?: React.ReactNode) => {
    setModalNode(modal)
    setOpen(true)
  }
  const handleClose = () => {
    setModalNode(undefined)
    setOpen(false)
  }
  const showModal = open && React.isValidElement(ModalNode)
  return (
    <ModalContext.Provider
      value={{
        onOpen: handleOpen,
        onClose: handleClose,
      }}
    >
      {showModal &&
        React.cloneElement(ModalNode, {
          onClose: handleClose,
          open,
        })}
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
