import { ReactNode } from "react"
import Constants from "config/constants"

const { CHAIN_CACHE_KEY, INIT_CHAIN, SUPPORTED_CHAINS, CHAIN_INFO } = Constants
const WalletCacheKey = "_WALLET_TYPE"

export interface WalletConnectorType {
  title: string
  icon: JSX.Element | ReactNode
  status: boolean
  Recommended?: boolean
  connect: any
}

export enum WalletList {
  OKExWallet = "OKExWallet",
  Metamask = "ConnectWallet",
  TokenPocket = "TokenPocket",
  HuobiWallet = "HuobiWallet",
  MathWallet = "MathWallet",
  ONTOWallet = "ONTO Wallet",
  Nabox = "Nabox",
  HyperPay = "HyperPay",
  BitKeep = "BitKeep",
  AoLink = "AoLink",
  Coinhub = "Coinhub",
  Bitpie = "Bitpie",
  WalletConnect = "WalletConnect",
}

export type SupportedChain = 1 | 256256 | 512512 | 5

export const hasWallet = () => Boolean(window?.ethereum)

export const WalletCache = {
  setType(value: WalletList) {
    localStorage.setItem(WalletCacheKey, value)
  },
  getType() {
    return localStorage.getItem(WalletCacheKey) || ""
  },
  removeType() {
    localStorage.removeItem(WalletCacheKey)
  },
  getChain: () => {
    const walletChain = parseInt(window?.ethereum?.chainId)
    const cache = Number(localStorage.getItem(CHAIN_CACHE_KEY))
    let correctChain = INIT_CHAIN
    if (hasWallet()) {
      if (SUPPORTED_CHAINS.includes(walletChain)) {
        correctChain = walletChain
      } else if (SUPPORTED_CHAINS.includes(cache)) {
        correctChain = cache
      }
    } else {
      if (SUPPORTED_CHAINS.includes(cache)) {
        correctChain = cache
      }
    }
    localStorage.setItem(CHAIN_CACHE_KEY, correctChain + "")
    return correctChain
  },
  getChainCache: () => {
    return Number(localStorage.getItem(CHAIN_CACHE_KEY))
  },
  setChain: (chainId: number | string | undefined | null) => {
    localStorage.setItem(CHAIN_CACHE_KEY, (chainId || INIT_CHAIN) + "")
  },
}

export const isBSC = WalletCache.getChain() === INIT_CHAIN

export const Supported_Wallets: WalletConnectorType[] = [
  {
    title: "Metamask",
    icon: "Metamask",
    status: true,
    Recommended: true,
    connect: window?.ethereum,
  },
  {
    title: "BitKeep",
    icon: "BitKeep",
    status: true,
    Recommended: true,
    connect: window?.bitkeep?.ethereum,
  },
]

export const IgnoredConnectErrors = ["NoEthereumProviderError", "t", "UserRejectedRequestError"]

export const monitorAccountsChanged = (cb: (data: any) => void) => {
  window?.ethereum?.on("accountsChanged", (accounts: any) => {
    cb(accounts)
    // window.location.reload()
  })
}
export const getChain = () => {
  return parseInt(window?.ethereum?.chainId)
}

export const monitorChainChange = (cb?: () => void) => {
  window?.ethereum?.on("chainChanged", (chain: any) => {
    if (chain) {
      const chainId = parseInt(chain, 16)
      if (SUPPORTED_CHAINS.includes(chainId)) {
        WalletCache.setChain(chainId)
      }
    }
    WalletCache.removeType()
    cb?.()
    window.location.reload()
  })
}

export const SwitchChainRequest = async (certainChain: SupportedChain, errorCB?: () => void) => {
  try {
    await window?.ethereum?.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0x" + certainChain.toString(16) }],
    })
    WalletCache.setChain(certainChain)
    return true
  } catch (e: any) {
    console.log("catch1: ", certainChain, e)
    if (e?.code === 4902) {
      try {
        await window?.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x" + certainChain.toString(16),
              chainName: CHAIN_INFO?.[SUPPORTED_CHAINS.indexOf(certainChain)]?.chainName,
              rpcUrls: [CHAIN_INFO?.[SUPPORTED_CHAINS.indexOf(certainChain)]?.rpcUrl],
            },
          ],
        })
        WalletCache.setChain(certainChain)
        return true
      } catch (addError: any) {
        console.log("catch2: ", certainChain, addError)
        return false
      }
    }
    return false
  }
}
