import React from 'react'
import { mouseEnter, mouseLeave } from 'utils/gsap'
import { S3Url } from 'api/apiList'
const Image = (props: any) => {
  return (
    <React.Fragment>
      <img
        onMouseEnter={() => props?.ismouse && mouseEnter(`.${props?.className}`)}
        onMouseLeave={() => props?.ismouse && mouseLeave(`.${props?.className}`)}
        {...props}
        src={`${S3Url}/images/${props.folder}/${props.src.split('.')[0]}.${props.src.split('.')[1] || 'png'}`}
        alt={props?.alt || 'img'}
        className={props?.className}
      />
    </React.Fragment>
  )
}

export default Image
