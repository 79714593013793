import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect, useState } from "react"
import useSize from "hooks/useSize"
import FlexBox from "components/FlexBox"
import { FAQInfo } from "../config/FAQ"
import Media from "react-media"
import FAQH5 from "./FAQH5"

const FAQ = () => {
  const { pcSize } = useSize()
  const [open, setOpen] = useState<any>(-999)
  useEffect(() => {
    if (pcSize) {
      gsap.fromTo(
        ".FAQ-title-style",
        { opacity: 0, x: -30 },
        { scrollTrigger: { trigger: ".FAQ-wrap", start: "top-=800" }, x: 0, opacity: 1, duration: 0.5 }
      )
    }
  }, [pcSize])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="FAQ-wrap">
            <Title className="FAQ-title-style">FAQ</Title>
            <FlexBox start column sx={{ margin: "0 auto", width: "57.135vw", minHeight: "25.844vw", overflowY: "auto", overflowX: "hidden" }}>
              {FAQInfo.map((item, keys) => (
                <FlexBox key={keys} start column>
                  <FlexBox between sx={{ width: "57.135vw" }}>
                    <Tips>{item.title}</Tips>
                    {open === keys ? (
                      <Balloon
                        folder="nft"
                        src="ButtonReduce@2x"
                        onClick={() => {
                          setOpen(-999)
                        }}
                      />
                    ) : (
                      <Balloon
                        folder="nft"
                        src="ButtonAdd@2x"
                        onClick={() => {
                          setOpen(keys)
                        }}
                      />
                    )}
                  </FlexBox>
                  {open === keys && <Content dangerouslySetInnerHTML={{ __html: item.content }}></Content>}
                  <Line />
                </FlexBox>
              ))}
            </FlexBox>
          </Wrap>
        ) : (
          <FAQH5></FAQH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: 27.813vw;
  padding-bottom: 8.9vw;
  width: 100%;
  position: relative;
`
const Title = styled.div`
  text-align: center;
  color: #7893be;
  line-height: 1.8vw;
  font-size: 2.5vw;
  margin: 0 auto 2.396vw;
`
const Tips = styled.div`
  width: 52.594vw;
  min-height: 2.239vw;
  font-size: 1.615vw;
  font-family: Albert Sans-Bold, Albert Sans;
  display: flex;
  align-items: center;
  padding: 0.521vw 0;
  color: #7893be;
`

const Balloon = styled(Image)`
  height: 1.615vw;
  width: 1.615vw;
  cursor: pointer;
`

const Line = styled.div`
  width: 57.135vw;
  height: 5px;
  transform: scale(1, 0.2);
  background-color: #7893be;
`
const Content = styled.div`
  width: 52.604vw;
  min-height: 2.083vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 2.031vw;
  margin-top: 0.833vw;
  a {
    color: #95add4;
  }
`

export default FAQ
