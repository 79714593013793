import React, { useRef, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { TeamInfo } from '../config/Team'
import { openNewTab } from 'utils'
import FlexBox from 'components/FlexBox'

const Carousel3D: React.FC = () => {
  const imgs = useRef<any>([])
  const [current, setCurrent] = useState(0)
  const [flag, setFlag] = useState(true)

  const len = TeamInfo.length // Update the len to the length of the images array

  useEffect(() => {
    const mlen = 3
    let limg, rimg
    for (let i = 0; i < len; i++) {
      imgs.current[i].style.visibility = 'visible'
      imgs.current[i].style.opacity = '0' // set opacity to 0
      imgs.current[i].style.transform = `translateZ(-300px)`
    }
    for (let i = 0; i < mlen; i++) {
      limg = len + current - i - 1
      rimg = current + i + 1
      if (limg >= len) {
        limg -= len
      }
      if (rimg >= len) {
        rimg -= len
      }
      if (imgs.current[limg] && imgs.current[rimg]) {
        imgs.current[limg].style.transform = `translateX(${280 * (i + 1) - (i + 1) * 0.8 * i * 30}px) translateZ(${120 - i * 30}px) rotateY(${(i + 1) * 8}deg) scale(${1 - (i + 2) * 0.1})`
        imgs.current[rimg].style.transform = `translateX(${-280 * (i + 1) + (i + 1) * 0.8 * i * 30}px) translateZ(${120 - i * 30}px) rotateY(${(i + 1) * -8}deg) scale(${1 - (i + 2) * 0.1})`
        imgs.current[limg].style.opacity = '1' // set opacity to 1
        imgs.current[rimg].style.opacity = '1' // set opacity to 1
      }
    }
    if (imgs.current[current]) {
      imgs.current[current].style.transform = `translateZ(0px)`
      imgs.current[current].style.opacity = '1' // set opacity to 1
    }
  }, [current, len])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.fromTo(entry.target, { opacity: 0, y: 100 }, { opacity: 1, y: 0, duration: 1 })
        }
      })
    })

    const elements = document.querySelectorAll('.team')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  const handleClick = (i: number) => {
    if (!flag) {
      return
    }
    setFlag(false)
    if (i === 0) {
      setCurrent(current <= 0 ? len - 1 : current - 1) // Update the current based on the length of the images array
    }
    if (i === 1) {
      setCurrent(current >= len - 1 ? 0 : current + 1) // Update the current based on the length of the images array
    }
    setTimeout(() => {
      setFlag(true)
    }, 460)
  }

  return (
    <Wrap className="box" id={'Team'}>
      <Title className="team">TEAM</Title>
      <Wrapper className="team">
        {TeamInfo.map((item, i) => (
          // <img key={i} src={item} className="image" ref={(el) => (imgs.current[i] = el)} alt="" />
          <TeamBox border={item?.borderColor} key={i} ref={(el) => (imgs.current[i] = el)}>
            <HeadImg folder="index2/Team" src={item?.url} />
            <FlexBox sx={{ position: 'absolute', right: '10px', top: '300px' }}>
              {item?.linkedin !== '' && <LinkedinImg onClick={() => openNewTab(item?.linkedin)} folder="index2/Team" src={'in.png'} />}
              {item?.twitter !== '' && <TwitterImg onClick={() => openNewTab(item?.twitter)} folder="index2/Team" src={'x.png'} />}
            </FlexBox>
            <HeadTitle>{item?.name}</HeadTitle>
            <Headjob name={item?.name}>{item?.Title}</Headjob>
            <Line></Line>
            <TeamContent>{item.content}</TeamContent>
          </TeamBox>
        ))}
      </Wrapper>

      <BtnBox>
        <ArrowBtn onClick={() => handleClick(0)}>
          <BtnImg folder="index2/Team" src="leftArrow.png" />
        </ArrowBtn>
        <SerialNumber>
          {current + 1}/{TeamInfo.length}
        </SerialNumber>
        <ArrowBtn onClick={() => handleClick(1)}>
          <BtnImg folder="index2/Team" src="rightArrow.png" />
        </ArrowBtn>
      </BtnBox>
    </Wrap>
  )
}

export default Carousel3D

const Wrap = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  margin: auto;

  .image {
    float: left;
    width: 308px;
    height: 524px;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    visibility: hidden; // Initially set visibility to hidden
    transition: transform 0.23s ease-in-out, opacity 0.23s ease-in-out;
  }
`
const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  perspective: 1400px;
  transform-style: preserve-3d;
`
const Title = styled.div`
  position: absolute;
  left: 13.229vw;
  top: 6.719vw;
  width: 13.385vw;
  height: 1.563vw;
  font-size: 2.083vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #000000;
  line-height: 1.563vw;
`
const BtnBox = styled.div`
  /* position: absolute;
  left: 50%;
  top: 46.146vw; */

  position: absolute;
  top: 85vh;
  left: 50%;
  width: 27.24vw;
  height: 4.063vw;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ArrowBtn = styled.div`
  width: 3.438vw;
  height: 3.438vw;
  cursor: pointer;
`

const BtnImg = styled(Image)`
  width: 3.438vw;
  height: 3.438vw;
`
const SerialNumber = styled.div`
  width: 3.646vw;
  font-size: 1.25vw;
  font-family: Onest-Medium, Onest;
  font-weight: 500;
  color: #767676;
  line-height: 4.063vw;
`
const TeamBox = styled.div<{ border?: string }>`
  float: left;
  width: 308px;
  height: 524px;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  visibility: hidden; // Initially set visibility to hidden
  transition: transform 0.23s ease-in-out, opacity 0.23s ease-in-out;
  border: 2px solid ${({ border }) => border};
  border-radius: 28px;
`
const TeamContent = styled.div`
  width: 282px;
  height: 132px;
  font-size: 16px;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 400;
  color: #3d3d3d;
  line-height: 22px;
  margin: 10px auto;
`
const HeadImg = styled(Image)`
  width: 282px;
  height: 282px;
  margin: 12px auto;
  display: block;
`
const LinkedinImg = styled(Image)`
  width: 35px;
  height: 35px;
  display: block;
  /* position: absolute; */
  border-radius: 50%;
  /* right: 60px; */
  /* top: 300px; */
  cursor: pointer;
`
const TwitterImg = styled(Image)`
  width: 35px;
  height: 35px;
  display: block;
  margin-left: 10px;
  /* position: absolute; */
  border-radius: 50%;

  cursor: pointer;
`

const HeadTitle = styled.div`
  width: 282px;
  height: 30px;
  font-size: 22px;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #3d3d3d;
  line-height: 30px;
  margin: 0 auto;
  white-space: nowrap;
`
const Headjob = styled.div<{ name?: string }>`
  width: 282px;
  height: 30px;
  font-size: ${({ name }) => (name === 'Edwin' ? 13 : 16)}px;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #767676;
  line-height: 30px;
  margin: 0 auto;
  white-space: nowrap;
  transform: translateY(-6px);
`
const Line = styled.div`
  width: 282px;
  height: 2px;
  background: #d8d8d8;
  margin: 0 auto 9px;
`
