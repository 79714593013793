import styled from '@emotion/styled'
import FlexBox from 'components/FlexBox'
import { Link, useNavigate } from 'react-router-dom'

interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress?: Number
}
const FooterNotification: React.FC<PreloaderProps> = ({ imageUrls }) => {
  const [di_haidao, di_fawu, logo_sketopia_zi] = imageUrls

  const navigate = useNavigate()
  const handleClose = (e: any) => {
    e.stopPropagation()
    navigate('/privacyagreement')
  }

  return (
    <Warp style={{ backgroundImage: `url(${di_fawu})` }}>
      <Yellow></Yellow>
      <Footer className="footer-animation">
        <LeftBox className="Partner">
          <FlexBox>
            <LogoImg src={di_haidao} alt="" />
            <SkyImg src={logo_sketopia_zi} alt="" />
          </FlexBox>
          <SkytopiaLabs>© 2023 Skytopia Ltd.</SkytopiaLabs>
          <Term>Term & Conditions</Term>
          <Copyright>
            Copyright 2023 <Elliptic></Elliptic>
            {/* <Terms href="#">Terms of Service</Terms>
            <Elliptic></Elliptic> */}
            <Terms onClick={handleClose}>Privacy Policy</Terms>
          </Copyright>
        </LeftBox>
      </Footer>
    </Warp>
  )
}

const Warp = styled.div`
  width: 100%;
  height: 12.708vw;
`
const Yellow = styled.div`
  width: 100vw;
  height: 0.625vw;
  background: #fad121;
`

const Footer = styled.div`
  width: 100vw;
  height: 10.104vw;
  display: flex;
  align-items: center;
  padding-left: 4.375vw;
`

const LeftBox = styled.div`
  width: 15.885vw;
  height: 8.958vw;
  margin-top: 2.031vw;
`

const LogoImg = styled.img`
  width: 3.49vw;
  height: 4.063vw;
  margin-right: 0.313vw;
`
const SkyImg = styled.img`
  width: 10.26vw;
  height: 4.063vw;
`

const SkytopiaLabs = styled.div`
  height: 0.625vw;
  font-size: 0.885vw;
  font-family: Insaniburger with Cheese;
  color: #ffffff;
  line-height: 2.604vw;
  width: 100%;
  margin: 0.313vw 0;
`
const Term = styled.div`
  width: fit-content;
  height: 1.8vw;
  font-size: 0.885vw;
  font-family: Insaniburger with Cheese;
  color: #ffffff;
  line-height: 2.604vw;
`
const Copyright = styled.div`
  width: 50vw;
  height: 1.5vw;
  font-size: 0.885vw;
  font-family: Albert Sans;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
`
const Elliptic = styled.div`
  width: 0.313vw;
  height: 0.313vw;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 0.781vw;
`
const Terms = styled.a`
  width: fit-content;
  font-size: 0.885vw;
  font-family: Albert Sans;
  font-weight: bold;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
`

export default FooterNotification
