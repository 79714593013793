import React, { useContext, useEffect, useState } from "react"
import Modal from "components/Modal"
import styled from "@emotion/styled"
import { ModalContext } from "context/ModalProvider"
import { Button } from "@mui/material"
import Image from "components/Image"
import message from "utils/message"
import FlexBox from "components/FlexBox"
import BigNumber from "bignumber.js"
import Btn from "./CodeCountdown"
import { getPersonalFun, withdrawFun, withdrawInfoFun } from "api/request"
import { getAuthCookie } from "utils/user"
import { useTokenBalance } from "hooks/useTokenBalance"
import { getBalanceNumber } from "utils/formatBalance"

const WithdrawModelH5: React.FC<any> = ({ available_amount }) => {
  const [CaduceusNum, setCaduceusNum] = useState("")
  const [emailAddress, setEmailAddress] = useState("")
  const [withdrawInfo, setWithdrawInfo] = useState<any>()
  const [Emailcode, setEmailcode] = useState("")
  const [CryptoSkylandFPU, setCryptoSkylandFPU] = useState(available_amount)
  const token = getAuthCookie()
  const { onClose } = useContext(ModalContext)
  const FPU = "0xcFc394db49D0A1582b3BF458A1789f08A5A792fe"

  const WithdrawHandler = () => {
    if (emailAddress === "") {
      message.error("Please connect the email for verification. Settings ->Email.")
      return
    } else if (Emailcode === "") {
      message.warn(" Verification code error!")
      return
    }
    withdrawFun({ token: "FPU", contract_address: FPU, code: Emailcode, amount: Number(CaduceusNum) })
      .then((res: any) => {
        message.success("Succeed!")
      })
      .catch((err: any) => {})
    setTimeout(() => {
      onClose()
    }, 1000)
  }

  const BigFPU = useTokenBalance(FPU)
  const FPUBalance = getBalanceNumber(BigFPU)
  useEffect(() => {
    if (token) {
      getPersonalFun()
        .then((data: any) => {
          setEmailAddress(data.data.email)
        })
        .catch((err: any) => {})

      withdrawInfoFun({ token: "FPU", contract_address: FPU })
        .then((res: any) => {
          setWithdrawInfo(res.data)
        })
        .catch((err: any) => {})
    }
  }, [token])

  const title = "withdraw"

  const content = (
    <Warp>
      <Title>CryptoSkyland</Title>
      <CaduceusBox>
        <input
          type="text"
          value={CaduceusNum}
          onChange={(evt: any) => {
            setCaduceusNum(evt.target.value || "")
          }}
          placeholder="0.0"
          style={{
            background: CaduceusNum ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
          }}
        />
        <FlexBox between>
          <FlexBox>
            <TokenIcon folder="setting" src="FPU.png"></TokenIcon>
            <TokenName>FPU</TokenName>
          </FlexBox>
          <FlexBox>
            <Balance>Balance:{new BigNumber(CryptoSkylandFPU).toFormat(2)}</Balance>
            {CryptoSkylandFPU > 0 && <Max onClick={() => setCaduceusNum(CryptoSkylandFPU + "")}>Max</Max>}
          </FlexBox>
        </FlexBox>
      </CaduceusBox>

      <ArrowBox>
        <ArrowIcon folder="setting" src="arrow.png"></ArrowIcon>
      </ArrowBox>

      <Title>Caduceus</Title>
      <CaduceusBox>
        <input
          type="text"
          value={Number(CaduceusNum) - withdrawInfo?.withdraw_fee > 0 ? Number(CaduceusNum) - withdrawInfo?.withdraw_fee : 0}
          disabled={true}
          placeholder="0.0"
          style={{
            background: CaduceusNum ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
          }}
        />
        <FlexBox between>
          <FlexBox>
            <TokenIcon folder="setting" src="FPU.png"></TokenIcon>
            <TokenName>FPU</TokenName>
          </FlexBox>
          <Balance>Balance:{FPUBalance}</Balance>
        </FlexBox>
      </CaduceusBox>

      <FlexBox startBetween sx={{ width: "100%", marginTop: "1.042vw" }}>
        <input
          type="text"
          value={Emailcode}
          onChange={(evt: any) => {
            setEmailcode(evt.target.value || "")
          }}
          maxLength={6}
          style={{
            background: Emailcode ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
            width: "47.467vw",
            height: "7.2vw",
          }}
        />
        <Btn isable={emailAddress}></Btn>
      </FlexBox>
      <Tips>Withdrawal fee:{withdrawInfo?.withdraw_fee}FPU</Tips>
      <Tips>Minimal Withdrawal Amount: {withdrawInfo?.min_withdraw_amount}FPU</Tips>
      <Signin
        disabled={CaduceusNum.length === 0 || Number(CaduceusNum) > CryptoSkylandFPU || Number(CaduceusNum) < withdrawInfo?.min_withdraw_amount}
        onClick={() => {
          WithdrawHandler()
        }}
      >
        Withdraw
      </Signin>
    </Warp>
  )
  return (
    <Modal
      title={title}
      content={content}
      sx={{
        width: "78.667vw",
        minHeight: "70vw",
      }}
    />
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4.267vw 5.467vw 4vw;
  position: relative;
  input {
    width: 67.733vw;
    height: 10vw;
    background: rgba(92, 127, 255, 0.5);
    border-radius: 3vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-size: 3.733vw;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    display: block;
    margin-bottom: 2vw;
    box-sizing: border-box;
    padding-left: 2.8vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.733vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  width: 67.6vw;
  height: 9vw;
  border-radius: 3.6vw;
  background: #5c7fff;
  font-size: 2.933vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 5vw auto 0;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-bottom: 1.042vw;
  font-size: 2.667vw;
`
const Tips = styled.div`
  width: 100%;
  text-align: right;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-top: 1.042vw;
  font-size: 2.667vw;
`

const CaduceusBox = styled.div`
  width: 100%;
  min-height: 10vw;
`

const TokenIcon = styled(Image)`
  width: 5vw;
  height: 5vw;
  margin-right: 0.4vw;
`
const TokenName = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  font-size: 2.667vw;
  color: rgba(92, 127, 255, 0.7);
`
const Balance = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  font-size: 2.667vw;
  color: rgba(92, 127, 255, 0.7);
`
const ArrowBox = styled.div`
  width: 10vw;
  height: 10vw;
  background-color: rgba(92, 127, 255, 0.3);
  border-radius: 50%;
  margin: 1.5vw auto;
`
const ArrowIcon = styled(Image)`
  width: 7vw;
  height: 7vw;
  margin-left: 1.5vw;
  margin-top: 1.5vw;
`
const Max = styled(Button)`
  width: 13vw;
  height: 5vw;
  background: #5c7fff;
  font-size: 0.8vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin-left: 0.521vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
export default WithdrawModelH5
