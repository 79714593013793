import React, { CSSProperties, useContext, useState } from 'react'
import Modal, { ModalProps } from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import styled from '@emotion/styled'
import Image from 'components/Image'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Box from '@mui/system/Box'
import { ModalContext as Context } from 'context/ModalProvider'
import { gsap } from 'gsap'
import useSize from 'hooks/useSize'

type StyledModalProps = Pick<ModalProps, 'sx'> & {
  title?: string | JSX.Element
  content: JSX.Element
  open?: boolean
  onClose?: (e?: any) => void
}

const NewModal: React.FC<StyledModalProps> = ({ open = true, onClose, title, content, sx }) => {
  const { pcSize } = useSize()

  const MouseEnter = (value: string) => {
    gsap.fromTo(`.${value}`, { opacity: 1 }, { opacity: 0, duration: 0.5 })
    gsap.fromTo(`.${value}show`, { opacity: 0.5 }, { opacity: 1, duration: 0.5 })
  }

  const MouseLeave = (value: string) => {
    gsap.fromTo(`.${value}`, { opacity: 0.5 }, { opacity: 1, duration: 0.5 })
    gsap.fromTo(`.${value}show`, { opacity: 1 }, { opacity: 0, duration: 0.5 })
  }

  const [display, setDisplay] = useState<boolean>(true)
  const { onClose: closeHandler } = useContext(Context)
  const initBoxStyle: CSSProperties = {
    color: '#FFF',
    background: 'rgba(255, 255, 255, 1)',
    borderRadius: '0.938vw',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    maxWidth: '100%',
    zIndex: 2,
    paddingBottom: '3.542vw',
    overflow: 'hidden',
  }
  const handlerModalClose = (e: any) => {
    closeHandler()
    setDisplay(false)
    onClose?.(e)
  }

  const styles: any = sx ? { ...initBoxStyle, ...sx } : initBoxStyle

  return (
    <Modal
      open={Boolean(open) && display}
      onClose={handlerModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
    >
      <Fade in={open}>
        <Box sx={styles}>
          {title && <StyledTitle variant="h3">{title}</StyledTitle>}
          <ModalContent className="styled_modal_content_wrap">{content}</ModalContent>

          <CloseIconShow
            onMouseEnter={() => {
              MouseEnter('close')
            }}
            onMouseLeave={() => {
              MouseLeave('close')
            }}
            className="closeshow"
            folder="index2/LoginModal"
            src="btn_close_dk@2x.png"
            onClick={handlerModalClose}
          />
          <CloseIcon className="close" folder="index2/LoginModal" src="btn_close@2x.png" onClick={handlerModalClose} />
        </Box>
      </Fade>
    </Modal>
  )
}

const StyledTitle = styled(Typography)`
  width: calc(100% + 2px);
  min-height: calc(1.146vw + 1px);
  border-radius: 5.104vw 5.104vw 0 0;
  font-size: 2.083vw;
  color: #3d3d3d;
  font-family: Albert Sans-Bold, Albert Sans;
  text-align: center;
  margin: 1.042vw auto 1.25vw;
  /* position: absolute; */
  z-index: 2;

  @media (max-width: 1000px) {
    height: calc(15.067vw + 1px);
    line-height: 15.067vw;
    border-radius: 8vw 8vw 0 0;
    font-size: 4.8vw;
  }
`

const ModalContent = styled(Box)`
  position: relative;
  background-color: #fff;
  border-radius: 0.938vw;
  @media (max-width: 1000px) {
    padding-bottom: 2.667vw;
  }
`
const CloseIconShow = styled(Image)`
  position: absolute;
  top: 0.521vw;
  right: 0.625vw;
  width: 1.771vw;
  height: 1.771vw;
  cursor: pointer;
  opacity: 0;
  z-index: 4;
  @media (max-width: 1000px) {
    top: -2.667vw;
    right: -8.267vw;
    width: 6.4vw;
    height: 6.4vw;
  }
`
const CloseIcon = styled(Image)`
  position: absolute;
  top: 0.521vw;
  right: 0.625vw;
  width: 1.771vw;
  height: 1.771vw;
  cursor: pointer;
  z-index: 3;
  @media (max-width: 1000px) {
    top: -2.667vw;
    right: -8.267vw;
    width: 6.4vw;
    height: 6.4vw;
  }
`

export default NewModal
export const ModalContext = Context
