import Image from 'components/Image'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect } from 'react'
import useSize from 'hooks/useSize'
import Media from 'react-media'
import PersonalSpaceH5 from './PersonalSpaceH5'

const PersonalSpace = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: '.personal-space-wrap', start: 'top-=800' } })
      tl.fromTo('.personal-space-title-style', { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo('.personal-space-title-Tips', { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo('.personal-space-banner', { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="personal-space-wrap">
            <Title className="personal-space-title-style">your personal space in the metaverse</Title>
            <Tips className="personal-space-title-Tips">Customize space, raise digital pets and party with your friends in your skyland.</Tips>
            <Banner className="personal-space-banner" folder="nft" src="banner" />
            <Lollipop folder="nft" src="lollipop" />
            <Stump folder="nft" src="stump" />
          </Wrap>
        ) : (
          <PersonalSpaceH5></PersonalSpaceH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  height: 57.552vw;
  width: 100%;
  position: relative;
`
const Title = styled.div`
  text-align: center;
  color: #7893be;
  z-index: 1;
  line-height: 1.8vw;
  font-size: 2.5vw;
  margin: 0 auto 1.979vw;
`
const Tips = styled.div`
  text-align: center;
  font-size: 1.615vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin: 0 auto 3.073vw;
  span {
    color: #b0d474;
  }
  p {
    color: #38e2b3;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  div {
    color: #32e5ef;
    display: inline-block;
  }
`
const Banner = styled(Image)`
  height: 38.594vw;
  width: 68.594vw;
  margin: 0 auto;
`
const Lollipop = styled(Image)`
  position: absolute;
  right: 0;
  top: -17.161vw;
  height: 25.052vw;
  width: 11.302vw;
`
const Stump = styled(Image)`
  position: absolute;
  left: 0;
  bottom: -6.38vw;
  height: 19.844vw;
  width: 15.938vw;
`

export default PersonalSpace
