import React, { useContext, useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Button } from '@mui/material'
import FlexBox from 'components/FlexBox'
import Btn from './CodeCountdown'
import { reset_pwdFun } from 'api/request'
import message from 'utils/message'
import Media from 'react-media'
import ForgotPasswordModelH5 from './ForgotPasswordModelH5'

interface ForgotPasswordModelProps {
  mobile_or_email?: string
  isAuthenticated?: boolean
}

const ForgotPasswordModel: React.FC<ForgotPasswordModelProps> = ({ mobile_or_email, isAuthenticated = true }) => {
  const [Emailcode, setEmailcode] = useState('')

  const [email, setEmail] = useState(mobile_or_email || '')

  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const { onClose } = useContext(ModalContext)

  const ChangePsd = () => {
    if (newPassword.length < 8) {
      message.error('Passwords must have at least 8 characters.')
      return false
    }
    if (email === undefined) {
      message.error('Please provide a valid email address')
      return false
    }
    if (newPassword !== repeatPassword) {
      message.error('Passwords do not match.')
      return false
    }
    // if (!isValidPassword(newPassword)) {
    //   message.error('Support upper and lower case letters only.')
    //   return false
    // }
    reset_pwdFun({ new_password: newPassword, mobile_or_email: email, code: Emailcode })
      .then((res: any) => {
        onClose()
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const title = 'forgot password'

  const content = (
    <Warp>
      <Title style={{ display: mobile_or_email ? 'none' : 'block' }}>Email Connection:</Title>
      <input
        type="text"
        value={email}
        onChange={(evt: any) => {
          setEmail(evt.target.value || '')
        }}
        style={{
          background: email ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
          display: mobile_or_email ? 'none' : 'block',
        }}
      />

      <Title>Verification Code:</Title>
      <FlexBox startBetween sx={{ width: '100%' }}>
        <input
          type="text"
          value={Emailcode}
          onChange={(evt: any) => {
            setEmailcode(evt.target.value || '')
          }}
          maxLength={6}
          style={{
            background: Emailcode ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
            width: '17.865vw',
          }}
        />
        <Btn isable={email} isAuthenticated={isAuthenticated}></Btn>
      </FlexBox>

      <Title>New Password:</Title>
      <input
        type="password"
        value={newPassword}
        onChange={(evt: any) => {
          setNewPassword(evt.target.value || '')
        }}
        style={{
          background: newPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>Confirm New Password:</Title>
      <input
        type="password"
        value={repeatPassword}
        onChange={(evt: any) => {
          setRepeatPassword(evt.target.value || '')
        }}
        style={{
          background: repeatPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
          marginBottom: 0,
        }}
      />
      <Signin
        disabled={Emailcode.length !== 6 || newPassword.length === 0 || repeatPassword.length === 0}
        onClick={() => {
          ChangePsd()
        }}
      >
        Submit
      </Signin>
    </Warp>
  )
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Modal
            title={title}
            content={content}
            sx={{
              width: '28.646vw',
              minHeight: '31.771vw',
            }}
          />
        ) : (
          <ForgotPasswordModelH5 mobile_or_email={mobile_or_email} />
        )
      }
    </Media>
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.563vw 1.979vw 1.563vw;
  position: relative;
  input {
    width: 24.635vw;
    height: 2.604vw;
    background: rgba(92, 127, 255, 0.5);
    border-radius: 1.042vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-size: 1.354vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    line-height: 1.406vw;
    display: block;
    margin-bottom: 49.997px;
    box-sizing: border-box;
    padding-left: 0.781vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  width: 24.635vw;
  height: 3.438vw;
  background: #5c7fff;
  border-radius: 1.719vw;
  font-size: 1.354vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 1.042vw auto 0;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  font-size: 1.354vw;
  height: 1.094vw;
  line-height: 1vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-bottom: 1.042vw;
`

export default ForgotPasswordModel
