import styled from '@emotion/styled'

interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress?: Number
}
const PartyCityH5: React.FC<PreloaderProps> = ({ imageUrls }) => {
  const [CityBgH5, CityImgH5] = imageUrls

  return (
    <Warp style={{ backgroundImage: `url(${CityBgH5})` }}>
      <Title>The Party Open City</Title>
      <CityImg src={CityImgH5} alt=""></CityImg>
      <Tips>
        Skycity is a visually immersive digital metropolis, where users, as digital citizens, can freely enjoy entertainment, make transactions, and engage in social interactions.
        <br />
        Party Planet is the very first Skycity in Skytopia – join in and dive into the fun!
      </Tips>
    </Warp>
  )
}

const Warp = styled.div`
  width: 100%;
  min-height: 108vw;
  background-size: 100%;
  overflow: hidden;
`
const Title = styled.div`
  width: 63.733vw;
  height: 9.333vw;
  font-size: 6.4vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #eef2f6;
  line-height: 9.333vw;
  margin: 7.2vw 0 1.867vw 3.733vw;
`

const CityImg = styled.img`
  width: 100%;
  height: auto;
`
const Tips = styled.div`
  width: 92.533vw;
  height: 21.067vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #a9aaab;
  line-height: 5.067vw;
  margin: 6.8vw auto 0;
`
export default PartyCityH5
