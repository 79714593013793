import styled from "@emotion/styled"
import Image from "components/Image"
import { gsap } from "gsap"
import Media from "react-media"
import SkytopiaH5 from "./SkytopiaH5"
import useSize from "hooks/useSize"
import { useEffect } from "react"
import { mouseEnter, mouseLeave } from "utils/gsap"
import { SkytopiaInfo } from "../config/Skytopia"

const Skytopia = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".skytopia-wrap", start: "top-=700" } })
      tl.fromTo(".skytopia-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".skytopia-tips-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })

      tl.fromTo(".Linked-img-style-01", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".Linked-img-style-03", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".Linked-img-style-02", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".Linked-img-style-04", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="skytopia-wrap">
            <Title className="skytopia-title-style">linkable world protocol</Title>
            <Subtitle className="skytopia-tips-style">Metaworlds are connected to Skytopia, the base Metaworld of CryptoSkyland,</Subtitle>
            <Subtitle className="skytopia-tips-style"> through standard protocols.</Subtitle>

            <Image className="Linked-img-style-05" folder="about" src="03-05@2x" ></Image>

            {SkytopiaInfo.map((item, key) => {
              return (
                <div
                  key={key}
                  className={`${item?.class} buildings-img-style-box`}
                  onMouseEnter={() => {
                    mouseEnter(`.${item?.class}-img`, 1.1)
                  }}
                  onMouseLeave={() => {
                    mouseLeave(`.${item?.class}-img`)
                  }}
                >
                  <Image folder="about" src={item.url} className={`Buildings-img-style ${item?.class}-img`}></Image>
                  <Image folder="about" src={`${item.leftUrl}`} className={`Buildings-img-style`}></Image>
                </div>
              )
            })}
            <Image className="Linked-img-style-06 RotationBack" folder="about" src="06@2x"></Image>
            <SkytopiaVal>Skytopia</SkytopiaVal>
          </Wrap>
        ) : (
          <SkytopiaH5 />
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 54.583vw;
  position: relative;
  .buildings-img-style-box {
    width: 20.104vw;
    height: 11.406vw;
    overflow: hidden;
    border-radius: 1.042vw;
    img {
      height: 11.406vw;
      width: auto;
    }
    .Buildings-img-style {
      position: absolute;
      z-index: 2;
    }
  }
  .Linked-img-style-01 {
    position: absolute;
    left: 14.792vw;
    top: 9.375vw;
  }
  .Linked-img-style-02 {
    position: absolute;
    left: 65vw;
    top: 9.375vw;
  }
  .Linked-img-style-03 {
    position: absolute;
    left: 14.792vw;
    top: 30.26vw;
  }
  .Linked-img-style-04 {
    position: absolute;
    left: 65vw;
    top: 30.26vw;
  }

  .Linked-img-style-05 {
    width: 34.583vw;
    height: 20.885vw;
    position: absolute;
    left: 32.708vw;
    top: 16.719vw;
  }
  .Linked-img-style-06 {
    width: 31.25vw;
    height: 31.25vw;
    position: absolute;
    left: 34.375vw;
    top: 11.615vw;
  }
  @keyframes rotationback {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 20s linear infinite;
  }
`
const Title = styled.div`
  font-size: 2.5vw;
  line-height: 2vw;
  margin-bottom: 1.875vw;
  text-align: center;
  color: #7893be;
`
const Subtitle = styled.div`
  font-size: 1.094vw;
  line-height: 1.094vw;
  margin-top: 0.625vw;
  text-align: center;
  color: #95add4;
  font-family: Albert Sans-Bold, Albert Sans;
`
const SkytopiaVal = styled.div`
  font-size: 1.927vw;
  line-height: 1.458vw;
  position: absolute;
  left: 45.677vw;
  top: 26.406vw;
  z-index: 1;
`
export default Skytopia
