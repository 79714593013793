// @ts-nocheck
/* eslint-disable */
import Image from "components/Image"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { getproofFun, storeOrderFun } from "api/request"
import FlexBox from "components/FlexBox"
import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom"
import BigNumber from "bignumber.js"
import { getBalanceNumber } from "utils/formatBalance"
import { useWeb3React } from "@web3-react/core"
import useWeb3 from "hooks/useWeb3"
import { useAssetsSaleWithCMP } from "hooks/useContract"
import message from "utils/message"
import { buyAssetsWithCMP, buyLandWithETH } from "utils/BuyNFT"
import useModal from "hooks/useModal"
import Navigation from "./Navigation"
import Loading from "./Loading"
import Media from "react-media"
import ProductDetailsH5 from "./ProductDetailsH5"

const ProductDetails = () => {
  const [productListInfo, setProductList] = useState<any>()
  const [buyCount, setBuyCount] = useState<any>(1)
  const [ErrColose, setErrColose] = useState<any>(false)
  const [loadingState, setLoadingState] = useState<any>(true)
  const [CMPBanlance, setCMPBanlance] = useState<any>()

  var { account } = useWeb3React()
  const web3 = useWeb3()
  const [ChangeNavigation] = useModal(<Navigation />)
  const [ChangeLoading] = useModal(<Loading loadingState={loadingState} />)
  // 交易合约
  const [requestedApproval, setRequestedApproval] = useState(false)

  useEffect(() => {
    const getBalance = async () => {
      const balance = await web3.eth.getBalance(`${account}`)
      setCMPBanlance(getBalanceNumber(new BigNumber(balance), 18))
    }
    account && getBalance()
  }, [account])

  const navigate = useNavigate()
  const handleToDetails = (e: any) => {
    e.stopPropagation()
    navigate("/store")
  }

  useEffect(() => {
    setProductList(JSON.parse(localStorage.getItem("productInfo")))
  }, [])
  const LandSaleWithETHAndDAIContract = useAssetsSaleWithCMP(localStorage.getItem("StoreContract"))

  const handleWalletBuy = async () => {
    if (account?.toLocaleLowerCase() !== localStorage.getItem("account")?.toLocaleLowerCase()) {
      message.warn("Connected address does not match. Please switch to your binding address.")
      return false
    }
    try {
      setRequestedApproval(true)
      await buyAssetsWithCMP(LandSaleWithETHAndDAIContract, account, buyCount, productListInfo?.asset_name_byte32, productListInfo?.price)
      ChangeNavigation()
      setRequestedApproval(false)
    } catch (error) {
      message.error("Buy Error.")
      setRequestedApproval(false)
    }
  }

  // const handleAssetsBuy = async () => {
  //   storeOrderFun()
  //     .then((res: any) => {
  //       setProductList(res.data.list)
  //     })
  //     .catch((err: any) => {})
  // }
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="product-wrap">
            <GoBack onClick={handleToDetails}>GO BACK</GoBack>
            <Title>STORE</Title>
            <FlexBox startBetween sx={{ width: "78.958vw", margin: "0 auto" }}>
              <ProductBox>
                <ProductTitle>{productListInfo?.name}</ProductTitle>
                <ProductImage src={productListInfo?.image_url} alt="NFT"></ProductImage>
                <ProductTip>{productListInfo?.description} </ProductTip>
              </ProductBox>
              <SelectCurrencyBox>
                <SelectCurrencyTitle>Select Currency:</SelectCurrencyTitle>
                <SelectWalletBox>
                  <ProductPriceImage folder="setting" src={productListInfo?.pay_currency?.toUpperCase() || "CMP" + ".png"}></ProductPriceImage>
                  <div>
                    <WalletName>{productListInfo?.pay_currency}</WalletName>
                    <WalletBalance>Balance:{CMPBanlance || 0}</WalletBalance>
                  </div>
                  <SelectWalletImage folder="store" src="blueRight.png" />
                </SelectWalletBox>
                <PriceTitle>Price:</PriceTitle>
                <FlexBox startCenter sx={{ height: "2.396vw", margin: "0.521vw 0 2.031vw 0 " }}>
                  <ProductPriceImage folder="setting" src={productListInfo?.pay_currency?.toUpperCase() || "CMP" + ".png"}></ProductPriceImage>
                  <ProductPrice>{productListInfo?.price}</ProductPrice>
                </FlexBox>
                <SelectCurrencyTitle>Quantity:(up to ∞ )</SelectCurrencyTitle>
                <ProductInputBox>
                  <ProductInputUpBtn
                    folder="store"
                    src="UP.png"
                    onClick={() => {
                      let val = buyCount
                      setErrColose(false)
                      setBuyCount(val + 1)
                    }}
                  />
                  <input
                    type="tel"
                    value={buyCount}
                    onChange={(evt: any) => {
                      setBuyCount(evt.target.value || "")
                      setErrColose(false)
                    }}
                    style={{
                      background: buyCount ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
                    }}
                  />
                  <ProductInputDownBtn
                    folder="store"
                    src="DOWN.png"
                    onClick={() => {
                      if (buyCount > 1) {
                        let val = buyCount
                        setErrColose(false)
                        setBuyCount(val - 1)
                      }
                    }}
                  />
                </ProductInputBox>
                {buyCount * productListInfo?.price > Number(CMPBanlance) && !ErrColose && (
                  <ErrorBox>
                    <ErrorBoxImage
                      onClick={() => {
                        setErrColose(true)
                      }}
                      folder="store"
                      src="close.png"
                    />
                    <ErrorVal>Your balance is not enougth funds. Please deposit enough balance</ErrorVal>
                  </ErrorBox>
                )}
                <Buy
                  disabled={buyCount === 0 || requestedApproval || buyCount * productListInfo?.price > Number(CMPBanlance)}
                  onClick={() => handleWalletBuy()}
                >
                  BUY
                </Buy>
              </SelectCurrencyBox>

              <div></div>
            </FlexBox>
          </Wrap>
        ) : (
          <ProductDetailsH5></ProductDetailsH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const GoBack = styled(Button)`
  width: 9.792vw;
  height: 2.604vw;
  background: rgba(92, 127, 255, 0.7);
  border-radius: 0.521vw;
  margin: 7.604vw 0 1.042vw 9.479vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  width: 7.083vw;
  height: 1.719vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 2.604vw;
  margin: 0 0 2.083vw 9.635vw;
`
const ProductBox = styled.div`
  width: 48.75vw;
  height: 40.729vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  margin-right: 3.854vw;
  padding: 0.729vw 1.563vw 0.99vw;
  box-sizing: border-box;
`
const ProductTitle = styled.div`
  width: 100%;
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
const ProductTip = styled.div`
  width: 42.448vw;
  height: 3.906vw;
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  line-height: 1.979vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin: 0 auto;
`

const ProductImage = styled.img`
  width: 29.167vw;
  height: 29.167vw;
  display: block;
  margin: 2.083vw auto 1.927vw;
`

const SelectCurrencyBox = styled.div`
  width: 26.354vw;
  height: 34vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  padding: 0.938vw 1.51vw 1.563vw;
  box-sizing: border-box;
  position: relative;
`
const SelectCurrencyTitle = styled.div`
  width: 100%;
  font-size: 1.458vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 0.938vw;
`
const SelectWalletBox = styled.div`
  width: 23.281vw;
  height: 4.167vw;
  background: rgba(120, 147, 190, 0.5);
  border-radius: 1.042vw;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  position: relative;
`
const WalletName = styled.div`
  width: 18.229vw;
  height: 1.979vw;
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
`
const WalletBalance = styled.div`
  width: 13.073vw;
  /* height: 1.198vw; */
  font-size: 1.458vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
`
const SelectWalletImage = styled(Image)`
  width: 2.083vw;
  height: 4.115vw;
  position: absolute;
  right: 0;
  top: 0;
`
const PriceTitle = styled.div`
  font-size: 1.458vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin: 2.031vw 0 0.521vw 0;
`

const ProductPriceImage = styled(Image)`
  width: 2.396vw;
  height: 2.396vw;
  margin-right: 0.521vw;
`

const ProductPrice = styled.div`
  min-width: 2.24vw;
  font-size: 1.979vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b3c2dd;
`

const ProductInputBox = styled.div`
  width: 23.281vw;
  height: 2.604vw;
  border-radius: 0.781vw;
  margin-bottom: 1.042vw;
  position: relative;
  input {
    width: 23.281vw;
    height: 2.604vw;
    background: rgba(120, 147, 190, 0.5) !important;
    border-radius: 0.781vw;
    font-size: 1.354vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    line-height: 1.406vw;
    display: block;
    box-sizing: border-box;
    padding: 0 2.8vw 0 3vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
`
const ProductInputUpBtn = styled(Image)`
  width: 2.604vw;
  height: 2.604vw;
  position: absolute;
  left: 0;
  top: 0;
`
const ProductInputDownBtn = styled(Image)`
  width: 2.604vw;
  height: 2.604vw;
  position: absolute;
  right: 0;
  top: 0;
`
const ErrorBox = styled.div`
  width: 23.281vw;
  height: 4.844vw;
  background: rgba(246, 75, 75, 0.7);
  border-radius: 0.781vw;
  display: flex;
  align-items: center;
`
const ErrorBoxImage = styled(Image)`
  width: 2.292vw;
  height: 2.292vw;
  margin: auto 0.521vw;
`
const ErrorVal = styled.div`
  width: 19.74vw;
  height: 2.708vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 1.563vw;
`
const Buy = styled(Button)`
  width: 23.281vw;
  height: 3.438vw;
  background: #5c7fff;
  border-radius: 1.042vw;
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  bottom: 1.563vw;
  left: 1.563vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
export default ProductDetails
/* eslint-disable */
// @ts-nocheck
