import React from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import useSize from 'hooks/useSize'
import LeftTabs from 'components/LeftTabs'
import Profile from './components/Profile'
import Settings from './components/Settings'
import Wallet from './components/Wallet'
import Inventory from './components/Inventory'
import Referral from './components/Referral'
import Header from 'components/Header'
import Media from 'react-media'
import WalletH5 from './components/WalletH5'

const Setting: React.FC<any> = () => {
  const { pcSize } = useSize()
  // gsap.registerPlugin(ScrollTrigger)
  // useEffect(() => {
  //   gsap.to(".skytopia-bg-text", {
  //     y: 0.1 * -ScrollTrigger.maxScroll(window),
  //     ease: "none",
  //     scrollTrigger: {
  //       scrub: true,
  //     },
  //   })
  //   document.body.scrollTop = document.documentElement.scrollTop = 0
  // }, [])

  return (
    <Warp className="skytopiawarp">
      <Header />
      <Contentbg>
        {pcSize ? <Image folder="index" src="indexBg" className="skytopia-bg-text" /> : <Image folder="index" src="H5Bg@2x" className="skytopia-bg-text" />}
        <LeftTabs />
        {window.location.href.includes('/profile') && <Profile />}
        {window.location.href.includes('/setting') && <Settings />}
        {window.location.href.includes('/wallet') && <Media query={{ maxWidth: 1000 }}>{(matches) => (!matches ? <Wallet /> : <WalletH5></WalletH5>)}</Media>}
        {window.location.href.includes('/inventory') && <Inventory />}
        {window.location.href.includes('/referral') && <Referral />}
      </Contentbg>
    </Warp>
  )
}

const Contentbg = styled.div`
  background: #e4e1f6;
  width: 100%;
`
const Warp = styled.div`
  .skytopia-bg-text {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    display: block !important;
  }
`

export default Setting
