import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import useSize from 'hooks/useSize'
import Image from 'components/Image'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import HunbsVideo from 'components/HunbsVideo'
import HunbsImg from 'components/HunbsImg'
import HunbsHyperlinks from 'components/HunbsHyperlinks'
import FlexBox from 'components/FlexBox'
import Plate from './Plate'
import { useLocation } from 'react-router-dom'
import { HubsInfo } from '../config/hubsInfo'
import Media from 'react-media'
import IndexH5 from './indexH5'

const Hunbs: React.FC<any> = () => {
  const { pcSize } = useSize()
  gsap.registerPlugin(ScrollTrigger)
  const [isfixed, setIsFixed] = useState<boolean>(false)
  const [heightLength, setHeightLength] = useState<any>(1)

  window.onscroll = function () {
    var heightTop = document.documentElement.scrollTop || document.body.scrollTop
    const heightLength = ((document.body.scrollWidth + 17) / 1920) * 225
    setHeightLength((heightLength - heightTop) / 100)
    const PlateLength = ((document.body.scrollWidth + 17) / 1920) * 130
    if (heightTop >= PlateLength) {
      setIsFixed(true)
    } else {
      setIsFixed(false)
    }
  }
  useEffect(() => {
    gsap.to('.bg-text', {
      y: 0.1 * -ScrollTrigger.maxScroll(window),
      ease: 'none',
      scrollTrigger: {
        // trigger: ".skytopiawarp",
        scrub: true,
      },
    })
  }, [])

  const [submenu, setSubmenus] = useState<any>(1)

  const location = useLocation()

  useEffect(() => {
    if (window.location.href.includes('metaworld')) {
      setSubmenus(1)
    } else if (window.location.href.includes('village')) {
      setSubmenus(2)
    } else {
      setSubmenus(3)
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [location])
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Warp className="metaworld-warp">
            <Image folder="index" src="indexBg" className="bg-text" />
            <Title>metaworld hub</Title>
            <Contentbg>
              <FlexBox startBetween sx={{ margin: '0 11.979vw', width: '75.99vw' }}>
                <HundsContent>
                  {window.location.href.includes('/hubs/metaworld') && (
                    //  style={{ opacity: heightLength > 0 ? heightLength : 0 }}
                    <HeadBox className="metaworld-headbox">
                      <HeadImg folder="hunbs" src="1@2x"></HeadImg>
                      <FlexBox sx={{ height: '4.792vw', position: 'absolute', margin: '3.594vw 0 2.031vw 1.563vw' }}>
                        <UserImg folder="hunbs" src="game2" />
                        <FlexBox startBetween column sx={{ height: '3.5vw' }}>
                          <UserName>Skytopia</UserName>
                          <IssuingTime>Last updated Aug 18 </IssuingTime>
                        </FlexBox>
                      </FlexBox>
                    </HeadBox>
                  )}
                  {/* {window.location.href.includes("/hubs/metaworld") && (
                    <SlidingRearTop
                      style={{
                        opacity: heightLength < 0 ? 1 : 1 - heightLength,
                        background: "#94cded",
                      }}
                    >
                      <UserImg folder="hunbs" src="game2"  />
                      <UserName>Skytopia</UserName>
                    </SlidingRearTop>
                  )} */}

                  {window.location.href.includes('/hubs/village') && (
                    //  style={{ opacity: heightLength > 0 ? heightLength : 0 }}
                    <HeadBox className="metaworld-headbox">
                      <HeadImg folder="hunbs" src="2@2x"></HeadImg>
                      <FlexBox sx={{ height: '4.792vw', position: 'absolute', margin: '3.594vw 0 2.031vw 1.563vw' }}>
                        <UserImg folder="hunbs" src="game3" />
                        <FlexBox startBetween column sx={{ height: '3.5vw' }}>
                          <UserName>Mysterious Village</UserName>
                          <IssuingTime>Last updated Aug 3 </IssuingTime>
                        </FlexBox>
                      </FlexBox>
                    </HeadBox>
                  )}
                  {/* {window.location.href.includes("/hubs/village") && (
                    <SlidingRearTop
                      style={{
                        opacity: heightLength < 0 ? 1 : 1 - heightLength,
                        background: "#9B7CC9",
                      }}
                    >
                      <UserImg folder="hunbs" src="game3"  />
                      <UserName>Mysterious Village</UserName>
                    </SlidingRearTop>
                  )} */}

                  {window.location.href.includes('/hubs/party') && (
                    //  style={{ opacity: heightLength > 0 ? heightLength : 0 }}
                    <HeadBox className="metaworld-headbox">
                      <HeadImg folder="hunbs" src="3@2x"></HeadImg>
                      <FlexBox sx={{ height: '4.792vw', position: 'absolute', margin: '3.594vw 0 2.031vw 1.563vw' }}>
                        <UserImg folder="hunbs" src="game1" />
                        <FlexBox startBetween column sx={{ height: '3.5vw' }}>
                          <UserName>Party Planet</UserName>
                          <IssuingTime>Last updated Aug 22 </IssuingTime>
                        </FlexBox>
                      </FlexBox>
                    </HeadBox>
                  )}
                  {/* {window.location.href.includes("/hubs/party") && (
                    <SlidingRearTop
                      style={{
                        opacity: heightLength < 0 ? 1 : 1 - heightLength,
                        background: "#FDC2AB",
                      }}
                    >
                      <UserImg folder="hunbs" src="game1"  />
                      <UserName>Mysterious Village</UserName>
                    </SlidingRearTop>
                  )} */}

                  {HubsInfo.map((item, key) => (
                    <div key={item.id}>
                      {item.type === 1 && submenu === item.state && <HunbsVideo VideoInfo={item}></HunbsVideo>}
                      {item.type === 2 && submenu === item.state && <HunbsImg ImgInfo={item} />}
                      {/* {item.type === 3 && <HunbsHyperlinks />} */}
                    </div>
                  ))}
                </HundsContent>
                <Plate isfixed={isfixed} />
              </FlexBox>
            </Contentbg>
          </Warp>
        ) : (
          <IndexH5 />
        )
      }
    </Media>
  )
}
const Contentbg = styled.div`
  background: #e4e1f6;
`
const Warp = styled.div`
  position: relative;
  padding-bottom: 22.604vw;
  .bg-text {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0vw;
    left: 0;
    z-index: 0;
    display: block !important;
  }
`
const HundsContent = styled.div`
  width: 52.604vw;
  min-height: 70.625vw;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 2.083vw;
  padding-bottom: 18.229vw;
  position: relative;
  box-sizing: border-box;
`
const Title = styled.div`
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.8vw;
  margin: 7.604vw 0 2.135vw 13.021vw;
  position: relative;
`
const HeadBox = styled.div`
  width: 52.604vw;
  height: 10.417vw;
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
  position: relative;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2.083vw 2.083vw 0 0;
`
const HeadImg = styled(Image)`
  width: 52.604vw;
  height: 10.417vw;
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
  position: absolute;
  z-index: 0;
`
const UserImg = styled(Image)`
  width: 4.792vw;
  height: 4.792vw;
  margin-right: 0.625vw;
`
const UserName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
`
const IssuingTime = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
`

const SlidingRearTop = styled.div`
  width: 52.604vw;
  height: 5.208vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: #94cded;
  padding: 0.208vw 1.563vw;
  box-sizing: border-box;
  position: fixed;
  left: 11.979vw;
  top: 3.594vw;
`

export default Hunbs
