import React, { CSSProperties, useContext, useState } from "react"
import Modal, { ModalProps } from "@mui/material/Modal"
import Backdrop from "@mui/material/Backdrop"
import styled from "@emotion/styled"
import Image from "components/Image"
import Typography from "@mui/material/Typography"
import Fade from "@mui/material/Fade"
import Box from "@mui/system/Box"
import { ModalContext as Context } from "context/ModalProvider"
import useSize from "hooks/useSize"

type StyledModalProps = Pick<ModalProps, "sx"> & {
  title?: string | JSX.Element
  content: JSX.Element
  open?: boolean
  onClose?: (e?: any) => void
}

const StyleModal: React.FC<StyledModalProps> = ({ open = true, onClose, title, content, sx }) => {
const { pcSize } = useSize()

  const [display, setDisplay] = useState<boolean>(true)
  const { onClose: closeHandler } = useContext(Context)
  const initBoxStyle: CSSProperties = {
    color: "#FFF",
    background: "rgba(255, 255, 255, 0.9)",
    borderRadius: !pcSize ? "8vw" : "2.083vw",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    outline: "none",
    maxWidth: "100%",
  }
  const handlerModalClose = (e: any) => {
    closeHandler()
    setDisplay(false)
    onClose?.(e)
  }

  const styles: any = sx ? { ...initBoxStyle, ...sx } : initBoxStyle

  return (
    <Modal
      open={Boolean(open) && display}
      onClose={handlerModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
    >
      <Fade in={open}>
        <Box sx={styles}>
          {title && <StyledTitle variant="h3">{title}</StyledTitle>}
          <ModalContent className="styled_modal_content_wrap">{content}</ModalContent>
          <CloseIcon folder="components" src="X@2x.png" onClick={handlerModalClose} />
        </Box>
      </Fade>
    </Modal>
  )
}

const StyledTitle = styled(Typography)`
  width: calc(100% + 2px);
  height: calc(4.896vw + 1px);
  background: #5c7fff;
  border-radius: 2.083vw 2.083vw 0 0;
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 4.896vw;
  text-align: center;
  position: relative;
  top: -1px;
  left: -1px;
  @media (max-width: 1000px) {
    height: calc(15.067vw + 1px);
    line-height: 15.067vw;
    border-radius: 8vw 8vw 0 0;
    font-size: 4.8vw;
  }
`

const ModalContent = styled(Box)`
  padding-bottom: 1.094vw;
  @media (max-width: 1000px) {
    padding-bottom: 2.667vw;
  }
`
const CloseIcon = styled(Image)`
  position: absolute;
  top: -1.094vw;
  right: -3.438vw;
  width: 2.344vw;
  height: 2.344vw;
  cursor: pointer;
  @media (max-width: 1000px) {
    top: -2.667vw;
    right: -8.267vw;
    width: 6.4vw;
    height: 6.4vw;
  }
`

export default StyleModal
export const ModalContext = Context
