import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import FlexBox from "components/FlexBox"
import { getPersonalFun } from "api/request"
import useModal from "hooks/useModal"
import Editemall from "./EditemallModel"
import EditPassword from "./EditPasswordModel"
import ForgotPassword from "./ForgotPasswordModel"
import EditSettingModel from "./EditSettingModel"
import { getAuthCookie } from "utils/user"
import { getShortHash } from "utils"

const SettingsH5 = () => {
  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [update, setUpdate] = useState("")
  const token = getAuthCookie()

  const [ChangeEmailAddress] = useModal(<Editemall setUpdate={setUpdate} mobile_or_email={Email} />)
  const [ChangePassword] = useModal(<EditPassword mobile_or_email={Email} />)
  const [ChangeForgotPassword] = useModal(<ForgotPassword mobile_or_email={Email} />)
  const [EditSetting] = useModal(<EditSettingModel setUpdate={setUpdate} />)

  useEffect(() => {
    if (token) {
      getPersonalFun()
        .then((data: any) => {
          setEmail(data.data.email)
          if (data.data.email !== "") {
            setPassword("111111")
          }
        })
        .catch((err: any) => {})
    }
  }, [update, token])

  return (
    <Wrap>
      <Title>ACCOUNT SETTINGS</Title>
      <SettingsBox>
        <IconTips>Email:</IconTips>
        <input type="text" value={getShortHash(Email, 4, 6)} disabled />

        <FlexBox between sx={{ width: "70.667vw", margin: "2.667vw auto" }}>
          <Edit></Edit>
          {Email === "" ? (
            <Edit
              onClick={() => {
                EditSetting()
              }}
            >
              connect
            </Edit>
          ) : (
            <Edit
              onClick={() => {
                ChangeEmailAddress()
              }}
            >
              Edit
            </Edit>
          )}
        </FlexBox>
        <IconTips>Password</IconTips>
        <input type="password" value={Password} disabled />

        <FlexBox between sx={{ width: "70.667vw", margin: "2.667vw auto 0" }}>
          {Email === "" ? (
            <div></div>
          ) : (
            <>
              <Edit
                onClick={() => {
                  ChangePassword()
                }}
              >
                Edit
              </Edit>
              <Forgot
                onClick={() => {
                  ChangeForgotPassword()
                }}
              >
                Forgot
              </Forgot>
            </>
          )}
        </FlexBox>
      </SettingsBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh - 33.867vw);
  width: 100%;
  position: relative;
  overflow: hidden;
  input {
    background: rgba(120, 147, 190, 0.5);
    outline: none;
    padding-left: 3.6vw;
    font-size: 3.2vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
    line-height: 9.067vw;
    box-sizing: border-box;
    width: 73.333vw;
    height: 9.067vw;
    border: 0;
    border-radius: 2vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`
const Title = styled.div`
  font-size: 7.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 7vw;
  text-align: center;
  margin: 19.067vw auto 24.8vw;
`

const SettingsBox = styled.div`
  margin: 0vw auto;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
  padding: 4.267vw 5.333vw 5.333vw;
  position: relative;
  width: 83.867vw;
  min-height: 52.133vw;
  border-radius: 2vw;
`
const IconTips = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 2.667vw;
  font-size: 3.467vw;
`
const Edit = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  cursor: pointer;
  height: 2.933vw;
  font-size: 3.2vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #86a0fe;
`
const Forgot = styled.div`
  min-width: 3.646vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  cursor: pointer;
`

export default SettingsH5
