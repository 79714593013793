import React from 'react'
import styled from '@emotion/styled'

const DownLoadH5: React.FC = () => {
  return (
    <Wrap>
      <Foes>4 Skytopian Friends or Foes</Foes>
      <Modes>Endless Exciting Gameplay Modes </Modes>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 22.4vw;
  position: relative;
  background-color: rgb(235, 81, 93);
  overflow: hidden;
`
const Foes = styled.div`
  margin: 5vw auto 3vw;
  width: fit-content;
  font-size: 5.333vw;
  font-family: Milky Nice-Regular, Milky Nice;
  font-weight: 400;
  color: #49ffdb;
`
const Modes = styled.div`
  width: fit-content;
  font-size: 5.333vw;
  font-family: Milky Nice-Regular, Milky Nice;
  font-weight: 400;
  color: #f7f41f;
  margin: 0 auto;
`

export default DownLoadH5
