import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import { partyplanetFun } from 'api/request'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'

const GameListH5 = () => {
  const [WinInfo, setWinInfo] = useState<any>([])
  const [MacInfo, setMacInfo] = useState<any>([])
  const [AndroidInfo, setAndroidInfo] = useState<any>([])
  const [IosInfo, setIosInfo] = useState<any>([])
  const navigate = useNavigate()
  const handleGame = (e: any) => {
    e.stopPropagation()
    navigate('/game')
  }
  useEffect(() => {
    const getdownloadInfo = () => {
      partyplanetFun()
        .then((res: any) => {
          if (res.data.length > 0) {
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index]
              switch (element.platform) {
                case 'pc':
                  setWinInfo(res.data[index])
                  break
                case 'mac':
                  setMacInfo(res.data[index])
                  break
                case 'android':
                  setAndroidInfo(res.data[index])
                  break
                case 'ios':
                  setIosInfo(res.data[index])
                  break
              }
            }
          }
        })
        .catch((err: any) => {})
    }
    getdownloadInfo()
  }, [])

  return (
    <Wrap>
      <Box>
        {/* city1 */}
        <GameListTextBox className='GameListtextbox'>
          <Game className='exit-animation' marginTop='city1'>
          MarsParty
          </Game>
          <CityImg position='left' folder='index2/GameListH5' className='city1' src='city1.png' />

          <BigTitleImg folder='download' className='exit-animation' src='marsxyz.png' />
          <SmallTitle className='exit-animation'>Join the party with Skytopians and friends!</SmallTitle>
          <Tips className='exit-animation'>
            <div>Compete and cooperate with various participants in different themed party games. </div>
            <div>Master rules of different parties and beat other players. </div>
            <div>Striving for dopamine!</div>
          </Tips>
          {/* <CityBottom className='exit-animation' onClick={handleGame}>
            Go
          </CityBottom> */}

          {/* <UnStartBtn className='exit-animation'  onClick={handleGame}>Go</UnStartBtn> */}

          {
          <DownLoadBox>

            <LogoBox href={AndroidInfo?.download} target="_blank" rel="noopener noreferrer">
              <SaveButton>
                <Logo className="androidLogo" folder="download" src="btn_Android.png"></Logo>
              </SaveButton>
            </LogoBox>

            <LogoBox href={IosInfo?.download} target="_blank" rel="noopener noreferrer">
              <SaveButton>
                <Logo className="iosLogo" folder="download" src="btn_iOS@2x.png"></Logo>
              </SaveButton>
            </LogoBox>

            <LogoBox href={WinInfo?.download} target="_blank" rel="noopener noreferrer">
              <SaveButton>
                <Logo className="winLogo" folder="download" src="btn_w@2x.png"></Logo>
              </SaveButton>
            </LogoBox>

            
          </DownLoadBox>
          }
        </GameListTextBox>
      </Box>
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100%;
  height: 250vw;
  position: relative;
`
const Box = styled.div`
  width: 100%;
  height: 100%;
  // border-radius: 9.067vw;
  background-color: #1e2020;
  position: relative;
  overflow: hidden;
`

const GameListTextBox = styled.div`
  width: 100%;
  height: 100%;
`
const Game = styled.div<{ marginTop?: string }>`
  width: 65.6vw;
  height: 9.333vw;
  font-size: 6.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #a3aaaf;
  line-height: 9.333vw;
  margin: ${({ marginTop }) => (marginTop === 'city1' ? '12.133vw' : '15.2vw')} 0 1.867vw 2.4vw;
`
const BigTitleImg = styled(Image)`
  width: 30.933vw;
  height: 30.467vw;
  display: block;
  margin: 4.933vw 0 4.8vw 2.4vw;
`
const ThreeTitle = styled.div`
  width: 58.933vw;
  height: 19.467vw;
  font-size: 9.067vw;
  font-family: AvenueX-Regular, AvenueX;
  font-weight: 400;
  color: #ffffff;
  line-height: 9.067vw;
  display: block;
  margin: 4.933vw 0 4.8vw 2.4vw;
`
const FourTitle = styled.div`
  width: 58.933vw;
  height: 19.467vw;
  display: block;
  margin: 4.933vw 0 4.8vw 2.4vw;
  font-size: 10.667vw;
  font-family: CRSL Arches-Regular, CRSL Arches;
  color: #ffffff;
  line-height: 9.6vw;
`
const FiveTitle = styled.div`
  width: 58.933vw;
  height: 19.467vw;
  font-size: 9.067vw;
  font-family: AvenueX-Regular, AvenueX;
  font-weight: 400;
  color: #ffffff;
  line-height: 9.067vw;
  display: block;
  margin: 4.933vw 0 4.8vw 2.4vw;
`

const SmallTitle = styled.div`
  width: 95.2vw;
  height: 11.733vw;
  font-size: 5.6vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #d8d8d8;
  line-height: 5.6vw;
  margin: 0 auto 1.867vw;
`
const Tips = styled.div`
  width: 95.2vw;
  line-height: 21.067vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #a9aaab;
  line-height: 5.067vw;
  margin: 0 auto 5.733vw;
`

const CityImg = styled(Image)<{ position?: 'string' }>`
  width: 97.6vw;
  height: 45.6vw;
  margin: 0 ${({ position }) => (position === 'left' ? '2.4vw 0 0' : '0 0 2.4vw ')};
`

const UnStartBtn = styled.div`
  width: 81.733vw;
  height: 13.333vw;
  background: #313134;
  border-radius: 13.333vw;
  font-size: 5.467vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #7b7b7b;
  line-height: 13.333vw;
  margin: 6.4vw auto 0;
  text-align: center;
`
const CityBottom = styled.div`
  width: 81.733vw;
  height: 13.333vw;
  border-radius: 13.333vw;
  font-size: 5.467vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #383938;
  line-height: 13.333vw;
  margin: 6.4vw auto 0;
  background: #ffffff;
  text-align: center;
`

// 下载按钮
const DownLoadBox = styled.div`
  width: 81.733vw;
  height: 28.8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 5.733vw auto;
`
const LogoBox = styled.a`
  width: 37.6vw;
  height: 13.333vw;
  background: rgba(92, 127, 255, 0);
  // border-radius: 13.333vw;
  cursor: pointer;
  margin: 1.733vw auto;
`
const SaveButton = styled(Button)`
  width: 37.6vw;
  height: 13.333vw;
  background: rgba(92, 127, 255, 0);
  // border-radius: 13.333vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`
const Logo = styled(Image)`
  width: 37.6vw;
  height: 13.333vw;
  margin: 0 auto;
`

export default GameListH5
