import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect } from "react"
import useSize from "hooks/useSize"
import { Box } from "@mui/material"
import { H5ImgInfo } from "../config/NFTHeader"

const NFTHeaderH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".friends-wrap", start: "top-=800" } })
      tl.fromTo(".friends-title-Tips", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".friends-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])
  return (
    <Wrap className="friends-wrap">
      <Tips className="friends-title-Tips">Become the owner of a unique</Tips>
      <Title className="friends-title-style">SKYLAND NFT </Title>
      <Box sx={{ margin: "0 auto", width: "82.133vw", height: "67.067vw", position: "relative" }}>
        {H5ImgInfo.map((item, keys) => (
          <Box key={item.url + keys} className={`${item.class}-box img-box`}>
            <Image folder="nft_h5" src={item.url} className={`${item.class}`}></Image>
          </Box>
        ))}
      </Box>
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 126.8vw;
  width: 100%;
  position: relative;
  overflow: hidden;
  .img-box {
    position: absolute;
    img {
      position: relative;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .small-01-box {
    width: 24vw;
    height: 31.333vw;
    left: 0;
    top: 0;
  }
  .small-02-box {
    width: 23.6vw;
    height: 23.6vw;
    left: 25.2vw;
    top: 0;
  }
  .small-03-box {
    width: 32.267vw;
    height: 31.467vw;
    left: 49.867vw;
    top: 0;
  }
  .small-04-box {
    width: 23.867vw;
    height: 31.333vw;
    left: 0;
    top: 35.333vw;
  }
  .small-05-box {
    width: 23.467vw;
    height: 23.6vw;
    left: 24.933vw;
    top: 26.133vw;
    img {
      width: 23.467vw;
      height: 23.6vw;
    }
  }

  .small-06-box {
    width: 15.733vw;
    height: 15.733vw;
    left: 28.933vw;
    top: 51.2vw;
    img {
      width: 15.733vw;
      height: 15.733vw;
    }
  }
  .small-07-box {
    width: 15.733vw;
    height: 15.733vw;
    left: 49.6vw;
    top: 32.933vw;
    img {
      width: 15.733vw;
      height: 15.733vw;
    }
  }
  .small-08-box {
    width: 15.733vw;
    height: 15.733vw;
    left: 66.133vw;
    top: 32.933vw;
    img {
      width: 15.733vw;
      height: 15.733vw;
    }
  }
  .small-09-box {
    width: 15.733vw;
    height: 15.733vw;
    left: 49.6vw;
    top: 51.2vw;
    img {
      width: 15.733vw;
      height: 15.733vw;
    }
  }
  .small-10-box {
    width: 15.733vw;
    height: 15.733vw;
    left: 66.133vw;
    top: 51.2vw;
    img {
      width: 15.733vw;
      height: 15.733vw;
    }
  }
`
const Title = styled.div`
  text-align: center;
  color: #7893be;
  line-height: 7.467vw;
  font-size: 7.467vw;
  margin: 0 auto 9.2vw;
`
const Tips = styled.div`
  text-align: center;
  font-size: 4.133vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin: 23.866vw auto 1.6vw;
`
export default NFTHeaderH5
