import styled from "@emotion/styled"
import Image from "components/Image"
import { mouseEnter, mouseLeave } from "utils/gsap"
import { gsap } from "gsap"
import Media from "react-media"
import useSize from "hooks/useSize"
import { useEffect } from "react"

const SkytopiaH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".skytopia-wrap", start: "top-=700" } })
      tl.fromTo(".skytopia-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".skytopia-tips-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })

      tl.fromTo(".Linked-img-style-01", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".Linked-img-style-02", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".Linked-img-style-03", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".Linked-img-style-04", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])
  return (
    <Wrap className="skytopia-wrap">
      <Title className="skytopia-title-style">linkable world protocol</Title>
      <Subtitle className="skytopia-tips-style">
        Metaworlds are connected to Skytopia, the base Metaworld of CryptoSkyland, through standard protocols.
      </Subtitle>
      <SkytopiaImage folder="about_h5" src="03-05@2x"></SkytopiaImage>
      <Image className="Linked-img-style-06 RotationBack" folder="about_h5" src="06@2x"></Image>
      <SkytopiaVal>Skytopia</SkytopiaVal>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 98.933vw;
  position: relative;
  .Linked-img-style-06 {
    width: 46.667vw;
    height: 46.8vw;
    position: absolute;
    left: 26.667vw;
    top: 37.6vw;
  }
  @keyframes rotationback {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 20s linear infinite;
  }
`
const Title = styled.div<{ marginVal?: string }>`
  height: 5.2vw;
  font-size: 7.467vw;
  color: #7893be;
  line-height: 43px;
  margin: 0 auto 8.4vw;
  text-align: center;
`
const SkytopiaImage = styled(Image)`
  width: 80.4vw;
  height: 46.133vw;
  margin: 0 auto;
`
const Subtitle = styled.div`
  width: 80vw;
  height: 13.867vw;
  font-size: 3.2vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  line-height: 5.333vw;
  margin: 0 auto 11.467vw;
`
const SkytopiaVal = styled.div`
  width: 21.733vw;
  height: 3.333vw;
  font-size: 4.8vw;
  line-height: 5.6vw;
  position: absolute;
  left: 39.2vw;
  top: 59vw;
  z-index: 1;
  text-align: center;
`
export default SkytopiaH5
