import styled from '@emotion/styled'
import Image from 'components/Image'
import { ImgInfo } from '../config/Roadmap'
import { useState } from 'react'

const RoadmapH5 = () => {
  const [isMouseDown, setIsMouseDown] = useState('false')
  return (
    <Wrap>
      <RoadMap ismousedown={isMouseDown} className="exit-animation">
        <RoadmapText className="exit-animation">ROADMAP</RoadmapText>

        <SolidLine ismousedown={isMouseDown}></SolidLine>
        <DashedLine></DashedLine>
        {isMouseDown === 'false' && (
          <Downbtn
            onClick={() => {
              setIsMouseDown('true')
            }}
            folder="index2/Roadmap"
            src="Downbtn.png"
          />
        )}
        {ImgInfo.map((item, key) => {
          return (
            <StageBox className={item.class} key={key}>
              <StageName>
                {item.name}
                {item.CurrentProgress ? <div></div> : <span></span>}
              </StageName>

              <StageContent>
                <StageVal>{item.content1}</StageVal>
                <StageVal>{item?.content2}</StageVal>
                {item?.content3 !== '' && <StageVal>{item?.content3}</StageVal>}
                {item?.content4 !== '' && <StageVal>{item?.content4}</StageVal>}
                {item?.content5 !== '' && <StageVal>{item?.content5}</StageVal>}
                {item?.content6 !== '' && <StageVal>{item?.content6}</StageVal>}
              </StageContent>
            </StageBox>
          )
        })}
      </RoadMap>
    </Wrap>
  )
}
const Wrap = styled.div`
  position: relative;
  width: 95.2vw;
  height: fit-content;
  margin: 0 auto;
  box-sizing: border-box;
  padding-bottom: 14.133vw;
`

const RoadMap = styled.div<{ ismousedown?: string }>`
  width: 95.2vw;
  /* height: 303.467vw; */
  /* height: 88vw; */
  height: ${({ ismousedown }) => (ismousedown === 'false' ? '88vw' : '303.467vw')};

  overflow: hidden;
  margin: 0 2.4vw;
  position: relative;
`
const RoadmapText = styled.div`
  width: 65.6vw;
  height: 9.333vw;
  font-size: 6.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 9.333vw;
  margin-bottom: 6vw;
`

const StageBox = styled.div`
  min-height: 32vw;
  width: 82.667vw;
  position: relative;
  margin-left: 12.533vw;
`
const Downbtn = styled(Image)`
  width: 1.867vw;
  height: 2.133vw;
  position: absolute;
  left: 6.85vw;
  top: 84.8vw;
`
const StageName = styled.div`
  width: 82.667vw;
  height: 8.667vw;
  font-size: 5.6vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 6.667vw;
  position: relative;
  span {
    width: 1.733vw;
    height: 1.733vw;
    background: #f3f3f3;
    border: 0.133vw solid #000000;
    position: absolute;
    top: 2.667vw;
    left: -5.733vw;
    border-radius: 50%;
  }
  div {
    width: 2.933vw;
    height: 2.933vw;
    background: #000000;
    position: absolute;
    top: 1.867vw;
    left: -6.267vw;
    border-radius: 50%;
  }
`

const StageContent = styled.div`
  width: 82.667vw;
  min-height: 23.2vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 5.067vw;
  padding-bottom: 2.667vw;
  box-sizing: border-box;
`
const StageVal = styled.div``

const SolidLine = styled.div<{ ismousedown?: string }>`
  width: 0px;
  height: ${({ ismousedown }) => (ismousedown === 'false' ? '64vw' : '128vw')};
  border: 1px solid #000000;
  position: absolute;
  left: 7.6vw;
  top: 19.2vw;
`
const DashedLine = styled.div`
  width: 0px;
  height: 165vw;
  border: 1px dashed #000000;
  position: absolute;
  position: absolute;
  left: 7.6vw;
  top: 115.2vw;
`
export default RoadmapH5
