import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect } from "react"
import useSize from "hooks/useSize"
import FlexBox from "components/FlexBox"

const BenefitsH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".benefits-wrap", start: "top-=800" } })
      tl.fromTo(".benefits-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo(".benefits001", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      tl.fromTo(".benefits002", { opacity: 0 }, { opacity: 1, duration: 0.5 })
      tl.fromTo(".benefits003", { opacity: 0 }, { opacity: 1, duration: 0.5 })
    }
  }, [pcSize])

  return (
    <Wrap className="benefits-wrap">
      <Title className="benefits-title-style">Benefits</Title>
      <FlexBox between sx={{ margin: "0 8.933vw 0 6vw", width: "85.067vw" }}>
        <BenefitsBox className="benefits001">
          <BenefitsImg folder="nft_h5" src="benefits001@2x" />
        </BenefitsBox>
        <BenefitsBox className="benefits002">
          <BenefitsImg folder="nft_h5" src="benefits002@2x" />
        </BenefitsBox>
        <BenefitsBox className="benefits003">
          <BenefitsImg folder="nft_h5" src="benefits003@2x" />
        </BenefitsBox>
      </FlexBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 59.6vw;
  width: 100%;
  position: relative;
`
const Title = styled.div`
  text-align: center;
  color: #7893be;
  line-height: 6vw;
  font-size: 7.733vw;
  margin: 0 auto 9.2vw;
`
const BenefitsImg = styled(Image)`
  height: 27.733vw;
  width: 28.933vw;
`
const BenefitsBox = styled.div`
  position: relative;
  height: 27.733vw;
  width: 28.933vw;
`
export default BenefitsH5
