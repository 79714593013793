import { useEffect } from 'react'
import Layout from 'components/Layout'
import Index from 'pages/Index/index'
import { Navigate, Route, Routes, useNavigate } from 'react-router'
import './App.css'
import Skytopia from 'pages/Skytopia'
import SkytopiaNFT from 'pages/SkytopiaNFT'
import About from 'pages/About'
import Hubs from 'pages/Hubs'
import Metaworld from 'pages/Hubs/Metaworld'
import Avatar from 'pages/Avatar'
import { useWeb3React } from '@web3-react/core'
import useWalletAuth from 'hooks/useWalletAuth'
import { getChain, monitorAccountsChanged, monitorChainChange, SupportedChain, SwitchChainRequest, WalletCache } from 'utils/walletUtils'
import Profile from 'pages/Profile'
import { getAuthCookie, clearAuthCookie, setAuthCookie } from 'utils/user'
import { contractsFun, facebookLogiFun, googleLoginFun, inviteSwitchFun, recaptchaFun } from 'api/request'
import LoginModal from 'components/LoginModal'
import useModal from 'hooks/useModal'
import Constants from 'config/constants'
import Store from 'pages/Store'
import Game from 'pages/Game'
import PrivacyAgreement from 'pages/PrivacyAgreement'
import { Google_Client_id, facebook_Client_id } from 'api/apiList'
import { resetRoute } from 'utils'
import Download from 'pages/download'

function App() {
  const { connect, disconnect } = useWalletAuth()
  const { SUPPORTED_CHAINS } = Constants
  const { account, chainId } = useWeb3React()

  const token = getAuthCookie()
  const [loginModalHandler] = useModal(<LoginModal />)

  useEffect(() => {
    // const lastEthereum = localStorage.getItem('ethereumNum')
    // if (token && account === undefined) {
    //   if (lastEthereum === '2') {
    //     window.ethereum = window.bitkeep.ethereum
    //   }
    //   connect()
    // }

    // if (!SUPPORTED_CHAINS.includes(getChain())) {
    //   // if (getChain() !== configs.INIT_CHAIN) {
    //   // SwitchChainRequest()
    //   SwitchChainRequest(WalletCache.getChain() as SupportedChain)
    // }
    // monitorAccountsChanged(() => {
    //   disconnect()
    //   clearAuthCookie()
    //   loginModalHandler()
    // })
    // monitorChainChange(() => {
    //   disconnect()
    //   clearAuthCookie()
    // })

    const url = window.location.href
    if (url.includes('?') && url.split('?')[1].length === 6) {
      sessionStorage.setItem('InvitationCode', url.split('?')[1])
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (token) {
      // localStorage.setItem("inviteSwitch", "0")
      inviteSwitchFun()
        .then((res: any) => {
          if (res.code === 0) {
            localStorage.setItem('inviteSwitch', res.data.Status)
          }
        })
        .catch((err: any) => {})
    }
  }, [token]) // eslint-disable-line

  useEffect(() => {
    contractsFun()
      .then((res: any) => {
        if (res.code === 0) {
          localStorage.setItem('MarketContract', res.data.MarketContract)
          localStorage.setItem('NftContract', res.data.NftContract)
          localStorage.setItem('StoreContract', res.data.StoreContract)
        }
      })
      .catch((err: any) => {})
  }, [chainId]) // eslint-disable-line

  // 谷歌验证 V3
  const executeRecaptcha = () => {
    if ((window as any).grecaptcha) {
      ;(window as any).grecaptcha.ready(() => {
        ;(window as any).grecaptcha.execute(siteKey, { action: 'homepage' }).then((token: string) => {
          recaptchaFun({ token: token })
            .then((res: any) => {
              if (res.data.success) {
                localStorage.setItem('isreCAPTCHA', res.data.success)
              }
            })
            .catch((err: any) => {
              console.log(err)
            })
          // setToken(token)
        })
      })
    }
  }

  const siteKey = '6LfsEBcmAAAAAJHEnvqh76iOoxwQFFe1wy9hoja3' // replace with your site key
  useEffect(() => {
    // Load the reCAPTCHA script
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
    script.addEventListener('load', executeRecaptcha)
    document.body.appendChild(script)
  }, [])

  const navigate = useNavigate()

  useEffect(() => {
    // 解析URL中的查询参数
    const urlParams = new URLSearchParams(window.location.search)
    const code = urlParams.get('code')
    const state = urlParams.get('state')

    // 检查state参数以防止CSRF攻击

    // google
    if (code && state) {
      googleLoginFun({ client_id: Google_Client_id, token_code: code, redirect_uri: window.location.origin })
        .then((res: any) => {
          if (res.code === 0) {
            setAuthCookie(res.data)
            resetRoute()
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
    }

    // facebook
    if (code && !state) {
      facebookLogiFun({ code: code + '#_=_', client_id: facebook_Client_id })
        .then((res: any) => {
          if (res.code === 0) {
            setAuthCookie(res.data)
            resetRoute()
            return
          }
        })
        .catch((err: any) => {})

      if (window.location.hash === '#_=_') {
        // 清理URL
        navigate(`/#/index?code=${code}`)
      }
    }
  }, []) // eslint-disable-line

  return (
    <Layout>
      <Routes>
        <Route path="/index" element={<Index />} />

        <Route path="/game" element={<Game />} />

        <Route path="/about" element={<About />} />
        <Route path="/privacyagreement" element={<PrivacyAgreement />} />
        <Route path="/store" element={<Store />} />
        <Route path="/store/details" element={<Store />} />
        <Route path="/order" element={<Store />} />
        <Route path="/hubs" element={<Hubs />} />
        <Route path={'/hubs/*'} element={<Metaworld />} />
        <Route path="/skytopia" element={<Skytopia />} />
        <Route path="/skyland" element={<SkytopiaNFT />} />
        <Route path="/avatar" element={<Avatar />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/setting" element={<Profile />} />
        <Route path="/wallet" element={<Profile />} />
        <Route path="/referral" element={<Profile />} />
        <Route path="/inventory" element={<Profile />} />
        <Route path="/download" element={<Download />} />
        {/* <Route path="/test" element={<Test />} /> */}
        <Route path="*" element={<Navigate to="/index" replace={true} />} />
      </Routes>
    </Layout>
  )
}

export default App
