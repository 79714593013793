import React, { useEffect } from "react"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import Image from "components/Image"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import useSize from "hooks/useSize"
import AboutHeader from "./components/AboutHeader"
import Characteristic from "./components/Characteristic"
import Skytopia from "./components/Skytopia"
import Team from "./components/Team"
const About: React.FC<any> = () => {
  const { pcSize } = useSize()
  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {
    gsap.to(".skytopia-bg-text", {
      y: 0.1 * -ScrollTrigger.maxScroll(window),
      ease: "none",
      scrollTrigger: {
        scrub: true,
      },
    })
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [])

  return (
    <Warp className="skytopiawarp">
      <Contentbg>
        {pcSize ? (
          <Image folder="index" src="indexBg" className="skytopia-bg-text" />
        ) : (
          <Image folder="index" src="H5Bg@2x" className="skytopia-bg-text" />
        )}
        <AboutHeader />
        <Characteristic />
        <Skytopia />
        <Team />
      </Contentbg>
    </Warp>
  )
}

const Contentbg = styled.div`
  background: #e4e1f6;
  width: 100%;
`
const Warp = styled.div`
  .skytopia-bg-text {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    display: block !important;
  }
`

export default About
