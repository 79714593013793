import styled from "@emotion/styled"
import { MenuList, MenuItem } from "@mui/material"
import { contractListFun } from "api/request"
import { useEffect, useState } from "react"

export default function NativeSelectDemo({ setContractAddress }: any) {
  const [isShow, setIsShow] = useState<any>(false)
  const [Select, setSelect] = useState<any>("All")
  useEffect(() => {
    const getPrice = async () => {
      contractListFun()
        .then((res: any) => {
          setSelectInfo(res.data)
        })
        .catch((err: any) => {})
    }
    getPrice()
  }, [])

  const [SelectInfo, setSelectInfo] = useState([])

  return (
    <InputBox
      onClick={() => {
        setIsShow(!isShow)
      }}
    >
      {Select}
      <MenuListInfo sx={{ display: isShow ? "block" : "none" }}>
        {SelectInfo?.length > 0 &&
          SelectInfo?.map((item:any, i) => (
            <MenuItems
              key={i}
              onClick={() => {
                setSelect(item?.Name)
                setContractAddress(item?.ContractAddress)
                setIsShow(false)
              }}
            >
              {item?.Name}
            </MenuItems>
          ))}
      </MenuListInfo>
    </InputBox>
  )
}

const InputBox = styled.div`
  width: 10.417vw;
  height: 1.823vw;
  background: #8aa3ff;
  border-radius: 0.521vw;
  font-size: 1.146vw;
  font-family:Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 1.823vw;
  padding-left: 0.781vw;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 1000px) {
    background: #8aa3ff;
    font-family:Albert Sans-Bold, Albert Sans;
    color: #ffffff;
    padding-left: 2vw;
    box-sizing: border-box;
    position: relative;
    width: 26.667vw;
    height: 6vw;
    line-height: 6vw;
    border-radius: 1.333vw;
    font-size: 3.467vw;
  }
`

const MenuListInfo = styled(MenuList)`
  position: absolute;
  top: 1.823vw;
  left: 0;
  width: 10.417vw;
  min-height: 12.031vw;
  background: rgba(138, 163, 255, 1);
  border-radius: 0.521vw;
  color: rgba(255, 255, 255, 0.6);
  z-index: 99;
  @media (max-width: 1000px) {
    position: absolute;
    top: 6vw;
    left: 0;
    background: rgba(138, 163, 255, 1);
    border-radius: 1.333vw;
    color: rgba(255, 255, 255, 0.6);
    z-index: 99;
    width: 26.8vw;
    min-height: 42.133vw;
    background: rgba(138, 163, 255, 0.6);
    border-radius: 1.333vw;
    padding: 0;
  }
`
const MenuItems = styled(MenuItem)`
  font-size: 1.146vw;
  font-family:Albert Sans-Bold, Albert Sans;
  @media (max-width: 1000px) {
    height: 4.8vw;
    min-height: 5vw;
    padding-left: 2vw;
    box-sizing: border-box;
    font-size: 3.2vw;
    margin: 5.333vw 0;
    font-family:Albert Sans-Bold, Albert Sans;
    color: #ffffff;
  }
  &:hover {
    background-color: rgba(255, 255, 255, 0) !important;
    color: #fff;
  }
`
