/* eslint-disable */
import styled from '@emotion/styled'
import { useCallback, useEffect, useRef, useState } from 'react'
import Image from 'components/Image'
import FlexBox from 'components/FlexBox'
import { useWeb3React } from '@web3-react/core'
import { Button } from '@mui/material'
import useWeb3 from 'hooks/useWeb3'
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from 'utils/formatBalance'
import { BandWalletFun, assetListFun, errMsgHandler, getPersonalFun, getRandomMsg, priceFun } from 'api/request'
import useModal from 'hooks/useModal'
import TransferModelH5 from './TransferModelH5'
import WithdrawModelH5 from './WithdrawModelH5'
import useBlock from 'hooks/useBlock'
import { useTokenBalance } from 'hooks/useTokenBalance'
import { getChain, Supported_Wallets, WalletCache } from 'utils/walletUtils'
import { getShortHash } from 'utils'
import useWalletAuth from 'hooks/useWalletAuth'
import message from 'utils/message'

const Wallet = () => {
  const [CMPBanlance, setCMPBanlance] = useState<any>()
  // const [CMPPrice, setCMPPrice] = useState<any>()
  const [FPUInfo, setFPUInfo] = useState<any>([])

  const web3 = useWeb3()
  const getAssetList = async () => {
    assetListFun({ asset_name: 'FPU' })
      .then((res: any) => {
        setFPUInfo(res.data[0])
      })
      .catch((err: any) => {})
  }
  const FPU = '0xcFc394db49D0A1582b3BF458A1789f08A5A792fe'
  const BigFPU = useTokenBalance(FPU)
  const FPUBalance = getBalanceNumber(BigFPU)
  const block = useBlock()

  useEffect(() => {
    account && getAssetList()
  }, [block, account])

  useEffect(() => {
    const getBalance = async () => {
      const balance = await web3.eth.getBalance(`${account}`)
      setCMPBanlance(getBalanceNumber(new BigNumber(balance), 18))
    }
    // const getPrice = async () => {
    //   priceFun({ token: "cmp" })
    //     .then((res: any) => {
    //       setCMPPrice(res.data.price)
    //     })
    //     .catch((err: any) => {})
    // }
    account && getBalance()
    // account && getPrice()
  }, [account])

  const [TransfeHandler] = useModal(<TransferModelH5 available_amount={FPUInfo?.available_amount} />)
  const [WithdrawHandler] = useModal(<WithdrawModelH5 available_amount={FPUInfo?.available_amount} />)

  const { connect, disconnect } = useWalletAuth()
  var { account, active, deactivate } = useWeb3React()
  const renderWallet = Supported_Wallets.filter((item: { status: any }) => item.status)

  const handleWalletConnect = useCallback(
    (val: any) => {
      if (val !== window?.ethereum) {
        window.ethereum = val
        localStorage.setItem('ethereumNum', '2')
      } else {
        localStorage.setItem('ethereumNum', '1')
      }

      if (active) {
        sessionStorage.setItem('ConnectWaller', 'open')
      } else {
        connect(() => {
          sessionStorage.setItem('ConnectWaller', 'open')
        })
      }
      console.log('handleWalletConnect called') // 追踪这个函数何时被调用
    },
    [active, connect]
  )

  const web3Ref = useRef<any>(web3)

  useEffect(() => {
    if (active && account && web3 !== web3Ref.current && sessionStorage.getItem('ConnectWaller') === 'open') {
      // 先获取nonce
      getRandomMsg({ wallet_address: account }).then((sign: any) => {
        if (sign) {
          web3.eth.personal
            .sign(`${sign.data.nonce}`, `${account}`, '')
            .then((value) => {
              console.log('getRandomMsg called') // 追踪这个函数何时被调用

              // 获取signature
              if (value) {
                // 绑定钱包
                BandWalletFun({ wallet_address: account, signature: value, nonce: sign.data.nonce })
                  .then((data: any) => {
                    if (data.code === 0) {
                      getPersonalFun()
                        .then((res: any) => {
                          localStorage.setItem('account', res.data.wallet_address)
                          window.location.reload()
                        })
                        .catch((err: any) => {})
                    } else {
                      message.warn(errMsgHandler())
                    }
                  })
                  .catch((err: any) => {
                    disconnect()
                  })
                  .finally(() => {
                    sessionStorage.removeItem('ConnectWaller')
                  })
              } else {
                deactivate()
                WalletCache.removeType()
              }
            })
            .catch((err) => {
              sessionStorage.removeItem('ConnectWaller')
              deactivate()
              WalletCache.removeType()
              // window.location.reload()
            })
        }
      })
    }
  }, [sessionStorage.getItem('ConnectWaller')]) // eslint-disable-line

  return (
    <Wrap>
      <Title>wallet</Title>
      <SmallTitle>My Currency</SmallTitle>
      <WalletBox>
        {WalletCache.getChain() === 1 || WalletCache.getChain() === 5 ? <Caduceus>Eth</Caduceus> : <Caduceus>Caduceus</Caduceus>}
        {/* <WithdrawButton
          onClick={() => {
            TransfeHandler()
          }}
        >
          Transfer
        </WithdrawButton> */}
        {/* <LogoImage folder="setting" src="CMP.png" className="tokenImg"></LogoImage> */}
        {WalletCache.getChain() === 1 || WalletCache.getChain() === 5 ? <LogoImage folder="setting" src="ETH.png" className="tokenImg"></LogoImage> : <LogoImage className="tokenImg" folder="setting" src="CMP.png"></LogoImage>}

        <LogoImage folder="setting" src="FPU.png" className="LOGOImg"></LogoImage>
        <FlexBox between>
          <TokenRight>
            <Balance>{CMPBanlance || 0}</Balance>
            {/* <TokenName>CMP</TokenName> */}
            {WalletCache.getChain() === 1 || WalletCache.getChain() === 5 ? <TokenName>ETH</TokenName> : <TokenName>CMP</TokenName>}

            {/* <TokenPrice> {CMPBanlance >= 0 ? getBalanceNumber(new BigNumber(CMPPrice * CMPBanlance), 0, 4) : 0} USD</TokenPrice> */}
          </TokenRight>
          <TokenRight>
            <Balance>{FPUBalance}</Balance>
            <TokenName>FPU</TokenName>
            {/* <TokenPrice>0.00 USD</TokenPrice> */}
          </TokenRight>
        </FlexBox>
      </WalletBox>

      <WalletBox>
        <Caduceus>Skytopia</Caduceus>
        {/* <WithdrawButton
          onClick={() => {
            WithdrawHandler()
          }}
        >
          Withdraw
        </WithdrawButton> */}
        <LogoImage folder="setting" src="FPU.png" className="tokenImg"></LogoImage>
        <FlexBox between>
          <TokenRight>
            <Balance>{new BigNumber(FPUInfo?.available_amount || 0).toFormat(2)}</Balance>
            <TokenName>FPU</TokenName>
            {/* <TokenPrice>0.00 USD</TokenPrice> */}
          </TokenRight>
        </FlexBox>
      </WalletBox>

      <SmallTitle>Wallet</SmallTitle>
      {localStorage.getItem('account')?.includes('0x') ? (
        <ConnectWallet
          onClick={() => {
            handleWalletConnect(renderWallet[0].connect)
          }}
        >
          {getShortHash(localStorage.getItem('account'), 4, 6)}
        </ConnectWallet>
      ) : (
        <ConnectWallet
          onClick={() => {
            handleWalletConnect(renderWallet[0].connect)
          }}
        >
          BIND WALLET
        </ConnectWallet>
      )}
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh - 33.867vw);
  width: 100%;
  position: relative;
  overflow: hidden;
`
const Title = styled.div`
  width: 25.333vw;
  font-size: 7.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 7vw;
  text-align: center;
  margin: 19.067vw auto 24.8vw;
`

const WalletBox = styled.div`
  width: 83.867vw;
  height: 38.533vw;
  margin-left: 8vw;
  margin-bottom: 3vw;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
  padding: 3.6vw 5.6vw 6.267vw 5.333vw;
  border-radius: 2vw;
  position: relative;
`

const SmallTitle = styled.div`
  min-width: 22.933vw;
  margin-left: 10.533vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin-bottom: 3.867vw;
`

const Caduceus = styled.div`
  min-width: 16.267vw;
  height: 2.8vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 6.667vw;
`
const LogoImage = styled(Image)`
  width: 12.8vw;
  height: 12.8vw;
  margin-right: 4vw;
  position: absolute;
  &.tokenImg {
    top: 13.067vw;
    left: 5.333vw;
  }
  &.LOGOImg {
    top: 13.067vw;
    left: 51.2vw;
  }
`
const TokenRight = styled.div`
  min-width: 27.333vw;
  height: 13.333vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  text-align: right;
`
const Balance = styled.div`
  margin-top: 2.533vw;
`
const TokenName = styled.div`
  margin: 2.8vw 0;
`
const TokenPrice = styled.div``

const WithdrawButton = styled(Button)`
  width: 20vw;
  height: 7vw;
  border-radius: 1.042vw;
  font-size: 1.25vw;
  background-color: rgba(92, 127, 255, 0.7);
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  right: 4vw;
  top: 2vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`

const ConnectWallet = styled(Button)`
  width: 30vw;
  height: 5vw;
  background: #5c7fff;
  border-radius: 2.5vw;
  box-sizing: border-box;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  margin-left: 10.533vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`

export default Wallet
/* eslint-disable */
