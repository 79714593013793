import React from "react"
import styled from "@emotion/styled"
import Image from "components/Image"
import FlexBox from "components/FlexBox"

const HunbsHeader: React.FC<any> = ({ Info }) => {
  return (
    <Warp>
      <FlexBox>
        <UserImg
          folder="hunbs"
          src={Info?.state === 1 ? "game2" : Info?.state === 2 ? "game3" : "game1"}
        />
        <FlexBoxs>
          <UserName>{Info?.state === 1 ? "Skytopia" : Info?.state === 2 ? "Mysterious Village" : "Party Planet"}</UserName>
          <IssuingTime>{Info?.time}</IssuingTime>
        </FlexBoxs>
      </FlexBox>
    </Warp>
  )
}
const Warp = styled.div`
  width: 52.604vw;
  height: 7.188vw;
  padding: 2.031vw 0 1.51vw 0vw;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    width: 100%;
    height: 13.333vw;
    padding: 3.733vw 0 2.8vw 0vw;
    box-sizing: border-box;
  }
`
const UserImg = styled(Image)`
  width: 3.646vw;
  height: 3.646vw;
  border-radius: 50%;
  margin-right: 0.99vw;
  @media (max-width: 1000px) {
    width: 6.933vw;
    height: 6.933vw;
    border-radius: 50%;
    margin-right: 1.6vw;
  }
`
const UserName = styled.div`
  height: 1.563vw;
  font-size: 1.615vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.5vw;
  @media (max-width: 1000px) {
    height: 3.733vw;
    font-size: 3.733vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #7893be;
    line-height: 1.7vw;
  }
`
const FlexBoxs = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: space-between;
  flex-direction: column;
  height: 3.021vw;
  @media (max-width: 1000px) {
    height: 5.333vw;
  }
`
const IssuingTime = styled.div`
  width: 6.875vw;
  height: 1.094vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  @media (max-width: 1000px) {
    height: 2.667vw;
    width: 80vw;
    font-size: 2.933vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #95add4;
    line-height: 4.8vw;
  }
`
export default HunbsHeader
