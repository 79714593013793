import styled from '@emotion/styled'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { useEffect, useRef, useState } from 'react'
import { ImgInfo } from '../config/StoryName'
import OurWorldH5 from './OurWorldH5'
import Media from 'react-media'

const OurWorld = () => {
  const [ClickStatus, setClickStatus] = useState<number>(0)
  const [isMove, setIsMove] = useState<boolean>(false)

  useEffect(() => {
    if (!isMove) {
      return
    }
    const interval = setInterval(() => {
      ClickEnter((ClickStatus + 1) % 5)
      var tl = gsap.timeline({ scrollTrigger: { trigger: '.ourWorld-wrap', start: 'top-=600' } })
      tl.fromTo('.titiebox', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.3 })
      tl.fromTo('.smalltitle', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.3 })
      tl.fromTo('.ourWorld-tips', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.3 })
    }, 5000)

    return () => clearInterval(interval)
  }, [ClickStatus, isMove])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsMove(true)
        } else {
          setIsMove(false)
        }
      })
    })

    const elements = document.querySelectorAll('.ourWorld-wrap')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  // 点击按钮
  const ClickEnter = (key: number) => {
    setClickStatus(key)
    gsap.fromTo(`.circletree${key + 1}`, { opacity: 1, scale: 1.25 }, { opacity: 1, scale: 1, duration: 3 })
  }

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="ourWorld-wrap">
            {ImgInfo.map((item, key) => (
              <OurWorldBox key={key}>
                <OurWorldTextBox className={`${ClickStatus === key ? 'show' : ''}`}>
                  <BigTitle>Story</BigTitle>
                  <SmallTitle className="smalltitle">{item.Title}</SmallTitle>
                  <Tips className="ourWorld-tips">{item.content1}</Tips>
                  <Tips className="ourWorld-tips">{item.content2}</Tips>
                </OurWorldTextBox>

                <StoryNameBox className={`${ClickStatus === key ? 'show' : ''}`}>
                  <TreeBox>
                    <TreeImg folder="index2/StoryName" className={`${item.class}`} src={item.urlBig} />
                  </TreeBox>
                </StoryNameBox>
              </OurWorldBox>
            ))}

            <Pagination>
              <PaginationLine></PaginationLine>

              {ImgInfo.map((item, key) => (
                <UnSelectBox
                  key={key}
                  onClick={() => {
                    ClickEnter(key)
                  }}
                >
                  {<SelectBox style={{ opacity: ClickStatus === key ? 1 : 0 }} />}
                </UnSelectBox>
              ))}
            </Pagination>
          </Wrap>
        ) : (
          <OurWorldH5></OurWorldH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  .show {
    z-index: 1;
    opacity: 1;
  }
`

const OurWorldBox = styled.div`
  width: 78.125vw;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const OurWorldTextBox = styled.div`
  width: 20.938vw;
  min-height: 28.75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
`

const BigTitle = styled.div`
  font-family: Albert Sans-Bold;
  font-weight: 700;
  color: #3d3d3d;
  width: 20.938vw;
  height: 5vw;
  font-size: 4.167vw;
  line-height: 5vw;
  white-space: nowrap;
`

const SmallTitle = styled.div`
  width: 20.938vw;
  min-height: 3.073vw;
  font-size: 2.083vw;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 2.083vw;
  font-family: Albert Sans-Bold;
  font-weight: 700;
  margin-top: 1.823vw;
`
const Tips = styled.div`
  width: 20.938vw;
  min-height: 3.125vw;
  font-size: 0.938vw;
  font-family: Albert Sans-Medium;
  color: #7b7b7b;
  line-height: 1.563vw;
  margin-top: 1.042vw;
  font-weight: 500;
`

const StoryNameBox = styled.div`
  width: 53.75vw;
  height: 28.75vw;
  position: relative;
  opacity: 0;
`
const TreeBox = styled.div`
  width: 53.75vw;
  height: 28.75vw;
  border-radius: 3.906vw;
  overflow: hidden;
`
const TreeImg = styled(Image)`
  width: 53.75vw;
  height: 28.75vw;
`

const Pagination = styled.div`
  width: 12.813vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 90vh;
  left: 50%;
  transform: translateX(-50%);
`
const PaginationLine = styled.div`
  width: 12.813vw;
  height: 0.208vw;
  background: #000000;
  position: absolute;
`

const UnSelectBox = styled.div`
  width: 0.521vw;
  height: 0.521vw;
  background: #f3f3f3;
  position: relative;
  border-radius: 50%;
  border: 2px solid #000000;
  transform: scale(1.1);
`
const SelectBox = styled.div`
  width: 2.083vw;
  height: 0.781vw;
  background: #000000;
  border-radius: 0.521vw;
  position: absolute;
  top: -0.156vw;
  left: -0.781vw;
`
export default OurWorld
