import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress?: Number
}
const FooterH5: React.FC<PreloaderProps> = ({ imageUrls }) => {
  const [di_haidaoH5, di_fawuH5] = imageUrls
  const navigate = useNavigate()
  const handleClose = (e: any) => {
    e.stopPropagation()
    navigate('/privacyagreement')
  }
  return (
    <Warp style={{ backgroundImage: `url(${di_fawuH5})` }}>
      <LogoImg src={di_haidaoH5} alt="" />
      <SkytopiaLabs>© 2023 Skytopia Ltd.</SkytopiaLabs>
      <Term>Term & Conditions</Term>
      <Copyright>
        Copyright 2023 <Elliptic></Elliptic>
        {/* <Terms href="#">Terms of Service</Terms>
            <Elliptic></Elliptic> */}
        <Terms onClick={handleClose}>Privacy Policy</Terms>
      </Copyright>
    </Warp>
  )
}

const Warp = styled.div`
  width: 100%;
  height: 35.733vw;
  overflow: hidden;
  background-size: 100% 100%;
`

const LogoImg = styled.img`
  width: 25.733vw;
  height: 10.4vw;
  display: block;
  margin: 7.467vw auto 1.333vw;
`

const SkytopiaLabs = styled.div`
  height: 1.867vw;
  font-size: 1.867vw;
  font-family: Milky Nice-Regular, Milky Nice;
  color: #ffffff;
  line-height: 1.867vw;
  text-align: center;
  margin-bottom: 0.667vw;
`

const Term = styled.div`
  font-size: 1.867vw;
  font-family: Milky Nice-Regular, Milky Nice;
  font-weight: 400;
  color: #ffffff;
  line-height: 1.867vw;
  text-align: center;
`

const Copyright = styled.div`
  width: fit-content;
  font-size: 1.867vw;
  font-family: Albert Sans;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.4vw auto 0;
`

const Elliptic = styled.div`
  width: 0.8vw;
  height: 0.8vw;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 1.733vw;
`

const Terms = styled.a`
  width: fit-content;
  font-size: 1.867vw;
  font-family: Albert Sans;
  font-weight: bold;
  text-decoration: underline;
  color: #ffffff;
  cursor: pointer;
`
export default FooterH5
