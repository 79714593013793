import styled from "@emotion/styled"
import Image from "components/Image"
import { mouseEnter, mouseLeave } from "utils/gsap"
import { gsap } from "gsap"
import { ImgInfo } from "../config/Team"
import FlexBox from "components/FlexBox"
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import useSize from "hooks/useSize"
import Media from "react-media"
import TeamH5 from "./TeamH5"

const Team = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".team-wrap", start: "top-=800" } })
      tl.fromTo(".team-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])
  const [hover, setHover] = useState(999)
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="team-wrap">
            <Title className="team-title-style">team</Title>
            <FlexBox between wrap sx={{ width: "50.417vw", minHeight: "24vw", margin: "0 auto" }}>
              {ImgInfo.map((item, key) => {
                return (
                  <Box key={key} className={`${item?.class} buildings-img-style-box`}>
                    <ImgActive
                      hovers={hover === key ? "1" : hover < 9 ? "0" : "1"}
                      className={item.class}
                      folder="about"
                      src={item.url}
                      onMouseEnter={() => {
                        setHover(key)
                        // gsap.fromTo(".content-bg", {  x: -30 }, {  x: 0, duration: 0.5 })
                      }}
                      onMouseLeave={() => {
                        setHover(9999)
                        // gsap.fromTo(".content-bg", { opacity: 1 }, { opacity: 0, duration: 0.5 })
                      }}
                    ></ImgActive>
                    {/* style={{ opacity: hover === key ? "1" : hover < 9 ? "0.4" : "1" }} */}
                    <SkytopiaVal>{item.name}</SkytopiaVal>
                    <Box className="content-bg" sx={{ width: "20.729vw", height: "10.313vw", display: key === hover ? "block" : "none" }}>
                      <ContentTitle tops={key === 3 || key === 6 ? "0.958vw" : key === 7 ? "0.938vw" : "1.458vw"}>{item?.Title}</ContentTitle>
                      <ContentTips
                        tops={key === 3 || key === 6 ? "4.083vw" : key === 7 ? "2.708vw" : "3.438vw"}
                        dangerouslySetInnerHTML={{ __html: item.content }}
                      ></ContentTips>
                      <Image folder="about" src={item.urlBg}></Image>
                    </Box>
                  </Box>
                )
              })}
            </FlexBox>
          </Wrap>
        ) : (
          <TeamH5 />
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 36.2vw;
  position: relative;
  .buildings-img-style-box {
    position: relative;
    width: 10.052vw;
    height: 9.792vw;
    margin-right: 3.385vw;
    :nth-of-type(4n + 0) {
      margin-right: 0;
    }
  }
  .content-bg {
    position: absolute;
    left: 7.135vw;
    top: 0.573vw;
    z-index: 2;
    img {
      width: 20.729vw;
      height: 10.313vw;
    }
  }
`
const ImgActive = styled(Image)<{ hovers?: string }>`
  position: absolute;
  width: 10.052vw;
  height: 9.792vw;
  z-index: ${({ hovers }) => (hovers === "1" ? 10 : 1)};
  /* opacity: ${({ hovers }) => (hovers === "1" ? 1 : 0.3)}; */
`
const Title = styled.div`
  font-size: 2.5vw;
  line-height: 2vw;
  margin-bottom: 1.25vw;
  text-align: center;
  color: #7893be;
`
const ContentTitle = styled.div<{ tops?: string }>`
  width: 16.042vw;
  height: 1.25vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 1.423vw;
  position: absolute;
  top: ${({ tops }) => tops};
  left: 3.281vw;
`

const ContentTips = styled.div<{ tops?: string }>`
  width: 16.042vw;
  height: 6.563vw;
  font-size: 0.885vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 1.2vw;
  position: absolute;
  top: ${({ tops }) => tops};
  left: 3.229vw;
`

const SkytopiaVal = styled.div`
  width: 10.052vw;
  /* height: 1.094vw; */
  font-size: 1.302vw;
  color: #7893be;
  line-height: 1vw;
  text-align: center;
  position: absolute;
  left: 0;
  bottom: -1.1vw;
  z-index: 1;
`
export default Team
