import styled from '@emotion/styled'
import Image from 'components/Image'
import { useNavigate } from 'react-router-dom'
const FooterH5 = () => {
  const MoveScroller = (ScrollHight: number = 0) => {
    const screenHeight = window.innerHeight
    const vh = screenHeight / 100

    window.scrollTo({
      top: ScrollHight * vh,
      behavior: 'smooth',
    })
  }

  const navigate = useNavigate()
  const handleClose = (e: any) => {
    e.stopPropagation()
    navigate('/privacyagreement')
  }

  return (
    <Footer>
      <LeftBox>
        <LogoImg folder="index2/Partner" src="marsxyz.png" />
        <SkytopiaLabs>© 2024 MarsXYZ Ltd</SkytopiaLabs>
        <Term>Term & Conditions</Term>
        <Copyright>
          Copyright 2024 <Elliptic></Elliptic>
          {/* <Terms href="#">Terms of Service</Terms>
            <Elliptic></Elliptic> */}
          <Terms onClick={handleClose}>Privacy Policy</Terms>
        </Copyright>
      </LeftBox>
      <RightBox
        onClick={() => {
          MoveScroller(0)
        }}
      >
        Top
      </RightBox>
    </Footer>
  )
}
const Footer = styled.div`
  position: absolute;
  left: 2.4vw;
  bottom: 0;
  width: 90.533vw;
  height: 33.333vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftBox = styled.div`
  min-width: 18.667vw;
  height: 10.533vw;
`

const LogoImg = styled(Image)`
  width: 7.133vw;
  height: 7.133vw;
`

const SkytopiaLabs = styled.div`
  width: 18.667vw;
  height: 1.867vw;
  font-size: 10px;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #7b7b7b;
  line-height: 1.867vw;
  margin: 0.667vw 0 0.667vw;
  white-space: nowrap;
`
const Term = styled.div`
  width: 18.667vw;
  white-space: nowrap;
  font-size: 10px;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #7b7b7b;
  height: 1.867vw;
  font-size: 1.333vw;
  line-height: 1.867vw;
`

const RightBox = styled.div`
  width: 8.933vw;
  height: 8.933vw;
  background: #ffffff;
  font-size: 3.2vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #3d3d3d;
  line-height: 8.933vw;
  border-radius: 50%;
  text-align: center;
`
const NameList = styled.div`
  width: 9.531vw; //366px
  height: 10.365vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`
const Name = styled.a`
  width: 9.531vw;
  height: 1.563vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #646464;
  line-height: 1.563vw;
  cursor: pointer;
`
const Copyright = styled.div`
  width: fit-content;
  font-size: 1.867vw;
  font-family: Albert Sans;
  font-weight: bold;
  color: #7b7b7b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.4vw auto 0;
`

const Elliptic = styled.div`
  width: 0.8vw;
  height: 0.8vw;
  background: #7b7b7b;
  border-radius: 50%;
  margin: 0 1.733vw;
`

const Terms = styled.a`
  width: fit-content;
  font-size: 1.867vw;
  font-family: Albert Sans;
  font-weight: bold;
  text-decoration: underline;
  color: #7b7b7b;
  cursor: pointer;
`
export default FooterH5
