import styled from '@emotion/styled'
import FooterNotificationH5 from './FooterNotificationH5'
import FooterH5 from './FooterH5'
import { S3Url } from "api/apiList";

const PartnerH5 = () => {
  const a1 = `${S3Url}/images/index2/partnersH5/a1.png`
  const a2 = `${S3Url}/images/index2/partnersH5/a2.png`
  const a3 = `${S3Url}/images/index2/partnersH5/a3.png`
  const a4 = `${S3Url}/images/index2/partnersH5/a4.png`
  const a5 = `${S3Url}/images/index2/partnersH5/a5.png`
  const a6 = `${S3Url}/images/index2/partnersH5/a6.png`
  const a7 = `${S3Url}/images/index2/partnersH5/a7.png`

  const a8 = `${S3Url}/images/index2/partnersH5/a8.png`
  const a9 = `${S3Url}/images/index2/partnersH5/a9.png`
  const a10 = `${S3Url}/images/index2/partnersH5/a10.png`
  const a11 = `${S3Url}/images/index2/partnersH5/a11.png`
  const a12 = `${S3Url}/images/index2/partnersH5/a12.png`
  const a13 = `${S3Url}/images/index2/partnersH5/a13.png`
  const a14 = `${S3Url}/images/index2/partnersH5/a14.png`

  const a15 = `${S3Url}/images/index2/partnersH5/a15.png`
  const a16 = `${S3Url}/images/index2/partnersH5/a16.png`
  const a17 = `${S3Url}/images/index2/partnersH5/a17.png`
  const a18 = `${S3Url}/images/index2/partnersH5/a18.png`
  const a19 = `${S3Url}/images/index2/partnersH5/a19.png`

  const images = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15, a16, a17, a18, a19]
  return (
    <Wrap className="Partner-wrap">
      <BottomBox className="exit-animation">
        <FooterH5 />
      </BottomBox>
      <Box className="exit-animation">
        <Partners>PARTNERS</Partners>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            width: '100vw', // 假设每个图片的宽度为100px，那么容器的宽度为300px，可以容纳每行三个图片
          }}
        >
          {images.map((img, index) => (
            <div key={index}>
              <img src={img} alt={`img-${index}`} style={{ width: '31.467vw', height: '12.267vw', marginTop: '5.333vw' }} />
            </div>
          ))}
        </div>

        <FooterNotificationH5></FooterNotificationH5>
      </Box>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 226.667vw;
  position: relative;

  input {
    width: 38.854vw;
    height: 4.792vw;
    background: #ffffff;
    border-radius: 0.938vw;

    font-size: 1.146vw;
    font-family: Albert Sans-Medium, Albert Sans;

    color: rgba(61, 61, 61, 1);
    line-height: 4.792vw;
    border: 0;
    padding-left: 1.25vw;
    outline: none;
    margin-right: 1.667vw;
    position: relative;
    z-index: 2;
  }

  input::-webkit-input-placeholder {
    color: rgba(229, 248, 252, 0.16);
  }
`
const Box = styled.div`
  width: 100vw;
  height: 165.333vw;
  border-radius: 9.067vw;
  background: #ebebeb;
  padding-top: 0.133vw;
  box-sizing: border-box;
  /* overflow: hidden; */
`
const Partners = styled.div`
  width: 65.6vw;
  height: 9.333vw;
  font-size: 6.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 9.333vw;
  margin: 4.8vw 32vw 0vw 2.4vw;
`
const BottomBox = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 69.6vw;
  background: #1e2020;
`
export default PartnerH5
