/* eslint-disable */
import React, { useContext, useEffect, useRef, useState } from 'react'
import Box from '@mui/system/Box'
import Modal from 'components/Modal/NewModal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Supported_Wallets, WalletCache } from 'utils/walletUtils'
import useWalletAuth from 'hooks/useWalletAuth'
import Image from 'components/Image'
import { errMsgHandler, getRandomMsg, loginByEmail, loginByWallet, requestTokenFun } from 'api/request'
import { useWeb3React } from '@web3-react/core'
import useWeb3 from 'hooks/useWeb3'
import message from 'utils/message'
import { setAuthCookie } from 'utils/user'
import Media from 'react-media'
import H5LoginModal from './H5LoginModal'
import FlexBox from 'components/FlexBox'
import { Google_Client_id, Twitter_consume_key, facebook_Client_id } from 'api/apiList'
import useModal from 'hooks/useModal'
import EmailRegistration from 'pages/Profile/components/NewRegisterModel'
import NewForgotPasswordModel from 'pages/Profile/components/NewForgotPasswordModel'

const LoginModal: React.FC<any> = (props) => {
  const [mailboxAddress, setMailboxAddress] = useState('')
  const [mailPassword, setMailPassword] = useState('')
  const [hasError, setHasError] = useState(false)

  const toLogin = () => {
    if (mailboxAddress === '') {
      setHasError(true)
      return true
    }
    if (mailPassword === '') {
      setHasError(true)
      return true
    }
    loginByEmail({ mobile_or_email: mailboxAddress, password: mailPassword })
      .then((res: any) => {
        if (res.code === 0) {
          setAuthCookie(res.data)
          onClose()
        } else {
          onClose()
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const { onClose } = useContext(ModalContext)
  const renderWallet = Supported_Wallets.filter((item: { status: any }) => item.status)

  const title = 'Log In'

  const { connect, disconnect } = useWalletAuth()
  const web3 = useWeb3()
  var { account, active, deactivate } = useWeb3React()
  const [signature, setSignature] = useState<string>('')
  const [nonce, setNonce] = useState<string>('')

  const handleWalletConnect = (val: any) => {
    if (val !== window?.ethereum) {
      window.ethereum = val
      localStorage.setItem('ethereumNum', '2')
    } else {
      localStorage.setItem('ethereumNum', '1')
    }
    connect()
  }
  const web3Ref = useRef<any>(web3)

  useEffect(() => {
    if (active && account && web3 !== web3Ref.current) {
      getRandomMsg({ wallet_address: account }).then((sign: any) => {
        setNonce(sign.data.nonce)
        web3.eth.personal
          .sign(`${sign.data.nonce}`, `${account}`, '')
          .then((value) => {
            if (value) {
              setSignature(value)
            } else {
              deactivate()
              WalletCache.removeType()
            }
          })
          .catch((err) => {
            deactivate()
            WalletCache.removeType()
            window.location.reload()
          })
      })
    }
  }, [account, active, web3, web3Ref]) // eslint-disable-line

  useEffect(() => {
    if (account && signature) {
      var registerCode
      if (sessionStorage.getItem('InvitationCode') + '' !== 'null') {
        registerCode = sessionStorage.getItem('InvitationCode') + ''
      } else {
        registerCode = ''
      }
      loginByWallet({ wallet_address: account, signature: signature, nonce: nonce, register_code: '' })
        .then((data: any) => {
          if (data.code === 0) {
            setAuthCookie(data.data)
            onClose()
          } else {
            message.warn(errMsgHandler())
            onClose()
          }
        })
        .catch((err: any) => {
          disconnect()
          onClose()
        })
    }
  }, [signature]) // eslint-disable-line

  // google登录
  const handleLogin = React.useCallback(() => {
    const url = new URL('https://accounts.google.com/o/oauth2/v2/auth')
    url.searchParams.append('client_id', Google_Client_id)
    url.searchParams.append('redirect_uri', window.location.origin)
    url.searchParams.append('response_type', 'code')
    url.searchParams.append('scope', 'openid email profile')
    url.searchParams.append('access_type', 'offline')

    // 生成并保存state参数用以防止CSRF攻击
    const state = Math.random().toString(36).substr(2)
    localStorage.setItem('loginState', state)
    url.searchParams.append('state', state)

    window.location.href = url.href
  }, [])

  // 推特登录
  const handleTwitter = () => {
    requestTokenFun({ consume_key: Twitter_consume_key })
      .then((res: any) => {
        console.log(res)
        if (res.code == 0) {
          window.location.href = `https://api.twitter.com/oauth/authorize?oauth_token=${res.data.oauth_token}`
        }
      })
      .catch((err: any) => {})
  }

  // facebook登录
  const handleFacebook = () => {
    const facebookAuthUrl = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${facebook_Client_id}&redirect_uri=${window.location.origin}`
    window.location.href = facebookAuthUrl
  }

  const [ChangeForgotPassword] = useModal(<NewForgotPasswordModel isAuthenticated={false} />)

  const [SetEmailRegistration] = useModal(<EmailRegistration isAuthenticated={false} />)

  const content = (
    <Box sx={{ margin: '0 auto', overflow: 'hidden' }}>
      <EmailBox>
        <InputBox style={{ marginTop: '1.042vw' }}>
          {hasError && (
            <TipsError>
              {mailboxAddress === '' && (
                <FlexBox>
                  <div>email is missing</div>
                </FlexBox>
              )}
            </TipsError>
          )}
          <input
            type="text"
            value={mailboxAddress}
            onChange={(evt: any) => {
              setMailboxAddress(evt.target.value || '')
              if (hasError) {
                setHasError(false)
              }
            }}
            className={hasError && mailboxAddress === '' ? 'inputError' : ''}
            placeholder="Username or email"
          />
        </InputBox>
        <InputBox style={{ marginTop: '2.604vw' }}>
          {hasError && (
            <TipsError>
              {mailboxAddress === '' && (
                <FlexBox>
                  <div>Password is missing</div>
                </FlexBox>
              )}
            </TipsError>
          )}
          <input
            type="password"
            value={mailPassword}
            onChange={(evt: any) => {
              setMailPassword(evt.target.value || '')
              if (hasError) {
                setHasError(false)
              }
            }}
            className={hasError && mailPassword === '' ? 'inputError' : ''}
            placeholder="Your password"
          />
        </InputBox>

        <ForgetPassword onClick={ChangeForgotPassword}>
          Forget password
          <QuestionMark folder="components" src="QuestionMark@2x.png" />
        </ForgetPassword>

        <FlexBox between style={{ width: '27.396vw', height: '2.813vw', margin: '0 auto' }}>
          <Login onClick={SetEmailRegistration}>Sign up</Login>
          <Login
            onClick={() => {
              toLogin()
            }}
          >
            log in
          </Login>
        </FlexBox>
      </EmailBox>

      <SmallTitle>Or login using</SmallTitle>
      <ButtonFlex FlexNum="three">
        <ImageBtnBox>
          <ImageBtn onClick={handleLogin} folder="components" src="icon_gg@2x.png" />
        </ImageBtnBox>
        <ImageBtnBox>
          <ImageBtn onClick={handleFacebook} folder="components" src="icon_fb@2x.png" />
        </ImageBtnBox>
        <ImageBtnBox>
          <ImageBtn onClick={handleTwitter} folder="components" src="icon_tt@2x.png" />
        </ImageBtnBox>
      </ButtonFlex>

      <SmallTitle>Alternative Wallet login</SmallTitle>
      <ButtonFlex>
        <ImageBtnBox>
          <ImageBtn
            folder="components"
            onClick={() => {
              handleWalletConnect(renderWallet[0].connect)
            }}
            src="icon_me@2x.png"
          />
        </ImageBtnBox>
        <ImageBtnBox>
          <ImageBtn
            folder="components"
            onClick={() => {
              handleWalletConnect(renderWallet[1].connect)
            }}
            src="icon_wa@2x.png"
          />
        </ImageBtnBox>
      </ButtonFlex>
    </Box>
  )
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Modal
            title={title}
            content={content}
            sx={{
              width: ' 32.708vw',
              minHeight: '28.75vw',
              position: 'relative',
              zIndex: 1,
            }}
          />
        ) : (
          <H5LoginModal />
        )
      }
    </Media>
  )
}

const EmailBox = styled(Box)`
  padding: 0 0 0.99vw;
  box-sizing: border-box;
  margin: 0 auto;

  input {
    width: 27.396vw;
    height: 2.604vw;
    background: #d8d8d8;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.938vw;
    text-align: left;
    font-size: 1.042vw;
    font-weight: 700;
    font-family: Albert Sans-Bold, Albert Sans;
    outline: none;
    color: #3d3d3d;
    border: 0;
    display: block;
    padding-left: 1.198vw;
    box-sizing: border-box;
  }
  input::-webkit-input-placeholder {
    font-size: 1.042vw;
    color: #b0b0b0;
  }
  .inputError {
    border: 0.208vw solid #f5a6ac;
  }
`
const InputBox = styled.div`
  width: 27.396vw;
  height: 2.604vw;
  position: relative;
  margin: 0 auto;
`
const TipsError = styled.div`
  font-size: 0.833vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #dc2727;
  position: absolute;
  left: 1.198vw;
  top: -1.302vw;
`
const ForgetPassword = styled.div`
  width: 10vw;
  height: 1.042vw;
  font-size: 0.938vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #424444;
  line-height: 1.042vw;
  margin: 1.667vw 0 0.938vw 21.198vw;
  display: flex;
  align-items: center;
  cursor: pointer;
`
const QuestionMark = styled(Image)`
  width: 1.458vw;
  height: 1.458vw;
  margin-left: 0.417vw;
`

const Login = styled.div`
  width: 11.979vw;
  height: 2.813vw;
  background: #383938;
  border-radius: 2.188vw;
  font-size: 1.042vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #ffffff;
  line-height: 2.708vw;
  text-align: center;
  cursor: pointer;
`
const SmallTitle = styled.div`
  width: fit-content;
  height: 1.042vw;
  font-size: 1.042vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #9e9e9e;
  line-height: 1.042vw;
  margin: 1.563vw 0 0.417vw 2.656vw;
`
const ImageBtnBox = styled.div`
  width: 3.125vw;
  height: 3.125vw;
  background: #666;
  border-radius: 50%;
  position: relative;
`
const ImageBtn = styled(Image)`
  width: 3.229vw;
  height: 3.229vw;
  position: absolute;
  left: -0.052vw;
  top: -0.052vw;
  cursor: pointer;
`

const ButtonFlex = styled.div<{ FlexNum?: string }>`
  width: 27.396vw;
  height: 4.271vw;
  background: #f6f6f6;
  border-radius: 0.938vw;
  margin: 0 auto;
  padding: 0 ${({ FlexNum }) => (FlexNum == 'three' ? '4.479vw' : '7.604vw')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`

export default LoginModal
/* eslint-disable */
