import React from "react"
import Box, { BoxProps } from "@mui/material/Box"
import Header from "components/Header"

const Layout: React.FC<BoxProps> = ({ children, sx = {}, ...props }) => {
  return (
    <Box
      sx={{
        ...sx,
      }}
      {...props}
    >
      {/* <Header /> */}
      {children}
      {/* <Footer/> */}
    </Box>
  )
}

export default Layout
