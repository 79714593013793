import axios from "axios"
import type { Canceler, CancelTokenSource } from "axios"
import qs from "qs"
import { baseURL } from "./apiList"
import message from "utils/message"
import { getAuthCookie, clearAuthCookie } from "utils/user"
import { WalletCache } from "utils/walletUtils"
let canceler: Canceler
const CancelToken = axios.CancelToken

axios.defaults.baseURL = baseURL
axios.defaults.timeout = 20000
axios.interceptors.request.use(
  (config: any) => {
    const token = getAuthCookie()
    const chainId = WalletCache.getChain()
    if (token) {
      config.headers.authentication = token
    }
    config.headers["x-app-id"] = baseURL.includes("marsxyz")
      ? "3b9cxer89prTdIroGvdAa9mfLM97LK1eltFRHJwRhEsKo"
      : "7a8hxez99pgvdIroTaDZh9mfON97LO1wldDGVMwEhWsHo"
    config.headers["x-app-key"] = baseURL.includes("marsxyz")
      ? "4RFhb9LrEgf3akF8KolkWxRfx9JBWaW75gO2c8lfo8Wvt"
      : "3XEhbwprbgf3akD8JplkTxYgx8JUIQT75hOIc9ldd8Wv6"
    // config.headers["chain-id"] = "256256"
    config.headers.app = "web"

    config.headers["chain-id"] = chainId

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response) {
      if (error.response.data.code === 401003) {
        clearAuthCookie()
        setTimeout(() => {
          window.location.reload()
        }, 2500)
      } else if (error.response.data.code === 502) {
        message.error("Internal error.Please try it later.")
      } else {
        message.error(error.response.data.message)
      }
    }
    return Promise.reject(error.response?.data) 
  }
)

export function post(url: string, params?: any) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export function get(url: string, params?: any) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export function getArray(url: string, params?: any) {
  return new Promise((resolve, reject) => {
    canceler?.("cancel")
    axios
      .get(url, {
        params,
        cancelToken: new CancelToken(function executor(c) {
          canceler = c
        }),
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: "repeat" })
        },
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}

export function getCancellable(url: string, params?: any, canceller?: CancelTokenSource) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params,
        cancelToken: canceller?.token,
      })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err))
  })
}
