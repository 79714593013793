export const ImgInfo = [
  {
    url: '04-03@2x',
    name: 'Alex',
    urlBg: '03@2x',
    class: 'team-img-style-three',
    Title: 'Founder | Head of Operations',
    content: 'A Web 3 community specialist and an avid gamer for over 20 years with an in-depth knowledge of multiple platforms & games. ',
    color: '#ff9c00',
  },
  {
    url: '04-04@2x',
    name: 'Edwin',
    urlBg: '01@2x',
    class: 'team-img-style-five',
    Title: 'COFOUNDER | Head of Business Development',
    content: 'An early Blockchain adopter  excellent insights into blockchain. Experinced in Incubating blockchain projects. ',
    color: '#23ca8f',
  },
  {
    url: '04-01@2x',
    name: 'Mason',
    urlBg: '01@2x',
    class: 'team-img-style-one',
    Title: 'COFOUNDER | Head of Technology ',
    content: 'Specialist in computer graphics and game development. Senior Tech Developer for over 10years. Previously working in Nvidia.',
    color: '#23ca8f',
  },
  {
    url: '04-05@2x',
    name: 'Lamar',
    urlBg: '04@2x',
    class: 'team-img-style-four',
    Title: 'Head of Community',
    content: 'Community builder with over 10 years’ experience working in various customer-focused and relationship building roles. ',
    color: '#5c7fff',
  },

  {
    url: '04-02@2x',
    name: 'Kathy',
    urlBg: '02@2x',
    class: 'team-img-style-two',
    Title: 'Head of Partnerships ',
    content: 'An experienced sales account manager, client onboarding, and relationship executive ',
    color: '#f74dd1',
  },
  {
    url: '04-07@2x',
    name: 'Philp',
    urlBg: '03@2x',
    class: 'team-img-style-serve',
    Title: 'Head of DApps Development',
    content: 'Senior Software Developer. Early dapp developer of many famous blockchains like Etherum , Difinity and Solana. ',
    color: '#ff9c00',
  },
  {
    url: '04-06@2x',
    name: 'Liz',
    urlBg: '02@2x',
    class: 'team-img-style-six',
    Title: 'Concept Art Director',
    content: 'Working in the film and game industry for over 10 years. Good at environment concept design, digital scenic painting and illustration for graphics.',
    color: '#f74dd1',
  },

  {
    url: '04-08@2x',
    name: 'Lennie',
    urlBg: '04@2x',
    class: 'team-img-style-eight',
    Title: '3D Art Director',
    content: 'Independent 3d Artist produced 3d assets for animation, gaming and film. She has worked on several independent games as a 3d art director.',
    color: '#5c7fff',
  },
]
