import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Loading from './components/Loading'
import Video from './components/Video'
import MarqueeBox from './components/Marquee'
import DownLoad from './components/DownLoad'
import GameMap from './components/GameMap'
import ContactUS from './components/ContactUS'
import Footer from './components/Footer'
import { gsap } from 'gsap'
import Media from 'react-media'
import LoadingH5 from './components/LoadingH5'
import VideoH5 from './components/VideoH5'
import DownLoadH5 from './components/DownLoadH5'
import GameMapH5 from './components/GameMapH5'
import MarqueeH5 from './components/MarqueeH5'
import ContactUSH5 from './components/ContactUSH5'
import FooterH5 from './components/FooterH5'
import useSize from 'hooks/useSize'
import PartyCity from './components/PartyCity'
import PartyCityH5 from './components/PartyCityH5'
import { S3Url } from 'api/apiList'

const Game: React.FC<any> = () => {
  const { pcSize } = useSize()
  window.scrollTo(0, 0)
  // 第一页所用图片
  const DMP = `${S3Url}/images/Game/Loading/DMP.png`
  const DMP1 = `${S3Url}/images/Game/Loading/DMP1.png`
  const FPU = `${S3Url}/images/Game/Loading/FPU.png`
  const loading_donghua = `${S3Url}/images/Game/Loading/loading_donghua@2x.png`
  const di_loadingi = `${S3Url}/images/Game/Loading/di_loadingi@2x.png`
  const di_loadingzi = `${S3Url}/images/Game/Loading/di_loadingzi@2x.png`
  const di_w_dog = `${S3Url}/images/Game/Loading/di_w_dog@2x.png`
  const di_w_su = `${S3Url}/images/Game/Loading/di_w_su.png`
  const di_w_yu = `${S3Url}/images/Game/Loading/di_w_yu@2x.png`

  // 第二页所用图片

  const btn_1 = `${S3Url}/images/Game/Video/btn_1@2x.png`
  const btn_2 = `${S3Url}/images/Game/Video/btn_2@2x.png`
  const btn_3 = `${S3Url}/images/Game/Video/btn_3@2x.png`
  const btn_4 = `${S3Url}/images/Game/Video/btn_4@2x.png`
  const btn_5 = `${S3Url}/images/Game/Video/btn_5@2x.png`
  const di_yinliang = `${S3Url}/images/Game/Video/di_yinliang@2x.png`
  const di_yinliang_kai = `${S3Url}/images/Game/Video/di_yinliang_kai@2x.png`
  const di_youtube = `${S3Url}/images/Game/Video/di_youtube@2x.png`
  const logo_pp = `${S3Url}/images/Game/Video/logo_pp@2x.png`
  const logo_sky = `${S3Url}/images/Game/Video/logo_sky@2x.png`
  const logo_xian = `${S3Url}/images/Game/Video/logo_xian@2x.png`
  const VideoBg = `${S3Url}/images/Game/Video/VideoBg.png`
  const btn_GooglePlay = `${S3Url}/images/Game/Video/btn_GooglePlay.png`
  // 第二第三公用图片
  const btn_a = `${S3Url}/images/Game/Video/btn_a@2x.png`
  const btn_w = `${S3Url}/images/Game/Video/btn_w@2x.png`
  const btn_Android = `${S3Url}/images/Game/Video/btn_Android.png`

  const CityBg = `${S3Url}/images/Game/PartyCity/CityBg.png`
  const CityImg = `${S3Url}/images/Game/PartyCity/CityImg.png`

  // 第四页所用图片
  const di_guanggao1 = `${S3Url}/images/Game/GameMap/di_guanggao1@2x.png`
  const di_guanggao2 = `${S3Url}/images/Game/GameMap/di_guanggao2@2x.png`
  const di_guanggao3 = `${S3Url}/images/Game/GameMap/di_guanggao3@2x.png`
  const di_guanggao4 = `${S3Url}/images/Game/GameMap/di_guanggao4@2x.png`
  const di_guanggao5 = `${S3Url}/images/Game/GameMap/di_guanggao5@2x.png`
  const di_guanggao6 = `${S3Url}/images/Game/GameMap/di_guanggao6@2x.png`
  const di_hongsedi = `${S3Url}/images/Game/GameMap/di_hongsedi@2x.png`
  const WordWar = `${S3Url}/images/Game/GameMap/WordWar.png`
  const ChaosCourseImg = `${S3Url}/images/Game/GameMap/ChaosCourse.png`

  // 第五页所用图片
  const di_huangsedi = `${S3Url}/images/Game/Marquee/di_huangsedi@2x.png`

  // 第六页所用的图片

  const bt_Discord = `${S3Url}/images/Game/ContactUS/bt_Discord@2x.png`
  const bt_linktree = `${S3Url}/images/Game/ContactUS/bt_linktree@2x.png`
  const bt_medium = `${S3Url}/images/Game/ContactUS/bt_medium@2x.png`
  const bt_twitter = `${S3Url}/images/Game/ContactUS/bt_twitter@2x.png`
  const di_blue = `${S3Url}/images/Game/ContactUS/di_blue@2x.png`
  const di_gou = `${S3Url}/images/Game/ContactUS/di_gou@2x.png`
  const di_yang = `${S3Url}/images/Game/ContactUS/di_yang@2x.png`

  // Footer 所用图片
  const di_haidao = `${S3Url}/images/Game/Footer/di_haidao@2x.png`
  const di_fawu = `${S3Url}/images/Game/Footer/di_fawu@2x.png`
  const logo_sketopia_zi = `${S3Url}/images/Game/Footer/logo_sketopia_zi@2x.png`

  const candyChaos = `${S3Url}/mp4/candyChaos.mp4`
  const Football = `${S3Url}/mp4/football.mp4`
  const Mushroom = `${S3Url}/mp4/mushroom.mp4`
  const FugitiveFinder = `${S3Url}/mp4/finder.mp4`
  const WordWarVideo = `${S3Url}/mp4/WordWar.mp4`

  const ArcticAssault = `${S3Url}/mp4/ArcticAssault.mp4`
  const ChaosCourse = `${S3Url}/mp4/ChaosCourse.mp4`

  const index = `${S3Url}/mp4/index.mp4`

  // Loading
  const FirstImgArray = [di_loadingi, di_w_dog, di_w_su, di_w_yu, di_loadingzi, DMP, DMP1, FPU, loading_donghua]
  const SecondImgArray = [btn_1, btn_2, btn_3, btn_4, btn_5, di_yinliang, di_youtube, di_yinliang_kai, logo_pp, logo_sky, logo_xian, btn_a, btn_w, VideoBg, btn_Android, btn_GooglePlay]
  const ThirdArray = [CityBg, CityImg]
  const fourthArray = [di_guanggao1, di_guanggao2, di_guanggao3, di_guanggao4, di_guanggao5, di_guanggao6, di_hongsedi, WordWar, ChaosCourseImg]
  const fifthArray = [di_huangsedi]
  const sixthArray = [bt_Discord, bt_linktree, bt_medium, bt_twitter, di_blue, di_gou, di_yang]
  const seventhArray = [di_haidao, di_fawu, logo_sketopia_zi]

  const indexvideoUrl = [index]
  const FirstvideoUrl = [FugitiveFinder, Mushroom, candyChaos, Football, WordWarVideo, ArcticAssault, ChaosCourse]

  const imageUrls: string[] = [...FirstImgArray, ...SecondImgArray, ...ThirdArray, ...fourthArray, ...fifthArray, ...sixthArray, ...seventhArray]
  const videoUrls: string[] = [...FirstvideoUrl, ...indexvideoUrl]

  const [loadedMediaCount, setLoadedMediaCount] = useState(0)
  const [H5loadedMediaCount, setH5LoadedMediaCount] = useState(0)

  // 移动端图片

  // 第一页所用图片
  const di_loadingiH5 = `${S3Url}/images/GameH5/Loading/di_loadingi@2x.png`
  const di_loadingziH5 = `${S3Url}/images/GameH5/Loading/di_loadingzi@2x.png`
  const di_loadingziBottomH5 = `${S3Url}/images/GameH5/Loading/di_loadingziBottom@2x.png`
  const loading_donghuaH5 = `${S3Url}/images/GameH5/Loading/loading_donghua@2x.png`

  // 第二页所用图片

  const btn_1H5 = `${S3Url}/images/GameH5/Video/btn_1@2x.png`
  const btn_2H5 = `${S3Url}/images/GameH5/Video/btn_2@2x.png`
  const btn_3H5 = `${S3Url}/images/GameH5/Video/btn_3@2x.png`
  const btn_4H5 = `${S3Url}/images/GameH5/Video/btn_4@2x.png`
  const btn_5H5 = `${S3Url}/images/GameH5/Video/btn_5@2x.png`
  const di_yinliangH5 = `${S3Url}/images/GameH5/Video/di_yinliang@2x.png`
  const di_yinliang_kaiH5 = `${S3Url}/images/GameH5/Video/di_yinliang_kai@2x.png`
  const di_youtubeH5 = `${S3Url}/images/GameH5/Video/di_youtube@2x.png`
  const logo_ppH5 = `${S3Url}/images/GameH5/Video/logo_pp@2x.png`
  const logo_skyH5 = `${S3Url}/images/GameH5/Video/logo_sky@2x.png`
  const VideoBgH5 = `${S3Url}/images/GameH5/Video/VideoBg.png`
  const btn_aH5 = `${S3Url}/images/GameH5/Video/btn_a@2x.png`
  const btn_wH5 = `${S3Url}/images/GameH5/Video/btn_w@2x.png`
  const btn_AndroidH5 = `${S3Url}/images/GameH5/Video/btn_AndroidH5.png`
  const btn_GooglePlayH5 = `${S3Url}/images/GameH5/Video/btn_GooglePlayH5.png`

  const CityBgH5 = `${S3Url}/images/GameH5/PartyCity/CityBgH5.png`
  const CityImgH5 = `${S3Url}/images/GameH5/PartyCity/CityImgH5.png`

  // 第四页所用图片
  const di_guanggao1H5 = `${S3Url}/images/GameH5/GameMap/di_guanggao1@2x.png`
  const di_guanggao2H5 = `${S3Url}/images/GameH5/GameMap/di_guanggao2@2x.png`
  const di_guanggao3H5 = `${S3Url}/images/GameH5/GameMap/di_guanggao3@2x.png`
  const di_guanggao4H5 = `${S3Url}/images/GameH5/GameMap/di_guanggao4@2x.png`
  const di_guanggao5H5 = `${S3Url}/images/GameH5/GameMap/di_guanggao5@2x.png`
  const di_hongsediH5 = `${S3Url}/images/GameH5/GameMap/di_hongsedi@2x.png`
  const videoStart = `${S3Url}/images/GameH5/Video/videoStart.png`
  const StayTuned = `${S3Url}/images/GameH5/GameMap/StayTuned.png`
  const WordWarH5 = `${S3Url}/images/GameH5/GameMap/WordWarH5.png`
  const ChaosCourseH5 = `${S3Url}/images/GameH5/GameMap/ChaosCourseH5.png`
  // 第五页所用图片
  const di_huangsediH5 = `${S3Url}/images/GameH5/Marquee/di_huangsedi@2x.png`
  // 第六页所用的图片

  const bt_DiscordH5 = `${S3Url}/images/GameH5/ContactUS/bt_Discord@2x.png`
  const bt_linktreeH5 = `${S3Url}/images/GameH5/ContactUS/bt_linktree@2x.png`
  const bt_mediumH5 = `${S3Url}/images/GameH5/ContactUS/bt_medium@2x.png`
  const bt_twitterH5 = `${S3Url}/images/GameH5/ContactUS/bt_twitter@2x.png`
  const di_blueH5 = `${S3Url}/images/GameH5/ContactUS/di_blue@2x.png`
  const di_gouH5 = `${S3Url}/images/GameH5/ContactUS/di_gou@2x.png`
  const di_yangH5 = `${S3Url}/images/GameH5/ContactUS/di_yang@2x.png`
  // Footer 所用图片
  const di_haidaoH5 = `${S3Url}/images/GameH5/Footer/di_haidao@2x.png`
  const di_fawuH5 = `${S3Url}/images/GameH5/Footer/di_fawu@2x.png`

  const FirstImgArrayH5 = [di_loadingiH5, di_loadingziH5, di_loadingziBottomH5, loading_donghuaH5]
  const ThirdArrayH5 = [CityBgH5, CityImgH5]
  const SecondImgArrayH5 = [btn_1H5, btn_2H5, btn_3H5, btn_4H5, btn_5H5, di_yinliangH5, di_yinliang_kaiH5, di_youtubeH5, logo_ppH5, logo_skyH5, VideoBgH5, btn_aH5, btn_wH5, btn_AndroidH5, btn_GooglePlayH5]
  const fourthArrayH5 = [di_guanggao1H5, di_guanggao2H5, di_guanggao3H5, di_guanggao4H5, di_guanggao5H5, di_hongsediH5, videoStart, StayTuned, WordWarH5, ChaosCourseH5]
  const fifthArrayH5 = [di_huangsediH5]
  const sixthArrayH5 = [bt_DiscordH5, bt_linktreeH5, bt_mediumH5, bt_twitterH5, di_blueH5, di_gouH5, di_yangH5]
  const seventhArrayH5 = [di_haidaoH5, di_fawuH5]
  const H5imageUrls: string[] = [...FirstImgArrayH5, ...SecondImgArrayH5, ...ThirdArrayH5, ...fourthArrayH5, ...fifthArrayH5, ...sixthArrayH5, ...seventhArrayH5]

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.fromTo(entry.target, { y: 60 }, { y: 0, duration: 1 })
        }
      })
    })

    const elements = document.querySelectorAll('.GameStyle')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  useEffect(() => {
    if (pcSize) {
      imageUrls.forEach((url) => {
        const image: any = new Image()
        image.src = url
        image.onload = () => {
          setLoadedMediaCount((prev) => prev + 1)
        }
      })
      videoUrls.forEach((url) => {
        const video = document.createElement('video')
        video.src = url
        video.onloadeddata = () => {
          setLoadedMediaCount((prev) => prev + 1)
        }
      })
    } else {
      H5imageUrls.forEach((url) => {
        const image: any = new Image()
        image.src = url
        image.onload = () => {
          setH5LoadedMediaCount((prev) => prev + 1)
        }
      })
      videoUrls.forEach((url) => {
        const video = document.createElement('video')
        video.src = url
        video.onloadeddata = () => {
          setH5LoadedMediaCount((prev) => prev + 1)
        }
      })
    }
  }, [imageUrls.length, videoUrls.length, pcSize, H5imageUrls.length]) // eslint-disable-line

  const totalMediaCount = imageUrls.length + videoUrls.length
  const progress = Math.round((loadedMediaCount / totalMediaCount) * 100)

  const H5totalMediaCount = H5imageUrls.length + videoUrls.length
  const progressH5 = Math.round((H5loadedMediaCount / H5totalMediaCount) * 100)

  return (
    <Warp className="homewarp">
      <Media query={{ maxWidth: 1000 }}>
        {(matches) =>
          !matches ? (
            <Contentbg>
              <Loading progress={progress} imageUrls={FirstImgArray} />
              <Video imageUrls={SecondImgArray} videoUrls={indexvideoUrl} />
              <DownLoad />
              <GameMap imageUrls={fourthArray} videoUrls={FirstvideoUrl} />
              <PartyCity imageUrls={ThirdArray} />
              <MarqueeBox imageUrls={fifthArray} />
              <ContactUS imageUrls={sixthArray} />
              <Footer imageUrls={seventhArray} />
            </Contentbg>
          ) : (
            <Contentbg>
              <LoadingH5 progress={progressH5} imageUrls={FirstImgArrayH5} />
              <VideoH5 imageUrls={SecondImgArrayH5} videoUrls={indexvideoUrl} />
              <DownLoadH5 />
              <GameMapH5 imageUrls={fourthArrayH5} videoUrls={FirstvideoUrl} />
              <PartyCityH5 imageUrls={ThirdArrayH5} />
              <MarqueeH5 imageUrls={fifthArrayH5} />
              <ContactUSH5 imageUrls={sixthArrayH5} />
              <FooterH5 imageUrls={seventhArrayH5} />
            </Contentbg>
          )
        }
      </Media>
    </Warp>
  )
}
const Contentbg = styled.div`
  background: #f3f3f3;
  width: 100%;
  overflow: hidden;
`
const Warp = styled.div`
  .skytopia-bg-text {
    box-sizing: border-box;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: block !important;
  }
`

export default Game
