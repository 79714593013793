import { getArray as pull, post, get, getCancellable } from './axios'
import {
  createRandomAPI,
  createAccessTokenWithSignature,
  band_emailAPI,
  login_by_emailAPI,
  personalAPI,
  getCountdownAPI,
  send_smsAPI,
  change_pwdAPI,
  reset_pwdAPI,
  proofAPI,
  modify_emailAPI,
  NfTlistAPI,
  recaptchaAPI,
  contractsAPI,
  progressAPI,
  priceAPI,
  contractListAPI,
  withdrawAPI,
  assetListAPI,
  withdraw_infoAPI,
  productListAPI,
  partyplanetAPI,
  inviteRewardAPI,
  inviteSwitchAPI,
  storeOrderAPI,
  checkWhiteListAPI,
  orderListAPI,
  googleLoginAPI,
  requestTokenAPI,
  twitterLoginAPI,
  facebookLoginAPI,
  facebookCodeAPI,
  registerAPI,
  UnLoginsend_smsAPI,
  BandWalletAPI,
} from './apiList'

export const errMsgHandler = () => {
  return 'Internal error.Please try it later.'
}

// 钱包登录
export const loginByWallet = (params: { wallet_address: string; signature: string; nonce: string; register_code: any }) => post(createAccessTokenWithSignature, params)

// 钱包登录前获取随机字符串
export const getRandomMsg = (params: { wallet_address: string }) => {
  return get(createRandomAPI, params)
}

// 设置个人信息
export const personalFun = (params: { description: string; name: string; username: string; personal_web: string; twitter: string; discord: string; instagram: string; telegram: string; image: string; area: string }) => post(personalAPI, params)

// 获取个人信息
export const getPersonalFun = () => {
  return get(personalAPI)
}

// 获取NFT倒计时
export const getCountdownFun = (params: { contract_address: any }) => {
  return get(getCountdownAPI, params)
}

// 邮箱登录
export const loginByEmail = (params: { mobile_or_email: string; password: string }) => post(login_by_emailAPI, params)

// 绑定邮箱
export const band_emailFun = (params: { password: string; mobile_or_email: string; code: string; platform: string; register_code: number }) => post(band_emailAPI, params)

// 获取邮箱验证码
export const getsend_smsFun = (params: { mobile_or_email: string }) => {
  return get(send_smsAPI, params)
}

// 修改登录密码
export const change_pwdFun = (params: { old_password: string; new_password: string; mobile_or_email: string }) => post(change_pwdAPI, params)

// 重置密码
export const reset_pwdFun = (params: { new_password: string; mobile_or_email: string; code: string }) => post(reset_pwdAPI, params)

// 获取NFT proof
export const getproofFun = (params: { contract_address: any }) => {
  return get(proofAPI, params)
}

// 修改邮箱
export const modify_emailFun = (params: { new_mobile_or_email: string; platform: string; code: string; password: string }) => post(modify_emailAPI, params)

// 获取nft列表
export const NfTlistFun = (params: { contract_address: string }) => get(NfTlistAPI, params)

// 校验recaptcha
export const recaptchaFun = (params: { token: string }) => post(recaptchaAPI, params)

// 校验recaptcha
export const contractsFun = () => get(contractsAPI)

// 获取NFTmint进度
export const progressFun = (params: { contract_address: any }) => get(progressAPI, params)

// 获取TOKEN CMP价格
export const priceFun = (params: { token: string }) => get(priceAPI, params)

// 获取NFT合约列表
export const contractListFun = () => get(contractListAPI)

// 提币
export const withdrawFun = (params: { token: string; contract_address: string; code: string; amount: number }) => post(withdrawAPI, params)

// 获取用户资产
export const assetListFun = (params: { asset_name: string }) => get(assetListAPI, params)

// 提币信息
export const withdrawInfoFun = (params: { token: string; contract_address: string }) => get(withdraw_infoAPI, params)

// 商品列表
export const productListFun = () => get(productListAPI)

// 用户下单
export const storeOrderFun = (params: { product_id: string; amount: string; code: string }) => post(storeOrderAPI, params)

// 获取客户端版本号以及下载路径
export const partyplanetFun = () => get(partyplanetAPI)

// 邀请list查询
export const inviteRewardFun = () => get(inviteRewardAPI)

// 邀请码开关状态：
export const inviteSwitchFun = () => get(inviteSwitchAPI)

// 检查是否白名单
export const checkWhiteListFun = (params: { contract_address: any; wallet_address: any }) => get(checkWhiteListAPI, params)

// 用户订单
export const orderListFun = () => get(orderListAPI)

// 谷歌登录
export const googleLoginFun = (params: { client_id: string; token_code: string; redirect_uri: string }) => post(googleLoginAPI, params)

// Twitter_request_token
export const requestTokenFun = (params: { consume_key: string }) => get(requestTokenAPI, params)

// Twitter登录
export const twitterLoginFun = (params: { oauth_token: string; oauth_verifier: string; consume_key: string }) => post(twitterLoginAPI, params)

// facebook_code 获取code
export const facebookCodeFun = (params: { state: any }) => get(facebookCodeAPI, params)

// facebook_login
export const facebookLogiFun = (params: { code: any; client_id: string }) => post(facebookLoginAPI, params)

// 官网邮箱注册
export const registerFun = (params: { mobile_or_email: string; sms_code: string; register_code: string; platform: string; password: string }) => post(registerAPI, params)

// 未注册用户获取验证码
export const getUnLoginsend_smsFun = (params: { mobile_or_email: string }) => {
  return get(UnLoginsend_smsAPI, params)
}

// 绑定钱包连接
export const BandWalletFun = (params: { wallet_address: any; nonce: string; signature: string }) => post(BandWalletAPI, params)
