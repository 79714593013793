import styled from '@emotion/styled'
import Image from 'components/Image'
import { Link, useNavigate } from 'react-router-dom'

const FooterNotification = () => {
  const MoveScroller = (ScrollHight: number = 0) => {
    const screenHeight = window.innerHeight
    const vh = screenHeight / 100

    window.scrollTo({
      top: ScrollHight * vh,
      behavior: 'smooth',
    })
  }

  const navigate = useNavigate()
  const handleClose = (e: any) => {
    e.stopPropagation()
    navigate('/privacyagreement')
  }

  return (
    <Footer className="footer-animation">
      <LeftBox className="Partner">
        <LogoImg className="footer-img-style" folder="index2/Partner" src="marsxyz.png" />
        <SkytopiaLabs>© 2024 MarsXYZ Ltd</SkytopiaLabs>
        <Term>Term & Conditions</Term>

        <Copyright>
          Copyright 2024 <Elliptic></Elliptic>
          {/* <Terms>Terms of Service</Terms>
          <Elliptic></Elliptic> */}
          <Terms onClick={handleClose}>Privacy Policy</Terms>
        </Copyright>
      </LeftBox>
      <RightBox className="Partner">
        <NameList>
          <Name
            onClick={() => {
              MoveScroller(490)
            }}
          >
            · Games
          </Name>
          <Name
            onClick={() => {
              MoveScroller(990)
            }}
          >
            · Team
          </Name>
          <Name
            onClick={() => {
              MoveScroller(1090)
            }}
          >
            · Roadmap
          </Name>
          <Name
            onClick={() => {
              MoveScroller(1190)
            }}
          >
            · Partners
          </Name>
          {/* <Name
            onClick={() => {
              MoveScroller(1385)
            }}
          >
            · Contact US
          </Name> */}
        </NameList>
        <NameList>
          <Name
            onClick={() => {
              MoveScroller(0)
            }}
          >
            · Home
          </Name>
          <Name
            onClick={() => {
              MoveScroller(133)
            }}
          >
            · Skytopia
          </Name>
          <Name
            onClick={() => {
              MoveScroller(285)
            }}
          >
            · Features
          </Name>
          <Name
            onClick={() => {
              MoveScroller(385)
            }}
          >
            · Story
          </Name>
          {/* <Name
            onClick={() => {
              MoveScroller(485)
            }}
          >
            · NFT
          </Name> */}
        </NameList>
      </RightBox>
    </Footer>
  )
}
const Footer = styled.div`
  position: absolute;
  left: 10.938vw;
  bottom: 0;
  width: 78.125vw;
  height: 19.063vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LeftBox = styled.div`
  width: 15.885vw;
  height: 8.958vw;
`

const LogoImg = styled(Image)`
  width: 5.208vw;
  height: 5.208vw;
`

const SkytopiaLabs = styled.div`
  width: 100%;
  height: 1.563vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #7b7b7b;
  line-height: 1.563vw;
  margin: 0.521vw 0 0.104vw;
`
const Term = styled.div`
  width: 100%;
  height: 1.563vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #7b7b7b;
  line-height: 1.563vw;
`

const RightBox = styled.div`
  width: 19.063vw; //366px
  height: 10.365vw;
  display: flex;
`
const NameList = styled.div`
  width: 9.531vw; //366px
  height: 10.365vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`
const Name = styled.a`
  width: 9.531vw;
  height: 1.563vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #646464;
  line-height: 1.563vw;
  cursor: pointer;
`

const Copyright = styled.div`
  width: 50vw;
  height: 1.5vw;
  font-size: 0.885vw;
  font-family: Albert Sans;
  font-weight: bold;
  color: #646464;
  display: flex;
  align-items: center;
`
const Elliptic = styled.div`
  width: 0.313vw;
  height: 0.313vw;
  background: #646464;
  border-radius: 50%;
  margin: 0 0.781vw;
`
const Terms = styled.div`
  width: fit-content;
  font-size: 0.885vw;
  font-family: Albert Sans;
  font-weight: bold;
  color: #646464;
  text-decoration: underline;
  cursor: pointer;
`

export default FooterNotification
