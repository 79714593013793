import BigNumber from "bignumber.js"

BigNumber.config({ ROUNDING_MODE: 0 })

export const getBalanceNumber = (balance: BigNumber, decimals = 18, scale = 2) => {
  let number: any = new BigNumber(0)
  const ZERO = new BigNumber(0)

  try {
    number = new BigNumber(balance).dividedBy(new BigNumber(10).pow(decimals)).toFormat(scale)
  } catch (e) {
    console.log(e)
  }

  return number
}

export const getmultDisplayBalance = (balance: BigNumber, decimals = 18) => {
  return balance.multipliedBy(new BigNumber(10).pow(decimals)).toNumber()
}

export const getFullDisplayBalance = (balance: BigNumber, decimals = 18) => {
  return balance.dividedBy(new BigNumber(10).pow(decimals))
}
