import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { S3Url } from "api/apiList";

interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress: number
}
const LoadingH5: React.FC<PreloaderProps> = ({ progress, imageUrls }) => {
  let [di_loadingiH5, di_loadingziH5, di_loadingziBottomH5, loading_donghuaH5] = imageUrls
  gsap.registerPlugin(ScrollTrigger)

  const isAnimationPlayed = useRef(false)

  useEffect(() => {
    if (progress >= 96 && !isAnimationPlayed.current) {
      isAnimationPlayed.current = true
      setTimeout(() => {
        gsap.fromTo('.loading-Wrap', { y: '0%' }, { y: '-100%', duration: 1.5 })
      }, 2000)
    }
  }, [progress])

  useEffect(() => {
    const oneAnimation = lottie.loadAnimation({
      container: document.getElementsByClassName('one')[0],
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `${S3Url}/json/loading_data.json`,
    })
  }, [])

  return (
    <Wrap className="loading-Wrap">
      <LoadingBox>
        <Leaf>
          <LeafText>{progress}%</LeafText>
          <LeafImg src={di_loadingiH5} alt=""></LeafImg>
        </Leaf>
        {/* <LoadingImg src={loading_donghuaH5} alt=""></LoadingImg> */}
        <div className="one"></div>
      </LoadingBox>
      <LoadingLineTop src={di_loadingziH5} alt="" />
      <LoadingLineBottom src={di_loadingziBottomH5} alt="" />
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  background-color: RGBA(50, 103, 217, 1);
  position: absolute;
  z-index: 10;
  overflow: hidden;
  .one {
    width: 35.733vw;
    height: 15.2vw;
    transform: translateY(-2.083vw);
  }
`

const LoadingLineTop = styled.img`
  width: auto;
  height: 35vh;
  display: block;
  position: absolute;
  top: -7.2vw;
  left: 50%;
  transform: translateX(-50%);
`
const LoadingLineBottom = styled.img`
  width: auto;
  height: 35vh;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`

const LoadingBox = styled.div`
  width: 36.8vw;
  height: 48vw;
  /* background-color: #000; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const Leaf = styled.div`
  width: 36.8vw;
  height: 31.733vw;
  margin: 0 auto 1.067vw;
  position: relative;
`
const LeafText = styled.div`
  width: 36.8vw;
  height: 31.733vw;
  line-height: 31.733vw;
  position: absolute;
  text-align: center;
  font-size: 8.533vw;
  font-family: Milky Nice;
  color: #fad121;
  margin-left: 0.26vw;
`
const LoadingImg = styled.img`
  width: 35.733vw;
  height: 15.2vw;
`
const LeafImg = styled.img`
  width: 36.8vw;
  height: 31.733vw;
`
export default LoadingH5
