/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"
import Modal from "components/Modal"
import styled from "@emotion/styled"
import { ModalContext } from "context/ModalProvider"
import { Button } from "@mui/material"
import FlexBox from "components/FlexBox"
import { useNavigate } from "react-router-dom"

const NavigationH5: React.FC<any> = () => {
  const { onClose } = useContext(ModalContext)

  const title = "purchase succeeded"
  const navigate = useNavigate()
  const handleToDetails = (e: any) => {
    e.stopPropagation()
    onClose()
    navigate("/store")
  }
  useEffect(() => {
    const interval = setInterval(async () => {
      onClose()
      navigate("/store")
    }, 3000)
    return () => clearInterval(interval)
  }, [])
  const content = (
    <Warp>
      <Title>Return to the Store(3S)</Title>
      <FlexBox between sx={{ width: "100%" }}>
        <Signin
          onClick={() => {
            onClose()
          }}
        >
          cancel
        </Signin>
        <Signin onClick={handleToDetails}>store</Signin>
      </FlexBox>
    </Warp>
  )
  return (
    <Modal
      title={title}
      content={content}
      sx={{
        width: "66vw",
        minHeight: "39.2vw",
      }}
    />
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4.267vw 2.267vw 4vw;
  position: relative;
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
// const Signin = styled(Button)`
//   width: 10.938vw;
//   height: 3.438vw;
//   background: #5c7fff;
//   border-radius: 1.042vw;
//   font-size: 1.667vw;
//   font-family: Albert Sans-Bold, Albert Sans;
//   color: #ffffff;
//   &:hover {
//     -webkit-text-decoration: none;
//     text-decoration: none;
//     background: #5c7fff;
//   }
// `
const Signin = styled(Button)`
  background: #5c7fff;
  font-size: 3.867vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
  width: 28.8vw;
  height: 7.867vw;
  padding: 0;
  border-radius: 3.867vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  height: 4vw;
  font-size: 4.533vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin: 0 auto 6.4vw;
`

export default NavigationH5
/* eslint-disable */
