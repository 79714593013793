/* eslint-disable */
import styled from '@emotion/styled'
import { useContext, useEffect, useRef, useState } from 'react'
import Image from 'components/Image'
import FlexBox from 'components/FlexBox'
import { useWeb3React } from '@web3-react/core'
import useWeb3 from 'hooks/useWeb3'
import { Button } from '@mui/material'
import BigNumber from 'bignumber.js'
import { getBalanceNumber } from 'utils/formatBalance'
import { BandWalletFun, assetListFun, errMsgHandler, getPersonalFun, getRandomMsg, priceFun } from 'api/request'
import useModal from 'hooks/useModal'
import TransferModel from './TransferModel'
import WithdrawModel from './WithdrawModel'
import { useTokenBalance } from 'hooks/useTokenBalance'
import useBlock from 'hooks/useBlock' //获取当前区块高度
import { Supported_Wallets, WalletCache } from 'utils/walletUtils'
import useWalletAuth from 'hooks/useWalletAuth'
import { setAuthCookie } from 'utils/user'
import { ModalContext } from 'context/ModalProvider'
import message from 'utils/message'
import { getShortHash } from 'utils'
import useSize from 'hooks/useSize'

const Wallet = () => {
  const [CMPBanlance, setCMPBanlance] = useState<any>()
  // const [CMPPrice, setCMPPrice] = useState<any>()
  const [FPUInfo, setFPUInfo] = useState<any>([])
  const { pcSize } = useSize()

  const web3 = useWeb3()
  // const getAssetList = async () => {
  //   assetListFun({ asset_name: 'FPU' })
  //     .then((res: any) => {
  //       setFPUInfo(res.data[0])
  //     })
  //     .catch((err: any) => {})
  // }
  const block = useBlock()
  // useEffect(() => {
  //   if (pcSize) {
  //     account && getAssetList()
  //   }
  // }, [block, account])

  useEffect(() => {
    let isCancelled = false

    const getAssetList = () => {
      assetListFun({ asset_name: 'FPU' })
        .then((res: any) => {
          if (!isCancelled) {
            setFPUInfo(res.data[0])
          }
        })
        .catch((err: any) => {
          // handle error
        })
    }

    if (pcSize && account && !isCancelled) {
      getAssetList()
    }

    return () => {
      isCancelled = true
    }
  }, [block, account])

  const FPU = '0xcFc394db49D0A1582b3BF458A1789f08A5A792fe'
  const BigFPU = useTokenBalance(FPU)
  const FPUBalance = getBalanceNumber(BigFPU)

  useEffect(() => {
    const getBalance = async () => {
      const balance = await web3.eth.getBalance(`${account}`)
      setCMPBanlance(getBalanceNumber(new BigNumber(balance), 18))
    }
    // const getPrice = async () => {
    //   priceFun({ token: "cmp" })
    //     .then((res: any) => {
    //       setCMPPrice(res.data.price)
    //     })
    //     .catch((err: any) => {})
    // }
    account && getBalance()
    // account && getPrice()
  }, [account])

  const [TransfeHandler] = useModal(<TransferModel available_amount={FPUInfo?.available_amount} />)
  const [WithdrawHandler] = useModal(<WithdrawModel available_amount={FPUInfo?.available_amount} />)

  const { connect, disconnect } = useWalletAuth()
  var { account, active, deactivate } = useWeb3React()
  const { onClose } = useContext(ModalContext)
  const renderWallet = Supported_Wallets.filter((item: { status: any }) => item.status)

  const handleWalletConnect = (val: any) => {
    if (val !== window?.ethereum) {
      window.ethereum = val
      localStorage.setItem('ethereumNum', '2')
    } else {
      localStorage.setItem('ethereumNum', '1')
    }

    if (active) {
      sessionStorage.setItem('ConnectWaller', 'open')
    } else {
      connect(() => {
        sessionStorage.setItem('ConnectWaller', 'open')
      })
    }
  }

  const web3Ref = useRef<any>(web3)

  useEffect(() => {
    if (active && account && web3 !== web3Ref.current && sessionStorage.getItem('ConnectWaller') === 'open') {
      // 先获取nonce
      getRandomMsg({ wallet_address: account }).then((sign: any) => {
        web3.eth.personal
          .sign(`${sign.data.nonce}`, `${account}`, '')
          .then((value) => {
            // 获取signature
            if (value) {
              // 绑定钱包
              BandWalletFun({ wallet_address: account, signature: value, nonce: sign.data.nonce })
                .then((data: any) => {
                  if (data.code === 0) {
                    getPersonalFun()
                      .then((res: any) => {
                        localStorage.setItem('account', res.data.wallet_address)
                        window.location.reload()
                      })
                      .catch((err: any) => {})
                  } else {
                    message.warn(errMsgHandler())
                  }
                })
                .catch((err: any) => {
                  disconnect()
                })
                .finally(() => {
                  sessionStorage.removeItem('ConnectWaller')
                })
            } else {
              deactivate()
              WalletCache.removeType()
            }
          })
          .catch((err) => {
            sessionStorage.removeItem('ConnectWaller')
            deactivate()
            WalletCache.removeType()
            // window.location.reload()
          })
      })
    }
  }, [sessionStorage.getItem('ConnectWaller')]) // eslint-disable-line

  return (
    <Wrap>
      <Title>Wallet</Title>
      <SmallTitle>My Currency</SmallTitle>

      <FlexBox between sx={{ width: '76vw', marginLeft: '16.563vw', marginBottom: '2.083vw' }}>
        <WalletBox>
          {WalletCache.getChain() === 1 || WalletCache.getChain() === 5 ? <Caduceus>Eth</Caduceus> : <Caduceus>Caduceus</Caduceus>}
          {/* <WithdrawButton
                  onClick={() => {
                    TransfeHandler()
                  }}
                >
                  Transfer
                </WithdrawButton> */}
          <FlexBox between>
            <FlexBox>
              {WalletCache.getChain() === 1 || WalletCache.getChain() === 5 ? <LogoImage folder="setting" src="ETH.png"></LogoImage> : <LogoImage folder="setting" src="CMP.png"></LogoImage>}
              <TokenRight>
                <Balance>{CMPBanlance || 0}</Balance>
                {WalletCache.getChain() === 1 || WalletCache.getChain() === 5 ? <TokenName>ETH</TokenName> : <TokenName>CMP</TokenName>}
                {/* <TokenPrice> {CMPBanlance >= 0 ? getBalanceNumber(new BigNumber(CMPPrice * CMPBanlance), 0, 4) : 0} USD</TokenPrice> */}
              </TokenRight>
            </FlexBox>

            <FlexBox>
              <LogoImage folder="setting" src="FPU.png"></LogoImage>
              <TokenRight>
                <Balance>{FPUBalance}</Balance>
                <TokenName>FPU</TokenName>
                {/* <TokenPrice>0.00 USD</TokenPrice> */}
              </TokenRight>
            </FlexBox>
          </FlexBox>
        </WalletBox>

        <WalletBox>
          <Caduceus>Skytopia</Caduceus>
          {/* <WithdrawButton
                  onClick={() => {
                    WithdrawHandler()
                  }}
                >
                  Withdraw
                </WithdrawButton> */}
          <FlexBox>
            <LogoImage folder="setting" src="FPU.png"></LogoImage>
            <TokenRight>
              <Balance>{new BigNumber(FPUInfo?.available_amount || 0).toFormat(2)}</Balance>
              <TokenName>FPU</TokenName>
              {/* <TokenPrice>0.00 USD</TokenPrice> */}
            </TokenRight>
          </FlexBox>
        </WalletBox>
      </FlexBox>

      <SmallTitle>Wallet</SmallTitle>
      {localStorage.getItem('account')?.includes('0x') ? (
        <ConnectWallet
          onClick={() => {
            handleWalletConnect(renderWallet[0].connect)
          }}
        >
          {getShortHash(localStorage.getItem('account'), 4, 6)}
        </ConnectWallet>
      ) : (
        <ConnectWallet
          onClick={() => {
            handleWalletConnect(renderWallet[0].connect)
          }}
        >
          BIND WALLET
        </ConnectWallet>
      )}
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: 94vh;
  width: 100%;
  position: relative;
  overflow: hidden;
`
const Title = styled.div`
  width: 30vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.8vw;
  text-align: center;
  margin: 6.927vw auto 2.083vw;
`

const WalletBox = styled.div`
  width: 36.875vw;
  height: 14.792vw;
  /* margin-right: 1.563vw; */
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
  padding: 1.563vw 4.167vw 2.292vw 4.167vw;
  border-radius: 1.042vw;
  position: relative;
`

const SmallTitle = styled.div`
  min-width: 10.208vw;
  margin-left: 16.563vw;
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin-bottom: 1.302vw;
`

const Caduceus = styled.div`
  min-width: 5.885vw;
  height: 0.99vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 2.552vw;
`
const LogoImage = styled(Image)`
  width: 4.167vw;
  height: 4.167vw;
  margin-right: 1.563vw;
`
const TokenRight = styled.div`
  min-width: 6.771vw;
  height: 5.729vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  text-align: left;
`
const Balance = styled.div`
  margin-top: 1.719vw;
`
const TokenName = styled.div`
  margin-top: 0.7vw 0;
`
const TokenPrice = styled.div``

const WithdrawButton = styled(Button)`
  width: 8vw;
  height: 2.5vw;
  border-radius: 1.042vw;
  font-size: 1.25vw;
  background-color: rgba(92, 127, 255, 0.7);
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  right: 3vw;
  top: 1vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`

const ConnectWallet = styled(Button)`
  width: 13vw;
  height: 2.604vw;
  background: #5c7fff;
  border-radius: 1.302vw;
  box-sizing: border-box;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  margin-left: 16.563vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const BindingAddress = styled.div`
  width: 13vw;
  height: 2.604vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #5c7fff;
  margin-left: 16.563vw;
  margin-top: 1vw;
`

export default Wallet
/* eslint-disable */
