/**
 * type  1:视频  2:图片 3:超链接  4:文本
 * state  1:skytopia  2:village  3:party
 */
export const HubsInfo = [
  {
    id: 1,
    state: 3,
    type: 1,
    title: "Candy Party",
    url: "https://www.youtube.com/embed/WEmlxQ0NNzQ?rel=0",
    content: "",
    time: "Aug 22",
  },
  {
    id: 2,
    state: 1,
    type: 2,
    title: "Life in Skytopia",
    url: ["Lift@2x.jpg"],
    content: "",
    time: "Aug 18",
  },
  {
    id: 3,
    state: 3,
    type: 1,
    title: "Jungle Party",
    url: "https://www.youtube.com/embed/UpvP0iY_0bs?rel=0",
    content: "",
    time: "Aug 08",
  },
  {
    id: 4,
    state: 2,
    type: 2,
    title: "Magicians #3",
    url: ["19"],
    content: "",
    time: "Aug 03",
  },
  {
    id: 5,
    state: 1,
    type: 2,
    title: "Space customization",
    url: ["customization.jpg"],
    content: "",
    time: "Jul 28",
  },
  {
    id: 6,
    state: 2,
    type: 2,
    title: "Magicians #2",
    url: ["21"],
    content: "",
    time: "Jul 25",
  },
  {
    id: 7,
    state: 1,
    type: 2,
    title: "",
    url: ["Magicians.jpg"],
    content: "",
    time: "Jul 14",
  },
  {
    id: 8,
    state: 2,
    type: 2,
    title: "Magicians #1",
    url: ["20"],
    content: "",
    time: "Jul 9",
  },
  {
    id: 9,
    state: 1,
    type: 2,
    title: "Ready, go!",
    url: ["Ready,go.jpg"],
    content: "",
    time: "Jul 8",
  },
  {
    id: 10,
    state: 2,
    type: 2,
    title: "Good night!",
    url: ["1.jpg"],
    content: "",
    time: "Jul 7",
  },
  {
    id: 11,
    state: 3,
    type: 2,
    title: "Ready for the party?",
    url: ["party"],
    content: "",
    time: "Jul 2",
  },
  {
    id: 12,
    state: 3,
    type: 1,
    title: "Desert Party",
    url: "https://www.youtube.com/embed/3isGVo99Wm4?rel=0",
    content: "",
    time: "Jun 28",
  },
  {
    id: 13,
    state: 2,
    type: 2,
    title: "Village Buildings.",
    url: ["b04.jpg", "b01.jpg", "b02.jpg", "b03.jpg", "b05.jpg", "b06.jpg"],
    content: "",
    time: "Jun 14",
  },
]
