import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import Image from 'components/Image'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import HunbsVideo from 'components/HunbsVideo'
import HunbsImg from 'components/HunbsImg'
import FlexBox from 'components/FlexBox'
import Plate from './components/Plate'
import { HubsInfo } from './config/hubsInfo'
import Media from 'react-media'
import { Link } from 'react-router-dom'

const Hunbs: React.FC<any> = () => {
  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {
    gsap.to('.bg-text', {
      y: 0.1 * -ScrollTrigger.maxScroll(window),
      ease: 'none',
      scrollTrigger: {
        // trigger: ".skytopiawarp",
        scrub: true,
      },
    })
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [])
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Warp className="homewarp">
            <Image folder="index" src="indexBg" className="bg-text" />
            <Title>hubs</Title>
            <Contentbg>
              <FlexBox startBetween sx={{ margin: '0 11.979vw', width: '75.99vw' }}>
                <HundsContent>
                  {HubsInfo.map((item, key) => (
                    <div key={item.id}>
                      {item.type === 1 && <HunbsVideo VideoInfo={item}></HunbsVideo>}
                      {item.type === 2 && <HunbsImg ImgInfo={item} />}
                      {/* {item.type === 3 && <HunbsHyperlinks />} */}
                    </div>
                  ))}
                </HundsContent>
                <Plate />
              </FlexBox>
            </Contentbg>
          </Warp>
        ) : (
          <Warp>
            <Contentbg>
              <Image folder="index" src="H5Bg@2x" className="bg-text" />
              <Title>hubs</Title>
              <FlexBox sx={{ position: 'relative', marginBottom: '4vw' }}>
                <Link to={'metaworld'}>
                  <FlexBox column center>
                    <UserImg folder="hunbs" src="game2" />
                    <UserName>Skytopia</UserName>
                  </FlexBox>
                </Link>
                <Link to={'village'}>
                  <FlexBox column center>
                    <UserImg folder="hunbs" src={'game3'} />
                    <UserName>Magic town</UserName>
                  </FlexBox>
                </Link>
                <Link to={'party'}>
                  <FlexBox column center>
                    <UserImg folder="hunbs" src={'game1'} />
                    <UserName>Party Planet</UserName>
                  </FlexBox>
                </Link>
              </FlexBox>
              {HubsInfo.map((item, key) => (
                <div key={item.id}>
                  {item.type === 1 && <HunbsVideo VideoInfo={item}></HunbsVideo>}
                  {item.type === 2 && <HunbsImg ImgInfo={item} />}
                  {/* {item.type === 3 && <HunbsHyperlinks />} */}
                </div>
              ))}
            </Contentbg>
          </Warp>
        )
      }
    </Media>
  )
}
const Contentbg = styled.div`
  background: #e4e1f6;
`
const Warp = styled.div`
  position: relative;
  padding-bottom: 9.011vw;
  .bg-text {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0vw;
    left: 0;
    z-index: 0;
    display: block !important;
  }
  @media (max-width: 1000px) {
    padding-bottom: 16vw;
    a {
      border-bottom: none;
      text-decoration: none;
      width: fit-content;
    }
  }
`
const HundsContent = styled.div`
  width: 52.604vw;
  min-height: 55vw;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 2.083vw;
  padding-bottom: 6vw;
  position: relative;
  box-sizing: border-box;
`
const Title = styled.div`
  width: 7.083vw;
  height: 12.893px;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.8vw;
  margin: 7.604vw 0 2.135vw 13.021vw;
  position: relative;
  @media (max-width: 1000px) {
    height: 5.2vw;
    font-size: 7.467vw;
    color: #7893be;
    line-height: 5.733vw;
    margin: 16.267vw 0 10vw 3.867vw;
    position: relative;
  }
`
const UserImg = styled(Image)`
  @media (max-width: 1000px) {
    width: 11.733vw;
    height: 11.733vw;
    margin: 0 4vw 0.267vw;
  }
`
const UserName = styled.div`
  @media (max-width: 1000px) {
    max-width: 18.667vw;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    height: 3.333vw;
    font-size: 3.2vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #7893be;
    line-height: 2.933vw;
  }
`

export default Hunbs
