/* eslint-disable */
import { useEffect, useState } from "react"
import BigNumber from "bignumber.js"
import { useWeb3React } from "@web3-react/core"

import { useERC20 } from "hooks/useContract"
// 获取购买币种 在marketPlace 的授权额度
export const useMarketPlaceAllowances = (coinAddress: string, address: string) => {
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const { account } = useWeb3React()
  const cakeContract = useERC20(coinAddress) // 购买币种合约
  useEffect(() => {
    const fetchAllowance = async () => {
      try {
        const res = await cakeContract.methods.allowance(account, address).call()
        setAllowance(new BigNumber(res))
      } catch (e) {}
    }

    if (account) {
      fetchAllowance()
    }
    const refreshInterval = setInterval(fetchAllowance, 6000)
    return () => clearInterval(refreshInterval)
  }, [address, cakeContract.methods])
  return allowance
}
/* eslint-disable */
