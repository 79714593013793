import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { HashRouter as Router } from "react-router-dom"
import ModalProvider from "context/ModalProvider"
import { BlockContextProvider } from "context/BlockContext"
import { Web3ReactProvider } from "@web3-react/core"
import { RefreshContextProvider } from "context/RefreshContext"
import { getLibrary } from "utils/web3Utils"
import GlobalSnackbar from "components/GlobalSnackbar"

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <BlockContextProvider>
        <RefreshContextProvider>
            <Router>
              <ModalProvider>
                <App />
                <GlobalSnackbar />
              </ModalProvider>
            </Router>
        </RefreshContextProvider>
      </BlockContextProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
)
reportWebVitals()
