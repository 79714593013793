/* eslint-disable */
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import Image from 'components/Image'
import FlexBox from 'components/FlexBox'
import { useWeb3React } from '@web3-react/core'
import { inviteRewardFun } from 'api/request'
import { CopyAddress } from 'utils'
import message from 'utils/message'

const ReferralH5 = () => {
  var { account } = useWeb3React()

  const [inviteListInfo, setInviteListInfo] = useState<any>([])
  const [inviteLink, setInviteLink] = useState('')
  const [RefernalId, setRefernalId] = useState('')
  const [Referrals, setReferrals] = useState('')
  const [Reward, setReward] = useState('')

  useEffect(() => {
    const getinviteReward = async () => {
      inviteRewardFun()
        .then((res: any) => {
          setInviteListInfo(res.data?.UserInfoList)
          setInviteLink(res.data?.RefernalLink)
          setRefernalId(res.data?.RefernalId)
          setReward(res.data?.Reward)
          setReferrals(res.data?.Referrals)
        })
        .catch((err: any) => {})
    }
    account && getinviteReward()
  }, [account])

  const setToastHanlder = () => {
    message.success('Copied')
  }
  return (
    <Wrap>
      <Title>referral</Title>
      <ReferralBox>
        <SmallTitle>You can earn 75 $DPM for each valid referral. </SmallTitle>
        <SmallTitle>Valid Referral: The invitee must finish at least one game in Party Planet. </SmallTitle>
        <FlexBox center sx={{ height: '2vw', margin: '13.733vw auto 1.867vw' }}>
          <Invitation>{inviteLink}</Invitation>
          <CopyImage
            folder="setting"
            src="copy@2x.png"
            onClick={() => {
              CopyAddress(`${inviteLink}`, setToastHanlder)
            }}
          ></CopyImage>
        </FlexBox>
        <ReferralLink>Referral Link</ReferralLink>

        <FlexBox center sx={{ height: '2vw', margin: '10.667vw auto 1.867vw' }}>
          <Invitation>{RefernalId}</Invitation>
          <CopyImage
            folder="setting"
            src="copy@2x.png"
            onClick={() => {
              CopyAddress(`${RefernalId}`, setToastHanlder)
            }}
          ></CopyImage>
        </FlexBox>
        <ReferralLink style={{ marginBottom: '15.333vw' }}>Referral Code</ReferralLink>
        <TotalKey>
          Total Referrals: <span>{Referrals || 0}</span>
        </TotalKey>
        <TotalKey style={{ margin: '1.733vw 0 9.733vw 0' }}>
          Total Rewards: <span style={{ marginLeft: '0.8vw' }}>{Reward || 0} $DPM</span>
        </TotalKey>
        <FlexBox sx={{ marginBottom: '1.467vw' }}>
          <TableTitle style={{ width: '26.667vw', boxSizing: 'border-box', paddingLeft: '6.667vw' }}>Name</TableTitle>
          <TableTitle style={{ width: '24vw', boxSizing: 'border-box', paddingLeft: '1.467vw' }}>Reward</TableTitle>
          <TableTitle style={{ width: '21.867vw' }}>Date</TableTitle>
          <TableTitle>Status</TableTitle>
        </FlexBox>
        {inviteListInfo?.length > 0 ? (
          inviteListInfo?.map((item: any, i: any) => (
            <TableBodyBox key={i}>
              <TableBody
                style={{
                  width: '18.667vw',
                  boxSizing: 'border-box',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                  marginLeft: '1.333vw',
                }}
              >
                {item?.name}
              </TableBody>
              <TableBody style={{ width: '25vw', textAlign: 'center', paddingLeft: '3vw', boxSizing: 'border-box' }}>{item?.status === 0 ? 0 : item?.reward} $DPM</TableBody>
              <TableBody style={{ width: '20vw', textAlign: 'center' }}>{item?.updated}</TableBody>
              <TableBody style={{ width: '17vw', textAlign: 'center', marginLeft: '6.23vw' }}>{item?.status === 0 ? 'Pending' : 'Done'}</TableBody>
            </TableBodyBox>
          ))
        ) : (
          <>
            <NoDataImg src="NoData.png" folder="setting"></NoDataImg>
            <Tips>No Data</Tips>
          </>
        )}
      </ReferralBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: 94vh;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const Title = styled.div`
  width: 25.333vw;
  font-size: 7.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 7vw;
  text-align: center;
  margin: 19.067vw auto 24.8vw;
`

const ReferralBox = styled.div`
  width: 94.667vw;
  min-height: 117.2vw;
  border-radius: 2.667vw;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
  margin-left: 2.533vw;
  margin-bottom: 20vw;
  padding: 5.333vw 4vw;
  position: relative;
`
const SmallTitle = styled.div`
  width: 78.4vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  line-height: 4.8vw;
  color: #95add4;
  margin: 0 auto;
`
const Invitation = styled.div`
  width: fit-content;
  max-width: 58vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #8ba4ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const CopyImage = styled(Image)`
  width: 4.667vw;
  height: 4.533vw;
  margin-left: 0.933vw;
  cursor: pointer;
`
const ReferralLink = styled.div`
  height: 2.667vw;
  font-size: 3.2vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  text-align: center;
`
const TableTitle = styled.div`
  font-size: 2.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(149, 173, 212, 0.5);
`
const TableBodyBox = styled.div`
  width: 86.933vw;
  height: 6.667vw;
  border-radius: 2vw;
  margin-bottom: 1.2vw;
  background: rgba(139, 164, 255, 0.5);
  display: flex;
  align-items: center;
`
const TableBody = styled.div`
  font-size: 2.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
`
const NoDataImg = styled(Image)`
  width: 30.133vw;
  height: 30.133vw;
  margin: 16.267vw auto 2vw;
`
const Tips = styled.div`
  font-size: 4.267vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b2c1dd;
  text-align: center;
`
const TotalKey = styled.div`
  font-size: 2.267vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(149, 173, 212, 0.7);
  span {
    color: rgba(152, 174, 255, 0.7);
  }
`
export default ReferralH5
/* eslint-disable */
