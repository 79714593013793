import Image from 'components/Image'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect, useState } from 'react'
import Media from 'react-media'
import useSize from 'hooks/useSize'
import FlexBox from 'components/FlexBox'
import { Box } from '@mui/material'
import { friendsInfo, selectInfo } from '../config/Residents'
import ResidentsH5 from './ResidentsH5'
import 'swiper/css/bundle'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
const Residents = () => {
  SwiperCore.use([Autoplay])
  const [mySwiper, setMySwiper] = useState<any>()
  const [big, setBig] = useState<any>(3)
  const { pcSize } = useSize()
  const [timer, setTimer] = useState<any>(1)

  // useEffect(() => {
  //   if (pcSize) {
  //     gsap.fromTo('.friends-title-style', { opacity: 0, x: -30 }, { scrollTrigger: { trigger: '.friends-wrap', start: 'top-=700' }, x: 0, opacity: 1, duration: 0.5 })
  //   }
  // }, [pcSize])

  return (
    <Wrap className="friends-wrap">
      {/* <Title className="friends-title-style">NFT Artist' story</Title> */}
      {selectInfo.map((item, keys) => (
        <Box key={item.name + keys} sx={{ display: keys + 1 === big ? 'block' : 'none', overflow: 'hidden' }}>
          <FlexBox between sx={{ margin: '10.104vw auto 0', width: '80vw' }}>
            <BigTitle style={{ color: `${item.color}` }}>{item.name}</BigTitle>
            <Tips style={{ color: `${item.color}` }}>
              <div dangerouslySetInnerHTML={{ __html: item.title }}></div>
            </Tips>
          </FlexBox>
          <Image className="friends-img-select" key={keys + item.name} folder="skytopia" src={item.url}></Image>
        </Box>
      ))}

      <Image
        folder="skytopia"
        onClick={() => {
          if (timer === 1) {
            setTimer(0)
            let selectIndex = big
            if (selectIndex === 1) {
              selectIndex = 11
            }
            setBig(selectIndex - 1)
            mySwiper.slidePrev()
            setTimeout(function () {
              setTimer(1)
            }, 350)
          }
        }}
        src="left@2x"
        className="skytopia-img-left-style"
      />
      <Image
        folder="skytopia"
        onClick={() => {
          if (timer === 1) {
            setTimer(0)
            let selectIndex = big
            if (selectIndex === 10) {
              selectIndex = 0
            }
            setBig(selectIndex + 1)
            mySwiper.slideNext()
            setTimeout(function () {
              setTimer(1)
            }, 350)
          }
        }}
        src="right@2x"
        className="skytopia-img-right-style"
      />
      <Swiper
        slidesPerView={7}
        centeredSlides={true}
        loop
        freeMode={true}
        speed={250}
        loopFillGroupWithBlank={true}
        normalizeSlideIndex={true}
        initialSlide={3}
        onInit={(ev) => {
          setMySwiper(ev)
        }}
        className="swiper-no-swiping"
        style={{
          width: '67.813vw',
          height: '11.458vw',
          boxSizing: 'border-box',
          cursor: 'pointer',
        }}
      >
        {friendsInfo.map((item, key) => (
          <SwiperSlide
            key={key}
            style={{ position: 'relative', width: '10.417vw', height: '11.458vw' }}
            onClick={() => {
              setBig(key + 1)
            }}
          >
            <Image src={item.url} folder="skytopia" className={`friends-img-style ${item.class}`} key={key + item.url}></Image>
            {key + 1 === big && <Image folder="skytopia" src="00@2x" className="skytopia-img-selected-style" />}
            <ImgName style={{ color: `${item.color}` }}>{item.name}</ImgName>
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 56.25vw;
  position: relative;
  box-sizing: border-box;
  .friends-img-select {
    width: 28.125vw;
    height: 28.125vw;
    position: absolute;
    top: 3.021vw;
    left: 50%;
    margin-left: -14.0625vw;
  }
  .friends-img-style {
    width: 10.417vw;
    height: 11.458vw;
    position: absolute;
    top: 0;
    left: 0vw;
    z-index: 1;
  }
  .swiper .swiper-wrapper {
    -webkit-transition-timing-function: linear !important; /*之前是ease-out*/
    -moz-transition-timing-function: linear !important;
    -ms-transition-timing-function: linear !important;
    -o-transition-timing-function: linear !important;
    transition-timing-function: linear !important;
    padding-left: 9.35vw;
  }
  .swiper {
    position: absolute;
    left: 15.938vw;
    top: 34.479vw;
    overflow: hidden;
  }
  .skytopia-img-left-style {
    width: 3.438vw;
    height: 3.438vw;
    position: absolute;
    top: 38.646vw;
    left: 12vw;
    z-index: 3;
    cursor: pointer;
  }
  .skytopia-img-right-style {
    width: 3.438vw;
    height: 3.438vw;
    position: absolute;
    top: 38.646vw;
    left: 84.063vw;
    z-index: 3;
    cursor: pointer;
  }
  .skytopia-img-selected-style {
    width: 10.417vw;
    height: 11.458vw;
    position: absolute;
    top: 0;
    left: 0vw;
  }
`
const BigTitle = styled.div`
  width: fit-content;
  font-size: 15.625vw;
  margin-right: 1.25vw;
  text-align: left;
  /* line-height: 15vw; */
`
const Tips = styled.div<{ setfontWidth?: boolean }>`
  font-size: 1.094vw;
  width: ${({ setfontWidth }) => (setfontWidth ? '40vw' : '25.906vw')};
  text-align: left;
  line-height: 1.771vw;
  /* margin-top: 5vw; */
  font-family: Albert Sans-Bold, Albert Sans;
`
const ImgName = styled.div`
  text-align: center;
  width: fit-content;
  font-size: 1.094vw;
  width: 60%;
  position: absolute;
  bottom: 0.208vw;
  left: 50%;
  margin-left: -30%;
`

export default Residents
