import Image from 'components/Image'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect } from 'react'
import useSize from 'hooks/useSize'
import FlexBox from 'components/FlexBox'
import { Box } from '@mui/material'
import CharacteristicH5 from './CharacteristicH5'
import Media from 'react-media'

const Characteristic = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: '.characteristic-wrap', start: 'top-=700' } })
      tl.fromTo('.characteristic-img-Life', { opacity: 0, x: 60 }, { x: 0, opacity: 1, duration: 0.5 })
      tl.fromTo('.characteristic-title-Life', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.5 })

      var t2 = gsap.timeline({ scrollTrigger: { trigger: '.characteristic-wrap', start: 'top-=100' } })
      t2.fromTo('.characteristic-img-community', { opacity: 0, x: -60 }, { x: 0, opacity: 1, duration: 0.5 })
      t2.fromTo('.characteristic-title-community', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.5 })

      var t3 = gsap.timeline({ scrollTrigger: { trigger: '.characteristic-wrap', start: 'top+=300' } })
      t3.fromTo('.characteristic-img-metaworld', { opacity: 0, x: 60 }, { x: 0, opacity: 1, duration: 0.5 })
      t3.fromTo('.characteristic-title-metaworld', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="characteristic-wrap">
            <FlexBox between sx={{ width: '72.083vw', height: '22.292vw', margin: '0 11.563vw 5.573vw 16.042vw' }}>
              <ContentLife className="characteristic-title-Life">
                <Title marginVal="6.875vw 0 2.135vw ">Experience a second Life</Title>
                <Tips>Travel through different Metaworlds, meet new civilizations, play immersive games and participate in virtual activities. New experience-new life.</Tips>
              </ContentLife>
              <LifeBox className="characteristic-img-Life">
                <LifeImage folder="about" src="02-01@2x.png" />
                <LifeImg folder="about" src="02-02@2x.png" />
              </LifeBox>
            </FlexBox>

            <FlexBox between sx={{ width: '85.99vw', height: '14.896vw' }}>
              <CommunityImage className="characteristic-img-community" folder="about" src="02-03@2x.png" />
              <Box sx={{ height: '14.896vw' }} className="characteristic-title-community">
                <Title marginVal="4.427vw 0 2.135vw">Join the community</Title>
                <Tips>Make new friends and build strong connections with each other in the virtual world. Join groups and establish your amazing civilizations.</Tips>
              </Box>
            </FlexBox>

            <FlexBox between sx={{ width: '81.146vw', height: '20.729vw', margin: '6.042vw 3.177vw 0 15.677vw' }}>
              <Box sx={{ height: '20.729vw' }} className="characteristic-title-metaworld">
                <Title marginVal="6.406vw 0 1.771vw">Create your Metaworld</Title>
                <Tips>CryptoSkyland is working on tools, SDKs and services for creators, developers and publishers to get the most out of connecting Metaworlds to CryptoSkyland.</Tips>
              </Box>
              <MetaworldImage folder="about" src="02-04@2x.png" className="characteristic-img-metaworld" />
            </FlexBox>
          </Wrap>
        ) : (
          <CharacteristicH5 />
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  height: 80vw;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const LifeBox = styled.div`
  width: 36.406vw;
  height: 22.292vw;
  position: relative;
`
const LifeImage = styled(Image)`
  width: 36.406vw;
  height: 22.292vw;
`
const LifeImg = styled(Image)`
  width: 17.24vw;
  height: 16.354vw;
  position: absolute;
  right: -2.24vw;
  bottom: -2.604vw;
`
const ContentLife = styled.div`
  height: 22.292vw;
`

const CommunityImage = styled(Image)`
  width: 51.979vw;
  height: 14.896vw;
`
const MetaworldImage = styled(Image)`
  width: 46.563vw;
  height: 20.729vw;
`

const Title = styled.div<{ marginVal?: string }>`
  min-width: 29.271vw;
  height: 1.771vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 2.604vw;
  margin: ${({ marginVal }) => marginVal};
`
const Tips = styled.div`
  width: 29.271vw;
  height: 4.74vw;
  font-size: 1.094vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  line-height: 1.823vw;
`
export default Characteristic
