import React, { CSSProperties, useContext, useState } from 'react'
import Modal, { ModalProps } from '@mui/material/Modal'
import Backdrop from '@mui/material/Backdrop'
import styled from '@emotion/styled'
import Image from 'components/Image'
import Typography from '@mui/material/Typography'
import Fade from '@mui/material/Fade'
import Box from '@mui/system/Box'
import { ModalContext as Context } from 'context/ModalProvider'

type StyledModalProps = Pick<ModalProps, 'sx'> & {
  title?: string | JSX.Element
  content: JSX.Element
  open?: boolean
  onClose?: (e?: any) => void
}

const H5Modal: React.FC<StyledModalProps> = ({ open = true, onClose, title, content, sx }) => {
  const [display, setDisplay] = useState<boolean>(true)
  const { onClose: closeHandler } = useContext(Context)
  const initBoxStyle: CSSProperties = {
    color: '#FFF',
    background: 'rgba(255, 255, 255, 1)',
    borderRadius: '9.067vw 9.067vw 0 0',
    position: 'fixed',
    bottom: '0%',
    left: '0%',
    outline: 'none',
    maxWidth: '100%',
    zIndex: 2,
    padding: '0 3.733vw',
    boxSizing: 'border-box',
  }
  const handlerModalClose = (e: any) => {
    closeHandler()
    setDisplay(false)
    onClose?.(e)
  }

  const styles: any = sx ? { ...initBoxStyle, ...sx } : initBoxStyle

  return (
    <Modal
      open={Boolean(open) && display}
      onClose={handlerModalClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 0,
      }}
      
    >
      <Fade in={open}>
        <Box sx={styles}>
          {title && <StyledTitle variant="h3">{title}</StyledTitle>}
          <ModalContent className="styled_modal_content_wrap">{content}</ModalContent>
          <CloseIcon className="close" folder="components" src="btn_close.png" onClick={handlerModalClose} />
        </Box>
      </Fade>
    </Modal>
  )
}

const StyledTitle = styled(Typography)`
  width: 100%;
  height: 8.267vw;
  font-size: 5.067vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 8.267vw;
  margin: 8.133vw auto;
`

const ModalContent = styled(Box)`
  position: relative;
`

const CloseIcon = styled(Image)`
  position: absolute;
  top: 8.133vw;
  right: 3.733vw;
  width: 8.267vw;
  height: 8.267vw;
  cursor: pointer;
  z-index: 3;
`

export default H5Modal
export const ModalContext = Context
