export const selectInfo = [
  {
    url: '01-sheep@2x.png',
    name: 'Adam',
    title: ' Adam was originally a writer who followed the Sky Expedition Fleet to record everything. However, an accident wiped out everyone except for Adam. He drifted at sea for an unknown period of time and, in a coma, felt as if someone was carrying him.',
    color: '#23CA8F',
  },
  {
    url: '01-eagle@2x.png',
    name: 'Bruce',
    title:
      'Bruce is the guardian of the ruins and loves adventure. He always pesters Adam to tell him everything about the outside world, over and over again. Adam patiently tells him, not knowing how long this peace will last.<br>As expected, a group of sky pirates discovered the ruins, and Bruce fought to protect them. Although he drove away the sky pirates, Bruce was seriously injured. "They will come back sooner or later," he said.',
    color: '#F74DD1',
  },

  {
    url: '01-chick@2x.png',
    name: 'Paco',
    title:
      'The dark alleyways are the true reflection of this world. Paco, who was born in the lower class, was influenced by graffiti culture from a young age. He had a crazy idea of turning the whole world into a graffiti kingdom, even at a young age.<br>One graffiti artwork after another appeared on the streets, which looked crazy and brought Paco a lot of trouble. However, Paco did not give up. He knew that one day he would fulfill his crazy idea. Until one day, a criminal organization found him.',
    color: '#FF9C00',
  },

  {
    url: '01-dog@2x.png',
    name: 'Goro',
    title: 'The people here always worship various technological powers, and Goro has modified his weapon into a lightsaber. He says that someday he will embark on a journey to save the world with this lightsaber.',
    color: '#23CA8F',
  },
  {
    url: '01-hamster@2x.png',
    name: 'Annie',
    title:
      "Annie's dream was to become a chef, but she always ended up making food that tasted terrible. One day, while she was out looking for ingredients, she accidentally entered a fairy tale kingdom.<br>Along the winding candy creek and through the vast mushroom forest, a huge castle stood before Annie's eyes. The lazy guards finally noticed Annie, the outsider, and panicked, not knowing what to do. As a result, an alarm sounded, and the castle began to stir.",
    color: '#F74DD1',
  },
  {
    url: '01-rabbit@2x.png',
    name: 'Lily',
    title:
      ' Lily was born in a fairy tale kingdom where everything was shrouded in mystery. With a lively nature, Lily always wanted to leave the castle and explore the outside world. Finally, one day, Lily seized the opportunity to run out of the castle by stepping on a giant mushroom.<br>Lily thought that as long as she escaped from the castle, she could get rid of everything that controlled her. She had her own things to do, and wanted to become a singer in the outside world.',
    color: '#F74DD1',
  },
  {
    url: '01-crocodile@2x.png',
    name: 'Lucky',
    title:
      "Lucky is the owner of the Party Planet Bar. He is very enthusiastic and greets everyone who comes in with a glass of his signature 'Happy Whiskey', which never fails to make them burst out laughing. However, one day Vivia arrived at the bar, and Lucky, as usual, offered her a glass of his special drink. Vivia finished her drink and jumped onto the dance floor, moving gracefully like an elf, before hastily leaving in the early hours of the morning.<br>After that night, Vivia never returned to the bar, leaving Lucky confused and wondering why she didn't burst out laughing after drinking the whiskey and why she left in such a hurry.",
    color: '#FF9C00',
  },
  {
    url: '01-vivia@2x.png',
    name: 'Vivia',
    title:
      'Every night, the beautiful Vivia glides through different parties with exquisite grace.Wherever she goes, she radiates a happy aura until dawn. Then, Vivia hastily departs, and nobody knows where she goes.<br> Vivia was born in a remote orphanage in the countryside. She yearns for all things joyful and fun, but time does not allow her to linger on the party planet for long. During the day, Vivia works for the welfare institution to make ends meet.',
    color: '#5C7FFF',
  },
  {
    url: '01-cat@2x.png',
    name: 'Sarah',
    title: 'Sarah is the high priestess of Mysterious Village. Her divination abilities have led the village to safety time and time again. However, revealing too many secrets eventually brought down divine retribution upon Sarah. She thought there was no escape, but she was saved by Ben.',
    color: '#5C7FFF',
  },
  {
    url: '01-bear@2x.png',
    name: 'Ben',
    title:
      'Ben, once hailed as a genius, was exiled from his family for his refusal to study anything other than healing magic. He traveled far and wide to the fabled Mysterious Village, known for its acceptance of all kinds. Here, he happened upon Sarah, who was receiving divine punishment, and risking his life, he rescued her, but lost an ear in the process.',
    color: '#FF9C00',
    // setfontWidth: true,
  },
]

export const friendsInfo = [
  {
    url: '01-small-sheep@2x.png',
    class: 'small-01',
    name: 'Adam',
    nameBg: '01-adam-nameBg@2x.png',
    color: '#23CA8F',
  },

  {
    url: '01-small-eagle@2x.png',
    class: 'small-09',
    name: 'Bruce',
    nameBg: '01-bruce-nameBg@2x.png',
    color: '#F74DD1',
  },

  {
    url: '01-small-chick@2x.png',
    class: 'small-03',
    name: 'Paco',
    nameBg: '01-paco-nameBg@2x.png',
    color: '#FF9C00',
  },

  {
    url: '01-small-dog@2x.png',
    class: 'small-05',
    name: 'Goro',
    nameBg: '01-goro-nameBg@2x.png',
    color: '#23CA8F',
  },
  {
    url: '01-small-hamster@2x.png',
    class: 'small-06',
    name: 'Annie',
    nameBg: '01-sarah-nameBg@2x.png',
    color: '#F74DD1',
  },
  {
    url: '01-small-rabbit@2x.png',
    class: 'small-02',
    name: 'Lily',
    nameBg: '01-lily-nameBg@2x.png',
    color: '#F74DD1',
  },
  {
    url: '01-small-crocodile@2x.png',
    class: 'small-07',
    name: 'Lucky',
    nameBg: '01-sarah-nameBg@2x.png',
    color: '#FF9C00',
    setfontWidth: true,
  },
  {
    url: '01-small-vivia@2x.png',
    class: 'small-08',
    name: 'Vivia',
    nameBg: '01-sarah-nameBg@2x.png',
    color: '#5C7FFF',
  },
  {
    url: '01-small-cat@2x.png',
    class: 'small-04',
    name: 'Sarah',
    nameBg: '01-sarah-nameBg@2x.png',
    color: '#5C7FFF',
  },
  {
    url: '01-small-bear@2x.png',
    class: 'small-10',
    name: 'Ben',
    nameBg: '01-sarah-nameBg@2x.png',
    color: '#FF9C00',
  },
]
