export const routerList = [
  // {
  //   router: '/index',
  //   name: 'Metaworlds',
  // },
  {
    router: '/skytopia',
    name: 'Skytopia',
    childItem: [
      {
        router: '/story',
        name: 'Story',
      },
      {
        router: '/locations',
        name: 'Locations',
      },
      {
        router: '/books',
        name: "Paco's Books",
      },
    ],
  },

  {
    router: '/skyland',
    name: 'NFT',
    childItem: [
      {
        router: '/sayc',
        name: 'SAYC',
      },
      {
        router: '/skyland',
        name: 'Skyland',
      },
    ],
  },

  {
    router: '/partyplanet',
    name: 'Games',
    childItem: [
      {
        router: '/partyplanet',
        name: 'Skytopia: Party Planet',
      },
      {
        router: '/mysteriousvillage',
        name: 'Skytopia: Mysterious Village',
      },
      {
        router: '/homeland',
        name: 'Skytopia: Homeland',
      },
    ],
  },

  {
    router: '/news',
    name: 'News',
    childItem: [
      {
        router: '/blog',
        name: 'Blog',
      },
      {
        router: '/hubs',
        name: 'Hubs',
      },
    ],
  },

  {
    router: '/help',
    name: 'Help',
    childItem: [
      {
        router: '/litepaper',
        name: 'Litepaper',
      },
      {
        router: '/whitepaper',
        name: 'Whitepaper',
      },
      {
        router: '/about',
        name: 'About',
      },
    ],
  },

  {
    router: '/download',
    name: 'download',
    childItem: []
  },

  // {
  //   router: '/store',
  //   name: 'Store',
  //   isPC: true,
  // },
  // {
  //   router: '/whitepaper',
  //   name: 'Whitepaper',
  //   target: true,
  //   OuterChain: 'https://whitepaper.cryptoskyland.com/',
  // },
]

export const LeftList = [
  {
    router: '/profile',
    name: 'Profile',
  },
  {
    router: '/wallet',
    name: 'Wallet',
  },
  {
    router: '/inventory',
    name: 'Inventory',
  },
  // {
  //   router: "/avatars",
  //   name: "AVATARS",
  // },
  {
    router: '/setting',
    name: 'Settings',
  },
  {
    router: '/referral',
    name: 'Referral',
  },
]

export const UserList = [
  {
    router: '/profile',
    name: 'Profile',
  },
  {
    router: '/wallet',
    name: 'Wallet',
  },
  {
    router: '/inventory',
    name: 'Inventory',
  },

  {
    router: '/setting',
    name: 'Settings',
  },
  {
    router: '/referral',
    name: 'Referral',
  },
  // {
  //   router: '/order',
  //   name: 'ORDER',
  // },
]
