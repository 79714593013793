import { useCallback } from "react"
import { useWeb3React } from "@web3-react/core"
import { InjectedConnector } from "@web3-react/injected-connector"
import { WalletCache, WalletList, IgnoredConnectErrors, getChain, SwitchChainRequest, SupportedChain } from "utils/walletUtils"
import message from "utils/message"
import Constants from "config/constants"
import configs from "config/constants"

// only support metamask currently
const useWalletAuth = () => {
  const { activate, deactivate } = useWeb3React()

  const connect = (cb?: Function) => {
    // if (getChain() !== configs.INIT_CHAIN) {
    //   // SwitchChainRequest()
    //   SwitchChainRequest(WalletCache.getChain() as SupportedChain)
    // }
    const injected = new InjectedConnector({ supportedChainIds: Constants.SUPPORTED_CHAINS })
    activate(
      injected,
      (error: Error) => {
        // if (!IgnoredConnectErrors.includes(error.name)) {
        //   console.log('=>error: ',error.name, error.message)
        // }
        // if (error.name === 'wallet connect error') {
        //   WalletCache.removeType()
        //   deactivate()
        // }
      },
      true
    )
      .then((res) => {
        WalletCache.setType(WalletList.Metamask)
        cb?.()
      })
      .catch((error: Error) => {
        message.error(error.message)
      })
  }

  const disconnect = (setLogout: boolean = true, cb?: Function) => {
    if (setLogout) {
      WalletCache.removeType()
    }
    deactivate()
    // window.location.reload()
    cb?.()
  }

  return { connect, disconnect }
}

export default useWalletAuth
