import styled from '@emotion/styled'
import { useState } from 'react'
import Image from 'components/Image'
import { S3Url } from "api/apiList";


import FlexBox from 'components/FlexBox'
import LoadingButton from '@mui/lab/LoadingButton'
import { http } from 'utils/http'
const discord = `${S3Url}/images/index2/partners/discord.png`
const twitter = `${S3Url}/images/index2/partners/twitter.png`
const medium = `${S3Url}/images/index2/partners/medium.png`
const link = `${S3Url}/images/index2/partners/link.png`
const Bg = `${S3Url}/images/index2/partners/Bg.png`

const FooterNotification = () => {
  const [mailboxAddress, setMailboxAddress] = useState('')
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [tipsValue, setTipsValue] = useState('')

  const useSubscribe = async () => {
    const data = { email: mailboxAddress }
    try {
      await http('contacts', { method: 'post', data })
      setShow(true)
      setMailboxAddress('')
    } catch (error: any) {
      if (error.code === 'duplicate_parameter') {
        setTipsValue('Contact already exist')
      } else {
        setTipsValue('Please enter the correct email address!')
      }
      setOpen(true)
      setMailboxAddress('')

      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  return (
    <Wrap className="Partner">
      <Title>Sign up for notification</Title>
      {!show ? (
        <FlexBox center sx={{ position: 'relative', zIndex: 1, marginBottom: '4.375vw' }}>
          <input type="text" value={mailboxAddress} onChange={(evt: any) => setMailboxAddress(evt.target.value || '')} placeholder="my@email.com" />
          <LoadingButton
            sx={{
              height: '3.438vw',
              width: '12.656vw',
              borderRadius: '3.438vw',
              fontSize: '1.25vw',
              fontFamily: 'Albert Sans-Black, Albert Sans',
              background: '#383938',
              boxShadow: 'none',
              color: '#FFFFFF',
              '&:hover': {
                background: '#383938',
              },
            }}
            onClick={useSubscribe}
          >
            SUBSCRIBE
          </LoadingButton>

          {open && (
            <TipsBox>
              <TipsImg folder="index2/Partner" src="tsdi@2x.png" />
              <span>{tipsValue}</span>
            </TipsBox>
          )}
        </FlexBox>
      ) : (
        <FlexBox
          center
          sx={{
            height: '4.792vw',
            fontSize: '1.25vw',
            margin: '0 auto 4.375vw',
            position: 'relative',
            fontFamily: 'Albert Sans-Black, Albert Sans',
            zIndex: 1,
          }}
        >
          Thank you! Your submission has been received!
        </FlexBox>
      )}

      <FlexBox between sx={{ width: '60.833vw', margin: '0 auto' }}>
        {/* <AImg href="https://discord.com/invite/UC9ud4QzP7" target="_blank" rel="noopener noreferrer">
          <img src={discord} alt="discord" />
        </AImg>
        <AImg href="https://twitter.com/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="twitter" />
        </AImg>
        <AImg href="https://medium.com/@SkylandNFTs" target="_blank" rel="noopener noreferrer">
          <img src={medium} alt="medium" />
        </AImg>
        <AImg href="https://linktr.ee/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={link} alt="linktree" />
        </AImg> */}
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={discord} alt="discord" />
        </AImg>
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="twitter" />
        </AImg>
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={medium} alt="medium" />
        </AImg>
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={link} alt="linktree" />
        </AImg>
      </FlexBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 78.125vw;
  height: 32.708vw;
  background-color: RGBA(27, 76, 110, 1);
  border-radius: 5.104vw;
  overflow: hidden;
  position: absolute;
  bottom: -10.26vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  background-image: url(${Bg});
  background-size: 100% 100%;

  input {
    width: 38.854vw;
    height: 4.792vw;
    background: #ffffff;
    border-radius: 0.938vw;
    text-align: center;
    font-size: 1.719vw;
    font-family: Albert Sans-Medium, Albert Sans;
    color: #3d3d3d;
    line-height: 4.792vw;
    border: 0;
    outline: none;
    margin-right: 1.667vw;
    position: relative;
    z-index: 2;
  }

  input::-webkit-input-placeholder {
    font-size: 1.719vw;
    color: #3d3d3d;
  }
`
const AImg = styled.a`
  width: 13.021vw;
  height: 10.521vw;
  img {
    width: 13.021vw;
    height: 10.521vw;
  }
`

const Title = styled.div`
  width: 100%;
  height: 1.563vw;
  font-size: 2.083vw;
  text-align: center;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #ffffff;
  line-height: 1.563vw;
  margin: 3.958vw 0 2.708vw;
`
const TipsBox = styled.div`
  width: 28.49vw;
  height: 3.281vw;
  margin: 0 auto;
  position: absolute;
  top: 5vw;
  left: 23%;
  span {
    width: 28.49vw;
    height: 3.281vw;
    font-size: 1.458vw;
    font-family: Albert Sans-Regular, Albert Sans;
    color: #ffffff;
    line-height: 3.81vw;
    display: block;
    position: absolute;
    text-align: center;
  }
`
const TipsImg = styled(Image)`
  width: 28.49vw;
  height: 3.281vw;
  position: absolute;
`

export default FooterNotification
