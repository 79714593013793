import React, { useContext, useEffect, useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { useNavigate } from 'react-router-dom'
import Image from 'components/Image'

const LoadingPage: React.FC<any> = ({ loadingState }) => {
  const { onClose } = useContext(ModalContext)
  const [loading, setLoading] = useState(loadingState)

  const title = 'Payment in progress'
  const navigate = useNavigate()
  if (!loading) {
    onClose()
  }
  const content = <Warp>{<Loading className="RotationBack" folder="components" src="loading.png"></Loading>}</Warp>
  return (
    <Modal
      title={title}
      content={content}
      sx={{
        width: '28.646vw',
        minHeight: '20.896vw',
      }}
    />
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.563vw 1.979vw 1.563vw;
  position: relative;
  @keyframes rotationback {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 3s linear infinite;
  }
`
const Loading = styled(Image)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 2vw;
  margin-left: -2.5vw;
  width: 5vw;
  height: 5vw;
`

export default LoadingPage
