import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import FlexBox from "components/FlexBox"
import { getPersonalFun } from "api/request"
import useModal from "hooks/useModal"
import Editemall from "./EditemallModel"
import EditPassword from "./EditPasswordModel"
import ForgotPassword from "./ForgotPasswordModel"
import EditSettingModel from "./EditSettingModel"
import { getAuthCookie } from "utils/user"
import { getShortHash } from "utils"
import Media from "react-media"
import SettingsH5 from "./SettingsH5"

const Settings = () => {
  const [Email, setEmail] = useState("")
  const [Password, setPassword] = useState("")
  const [update, setUpdate] = useState("")
  const token = getAuthCookie()

  const [ChangeEmailAddress] = useModal(<Editemall setUpdate={setUpdate} mobile_or_email={Email} />)
  const [ChangePassword] = useModal(<EditPassword mobile_or_email={Email} />)
  const [ChangeForgotPassword] = useModal(<ForgotPassword mobile_or_email={Email} />)
  const [EditSetting] = useModal(<EditSettingModel setUpdate={setUpdate} />)

  useEffect(() => {
    if (token) {
      getPersonalFun()
        .then((data: any) => {
          setEmail(data.data.email)
          if (data.data.email !== "") {
            setPassword("111111")
          }
        })
        .catch((err: any) => {})
    }
  }, [update, token])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap>
            <Title>Account settings</Title>
            <SettingsBox>
              <IconTips>Email:</IconTips>
              <FlexBox startCenter sx={{ marginBottom: "2.2vw" }}>
                <input type="text" value={getShortHash(Email, 4, 6)} disabled />
                {Email === "" ? (
                  <Edit
                    onClick={() => {
                      EditSetting()
                    }}
                  >
                    connect
                  </Edit>
                ) : (
                  <Edit
                    onClick={() => {
                      ChangeEmailAddress()
                    }}
                  >
                    Edit
                  </Edit>
                )}
              </FlexBox>
              <IconTips>Password</IconTips>
              <FlexBox startCenter>
                <input type="password" value={Password} disabled />
                {Email === "" ? (
                  <Edit></Edit>
                ) : (
                  <>
                    <Edit
                      onClick={() => {
                        ChangePassword()
                      }}
                    >
                      Edit
                    </Edit>
                    <Forgot
                      onClick={() => {
                        ChangeForgotPassword()
                      }}
                    >
                      Forgot
                    </Forgot>
                  </>
                )}
              </FlexBox>
            </SettingsBox>
          </Wrap>
        ) : (
          <SettingsH5></SettingsH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: 94vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  input {
    width: 20.833vw;
    height: 2.604vw;
    background: rgba(120, 147, 190, 0.5);
    border-radius: 0.781vw;
    border: 0;
    outline: none;
    padding-left: 1.146vw;
    font-size: 1.146vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
    line-height: 2.604vw;
    border: 2px solid rgba(133, 160, 254, 0);
    box-sizing: border-box;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
`
const Title = styled.div`
  width: 30vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.8vw;
  text-align: center;
  margin: 6.927vw auto 2.083vw;
`

const SettingsBox = styled.div`
  width: 77.708vw;
  height: 14.792vw;
  margin-left: 16.563vw;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
  padding: 1.458vw 0vw 1.563vw 4.219vw;
  border-radius: 1.042vw;
  position: relative;
`

const IconTips = styled.div`
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 0.7vw;
`
const Edit = styled.div`
  min-width: 2.135vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin: 0 49px 0 21px;
  cursor: pointer;
`
const Forgot = styled.div`
  min-width: 3.646vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  cursor: pointer;
`

export default Settings
