import styled from '@emotion/styled'
import { useState } from 'react'
import FlexBox from 'components/FlexBox'
import LoadingButton from '@mui/lab/LoadingButton'
import { http } from 'utils/http'
interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress?: Number
}

const ContactUSH5: React.FC<PreloaderProps> = ({ imageUrls }) => {
  // const [bt_Discord, bt_linktree, bt_medium, bt_twitter, di_blue, di_gou, di_yang] = imageUrls
  const [bt_DiscordH5, bt_linktreeH5, bt_mediumH5, bt_twitterH5, di_blueH5, di_gouH5, di_yangH5] = imageUrls

  const [mailboxAddress, setMailboxAddress] = useState('')
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [tipsValue, setTipsValue] = useState('')

  const useSubscribe = async () => {
    const data = { email: mailboxAddress }
    try {
      await http('contacts', { method: 'post', data })
      setShow(true)
      setMailboxAddress('')
    } catch (error: any) {
      if (error.code === 'duplicate_parameter') {
        setTipsValue('Contact already exist')
      } else {
        setTipsValue('Please enter the correct email address!')
      }
      setOpen(true)
      setMailboxAddress('')

      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  return (
    <Wrap className="Partner" style={{ backgroundImage: `url(${di_blueH5})` }}>
      <DogImg className="GameStyle" src={di_gouH5} alt="" />
      <SheepImg className="GameStyle" src={di_yangH5} alt="" />

      {!show ? (
        <>
          {open && <TipsBox>{tipsValue}</TipsBox>}
          <input type="text" value={mailboxAddress} onChange={(evt: any) => setMailboxAddress(evt.target.value || '')} placeholder="my@email.com" />
          <LoadingButton
            sx={{
              height: '10.667vw',
              width: '44.533vw',
              borderRadius: '10.667vw',
              fontSize: '5.333vw',
              fontFamily: 'Milky Nice-Regular, Milky Nice',
              background: '#000',
              boxShadow: 'none',
              color: '#FFFFFF',
              display: 'block',
              margin: '0 auto',
              '&:hover': {
                background: '#383938',
              },
            }}
            onClick={useSubscribe}
          >
            SUBSCRIBE
          </LoadingButton>
        </>
      ) : (
        <FlexBox
          center
          sx={{
            height: '10.667vw',
            fontSize: '1.719vw',
            margin: '14.667vw auto 13.733vw',
            position: 'relative',
            fontFamily: 'Insaniburger with Cheese',
            zIndex: 1,
          }}
        >
          Thank you! Your submission has been received!
        </FlexBox>
      )}

      <FlexBox className="GameStyle" between sx={{ width: '92.667vw', margin: '11.333vw auto 12.133vw', position: 'relative' }}>
        <AImg href="https://discord.com/invite/UC9ud4QzP7" target="_blank" rel="noopener noreferrer">
          <img src={bt_DiscordH5} alt="discord" />
        </AImg>
        <AImg href="https://twitter.com/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={bt_twitterH5} alt="twitter" />
        </AImg>
        <AImg href="https://medium.com/@SkylandNFTs" target="_blank" rel="noopener noreferrer">
          <img src={bt_mediumH5} alt="medium" />
        </AImg>
        <AImg href="https://linktr.ee/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={bt_linktreeH5} alt="linktree" />
        </AImg>
      </FlexBox>
      <NFTBottom className="GameStyle" href="https://cmp.coinswap.com/#/nft/collection/0x818941409301de5ff759bd9026c5f761422f5fb1?lang=en" target="_blank">
        Go to Marketplace
      </NFTBottom>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 122.4vw;
  background-size: 100% 100%;
  position: relative;
  padding-top: 0.052vw;
  box-sizing: border-box;
  a {
    /* border-bottom: none; */
    text-decoration: none;
  }

  input {
    width: 70.667vw;
    height: 10.933vw;
    background: #ffffff;
    border-radius: 2.133vw;
    font-size: 2.933vw;
    font-family: Milky Nice-Regular, Milky Nice;
    color: #39445d;
    line-height: 10.933vw;
    text-align: center;
    border: 0;
    outline: none;
    position: relative;
    z-index: 2;
    padding-left: 3.067vw;
    box-sizing: border-box;
    margin: 14.667vw auto 3.733vw;
    display: block;
  }

  input::-webkit-input-placeholder {
    font-size: 2.933vw;
    color: rgba(154, 166, 194, 0.8392);
  }
`
const AImg = styled.a`
  width: 21.333vw;
  height: 22.4vw;
  img {
    width: 21.333vw;
    height: 22.4vw;
  }
`
const TipsBox = styled.div`
  position: absolute;
  top: 10vw;
  left: 14.4vw;
  width: fit-content;
  height: 2.933vw;
  font-size: 2.933vw;
  font-family: Milky Nice-Regular, Milky Nice;
  font-weight: 400;
  color: #fa4a58;
  line-height: 2.933vw;
`

const DogImg = styled.img`
  width: 38.933vw;
  height: 36.533vw;
  position: absolute;
  bottom: 0;
  left: 1.733vw;
`
const SheepImg = styled.img`
  width: 22.133vw;
  height: 25.867vw;
  position: absolute;
  top: -11.2vw;
  right: 1.6vw;
`
const NFTBottom = styled.a`
  width: 53.6vw;
  height: 11.2vw;
  border-radius: 11.2vw;
  border: 0.8vw solid #ffffff;
  font-size: 4.533vw;
  font-family: Milky Nice-Regular, Milky Nice;
  color: #ffffff;
  line-height: 11.2vw;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 7.333vw;
  bottom: 9.2vw;
`
export default ContactUSH5
