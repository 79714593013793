import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import GameList from './components/GameList'
import { resetRoute } from 'utils'
import Header from 'components/Header'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useSize from 'hooks/useSize'
import Image from 'components/Image'
import { useNavigate } from 'react-router-dom'
gsap.registerPlugin(ScrollTrigger)

const Download: React.FC<any> = () => {
  const [isShowActive, setIsShowActive] = useState<any>(true)

  const navigate = useNavigate()
  const handleGame = (e: any) => {
    e.stopPropagation()
    navigate('/game')
  }
  const handleMint = (e: any) => {
    e.stopPropagation()
    navigate('/skytopia')
  }
  // const t2 = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: '.homewarp',
  //     start: ScrollTrigger.maxScroll(window) - 800,
  //     scrub: 1,
  //   },
  // })

  // t2.to('.activitybox', { x: 1200 })

  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      window.addEventListener('resize', resetRoute)

      return () => {
        window.removeEventListener('resize', resetRoute)
      }
    }
  }, [pcSize])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.fromTo(entry.target, { y: 60 }, { y: 0, duration: 1 })
        }
      })
    })

    const elements = document.querySelectorAll('.exit-animation')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  // twitter登录
  useEffect(() => {
    // // 解析URL中的查询参数
    // const urlParams = new URLSearchParams(window.location.search)
    // const oauth_token = urlParams.get('oauth_token')
    // const oauth_verifier = urlParams.get('oauth_verifier')

    // // 检查state参数以防止CSRF攻击
    // if (oauth_token && oauth_verifier) {
    //   twitterLoginFun({ oauth_token: oauth_token, oauth_verifier: oauth_verifier, consume_key: Twitter_consume_key })
    //     .then((res: any) => {
    //       if (res.code === 0) {
    //         setAuthCookie(res.data)
    //         resetRoute()
    //       }
    //     })
    //     .catch((err: any) => {
    //       console.log(err)
    //     })
    // }
  }, [])

  return (
    <Warp className="homewarp">
      <Header />
        {/* <NFTSkytopia /> */}
        <GameList />
    </Warp>
  )
}
const Contentbg = styled.div`
  background: #f3f3f3;
  width: 100%;
  overflow: hidden;
`
const Warp = styled.div`
  .skytopia-bg-text {
    box-sizing: border-box;
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    display: block !important;
  }
`
const ActivityBox = styled.div`
  position: fixed;
  bottom: 0vh;
  right: 0;
  width: 20.208vw;
  height: 24vw;
  z-index: 999999;
`
const ActivityBg = styled.div`
  width: 16.042vw;
  height: 16vw;
  margin: 1.927vw 0 0 0.521vw;
  background-size: 100% 100%;
  overflow: hidden;
  position: relative;
`
const GameDownBtn = styled(Image)`
  width: 14.896vw;
  height: 6.458vw;
  margin: 1.094vw auto 0.833vw;
  cursor: pointer;
  display: block;
`
const MintBtn = styled(Image)`
  width: 14.896vw;
  height: 6.458vw;
  margin: 0 auto;
  cursor: pointer;
  display: block;
`

const ActiveTop = styled.div`
  width: 16.042vw;
  height: calc(16vw - 7.708vw);
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(165, 175, 234);
  border-radius: 0.573vw 0.573vw 0 0;
  z-index: -1;
`
const ActiveBottom = styled(Image)`
  width: 16.042vw;
  height: 7.708vw;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
`

const AnimalModel = styled(Image)`
  width: 19.375vw;
  height: 11.146vw;
  position: absolute;
  bottom: 0;
  right: 0;
`
const ActiveityClose = styled(Image)`
  width: 2.188vw;
  height: 2.188vw;
  position: absolute;
  top: 1.146vw;
  right: 2.813vw;
`

export default Download
