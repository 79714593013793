import Image from 'components/Image'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect, useRef, useState } from 'react'
import { Box, Button } from '@mui/material'
import { progressFun, getproofFun, checkWhiteListFun } from 'api/request'
import message from 'utils/message'
import { useWeb3React } from '@web3-react/core'
import { buyLandWithETH } from 'utils/BuyNFT'
import { useLandSaleWithETHAndDAI } from 'hooks/useContract'
import { S3Url } from "api/apiList";

const MintH5 = () => {
  const a1 = `${S3Url}/images/skytopia/a1H5.png`
  const a2 = `${S3Url}/images/skytopia/a2H5.png`
  const a3 = `${S3Url}/images/skytopia/a3H5.png`

  const [loading, setLoading] = useState(false)

  const [MintList, setMintList] = useState<any>([])
  const [isWhiteList, setIsWhiteList] = useState<any>()

  const images = [a1, a2, a3] // eslint-disable-line
  var { account } = useWeb3React()

  const LandSaleWithETHAndDAIContract = useLandSaleWithETHAndDAI(localStorage.getItem('MarketContract'))

  // 获取mint进度
  useEffect(() => {
    const getProgressFun = () => {
      progressFun({ contract_address: localStorage.getItem('NftContract') })
        .then((res: any) => {
          setMintList({ ...res.data })
        })
        .catch((err: any) => {})
    }
    const interval = setInterval(async () => {
      await getProgressFun()
    }, 6000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    if (account === '' || account === null || account === undefined) {
      return
    }
    const getCheckWhiteListFun = () => {
      checkWhiteListFun({ contract_address: localStorage.getItem('NftContract'), wallet_address: account })
        .then((res: any) => {
          if (res.data) {
            setIsWhiteList(res.data.is_white_list)
          }
        })
        .catch((err: any) => {})
    }
    getCheckWhiteListFun()
  }, [account])

  // 图片无缝播放
  const marqueeRef = useRef<any>(null)
  const [totalWidth, setTotalWidth] = useState(0)
  let animationFrameId: number | null = null

  useEffect(() => {
    const marquee = marqueeRef.current
    let totalWidth = 0
    for (const child of marquee.children) {
      totalWidth += (child as HTMLElement).offsetWidth
    }
    setTotalWidth(totalWidth)
  }, [images]) // eslint-disable-line

  const step = () => {
    const marquee = marqueeRef.current
    const firstChild = marquee.firstChild as HTMLElement
    marquee.scrollLeft += 1

    if (marquee.scrollLeft >= firstChild.offsetWidth) {
      marquee.appendChild(marquee.firstChild)
      marquee.scrollLeft -= firstChild.offsetWidth
    }

    animationFrameId = requestAnimationFrame(step)
  }

  useEffect(() => {
    step()
    return () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId)
      }
    }
  }, [totalWidth]) // eslint-disable-line

  return (
    <Wrap className="Mint-wrap">
      <StoryBox ref={marqueeRef}>
        {images.map((image, index) => (
          <img key={index} src={image} alt="" />
        ))}
      </StoryBox>
      <MintTextBox className="Minttextbox">
        <BigTitle className="Minttitie">Skytopian Residents</BigTitle>
        <SmallTitle className="Minttitie">This series is designed by the Skytopia team. It's made up of 10,000 unique 3D avatar NFTs.</SmallTitle>
        <Progress>
          {MintList?.nft_minted_amount || 0}/{MintList?.nft_total || 0}
        </Progress>
        <TipBtn className="Minttitie">Third round</TipBtn>
        <Whitelisted>
          <Box>Coming Soon</Box>
        </Whitelisted>
        {/* <MintPrice>Mint price: {isWhiteList ? 'Free' : '500CMP'}</MintPrice>
        <Whitelisted>
          <Box>Whitelisted：</Box>
          {isWhiteList ? <IsWhitelisted folder="skytopia" src="yes.png" /> : <IsWhitelisted folder="skytopia" src="no.png" />}
        </Whitelisted>

        <MintBtn
          onClick={async () => {
            if (account === '' || account === null || account === undefined) {
              message.warn('Please connect your wallet. ')
              return false
            } else if (account?.toLocaleLowerCase() !== localStorage.getItem('account')?.toLocaleLowerCase()) {
              message.warn('Connected address does not match. Please switch to your binding address.')
              return false
            }
            var list: any = []
            try {
              await getproofFun({ contract_address: localStorage.getItem('NftContract') }).then(async (res: any) => {
                if (res.code === 0) {
                  list = res.data
                }
              })
            } catch (error) {
              return false
            }

            try {
              if (list.length === 0) {
                return false
              }
              setLoading(true)
              await buyLandWithETH(LandSaleWithETHAndDAIContract, account, list.token_id, list.price, list.salt, list.proofs)
              message.success('Mint Success')
              setLoading(false)
            } catch (error) {
              message.error('Mint Error.')
              setLoading(false)
            }
          }}
          disabled={loading}
        >
          <MintImg folder="skytopia" src="Mint.png" />
        </MintBtn>
       */}
      </MintTextBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: 69.067vw;
  width: 100%;
  position: relative;
  overflow: hidden;
`

const MintTextBox = styled.div`
  width: 85.6vw;
  min-height: 69.067vw;
  margin: 0 auto;
`

const BigTitle = styled.div`
  width: 100%;
  height: 22.4vw;
  font-size: 10.133vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 10.133vw;
`

const SmallTitle = styled.div`
  width: 84.533vw;
  height: 11.733vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #3d3d3d;
  line-height: 5.067vw;
  margin: 0vw 0 0.667vw;
`
const TipBtn = styled.div`
  width: 85.6vw;
  height: 6.933vw;
  background: #7e8084;
  border-radius: 1.467vw;
  font-size: 4.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #ffffff;
  line-height: 6.933vw;
  text-align: center;
`
const Progress = styled.div`
  width: 100%;
  height: 14.533vw;
  font-size: 13vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  line-height: 13.867vw;
  background: linear-gradient(90deg, #ff58a9 26%, #26a9f0 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-top: 1.867vw;

`
const MintPrice = styled.div`
  width: 34vw;
  height: 5.6vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 5.6vw;
`
const Whitelisted = styled.div`
  width: 23.067vw;
  height: 5.6vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 5.6vw;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const IsWhitelisted = styled(Image)`
  width: 3.733vw;
  height: 3.733vw;
`
const MintBtn = styled(Button)`
  width: 81.733vw;
  height: 13.333vw;
  background: #383938;
  border-radius: 13.333vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8vw 0 8vw;
  box-sizing: border-box;
  margin-top: 10.267vw;
  :hover {
    background: #383938;
  }
  @keyframes rotationback {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 3s linear infinite;
  }
`
const MintImg = styled(Image)`
  width: 21.867vw;
  height: 6.933vw;
`

// const ArrowImg = styled(Image)`
//   width: 1.563vw;
//   height: 0.677vw;
// `
const Loading = styled(Image)`
  width: 2.5vw;
  height: 2.5vw;
`

const StoryBox = styled.div`
  width: 100%;
  height: 72.267vw;
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  margin: 20.4vw auto 5.067vw;
  img {
    width: 228.133vw;
    height: 72.267vw;
  }
`
export default MintH5
