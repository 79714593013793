import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import { JsonUrl } from 'api/apiList'
import FlexBox from 'components/FlexBox'
import Box from '@mui/material/Box/Box'
import { partyplanetFun } from 'api/request'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'
import { resetRoute } from 'utils'
interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: any
  progress?: Number
}
const VideoH5: React.FC<PreloaderProps> = ({ imageUrls, videoUrls }) => {
  let [btn_1H5, btn_2H5, btn_3H5, btn_4H5, btn_5H5, di_yinliangH5, di_yinliang_kaiH5, di_youtubeH5, logo_ppH5, logo_skyH5, VideoBgH5, btn_aH5, btn_wH5, btn_AndroidH5, btn_GooglePlayH5] = imageUrls

  const [index] = videoUrls

  const [isLoaded, setIsLoaded] = useState(false)

  const [WinInfo, setWinInfo] = useState<any>([])
  const [MacInfo, setMacInfo] = useState<any>([])
  const [AndroidInfo, setAndroidInfo] = useState<any>([])
  const [IosInfo, setIosInfo] = useState<any>([])

  const [Mute, setMute] = useState<any>(true)

  const toggleMute = () => {
    setMute(!Mute)
  }
  useEffect(() => {
    const getdownloadInfo = () => {
      partyplanetFun()
        .then((res: any) => {
          if (res.data.length > 0) {
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index]
              switch (element.platform) {
                case 'pc':
                  setWinInfo(res.data[index])
                  break
                case 'mac':
                  setMacInfo(res.data[index])
                  break
                case 'android':
                  setAndroidInfo(res.data[index])
                  break
                case 'ios':
                  setIosInfo(res.data[index])
                  break
              }
            }
          }
        })
        .catch((err: any) => {})
    }
    getdownloadInfo()
  }, [])
  return (
    <Wrap>
      <HeadBox>
        <Logo onClick={resetRoute} src={logo_skyH5} alt="Logo" />
        <FlexBox>
          <HeadA href="https://discord.com/invite/UC9ud4QzP7" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_1H5} alt="" />}
          </HeadA>
          <HeadA href="https://twitter.com/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_2H5} alt="" />}
          </HeadA>
          <HeadA href="https://medium.com/@SkylandNFTs" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_3H5} alt="" />}
          </HeadA>
          <HeadA href="https://linktr.ee/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
            {<HeadRight src={btn_4H5} alt="" />}
          </HeadA>
          <HeadLink to={'/'}>{<HeadRight src={btn_5H5} alt="" />}</HeadLink>
        </FlexBox>
      </HeadBox>

      <GameLogo onClick={() => window.location.reload()} src={logo_ppH5} alt="GameLogo"></GameLogo>

      <FootBox style={{ backgroundImage: `url(${VideoBgH5})` }}>
        <video
          playsInline
          muted={Mute}
          autoPlay
          loop
          className="back-video-style"
          onCanPlayThrough={() => {
            setIsLoaded(true)
          }}
        >
          <source src={index} type="video/mp4"></source>
        </video>
        <FlexBox between sx={{ width: '81.067vw', height: '15.333vw', margin: '0 auto' }}>
          <YoutobeA href="https://www.youtube.com/watch?v=4dlorfwG4P4" target="_blank" rel="noopener noreferrer">
            <Youtobe src={di_youtubeH5} alt="" />
          </YoutobeA>

          {Mute ? <Volume onClick={toggleMute} src={di_yinliangH5} alt="" /> : <Volume onClick={toggleMute} src={di_yinliang_kaiH5} alt="" />}
        </FlexBox>
      </FootBox>

      {/* <DownLoadBox>
        <LogoBox href={'https://play.google.com/store/apps/details?id=com.Skytopia.PartyPlanet'} target="_blank" rel="noopener noreferrer">
          <SaveButton>
            <LogoBtn src={btn_GooglePlayH5} alt=""></LogoBtn>
          </SaveButton>
        </LogoBox>

        <LogoBox href={AndroidInfo?.download} target="_blank" rel="noopener noreferrer">
          <SaveButton>
            <LogoBtn className="winLogo" src={btn_AndroidH5} alt=""></LogoBtn>
          </SaveButton>
        </LogoBox>

        <LogoBox href={MacInfo?.download} target="_blank" rel="noopener noreferrer">
          <SaveButton>
            <LogoBtn className="macLogo" src={btn_aH5} alt=""></LogoBtn>
          </SaveButton>
        </LogoBox>

        <LogoBox href={WinInfo?.download} target="_blank" rel="noopener noreferrer">
          <SaveButton>
            <LogoBtn className="winLogo" src={btn_wH5} alt=""></LogoBtn>
          </SaveButton>
        </LogoBox>
      </DownLoadBox> */}
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 80vh;
  position: relative;
  background-color: RGBA(50, 103, 217, 1);

  overflow: hidden;
`
const HeadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 92.667vw;
  height: 7.333vw;
  margin: 0 auto;
  position: absolute;
  left: 3.733vw;
  top: 6.8vw;
`
const Logo = styled.img`
  width: 10.4vw;
  height: auto;
`
const GameLogo = styled.img`
  width: 37.067vw;
  height: 20vw;
  margin: 26.933vw auto 8.4vw;
  display: block;
`

const HeadA = styled.a`
  width: 6.667vw;
  height: 6.667vw;
  display: block;
  position: relative;
  margin-left: 2.133vw;
`
const HeadLink = styled(Link)`
  width: 6.667vw;
  height: 6.667vw;
  display: block;
  position: relative;
  margin-left: 2.133vw;
`

const HeadRight = styled.img`
  width: 6.667vw;
  display: block;
  position: absolute;
`
const FootBox = styled.div`
  width: 100vw;
  height: 65.067vw;
  background-size: 100% 100%;
  overflow: hidden;
  .back-video-style {
    margin: 4.133vw auto 0;
    width: 81.067vw;
    height: 45.6vw;
    display: block;
  }
`
const YoutobeA = styled.a`
  width: 23.467vw;
  height: 6.4vw;
  display: block;
  position: relative;
  margin-right: 1.771vw;
`
const Youtobe = styled.img`
  width: 23.467vw;
  height: auto;
`
const Volume = styled.img`
  width: 6.4vw;
  height: auto;
`

// 下载按钮
const DownLoadBox = styled.div`
  width: 100vw;
  height: 130vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* padding: 12.533vw 0 25.533vw 0; */
  padding: 18.533vw 0 29.2vw 0;
  box-sizing: border-box;
`

const LogoBox = styled.a`
  width: 75.2vw;
  height: 15.6vw;
  cursor: pointer;
`
const SaveButton = styled(Button)`
  width: 75.2vw;
  height: 15.6vw;
  background-color: rgba(92, 127, 255, 0);
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`
const LogoBtn = styled.img`
  width: 75.2vw;
  height: auto;
  position: absolute;
`
export default VideoH5
