import styled from "@emotion/styled"
import HunbsHeader from "components/HunbsHeader"
import Content from "components/HunbsHeader/Content"
import Title from "components/HunbsHeader/Title"

const HunbsVideo: React.FC<any> = ({ VideoInfo }) => {
  return (
    <Warp>
      <HunbsHeader Info={VideoInfo} />
      <HunbsBg></HunbsBg>
      <iframe id="youtubeid" src={VideoInfo.url} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
      {VideoInfo?.title !== "" && <Title>{VideoInfo?.title}</Title>}
      {VideoInfo?.content !== "" && <Content>{VideoInfo?.content}</Content>}
      <Line></Line>
    </Warp>
  )
}
const Warp = styled.div`
  width: 52.604vw;
  min-height: 25.135vw;
  padding: 0 1.563vw 2.083vw;
  box-sizing: border-box;
  position: relative;
  #youtubeid {
    width: 35.417vw;
    height: 20vw;
    border-radius: 1.042vw;
    margin-bottom: 1.458vw;
    position: relative;
  }
  @media (max-width: 1000px) {
    width: 96.96vw;
    min-height: 78vw;
    padding: 0 2.8vw 2vw;
    box-sizing: border-box;
    position: relative;
    border-radius: 4vw;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0 auto 2.667vw;
    #youtubeid {
      width: 91.354vw;
      height: 50.88vw;
      border-radius: 2.667vw;
      margin-bottom: 2vw;
      position: relative;
    }
  }
`

const HunbsBg = styled.div`
  width: 35.417vw;
  height: 20vw;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 1.042vw;
  position: absolute;
  top: 7.2vw;
  left: 1.563vw;
  @media (max-width: 1000px) {
    width: 91.354vw;
    height: 50.88vw;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.667vw;
    position: absolute;
    top: 13.3vw;
    left: 2.8vw;
  }
`
const Line = styled.div`
  width: 50.521vw;
  height: 1px;
  background: #7893be;
  position: absolute;
  bottom: 0;
  left: 1.042vw;
  @media (max-width: 1000px) {
    display: none;
  }
`
export default HunbsVideo
