import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import { ethers } from 'ethers'
import config from 'config/constants'
import ERC20Abi from 'config/abi/erc20.json'
import { WalletCache } from './walletUtils'
import LandSaleWithETHAndDAIAbi from 'config/abi/LandSaleWithETHAndDAI.json'
import EthLandSaleWithETHAndDAIAbi from 'config/abi/eth/LandSaleWithETHAndDAI.json'
import AssetsSaleWithCMPTestAbi from 'config/abi/AssetsSaleWithCMPTest.json'

const chainId = WalletCache.getChain()
export const getRpcUrl = (): string => {
  return config.CHAIN_INFO[0].rpcUrl
}

export const getWeb3NoAccount = (): Web3 => {
  const httpProvider = new Web3.providers.HttpProvider(getRpcUrl(), { timeout: 10000 })
  return new Web3(httpProvider)
}

export const getContract = (abi: any, address: string, web3?: Web3) => {
  const _web3 = web3 || getWeb3NoAccount()
  return new _web3.eth.Contract(abi as unknown as AbiItem, address)
}

export const getErc20Contract = (address: string, web3?: Web3) => {
  return getContract(ERC20Abi as AbiItem[], address, web3)
}

export const getLandSaleWithETHAndDAI = (contractAddress: any, web3?: Web3) => {
  return getContract(LandSaleWithETHAndDAIAbi, contractAddress, web3)
}

export const getEthLandSaleWithETHAndDAI = (contractAddress: any, web3?: Web3) => {
  return getContract(EthLandSaleWithETHAndDAIAbi, contractAddress, web3)
}

export const getAssetsSaleWithCMP = (contractAddress: any, web3?: Web3) => {
  return getContract(AssetsSaleWithCMPTestAbi, contractAddress, web3)
}

export const getLibrary = (provider: any) => {
  return provider
}

export const approve = async (lpContract: any, masterChefContract: any, account: any) => {
  return lpContract.methods.approve(masterChefContract.options.address, ethers.constants.MaxUint256).send({ from: account })
}

export const setApprovalForAll = async (lpContract: any, masterChefContract: any, account: any, bool = true) => {
  return lpContract.methods.setApprovalForAll(masterChefContract.options.address, bool).send({ from: account })
}

export const blockTime = chainId === 66 ? 3.8 : 3
