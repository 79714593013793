import Image from "components/Image"
import styled from "@emotion/styled"
import { ChangeEvent, useEffect, useState } from "react"
import FlexBox from "components/FlexBox"
import { Box, Button } from "@mui/material"
import { getPersonalFun, personalFun } from "api/request"
import axios from "axios"
import { getAuthCookie } from "utils/user"
import { WalletCache } from "utils/walletUtils"
import { baseURL } from "api/apiList"
import message from "utils/message"

const ProfileH5 = () => {
  const [username, setUsername] = useState("")
  const [Name, setName] = useState("")
  const [Personal, setPersonal] = useState("")
  const [Description, setDescription] = useState("")
  const [Telegram, setTelegram] = useState("")
  const [Instagram, setInstagram] = useState("")
  const [discord, setDiscord] = useState("")
  const [Twitter, setTwitter] = useState("")
  const [ImgUrl, setImgUrl] = useState("")
  var reg = /^[a-z0-9A-Z]+$/
  const [hasBorder, setHasBorder] = useState("")

  const token = getAuthCookie()
  const chainId = WalletCache.getChain()
  useEffect(() => {
    getPersonalFun()
      .then((data: any) => {
        setName(data.data.name)
        setUsername(data.data.username)
        setPersonal(data.data.personal_web)
        setDescription(data.data.description)
        setTelegram(data.data.telegram)
        setInstagram(data.data.instagram)
        setDiscord(data.data.discord)
        setTwitter(data.data.twitter)
        setImgUrl(data.data.image)
        localStorage.setItem("account", data.data.wallet_address)
      })
      .catch((err: any) => {})
  }, [token])

  const handleUpdate = (event: any) => {
    let fileImg = event.target.files[0]
    Accepts(fileImg)
  }
  const Accepts = async (files: any) => {
    if (!files) {
      return false
    }
    if (files.size > 2097152) {
      message.error("The picture size is larger than 2MB.")
      return
    } else {
      let formdata = new FormData()
      formdata.append("file", files)
      await axios
        .post(`${baseURL}/v1/web/user/image/upload`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
            "x-app-id": baseURL.includes("offical") ? "3b9cxer89prTdIroGvdAa9mfLM97LK1eltFRHJwRhEsKo" : "7a8hxez99pgvdIroTaDZh9mfON97LO1wldDGVMwEhWsHo",
            "x-app-key": baseURL.includes("offical") ? "4RFhb9LrEgf3akF8KolkWxRfx9JBWaW75gO2c8lfo8Wvt" : "3XEhbwprbgf3akD8JplkTxYgx8JUIQT75hOIc9ldd8Wv6",
            "chain-id": chainId,
            app: "web",
            authentication: token,
          },
        })
        .then((res) => {
          setImgUrl(res.data.data.link)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  return (
    <Wrap>
      <Title>profile settings</Title>
      <UserBox>
        <input type="file" className="UpdateBtn" accept="image/*" onChange={(event) => handleUpdate(event)} />
        {ImgUrl?.length > 0 ? (
          <ImgBox>
            <img className="headImg" src={ImgUrl} alt="img" />{" "}
          </ImgBox>
        ) : (
          <NouserImage folder="setting" src="Nouser.png"></NouserImage>
        )}
      </UserBox>
      <SmallTitle>Basic information</SmallTitle>

      <BasicBox>
        <InputName>Username</InputName>
        <input
          type="text"
          value={username}
          onChange={(evt: any) => {
            setUsername(evt.target.value || "")
          }}
          onFocus={() => {
            setHasBorder("username")
          }}
          onBlur={() => {
            setHasBorder("")
          }}
          style={{ marginBottom: "7.867vw" }}
          className={hasBorder === "username" ? "inputError" : ""}
        />

        <InputName>Name</InputName>
        <input
          type="text"
          value={Name}
          onChange={(evt: any) => {
            setName(evt.target.value || "")
          }}
          onFocus={() => {
            setHasBorder("Name")
          }}
          onBlur={() => {
            setHasBorder("")
          }}
          style={{ marginBottom: "2.8vw" }}
          className={hasBorder === "Name" ? "inputError" : ""}
        />

        <InputName>Personal web</InputName>
        <input
          type="text"
          value={Personal}
          onChange={(evt: any) => {
            setPersonal(evt.target.value || "")
          }}
          onFocus={() => {
            setHasBorder("Personal")
          }}
          onBlur={() => {
            setHasBorder("")
          }}
          style={{ marginBottom: "6.8vw" }}
          className={hasBorder === "Personal" ? "inputError" : ""}
        />

        <InputName>Description</InputName>
        <textarea
          value={Description}
          maxLength={500}
          onChange={(evt: any) => {
            setDescription(evt.target.value || "")
          }}
          onFocus={() => {
            setHasBorder("Description")
          }}
          onBlur={() => {
            setHasBorder("")
          }}
          className={hasBorder === "Description" ? "inputError" : ""}
        />
        <Box
          sx={{
            position: "absolute",
            right: "5.333vw",
            bottom: "2vw",
            fontSize: "2.4vw",
            fontFamily: "Microsoft Tai Le",
            color: "rgba(120, 147, 190, 0.5)",
          }}
        >
          {Description.length} / 500
        </Box>
      </BasicBox>

      <SmallTitle>Social information</SmallTitle>
      <SocialBox>
        <FlexBox start>
          <IconImg folder="setting" src="icon1.png"></IconImg>
          <Box>
            <input
              type="text"
              value={Twitter}
              placeholder="@SkylandNFTs"
              onChange={(evt: any) => {
                setTwitter(evt.target.value || "")
              }}
              onFocus={() => {
                setHasBorder("Twitter")
              }}
              onBlur={() => {
                setHasBorder("")
              }}
              className={hasBorder === "Twitter" ? "inputError" : ""}
            />
            <IconTips>Enter your Twitter Handle</IconTips>
          </Box>
        </FlexBox>

        <FlexBox start>
          <IconImg folder="setting" src="icon2.png"></IconImg>
          <Box>
            <input
              type="text"
              value={discord}
              placeholder="Skytopia#1234"
              onChange={(evt: any) => {
                setDiscord(evt.target.value || "")
              }}
              onFocus={() => {
                setHasBorder("discord")
              }}
              onBlur={() => {
                setHasBorder("")
              }}
              className={hasBorder === "discord" ? "inputError" : ""}
            />
            <IconTips>Enter your user#id</IconTips>
          </Box>
        </FlexBox>

        <FlexBox start>
          <IconImg folder="setting" src="icon3.png"></IconImg>
          <Box>
            <input
              type="text"
              placeholder="Skytopia"
              value={Instagram}
              onChange={(evt: any) => {
                setInstagram(evt.target.value || "")
              }}
              onFocus={() => {
                setHasBorder("Instagram")
              }}
              onBlur={() => {
                setHasBorder("")
              }}
              className={hasBorder === "Instagram" ? "inputError" : ""}
            />
            <IconTips>Enter your Instagram username</IconTips>
          </Box>
        </FlexBox>

        <FlexBox start>
          <IconImg folder="setting" src="icon4.png"></IconImg>
          <Box>
            <input
              type="Telegram"
              value={Telegram}
              placeholder="Skytopia"
              onChange={(evt: any) => {
                setTelegram(evt.target.value || "")
              }}
              onFocus={() => {
                setHasBorder("Telegram")
              }}
              onBlur={() => {
                setHasBorder("")
              }}
              className={hasBorder === "Telegram" ? "inputError" : ""}
            />
            <IconTips>Enter your Telegram username</IconTips>
          </Box>
        </FlexBox>
      </SocialBox>
      <SaveButton
        onClick={() => {
          if (username.length === 0) {
            message.error("Username can not be empty")
            return false
          }

          if (!reg.test(username)) {
            message.error("Invalid username.")
            return false
          }

          if ((Personal && Personal.indexOf("http") !== 0) || (Personal && Personal.indexOf(".") < 4)) {
            message.error("Invalid personal website")
            return false
          }

          localStorage.setItem("update", Math.round(new Date().getTime() / 1000).toString())
          personalFun({
            description: Description,
            name: Name,
            username: username,
            personal_web: Personal,
            twitter: Twitter,
            discord: discord,
            instagram: Instagram,
            telegram: Telegram,
            image: ImgUrl,
            area: "ok",
          }).then(() => {
            message.success("success")
          })
        }}
      >
        SAVE
      </SaveButton>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: 94vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  input {
    background: rgba(120, 147, 190, 0.5);
    border: 0;
    outline: none;
    padding-left: 3.867vw;
    font-size: 3.2vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
    line-height: 9.6vw;
    border: 2px solid rgba(133, 160, 254, 0);
    box-sizing: border-box;
    min-width: 64.267vw;
    width: 100%;
    height: 9.6vw;
    border-radius: 2vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  .inputError {
    border: 2px solid rgba(133, 160, 254, 1) !important;
  }
  textarea {
    background: rgba(120, 147, 190, 0.5);
    border: 0;
    outline: none;
    padding: 3.867vw;
    font-size: 3.2vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
    line-height: 3.2vw;
    box-sizing: border-box;
    border: 2px solid rgba(133, 160, 254, 0);
    resize: none;
    width: 73.467vw;
    height: 39.333vw;
    border-radius: 2vw;
  }
`
const Title = styled.div`
  font-size: 7.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 7vw;
  text-align: center;
  margin: 19.067vw auto 26.133vw;
`

const SmallTitle = styled.div`
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 2.604vw;
  margin-bottom: 4vw;
  padding-left: 10.533vw;
`
const BasicBox = styled.div`
  background: rgba(255, 255, 255, 0.4);
  padding: 5.333vw;
  box-sizing: border-box;
  width: 84vw;
  min-height: 117.2vw;
  border-radius: 2.667vw;
  margin: 0 auto 8.133vw;
  position: relative;
`
const SocialBox = styled.div`
  background: rgba(255, 255, 255, 0.4);
  position: relative;
  padding: 5.333vw 5.333vw 0.667vw 5.733vw;
  box-sizing: border-box;
  width: 84.133vw;
  min-height: 68.133vw;
  border-radius: 2.667vw;
  margin: 0 auto 5.333vw;
`
const InputName = styled.div`
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 2.8vw;
`
const IconImg = styled(Image)`
  width: 6vw;
  height: 6vw;
  margin-right: 2.4vw;
  margin-top: 1.6vw;
`
const IconTips = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(120, 147, 190, 0.5);
  margin-top: 1.067vw;
  margin-bottom: 3.467vw;
  margin-left: 1.6vw;
  font-size: 2.933vw;
`
const SaveButton = styled(Button)`
  background-color: rgba(92, 127, 255, 0.7);
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  width: 29.333vw;
  height: 8.8vw;
  border-radius: 2.667vw;
  font-size: 4.267vw;
  margin: 0 34.667vw 30vw 35.867vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`

const ImgBox = styled.div`
  width: 23.467vw;
  height: 23.467vw;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  /* left: -0.313vw;
  top: -0.313vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .headImg {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
`

const UserBox = styled.div`
  width: 23.467vw;
  height: 23.467vw;
  background-color: rgba(120, 147, 190, 0.5);
  border-radius: 50%;
  margin: 0 auto 6.667vw;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  .UpdateBtn {
    width: 23.467vw;
    height: 23.467vw;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    outline: none;
    box-sizing: border-box;
    background: none;
    opacity: 0;
    z-index: 10;
    min-width: 10vw;
  }
`
const NouserImage = styled(Image)`
  width: 23.467vw;
  height: 23.467vw;
  border-radius: 50%;
`
export default ProfileH5
