/* eslint-disable */
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import Image from "components/Image"
import FlexBox from "components/FlexBox"
import BasicSelect from "./DropDownSelection"
import { NfTlistFun } from "api/request"
import { getAuthCookie } from "utils/user"
import { Box } from "@mui/material"
import Backdrop from "@mui/material/Backdrop"

const InventoryH5 = () => {
  const [totalPage, setTotalPage] = useState(0)

  const [contractAddress, setContractAddress] = useState<any>("all")
  const [loading, setLoading] = useState(false)

  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(1)
  const handleClose = () => {
    setOpen(false)
  }

  const [getAllDataList, setAllDataList] = useState<any>([])
  const token = getAuthCookie()

  const selectSendDataFn = (pageIndex?: number) => {
    setAllDataList([])
    setTotalPage(0)
    const param = {
      contract_address: contractAddress,
    }
    setLoading(true)
    NfTlistFun(param)
      .then((res: any) => {
        setTotalPage(res.data.length || 0)
        setAllDataList([...res.data])
      })
      .catch((e) => {
        setTotalPage(0)
        setAllDataList([])
      })
  }

  useEffect(() => {
    if (token) {
      selectSendDataFn()
    }
  }, [token, contractAddress])
  return (
    <Wrap>
      <Title>Inventory</Title>
      <FlexBox startCenter sx={{ margin: "0 0 2.667vw 10.533vw" }}>
        <Total>{totalPage || 0}</Total>
        <BasicSelect setContractAddress={setContractAddress}></BasicSelect>
      </FlexBox>
      {getAllDataList?.length > 0 ? (
        <>
          <InventoryBox>
            {getAllDataList?.map((item: any, i: any) => (
              <ImgBox
                key={i}
                onClick={() => {
                  setOpen(true)
                  setShow(i + 1)
                }}
              >
                <NFTImgBox>
                  {item?.meta_info.animation_url === "" ? (
                    <NFTImg
                      src={item.meta_info.image}
                      alt="NFT"
                      style={{ display: loading ? "none" : "block" }}
                      onLoad={() => {
                        setLoading(false)
                      }}
                    />
                  ) : (
                    <NFTVideo
                      playsInline
                      muted
                      autoPlay
                      loop
                      onCanPlayThrough={() => {
                        setLoading(false)
                      }}
                    >
                      <source src={item.meta_info.animation_url} type="video/mp4"></source>
                    </NFTVideo>
                  )}
                </NFTImgBox>
                {loading && <Loading className="RotationBack" folder="components" src="rotate.png"></Loading>}
                <Box
                  sx={{
                    width: "24vw",
                    height: "8.267vw",
                    background: "#7D99FF",
                    borderRadius: "0 0 0 0",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    zIndex: 3,
                  }}
                >
                  <NFTID>#{item.token_id}</NFTID>
                  <NFTName>{item.meta_info.name}</NFTName>
                </Box>
              </ImgBox>
            ))}
          </InventoryBox>
        </>
      ) : (
        <NoData>
          <NoDataImg src="NoData.png" folder="setting"></NoDataImg>
          <Tips>You don’t have nft yet</Tips>
        </NoData>
      )}

      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        {getAllDataList.map((item: any, key: any) =>
          item?.meta_info.animation_url === ""
            ? show === key + 1 && <img src={item.meta_info.image} alt="NFT" className="img-style-big" key={key + item} />
            : show === key + 1 && (
                <video playsInline muted autoPlay loop className="video-style-big" key={key + item}>
                  <source src={item.meta_info.animation_url} type="video/mp4"></source>
                </video>
              )
        )}
      </Backdrop>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh - 42.867vw);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .img-style-big {
    width: 60vw;
    height: auto;
  }
  .video-style-big {
    max-width: 80vw;
    max-height: 60vh;
    width: auto;
    height: auto;
  }
`
const InventoryBox = styled.div`
  overflow-y: auto;
  min-height: 26.771vw;
  background: rgba(255, 255, 255, 0.4);
  overflow: hidden;
  box-sizing: border-box;
  padding: 3.867vw;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 84vw;
  border-radius: 2.667vw;
  margin: 0 auto 10vw;
`
const Title = styled.div`
  font-size: 7.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 7vw;
  text-align: center;
  margin: 19.067vw auto 24vw;
`
const Total = styled.div`
  min-width: 4vw;
  font-size: 4.267vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin-right: 4.133vw;
`

const ImgBox = styled.div`
  width: 24vw;
  height: 31.067vw;
  overflow: hidden;
  position: relative;
  margin-bottom: 2.667vw;
  border-radius: 2vw;
  margin-right: 2.133vw;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  @keyframes rotationback {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 3s linear infinite;
  }
`

const NFTImgBox = styled.div`
  width: 24vw;
  height: 24vw;
  background-color: rgba(120, 147, 190, 0.5);

  position: absolute;
  left: 0;
  top: 0;
`
const NFTImg = styled.img`
  width: 100%;
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`
const NFTVideo = styled.video`
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
`

const NFTID = styled.div`
  background: rgba(255, 255, 255, 0.3);
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 5;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 25.333vw;
  height: 3.733vw;
  font-size: 2.933vw;
`
const NFTName = styled.div`
  padding: 0 1.333vw;
  text-align: center;
  box-sizing: border-box;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 24vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
`
const Loading = styled(Image)`
  position: absolute;
  left: 50%;
  top: 40%;
  margin-top: -2.5vw;
  margin-left: -2.5vw;
  width: 5vw;
  height: 5vw;
`
const NoData = styled.div`
  background: rgba(255, 255, 255, 0.4);
  margin: 2.667vw auto 0;
  overflow: hidden;
  position: relative;
  width: 84vw;
  height: 86.8vw;
  border-radius: 2.667vw;
`
const NoDataImg = styled(Image)`
  width: 30.133vw;
  height: 34.107vw;
  margin: 16.267vw auto 5.067vw;
`
const Tips = styled.div`
  font-size: 4.267vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b2c1dd;
  text-align: center;
  position: absolute;
  bottom: 27.2vw;
  left: 18.667vw;
`
export default InventoryH5
/* eslint-disable */
