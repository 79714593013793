import qs from "qs"
const apiUrl = process.env.REACT_APP_API_URL

interface Config extends RequestInit {
  token?: string
  data?: object
}
export const http = async (endpoint: string, { data, token, headers, ...customConfig }: Config = {}) => {
  const config = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": data ? "application/json" : "",
      "api-key": "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",
    },
    ...customConfig,
  }

  if (config.method.toUpperCase() === "GET") {
    endpoint += `?${qs.stringify(data)}`
  } else {
    config.body = JSON.stringify(data || {})
  }

  return window.fetch(`${apiUrl}/${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      window.location.reload()
      return Promise.reject({ message: "login" })
    }
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}
