import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect } from "react"
import useSize from "hooks/useSize"
import Media from "react-media"
import AboutHeaderH5 from "./AboutHeaderH5"

const AboutHeader = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".about-wrap", start: "top-=800" } })
      tl.fromTo(".about-title-Tips", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 1 })
      tl.fromTo(".about-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 1 })
    }
  }, [pcSize])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="about-wrap">
            <HeaderImage folder="about" src="headerImg.png"/>
            <Tips className="about-title-Tips">join us to build a truly generational</Tips>
            <Title className="about-title-style">metaverse</Title>
          </Wrap>
        ) : (
          <AboutHeaderH5 />
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  height: 67.24vw;
  width: 100%;
  position: relative;
  overflow: hidden;
`
const HeaderImage = styled(Image)`
  height: 50.052vw;
  width: 88.958vw;
  margin: 6.771vw auto 0;
`

const Title = styled.div`
  text-align: center;
  width: 30vw;
  height: 2.917vw;
  font-size: 4.167vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 2.604vw;
  position: absolute;
  left: 50%;
  top: 33.958vw;
  margin-left: -15vw;
`
const Tips = styled.div`
  width: 50vw;
  text-align: center;
  height: 1.771vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 1.604vw;
  position: absolute;
  left: 50%;
  top: 31.094vw;
  margin-left: -25vw;
`
export default AboutHeader
