import styled from '@emotion/styled'
import { useState } from 'react'
import FlexBox from 'components/FlexBox'
import LoadingButton from '@mui/lab/LoadingButton'
import { http } from 'utils/http'
interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress?: Number
}

const ContactUS: React.FC<PreloaderProps> = ({ imageUrls }) => {
  const [bt_Discord, bt_linktree, bt_medium, bt_twitter, di_blue, di_gou, di_yang] = imageUrls

  const [mailboxAddress, setMailboxAddress] = useState('')
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [tipsValue, setTipsValue] = useState('')

  const useSubscribe = async () => {
    const data = { email: mailboxAddress }
    try {
      await http('contacts', { method: 'post', data })
      setShow(true)
      setMailboxAddress('')
    } catch (error: any) {
      if (error.code === 'duplicate_parameter') {
        setTipsValue('Contact already exist')
      } else {
        setTipsValue('Please enter the correct email address!')
      }
      setOpen(true)
      setMailboxAddress('')

      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  return (
    <Wrap className="Partner" style={{ backgroundImage: `url(${di_blue})` }}>
      <DogImg className="GameStyle" src={di_gou} alt="" />
      <SheepImg className="GameStyle" src={di_yang} alt="" />

      {!show ? (
        <FlexBox className="GameStyle" center sx={{ position: 'relative', zIndex: 1, margin: '4.271vw auto 3.385vw' }}>
          <input type="text" value={mailboxAddress} onChange={(evt: any) => setMailboxAddress(evt.target.value || '')} placeholder="my@email.com" />
          <LoadingButton
            sx={{
              height: '3.542vw',
              width: '12.292vw',
              borderRadius: '1.771vw',
              fontSize: '1.719vw',
              fontFamily: 'Insaniburger with Cheese',
              background: '#000',
              boxShadow: 'none',
              color: '#FFFFFF',
              '&:hover': {
                background: '#383938',
              },
            }}
            onClick={useSubscribe}
          >
            SUBSCRIBE
          </LoadingButton>

          {open && (
            <TipsBox>
              <TipsImg src="tsdi@2x.png" />
              <span>{tipsValue}</span>
            </TipsBox>
          )}
        </FlexBox>
      ) : (
        <FlexBox
          center
          sx={{
            height: '3.542vw',
            fontSize: '1.719vw',
            margin: '4.271vw auto 3.385vw',
            position: 'relative',
            fontFamily: 'Insaniburger with Cheese',
            zIndex: 1,
          }}
        >
          Thank you! Your submission has been received!
        </FlexBox>
      )}

      <FlexBox className="GameStyle" between sx={{ width: '64.583vw', margin: '0 auto', position: 'relative' }}>
        <AImg href="https://discord.com/invite/UC9ud4QzP7" target="_blank" rel="noopener noreferrer">
          <img src={bt_Discord} alt="discord" />
        </AImg>
        <AImg href="https://twitter.com/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={bt_twitter} alt="twitter" />
        </AImg>
        <AImg href="https://medium.com/@SkylandNFTs" target="_blank" rel="noopener noreferrer">
          <img src={bt_medium} alt="medium" />
        </AImg>
        <AImg href="https://linktr.ee/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={bt_linktree} alt="linktree" />
        </AImg>
      </FlexBox>
      <NFTBottom className="GameStyle" href="https://cmp.coinswap.com/#/nft/collection/0x818941409301de5ff759bd9026c5f761422f5fb1?lang=en" target="_blank">
        <ContactBtn>Go to Marketplace</ContactBtn>
      </NFTBottom>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 32.969vw;
  background-size: 100% 100%;
  position: relative;
  padding-top: 0.052vw;
  box-sizing: border-box;
  a {
    /* border-bottom: none; */
    text-decoration: none;
  }

  input {
    width: 33.438vw;
    height: 3.542vw;
    background: #e7e7e7;
    border-radius: 0.729vw;
    text-align: center;
    font-size: 1.719vw;
    font-family: Milky Nice;
    color: #3d3d3d;
    line-height: 3.542vw;
    border: 0;
    outline: none;
    margin-right: 0.938vw;
    position: relative;
    z-index: 2;
  }

  input::-webkit-input-placeholder {
    font-size: 1.719vw;
    color: rgba(154, 166, 194, 1);
  }
`
const AImg = styled.a`
  width: 13.958vw;
  height: 8.854vw;
  img {
    width: 13.958vw;
    height: 8.854vw;
  }
`
const TipsBox = styled.div`
  width: 28.49vw;
  height: 3.281vw;
  margin: 0 auto;
  position: absolute;
  top: 3.542vw;
  left: 30%;
  span {
    width: 28.49vw;
    height: 3.281vw;
    font-size: 1.458vw;
    font-family: Albert Sans-Regular, Albert Sans;
    color: #ffffff;
    line-height: 3.81vw;
    display: block;
    position: absolute;
    text-align: center;
  }
`
const TipsImg = styled.img`
  width: 28.49vw;
  height: 3.281vw;
  position: absolute;
`

const DogImg = styled.img`
  width: 18.802vw;
  height: 19.896vw;
  position: absolute;
  top: 4.896vw;
  left: 2.135vw;
`
const SheepImg = styled.img`
  width: 15.208vw;
  height: 19.583vw;
  position: absolute;
  top: -4.01vw;
  right: 2.5vw;
`
const ContactBtn = styled.div`
  /* width: 27.083vw;
  height: 4.271vw;
  background: rgba(0, 255, 120, 0);
  border: 0.156vw solid #ffffff;
  border-radius: 2.135vw;
  font-size: 1.51vw;
  font-family: Milky Nice;
  color: #ffffff;
  line-height: 4.271vw;
  text-align: center; */
`
const NFTBottom = styled.a`
  width: 27.083vw;
  height: 4.271vw;
  background: rgba(0, 255, 120, 0);
  border: 0.156vw solid #ffffff;
  border-radius: 2.135vw;
  font-size: 1.51vw;
  font-family: Milky Nice;
  color: #ffffff;
  line-height: 4.271vw;
  text-align: center;
  margin: 3.854vw auto 0;
  display: block;
  cursor: pointer;
`
export default ContactUS
