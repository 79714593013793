import React, { useCallback, useContext, useState } from "react"
import Modal from "components/Modal"
import styled from "@emotion/styled"
import { ModalContext } from "context/ModalProvider"
import { Button } from "@mui/material"
import Image from "components/Image"
import message from "utils/message"
import BigNumber from "bignumber.js"
import FlexBox from "components/FlexBox"
import { useTokenBalance } from "hooks/useTokenBalance"
import { getBalanceNumber, getFullDisplayBalance } from "utils/formatBalance"
import { useMarketPlaceAllowances } from "hooks/useAllowance"
import { useWeb3React } from "@web3-react/core"
import { useSousApprove } from "hooks/useApprove"
import { useERC20 } from "hooks/useContract"
import { transferFPU } from "utils/BuyNFT"

const TransferModelH5: React.FC<any> = ({ available_amount }) => {
  const [CaduceusNum, setCaduceusNum] = useState("")
  const [requestedApproval, setRequestedApproval] = useState(false)
  const [CryptoSkylandFPU, setCryptoSkylandFPU] = useState(available_amount)
  const { account } = useWeb3React()

  const FPU = "0xcFc394db49D0A1582b3BF458A1789f08A5A792fe"
  // 收款地址
  const CollectionAddress = "0xa54AFb1EAdCB20D8A73B0CDD872C2a4EB6fdc5fE"

  const stakingTokenContract = useERC20(FPU)
  const allowanceBUMB = useMarketPlaceAllowances(FPU, CollectionAddress)
  const needsBUMBApproval = getFullDisplayBalance(new BigNumber(allowanceBUMB), 18).toNumber()

  const { onApprove } = useSousApprove(stakingTokenContract, CollectionAddress)
  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      var txHash = await onApprove()
      if (!txHash) {
        setRequestedApproval(false)
      }
    } catch (e) {
      console.error(e)
    }
  }, [onApprove, setRequestedApproval])

  const transferAccounts = async () => {
    try {
      setRequestedApproval(true)
      await transferFPU(stakingTokenContract, account, CollectionAddress, CaduceusNum)
      setRequestedApproval(false)
      message.success("Succeed!")
      setTimeout(() => {
        onClose()
      }, 1000)
    } catch (e) {
      setRequestedApproval(false)
    }
  }

  const BigFPU = useTokenBalance(FPU)
  const FPUBalance = getBalanceNumber(BigFPU)
  const NormalFPUBalance = new BigNumber(BigFPU).dividedBy(new BigNumber(10).pow(18)).toFixed()

  const { onClose } = useContext(ModalContext)

  const title = "transfer"

  const content = (
    <Warp>
      <Title>Caduceus</Title>
      <CaduceusBox>
        <input
          type="text"
          value={CaduceusNum}
          onChange={(evt: any) => {
            setCaduceusNum(evt.target.value || "")
          }}
          placeholder="0.0"
          style={{
            background: CaduceusNum ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
          }}
        />
        <FlexBox between>
          <FlexBox>
            <TokenIcon folder="setting" src="FPU.png"></TokenIcon>
            <TokenName>FPU</TokenName>
          </FlexBox>
          <FlexBox>
            <Balance>Balance:{FPUBalance}</Balance>
            {Number(NormalFPUBalance) > 0 && <Max onClick={() => setCaduceusNum(NormalFPUBalance)}>Max</Max>}
          </FlexBox>
        </FlexBox>
      </CaduceusBox>

      <ArrowBox>
        <ArrowIcon folder="setting" src="arrow.png"></ArrowIcon>
      </ArrowBox>

      <Title>CryptoSkyland</Title>
      <CaduceusBox>
        <input
          type="text"
          value={CaduceusNum}
          onChange={(evt: any) => {
            setCaduceusNum(evt.target.value || "")
          }}
          placeholder="0.0"
          style={{
            background: CaduceusNum ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
          }}
        />
        <FlexBox between>
          <FlexBox>
            <TokenIcon folder="setting" src="FPU.png"></TokenIcon>
            <TokenName>FPU</TokenName>
          </FlexBox>
          <Balance>Balance:{new BigNumber(CryptoSkylandFPU).toFormat(2)}</Balance>
        </FlexBox>
      </CaduceusBox>

      {needsBUMBApproval >= Number(CaduceusNum) ? (
        <Signin
          disabled={CaduceusNum.length === 0 || Number(CaduceusNum) > Number(NormalFPUBalance) || requestedApproval}

          onClick={() => {
            transferAccounts()
          }}
        >
          Transfer
        </Signin>
      ) : (
        <Signin
          disabled={requestedApproval}
          onClick={() => {
            handleApprove()
          }}
        >
          Approve
        </Signin>
      )}
    </Warp>
  )
  return (
    <Modal
      title={title}
      content={content}
      sx={{
        width: "78.667vw",
        minHeight: "70vw",
      }}
    />
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4.267vw 5.467vw 4vw;
  position: relative;
  input {
    width: 67.733vw;
    height: 10vw;
    background: rgba(92, 127, 255, 0.5);
    border-radius: 3vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-size: 3.733vw;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    display: block;
    margin-bottom: 2vw;
    box-sizing: border-box;
    padding-left: 2.8vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 3.733vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  width: 67.6vw;
  height: 9vw;
  border-radius: 3.6vw;
  background: #5c7fff;
  font-size: 2.933vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 5vw auto 0;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-bottom: 1.042vw;
  font-size: 2.667vw;
`
const CaduceusBox = styled.div`
  width: 100%;
  min-height: 10vw;
`

const TokenIcon = styled(Image)`
  width: 5vw;
  height: 5vw;
  margin-right: 0.4vw;
`
const TokenName = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  font-size: 2.667vw;
  color: rgba(92, 127, 255, 0.7);
`
const Balance = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  font-size: 2.667vw;
  color: rgba(92, 127, 255, 0.7);
`
const ArrowBox = styled.div`
  width: 10vw;
  height: 10vw;
  background-color: rgba(92, 127, 255, 0.3);
  border-radius: 50%;
  margin: 1.5vw auto;
`
const ArrowIcon = styled(Image)`
  width: 7vw;
  height: 7vw;
  margin-left: 1.5vw;
  margin-top: 1.5vw;
`
const Max = styled(Button)`
  width: 13vw;
  height: 5vw;
  background: #5c7fff;
  font-size: 0.8vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin-left: 0.521vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
export default TransferModelH5
