import React, { useState } from 'react'
import styled from '@emotion/styled'
import Image from 'components/Image'
import FlexBox from 'components/FlexBox'
import { Link } from 'react-router-dom'

export default function Plate() {
  const [isfixed, setIsFixed] = useState<boolean>(false)

  window.onscroll = function () {
    var heightTop = document.documentElement.scrollTop || document.body.scrollTop
    const heightLength = ((document.body.scrollWidth + 17) / 1920) * 130
    if (heightTop >= heightLength) {
      setIsFixed(true)
    } else {
      setIsFixed(false)
    }
  }

  return (
    <Warp style={{ position: isfixed ? 'fixed' : 'relative', top: isfixed ? '3.594vw' : '', left: isfixed ? '67.135vw' : '0' }}>
      <Title>POPULAR HUBS</Title>
      <Line />
      <Content>
        <Link to={'metaworld'}>
          <FlexBox sx={{ height: '4.792vw', marginBottom: '1.51vw' }}>
            <UserImg folder="hunbs" src="game2" />
            <FlexBox startBetween column sx={{ height: '3.021vw' }}>
              <UserName>Skytopia</UserName>
              <IssuingTime>Last updated Aug 18 </IssuingTime>
            </FlexBox>
          </FlexBox>
        </Link>
        <Link to={'village'}>
          <FlexBox sx={{ height: '4.792vw', marginBottom: '1.51vw' }}>
            <UserImg folder="hunbs" src="game3" />
            <FlexBox startBetween column sx={{ height: '3.021vw' }}>
              <UserName>Mysterious Village</UserName>
              <IssuingTime>Last updated Aug 3 </IssuingTime>
            </FlexBox>
          </FlexBox>
        </Link>
        <Link to={'party'}>
          <FlexBox sx={{ height: '4.792vw', marginBottom: '1.51vw' }}>
            <UserImg folder="hunbs" src="game1" />
            <FlexBox startBetween column sx={{ height: '3.021vw' }}>
              <UserName>Party Planet</UserName>
              <IssuingTime>Last updated Aug 22 </IssuingTime>
            </FlexBox>
          </FlexBox>
        </Link>
      </Content>
      <Line />
    </Warp>
  )
}
const Warp = styled.div`
  width: 20.833vw;
  min-height: 17.552vw;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  padding-bottom: 2.344vw;
  box-sizing: border-box;
  position: relative;
  a {
    border-bottom: none;
    text-decoration: none;
    width: fit-content;
  }
  .plate-style-change {
    position: fixed;
    top: 3.594vw;
    left: 67.135vw;
  }
`
const Title = styled.div`
  width: 20.833vw;
  height: 3.698vw;
  font-size: 2.188vw;
  color: #7893be;
  line-height: 3.698vw;
  padding: 0 20px;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const Line = styled.div`
  width: 19.792vw;
  height: 0.26vw;
  transform: scale(1, 0.2);
  background: #7893be;
  margin: auto;
`
const Content = styled.div`
  width: 20.833vw;
  min-height: 17.552vw;
  padding: 1.042vw 1.042vw 0;
  box-sizing: border-box;
`

const UserImg = styled(Image)`
  width: 4.792vw;
  height: 4.792vw;
  margin-right: 0.625vw;
`
const UserName = styled.div`
  width: 13.333vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
`
const IssuingTime = styled.div`
  width: 13.333vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.938vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
`
