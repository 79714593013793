import React, { useEffect } from 'react'
import styled from '@emotion/styled'
import { gsap } from 'gsap'
import Image from 'components/Image'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useSize from 'hooks/useSize'
import Residents from './components/Residents'
import Buildings from './components/Buildings'
import Events from './components/Events'
import Daily from './components/Daily'
import LaputaHeader from './components/LaputaHeader'
import Mint from './components/Mint'
import Header from 'components/Header'
import MintH5 from './components/MintH5'
import Media from 'react-media'
import ResidentsH5 from './components/ResidentsH5'
const Skytopia: React.FC<any> = () => {
  const { pcSize } = useSize()
  gsap.registerPlugin(ScrollTrigger)
  useEffect(() => {
    gsap.to('.skytopia-bg-text', {
      y: 0.1 * -ScrollTrigger.maxScroll(window),
      ease: 'none',
      scrollTrigger: {
        // trigger: ".skytopiawarp",
        scrub: true,
      },
    })
    document.body.scrollTop = document.documentElement.scrollTop = 0
  }, [])

  return (
    <Warp className="skytopiawarp">
      <Header />
      <Contentbg>
        {pcSize ? (
          <Image folder="index" src="indexBg" className="skytopia-bg-text" />
        ) : (
          <></>
          // <Image folder="index" src="H5Bg@2x"  className="skytopia-bg-text" />
        )}
        {/* <LaputaHeader /> */}
        {/* <Buildings /> */}
        <Media query={{ maxWidth: 1000 }}>{(matches) => (!matches ? <Mint /> : <MintH5 />)}</Media>
        <Media query={{ maxWidth: 1000 }}>{(matches) => (!matches ? <Residents /> : <ResidentsH5 />)}</Media>
        {/* <Events /> */}
        {/* <Daily /> */}
      </Contentbg>
    </Warp>
  )
}

const Contentbg = styled.div`
  /* background: #e4e1f6; */
  width: 100%;
`
const Warp = styled.div`
  .skytopia-bg-text {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    display: block !important;
  }
`

export default Skytopia
