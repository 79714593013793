import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect, useState } from "react"
import useSize from "hooks/useSize"
import FlexBox from "components/FlexBox"
import { FAQInfo } from "../config/FAQ"

const FAQH5 = () => {
  const { pcSize } = useSize()
  const [open, setOpen] = useState<any>(-999)
  useEffect(() => {
    if (pcSize) {
      gsap.fromTo(
        ".FAQ-title-style",
        { opacity: 0, x: -30 },
        { scrollTrigger: { trigger: ".FAQ-wrap", start: "top-=800" }, x: 0, opacity: 1, duration: 0.5 }
      )
    }
  }, [pcSize])

  return (
    <Wrap className="FAQ-wrap">
      <Title className="FAQ-title-style">FAQ</Title>
      <FlexBox start column sx={{ margin: "0 auto", width: "69.733vw", minHeight: "62.4vw", overflowY: "auto", overflowX: "hidden" }}>
        {FAQInfo.map((item, keys) => (
          <FlexBox key={keys} start column>
            <FlexBox between sx={{ width: "69.733vw" }}>
              <Tips>{item.title}</Tips>
              {open === keys ? (
                <Balloon
                  folder="nft_h5"
                  src="ButtonReduce@2x"
                  onClick={() => {
                    setOpen(-999)
                  }}
                />
              ) : (
                <Balloon
                  folder="nft_h5"
                  src="ButtonAdd@2x"
                  onClick={() => {
                    setOpen(keys)
                  }}
                />
              )}
            </FlexBox>
            {open === keys && <Content dangerouslySetInnerHTML={{ __html: item.content }}></Content>}
            <Line />
          </FlexBox>
        ))}
      </FlexBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: 78.2vw;
  width: 100%;
  padding-bottom: 17vw;
  position: relative;
`
const Title = styled.div`
  text-align: center;
  color: #7893be;
  line-height: 6vw;
  font-size: 7.733vw;
  margin: 0 auto 8.267vw;
`
const Tips = styled.div`
  width: 70vw;
  min-height: 6.133vw;
  font-size: 3.6vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  display: flex;
  align-items: center;
  padding: 1.333vw 0;
`

const Balloon = styled(Image)`
  height: 4.8vw;
  width: 4.8vw;
  cursor: pointer;
`

const Line = styled.div`
  width: 69.6vw;
  height: 0.533vw;
  background-color: #7893be;
`
const Content = styled.div`
  width: 60.267vw;
  min-height: 8.933vw;
  font-size: 2.933vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 3.6vw;
  a {
    color: #95add4;
  }
`

export default FAQH5
