import React from "react"
import Box, { BoxProps } from "@mui/material/Box"
import { SxProps } from "@mui/system"

export interface FlexBoxProps extends BoxProps {
  column?: boolean
  center?: boolean
  reverse?: boolean
  start?: boolean
  startCenter?: boolean
  end?: boolean
  between?: boolean
  around?: boolean
  wrap?: boolean
  endCenter?: boolean
  endAlignCenter?: boolean
  startBetween?: boolean
  Startend?: boolean
}

const FlexBox: React.FC<FlexBoxProps> = ({
  startCenter,
  column,
  endCenter,
  center,
  reverse,
  children,
  sx,
  start,
  end,
  endAlignCenter,
  between,
  around,
  wrap,
  startBetween,
  Startend,
  ...props
}) => {
  const flexStyle: SxProps = { display: "flex", flexDirection: "row", alignItems: "center" }
  if (column) {
    flexStyle.flexDirection = reverse ? "column-reverse" : "column"
  }
  if (wrap) {
    flexStyle.flexWrap = "wrap"
  }
  if (center) {
    flexStyle.justifyContent = "center"
    flexStyle.alignItems = "center"
  } else if (start) {
    flexStyle.justifyContent = "flex-start"
    flexStyle.alignItems = "flex-start"
  } else if (startCenter) {
    flexStyle.justifyContent = "flex-start"
    flexStyle.alignItems = "center"
  } else if (Startend) {
    flexStyle.justifyContent = "flex-start"
    flexStyle.alignItems = "flex-end"
  } else if (end) {
    flexStyle.justifyContent = "flex-end"
    flexStyle.alignItems = "flex-end"
  } else if (endAlignCenter) {
    flexStyle.justifyContent = "flex-end"
    flexStyle.alignItems = "center"
  } else if (startBetween) {
    flexStyle.justifyContent = "space-between"
    flexStyle.alignItems = "flex-start"
  } else if (endCenter) {
    flexStyle.justifyContent = "space-between"
    flexStyle.alignItems = "flex-end"
  } else if (between) {
    flexStyle.justifyContent = "space-between"
    flexStyle.alignItems = "center"
  } else if (around) {
    flexStyle.justifyContent = "space-around"
    flexStyle.alignItems = "center"
  }
  const flexSX = { ...flexStyle, ...sx }
  return (
    <Box {...props} sx={flexSX}>
      {children}
    </Box>
  )
}

export default FlexBox
