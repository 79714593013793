import styled from '@emotion/styled'
import { useState } from 'react'
import Image from 'components/Image'
import FlexBox from 'components/FlexBox'
// import LoadingButton from '@mui/lab/LoadingButton'
import { http } from 'utils/http'
import { S3Url } from "api/apiList";


const discord = `${S3Url}/images/index2/partnersH5/discord.png`
const twitter = `${S3Url}/images/index2/partnersH5/twitter.png`
const medium = `${S3Url}/images/index2/partnersH5/medium.png`
const link = `${S3Url}/images/index2/partnersH5/link.png`
const Bg = `${S3Url}/images/index2/partnersH5/Bg.png`

const FooterNotificationH5 = () => {
  const [mailboxAddress, setMailboxAddress] = useState('')
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [tipsValue, setTipsValue] = useState('')

  const useSubscribe = async () => {
    const data = { email: mailboxAddress }
    try {
      await http('contacts', { method: 'post', data })
      setShow(true)
      setMailboxAddress('')
    } catch (error: any) {
      if (error.code === 'duplicate_parameter') {
        setTipsValue('Contact already exist')
      } else {
        setTipsValue('Please enter the correct email address!')
      }
      setOpen(true)
      setMailboxAddress('')

      setTimeout(() => {
        setOpen(false)
      }, 3000)
    }
  }

  return (
    <Wrap className="exit-animation">
      <Title>Sign up for notification</Title>
      {!show ? (
        <FlexBox center sx={{ position: 'relative', zIndex: 1, marginBottom: '7.333vw' }}>
          <input type="text" value={mailboxAddress} onChange={(evt: any) => setMailboxAddress(evt.target.value || '')} placeholder="my@email.com" />
          <LoadingButton onClick={useSubscribe}>Subscribe</LoadingButton>

          {open && (
            <TipsBox>
              <TipsImg folder="index2/Partner" src="tsdi@2x.png" />
              <span>{tipsValue}</span>
            </TipsBox>
          )}
        </FlexBox>
      ) : (
        <FlexBox
          center
          sx={{
            height: '4.792vw',
            fontSize: '1.25vw',
            margin: '0 auto 4.375vw',
            position: 'relative',
            fontFamily: 'Albert Sans-Black, Albert Sans',
            zIndex: 1,
          }}
        >
          Thank you! Your submission has been received!
        </FlexBox>
      )}

      <FlexBox between sx={{ width: '74.8vw', margin: '0 auto' }}>
        {/* <AImg href="https://discord.com/invite/UC9ud4QzP7" target="_blank" rel="noopener noreferrer">
          <img src={discord} alt="discord" />
        </AImg>
        <AImg href="https://twitter.com/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="twitter" />
        </AImg>
        <AImg href="https://medium.com/@SkylandNFTs" target="_blank" rel="noopener noreferrer">
          <img src={medium} alt="medium" />
        </AImg>
        <AImg href="https://linktr.ee/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
          <img src={link} alt="linktree" />
        </AImg> */}
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={discord} alt="discord" />
        </AImg>
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={twitter} alt="twitter" />
        </AImg>
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={medium} alt="medium" />
        </AImg>
        <AImg href="" target="_blank" rel="noopener noreferrer">
          <img src={link} alt="linktree" />
        </AImg>
      </FlexBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 95.2vw;
  height: 45.867vw;
  border-radius: 9.067vw;
  background-color: RGBA(27, 76, 110, 1);
  overflow: hidden;
  position: relative;
  bottom: -10vw;
  left: 50%;
  transform: translateX(-50%);
  z-index: 30;
  background-image: url(${Bg});
  background-size: 100% 100%;

  input {
    width: 56.267vw;
    height: 6.933vw;
    background: #ffffff;
    border-radius: 1.333vw;
    text-align: center;
    font-size: 2.4vw;
    font-family: Albert Sans-Medium, Albert Sans;
    color: #3d3d3d;
    line-height: 6.933vw;
    border: 0;
    outline: none;
    margin-right: 2.4vw;
    position: relative;
    z-index: 2;
  }

  input::-webkit-input-placeholder {
    font-size: 2.4vw;
    color: #3d3d3d;
  }
`
const AImg = styled.a`
  width: 16vw;
  height: 12.933vw;
  img {
    width: 16vw;
    height: 12.933vw;
  }
`

const Title = styled.div`
  width: 100%;
  height: 4vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #ffffff;
  line-height: 4vw;
  text-align: center;
  margin: 6vw auto 2.533vw;
`
const TipsBox = styled.div`
  width: 60vw;
  height: 6vw;
  margin: 0 auto;
  position: absolute;
  top: 7vw;
  left: 10%;
  span {
    width: 60vw;
    height: 6vw;
    font-size: 1.6vw;
    font-family: Albert Sans-Regular, Albert Sans;
    color: #ffffff;
    line-height: 7vw;
    display: block;
    position: absolute;
    text-align: center;
  }
`
const TipsImg = styled(Image)`
  width: 60vw;
  height: 6vw;
  position: absolute;
`
const LoadingButton = styled.div`
  width: 18.4vw;
  height: 4.933vw;
  background: #383938;
  border-radius: 4.933vw;
  font-size: 2.667vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #ffffff;
  line-height: 4.933vw;
  text-align: center;
  cursor: pointer;
`
export default FooterNotificationH5
