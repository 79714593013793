import React, { Fragment, useEffect, useState } from 'react'
import FlexBox from 'components/FlexBox'
import Image from 'components/Image'
import styled from '@emotion/styled'
import { Link, useNavigate } from 'react-router-dom'
import { Box, Button, MenuItem, MenuList } from '@mui/material'
// import { gsap } from 'gsap'
import useModal from 'hooks/useModal'
import LoginModal from './LoginModal'
import { getAuthCookie } from 'utils/user'
import { clearAuthCookie } from 'utils/user'
import { getPersonalFun } from 'api/request'
import useWalletAuth from 'hooks/useWalletAuth'
import { baseURL, JsonUrl } from 'api/apiList'
import Setmedal from './Nav/Setmedal'
import { SwitchChainRequest, WalletCache } from 'utils/walletUtils'
import { UserList } from 'config/Nav'
import { resetRoute } from 'utils'
import UserModal from './LoginModal/UserModal'

const HeaderH5: React.FC<any> = () => {
  // 鼠标划到切链改变颜色
  const [MouseStatus, setMouseStatus] = useState<boolean>(false)
  // 展示登陆弹窗
  const [MouseLogin, setMouseLogin] = useState<boolean>(false)

  const { disconnect } = useWalletAuth()

  const [loginModalHandler] = useModal(<LoginModal />)

  const [UserModalHandler] = useModal(<UserModal />)

  const token = getAuthCookie()

  const [userInfo, setUserInfo] = useState<any>()

  const update = localStorage.getItem('update')

  const chainId = WalletCache.getChain()

  const [isShow, setIsShow] = useState<any>(false)
  const [Select, setSelect] = useState<any>('CMP')
  const [SelectInfo, setSelectInfo] = useState<any>([])

  useEffect(() => {
    if (token) {
      getPersonalFun()
        .then((res: any) => {
          localStorage.setItem('account', res.data.wallet_address)
          setUserInfo(res.data)
        })
        .catch((err: any) => {})
    }
  }, [token, update])

  const navigate = useNavigate()
  const handleSetting = (e: any) => {
    e.stopPropagation()
    navigate('/setting')
  }

  const handleDownload = () => {
    navigate('/download')
  }

  return (
    <Wrap className="header-animation">
      <div></div>

      {
        <DownloadBtn onClick={() => {
          handleDownload()
        }}>
          
          {/* <CMPLogo folder="index2/GameDownload" src="Connectwallet.png" /> */}
          <DownloadTips>Download</DownloadTips>
        </DownloadBtn> 
      }

      {token !== '' ? (
        <UserBox
          onClick={() => {
            UserModalHandler()
          }}
        >
          <UserImg folder="components" src="user.png" />
        </UserBox>
      ) : (
        <LoginBtn
          onClick={() => {
            loginModalHandler()
          }}
        >
          <SignUpImg folder="components" src="wallet.png" />
          <LoginTips>Connect Wallet</LoginTips>
        </LoginBtn>
      )}
    </Wrap>
  )
}

const Wrap = styled.div`
  width: 100vw;
  height: 14.933vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2.4vw;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  a {
    border-bottom: none;
    text-decoration: none;
    /* width: fit-content; */
    color: #fff;
    &:hover {
      color: #eff11f;
    }
  }
`
const UserBox = styled.div`
  width: 9.067vw;
  height: 9.067vw;
  display: block;
  border-radius: 50%;
  background-color: #fff;
  overflow: hidden;
`
const UserImg = styled(Image)`
  width: 9.067vw;
  height: 9.067vw;
  display: block;
`

const LoginBtn = styled.div`
  width: 34.533vw;
  height: 7.2vw;
  background: #eff11f;
  border-radius: 1.067vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.4vw;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  font-size: 2.933vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  line-height: 7.2vw;

  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #eff11f;
  }
`

const DownloadBtn = styled.div`
  width: 34.533vw;
  height: 7.2vw;
  background: #313134;
  border-radius: 1.067vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 7.4vw;
  box-sizing: border-box;
  font-size: 2.933vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: white;
  position: relative;
  cursor: pointer;
  // margin-right: 0.25vw;
  line-height: 7.2vw;
  font-weight: 700;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    /* background: #313134;
    color: #eff11f; */
  }
`

const LoginTips = styled.div`
  font-size: 2.933vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #3d3d3d;
  white-space: nowrap;
`

const DownloadTips = styled.div`
  font-size: 2.933vw;
  font-family: Albert Sans-Bold, Albert Sans;
  // font-weight: 700;
  color: white;
  white-space: nowrap;
`

const SignUpImg = styled(Image)`
  width: 5.067vw;
  height: 5.067vw;
  display: block;
`
export default HeaderH5
