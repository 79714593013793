import styled from "@emotion/styled"
import Backdrop from "@mui/material/Backdrop"
import HunbsHeader from "components/HunbsHeader"
import Title from "components/HunbsHeader/Title"
import Image from "components/Image"
import { useState } from "react"

const HunbsImg: React.FC<any> = ({ ImgInfo }) => {
  const [open, setOpen] = useState(false) 
  const [show, setShow] = useState(1) 
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Warp>
      <HunbsHeader Info={ImgInfo} />
      {ImgInfo?.title !== "" && <Title>{ImgInfo?.title}</Title>}
      {/* <Title>{ImgInfo.title}</Title> */}
      {ImgInfo?.url?.length > 1 ? (
        <FlexBoxs>
          {ImgInfo.url.map((item: any, key: any) => (
            <ImgCenter key={key}>
              <ImgBoxs>
                <Imgs
                  folder="hunbs"
                  src={item}
                  onClick={() => {
                    setOpen(true)
                    setShow(key + 1)
                  }}
                />
              </ImgBoxs>
            </ImgCenter>
          ))}
        </FlexBoxs>
      ) : (
        <ImgBox>
          <Img
            folder="hunbs"
            src={ImgInfo.url[0]}
            onClick={() => {
              setOpen(true)
            }}
          />
        </ImgBox>
      )}

      <Line></Line>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={handleClose}>
        {ImgInfo.url.map((item: any, key: any) => show === key + 1 && <Image folder="hunbs" src={item} key={key + item} className="img-style-big" />)}
      </Backdrop>
    </Warp>
  )
}
const Warp = styled.div`
  width: 52.604vw;
  min-height: 20.229vw;
  padding: 0 1.563vw 2.083vw;
  box-sizing: border-box;
  position: relative;

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .img-style-big {
    max-width: 80vw;
    max-height: 80vh;
    height: auto;
    width: auto;
    border-radius: 1.563vw;
  }
  @media (max-width: 1000px) {
    width: 96.96vw;
    min-height: 45vw;
    padding: 0 2.8vw 4vw;
    box-sizing: border-box;
    position: relative;
    border-radius: 4vw;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0 auto 2.667vw;
    .img-style-big {
      border-radius: 4vw;
    }
  }
`
const ImgBox = styled.div`
  width: 23.958vw;
  height: 13.594vw;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.625vw;
  overflow: hidden;
  @media (max-width: 1000px) {
    width: 44.133vw;
    height: 24.933vw;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 2.667vw;
    overflow: hidden;
  }
`

const Img = styled(Image)`
  height: 13.594vw;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0.625vw;
  cursor: pointer;
  @media (max-width: 1000px) {
    height: 24.933vw;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    border-radius: 2.667vw;
  }
`
const ImgBoxs = styled.div`
  width: 10.417vw;
  height: 10.417vw;
  border-radius: 0.625vw;
  background-color: rgba(0, 0, 0, 0.2);
  @media (max-width: 1000px) {
    height: 19.2vw;
    width: 19.02vw;
    border-radius: 0.625vw;
    background-color: rgba(0, 0, 0, 0.2);
  }
`

const Imgs = styled(Image)`
  position: relative;
  left: 50%;
  transform: translate(-50%);
  height: 10.417vw;
  cursor: pointer;
  @media (max-width: 1000px) {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    height: 19.2vw;
    cursor: pointer;
  }
`
const FlexBoxs = styled.div`
  display: flex;
  align-content: flex-start;
  justify-content: center;
  width: 32.709vw;
  min-height: 19.2vw;
  flex-wrap: wrap;
  @media (max-width: 1000px) {
    width: 60vw;
    min-height: 19.2vw;
    flex-wrap: wrap;
  }
`
const Line = styled.div`
  width: 50.521vw;
  height: 1px;
  background: #7893be;
  position: absolute;
  bottom: 0;
  left: 1.042vw;
  @media (max-width: 1000px) {
    display: none;
  }
`

const ImgCenter = styled.div`
  width: 10.417vw;
  height: 10.417vw;
  margin-right: 0.469vw;
  margin-bottom: 0.469vw;
  border-radius: 0.625vw;
  overflow: hidden;
  @media (max-width: 1000px) {
    width: 19.2vw;
    height: 19.2vw;
    margin-right: 0.933vw;
    margin-bottom: 0.933vw;
    overflow: hidden;
    border-radius: 2.667vw;
  }
  :nth-of-type(3n + 0) {
    margin-right: 0;
  }
`
export default HunbsImg
