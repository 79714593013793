import Image from 'components/Image'
import styled from '@emotion/styled'
import { ChangeEvent, useEffect, useState } from 'react'
import FlexBox from 'components/FlexBox'
import { Box, Button } from '@mui/material'
import { getPersonalFun, personalFun } from 'api/request'
import axios from 'axios'
import { getAuthCookie } from 'utils/user'
import { WalletCache } from 'utils/walletUtils'
import { baseURL } from 'api/apiList'
import message from 'utils/message'
import Media from 'react-media'
import ProfileH5 from './ProfileH5'

const Profile = () => {
  const [username, setUsername] = useState('')
  const [Name, setName] = useState('')
  const [Personal, setPersonal] = useState('')
  const [Description, setDescription] = useState('')
  const [Telegram, setTelegram] = useState('')
  const [Instagram, setInstagram] = useState('')
  const [discord, setDiscord] = useState('')
  const [Twitter, setTwitter] = useState('')
  const [ImgUrl, setImgUrl] = useState('')
  var reg = /^[a-z0-9A-Z]+$/
  const [isMouse, setIsMouse] = useState(false)
  const [hasBorder, setHasBorder] = useState('')

  const token = getAuthCookie()
  const chainId = WalletCache.getChain()

  useEffect(() => {
    let isMounted = true
    if (!isMounted) return // 在设置状态之前检查组件是否还在挂载状态
    getPersonalFun()
      .then((data: any) => {
        setName(data.data.name)
        setUsername(data.data.username)
        setPersonal(data.data.personal_web)
        setDescription(data.data.description)
        setTelegram(data.data.telegram)
        setInstagram(data.data.instagram)
        setDiscord(data.data.discord)
        setTwitter(data.data.twitter)
        setImgUrl(data.data.image)
        localStorage.setItem('account', data.data.wallet_address)
      })
      .catch((err: any) => {})
    return () => {
      isMounted = false // 在组件卸载时，设置isMounted为false
    }
  }, [token])

  const handleUpdate = (event: any) => {
    let fileImg = event.target.files[0]
    Accepts(fileImg)
  }
  const Accepts = async (files: any) => {
    if (!files) {
      return false
    }
    if (files.size > 2097152) {
      message.error('The picture size is larger than 2MB.')
      return
    } else {
      let formdata = new FormData()
      formdata.append('file', files)
      await axios
        .post(`${baseURL}/v1/web/user/image/upload`, formdata, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'x-app-id': '7a8hxez99pgvdIroTaDZh9mfON97LO1wldDGVMwEhWsHo',
            'x-app-key': '3XEhbwprbgf3akD8JplkTxYgx8JUIQT75hOIc9ldd8Wv6',
            'chain-id': chainId,
            app: 'web',
            authentication: token,
          },
        })
        .then((res) => {
          setImgUrl(res.data.data.link)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap>
            <Title>Profile settings</Title>
            <ProfileBox>
              <SmallTitle>Basic information</SmallTitle>
              <BasicBox>
                <Box>
                  <FlexBox start column sx={{ width: '21.042vw', marginBottom: '2.5vw' }}>
                    <InputName>Username</InputName>
                    <input
                      type="text"
                      value={username}
                      onChange={(evt: any) => {
                        setUsername(evt.target.value || '')
                      }}
                      onFocus={() => {
                        setHasBorder('username')
                      }}
                      onBlur={() => {
                        setHasBorder('')
                      }}
                      className={hasBorder === 'username' ? 'inputError' : ''}
                    />
                  </FlexBox>

                  <FlexBox start column sx={{ width: '21.042vw', marginBottom: '1.042vw' }}>
                    <InputName>Name</InputName>
                    <input
                      type="text"
                      value={Name}
                      onChange={(evt: any) => {
                        setName(evt.target.value || '')
                      }}
                      onFocus={() => {
                        setHasBorder('Name')
                      }}
                      onBlur={() => {
                        setHasBorder('')
                      }}
                      className={hasBorder === 'Name' ? 'inputError' : ''}
                    />
                  </FlexBox>

                  <FlexBox start column sx={{ width: '21.042vw' }}>
                    <InputName>Personal web</InputName>
                    <input
                      type="text"
                      value={Personal}
                      onChange={(evt: any) => {
                        setPersonal(evt.target.value || '')
                      }}
                      onFocus={() => {
                        setHasBorder('Personal')
                      }}
                      onBlur={() => {
                        setHasBorder('')
                      }}
                      className={hasBorder === 'Personal' ? 'inputError' : ''}
                    />
                  </FlexBox>
                </Box>

                <FlexBox start column sx={{ marginLeft: '4.219vw', position: 'relative' }}>
                  <InputName>Description</InputName>
                  <textarea
                    value={Description}
                    maxLength={500}
                    onChange={(evt: any) => {
                      setDescription(evt.target.value || '')
                    }}
                    onFocus={() => {
                      setHasBorder('Description')
                    }}
                    onBlur={() => {
                      setHasBorder('')
                    }}
                    className={hasBorder === 'Description' ? 'inputError' : ''}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      right: '0.1vw',
                      bottom: '-1.3vw',
                      fontSize: '0.938vw',
                      fontFamily: 'Microsoft Tai Le',
                      color: 'rgba(120, 147, 190, 0.5)',
                    }}
                  >
                    {Description.length} / 500
                  </Box>
                </FlexBox>

                <UserBox
                  onMouseEnter={() => setIsMouse(true)}
                  onMouseLeave={() => {
                    setIsMouse(false)
                  }}
                >
                  <input type="file" className="UpdateBtn" accept="image/*" onChange={(event) => handleUpdate(event)} />
                  {ImgUrl?.length > 0 ? (
                    <ImgBox>
                      <img className="headImg" src={ImgUrl} alt="img" />{' '}
                    </ImgBox>
                  ) : (
                    <NouserImage folder="setting" src="Nouser.png"></NouserImage>
                  )}

                  {isMouse && (
                    <CameraBox>
                      <CameraImage folder="setting" src="camera.png"></CameraImage>
                    </CameraBox>
                  )}
                </UserBox>
              </BasicBox>
              <SmallTitle>Social information</SmallTitle>
              <SocialBox>
                <FlexBox start sx={{ width: '23.594vw' }}>
                  <IconImg folder="setting" src="icon1.png"></IconImg>
                  <Box>
                    <input
                      type="text"
                      value={Twitter}
                      placeholder="@SkylandNFTs"
                      onChange={(evt: any) => {
                        setTwitter(evt.target.value || '')
                      }}
                      onFocus={() => {
                        setHasBorder('Twitter')
                      }}
                      onBlur={() => {
                        setHasBorder('')
                      }}
                      className={hasBorder === 'Twitter' ? 'inputError' : ''}
                    />
                    <IconTips>Enter your Twitter Handle</IconTips>
                  </Box>
                </FlexBox>

                <FlexBox start sx={{ width: '23.594vw', marginLeft: '2.292vw' }}>
                  <IconImg folder="setting" src="icon2.png"></IconImg>
                  <Box>
                    <input
                      type="text"
                      value={discord}
                      placeholder="Skytopia#1234"
                      onChange={(evt: any) => {
                        setDiscord(evt.target.value || '')
                      }}
                      onFocus={() => {
                        setHasBorder('discord')
                      }}
                      onBlur={() => {
                        setHasBorder('')
                      }}
                      className={hasBorder === 'discord' ? 'inputError' : ''}
                    />
                    <IconTips>Enter your user#id</IconTips>
                  </Box>
                </FlexBox>

                <FlexBox start sx={{ width: '23.594vw', marginTop: '1.823vw' }}>
                  <IconImg folder="setting" src="icon3.png"></IconImg>
                  <Box>
                    <input
                      type="text"
                      placeholder="Skytopia"
                      value={Instagram}
                      onChange={(evt: any) => {
                        setInstagram(evt.target.value || '')
                      }}
                      onFocus={() => {
                        setHasBorder('Instagram')
                      }}
                      onBlur={() => {
                        setHasBorder('')
                      }}
                      className={hasBorder === 'Instagram' ? 'inputError' : ''}
                    />
                    <IconTips>Enter your Instagram username</IconTips>
                  </Box>
                </FlexBox>

                <FlexBox start sx={{ width: '23.594vw', marginTop: '1.823vw', marginLeft: '2.292vw' }}>
                  <IconImg folder="setting" src="icon4.png"></IconImg>
                  <Box>
                    <input
                      type="Telegram"
                      value={Telegram}
                      placeholder="Skytopia"
                      onChange={(evt: any) => {
                        setTelegram(evt.target.value || '')
                      }}
                      onFocus={() => {
                        setHasBorder('Telegram')
                      }}
                      onBlur={() => {
                        setHasBorder('')
                      }}
                      className={hasBorder === 'Telegram' ? 'inputError' : ''}
                    />
                    <IconTips>Enter your Telegram username</IconTips>
                  </Box>
                </FlexBox>

                <SaveButton
                  onClick={() => {
                    if (username.length === 0) {
                      message.error('Username can not be empty')
                      return false
                    }

                    if (!reg.test(username)) {
                      message.error('Invalid username.')
                      return false
                    }

                    if ((Personal && Personal.indexOf('http') !== 0) || (Personal && Personal.indexOf('.') < 4)) {
                      message.error('Invalid personal website')
                      return false
                    }

                    localStorage.setItem('update', Math.round(new Date().getTime() / 1000).toString())
                    personalFun({
                      description: Description,
                      name: Name,
                      username: username,
                      personal_web: Personal,
                      twitter: Twitter,
                      discord: discord,
                      instagram: Instagram,
                      telegram: Telegram,
                      image: ImgUrl,
                      area: 'ok',
                    }).then(() => {
                      message.success('success')
                    })
                  }}
                >
                  SAVE
                </SaveButton>
              </SocialBox>
            </ProfileBox>
          </Wrap>
        ) : (
          <ProfileH5></ProfileH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: 94vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  input {
    width: 20.833vw;
    height: 2.604vw;
    background: rgba(120, 147, 190, 0.5);
    border-radius: 0.781vw;
    border: 0;
    outline: none;
    padding-left: 1.146vw;
    font-size: 1.146vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
    line-height: 2.604vw;
    border: 2px solid rgba(133, 160, 254, 0);
    box-sizing: border-box;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  .inputError {
    border: 2px solid rgba(133, 160, 254, 1) !important;
  }
  textarea {
    width: 31.25vw;
    height: 15.365vw;
    background: rgba(120, 147, 190, 0.5);
    border-radius: 0.781vw;
    outline: none;
    padding: 1.146vw;
    font-size: 1.146vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #ffffff;
    line-height: 1.146vw;
    box-sizing: border-box;
    border: 2px solid rgba(133, 160, 254, 0);
    resize: none;
  }
`
const Title = styled.div`
  width: 21vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 2.604vw;
  text-align: center;
  margin: 6.927vw auto 2.083vw;
`

const ProfileBox = styled.div`
  width: 77.708vw;
  min-height: 60vw;
  margin-left: 16.563vw;
  box-sizing: border-box;
`
const SmallTitle = styled.div`
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 2.604vw;
  margin-bottom: 1.51vw;
  padding-left: 0.208vw;
`
const BasicBox = styled.div`
  width: 77.708vw;
  height: 20.938vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  margin-bottom: 3.646vw;
  padding: 1.563vw 4.167vw 2.083vw;
  box-sizing: border-box;
  display: flex;
`
const SocialBox = styled.div`
  background: rgba(255, 255, 255, 0.4);
  width: 52.552vw;
  height: 13.333vw;
  border-radius: 1.042vw;
  position: relative;
  padding: 2.083vw 1.563vw 1.25vw 1.198vw;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`
const InputName = styled.div`
  height: 0.938vw;
  line-height: 0.938vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  margin-bottom: 0.885vw;
`
const IconImg = styled(Image)`
  width: 1.875vw;
  height: 1.875vw;
  margin-right: 0.885vw;
  margin-top: 0.365vw;
`
const IconTips = styled.div`
  width: 20.833vw;
  font-size: 1.042vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(120, 147, 190, 0.5);
  margin-top: 0.417vw;
  margin-left: 0.469vw;
`
const SaveButton = styled(Button)`
  width: 11.458vw;
  height: 3.438vw;
  border-radius: 1.042vw;
  font-size: 1.667vw;
  background-color: rgba(92, 127, 255, 0.7);
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  position: absolute;
  right: -15.938vw;
  bottom: 0;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`

const ImgBox = styled.div`
  width: 8.802vw;
  height: 8.802vw;
  overflow: hidden;
  border-radius: 50%;
  position: absolute;
  left: -0.313vw;
  top: -0.313vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .headImg {
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
`

const UserBox = styled.div`
  width: 8.802vw;
  height: 8.802vw;
  background-color: rgba(120, 147, 190, 0.5);
  border-radius: 50%;
  margin-left: 4.167vw;
  border: 0.313vw solid transparent;
  margin-top: 5.208vw;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;

  .UpdateBtn {
    width: 8.802vw;
    height: 8.802vw;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    outline: none;
    box-sizing: border-box;
    background: none;
    opacity: 0;
    z-index: 10;
  }
  &:hover {
    border: 0.313vw solid #859ffe;
  }
`
const NouserImage = styled(Image)`
  width: 8.229vw;
  height: 8.229vw;
`
const CameraBox = styled.div`
  width: 2.344vw;
  height: 2.344vw;
  background: #859ffe;
  border-radius: 0.781vw;
  position: absolute;
  bottom: 0.677vw;
  right: 0.052vw;
  z-index: 1;
`

const CameraImage = styled(Image)`
  width: 1.771vw;
  height: 1.302vw;
  margin: 0.521vw auto;
`

export default Profile
