import styled from '@emotion/styled'
import Button from '@mui/material/Button'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useState, useEffect, useRef } from 'react'
import Media from 'react-media'
import GameListH5 from './GameListH5'
import { useNavigate } from 'react-router'
import { S3Url } from "api/apiList";
import { partyplanetFun } from 'api/request'


const GameList = () => {
  gsap.registerPlugin(ScrollTrigger)

  const game1 = `${S3Url}/images/index2/GameList/game1.png`
  const game2 = `${S3Url}/images/index2/GameList/game2.png`
  const game3 = `${S3Url}/images/index2/GameList/game3.png`
  const game4 = `${S3Url}/images/index2/GameList/game4.png`
  const game5 = `${S3Url}/images/index2/GameList/game5.png`

  const [scrollH, setScrollH] = useState(0)
  const [changeVw, setChangeVw] = useState(0)

  const navigate = useNavigate()
  const handleGame = (e: any) => {
    e.stopPropagation()
    navigate('/game')
  }

  //视频模块
  // const videoRef = useRef<any>(null)

  // 鼠标滚动的高度
  useEffect(() => {
    const screenHeight = window.innerHeight
    const vh = screenHeight / 100
    const changeWidth = 400 * vh
    setChangeVw(changeWidth)

    const handleScroll = () => {
      setScrollH(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    // 开启覆盖模式
    let trigger = ScrollTrigger.create({
      trigger: '.TestPinSpacing',
      start: 'top top',
      end: 'buttom bottom',
      pin: true,
      pinSpacing: false,
    })
  }, [])

  const mouseEnter = (className: string) => {
    gsap.fromTo(`.${className}`, { scale: 1.1 }, { scale: 1, duration: 0.5 })
  }
  const mouseLeave = (className: string) => {
    gsap.fromTo(`.${className}`, { scale: 1 }, { scale: 1.1, duration: 0.5 })
  }

  const [WinInfo, setWinInfo] = useState<any>([])
  const [MacInfo, setMacInfo] = useState<any>([])
  const [AndroidInfo, setAndroidInfo] = useState<any>([])
  const [IosInfo, setIosInfo] = useState<any>([])

  useEffect(() => {
    const getdownloadInfo = () => {
      partyplanetFun()
        .then((res: any) => {
          if (res.data.length > 0) {
            for (let index = 0; index < res.data.length; index++) {
              const element = res.data[index]
              switch (element.platform) {
                case 'pc':
                  setWinInfo(res.data[index])
                  break
                case 'mac':
                  setMacInfo(res.data[index])
                  break
                case 'android':
                  setAndroidInfo(res.data[index])
                  break
                case 'ios':
                  setIosInfo(res.data[index])
                  break
              }
            }
          }
        })
        .catch((err: any) => {})
    }
    getdownloadInfo()
  }, [])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className='gamelist-wrap'>
            <Box className={scrollH > changeVw ? 'actived' : ''}>
              {/* city1 */}
              <GamePage imgurl={game1}>
                <GameListBox style={{ right: 0 }}>
                  <GameListTextBox className='GameListtextbox'>
                    <Game className='exit-animation'>MarsParty</Game>
                    <BigTitleImg folder='download' className='exit-animation' src='marsxyz.png' />
                    <SmallTitle className='exit-animation'>Join the party with Skytopians and friends!</SmallTitle>
                    <Tips className='exit-animation'>
                      <div>Compete and cooperate with various participants in different themed party games. </div>
                      <div>Master rules of different parties and beat other players. </div>
                      <div>Striving for dopamine!</div>
                    </Tips>
                    {
                    <DownLoadBox>
                      <LogoBox href={AndroidInfo?.download} target="_blank" rel="noopener noreferrer">
                        <SaveButton>
                          <Logo className="androidLogo" folder="download" src="btn_Android.png"></Logo>
                        </SaveButton>
                      </LogoBox>

                      <LogoBox href={IosInfo?.download} target="_blank" rel="noopener noreferrer">
                        <SaveButton>
                          <Logo className="iosLogo" folder="download" src="btn_iOS@2x.png"></Logo>
                        </SaveButton>
                      </LogoBox>

                      <LogoBox href={WinInfo?.download} target="_blank" rel="noopener noreferrer">
                        <SaveButton>
                          <Logo className="winLogo" folder="download" src="btn_w@2x.png"></Logo>
                        </SaveButton>
                      </LogoBox>
                    </DownLoadBox>
                    }

                    {/* <CityBottom className='exit-animation' onClick={handleGame}>
                      <Btnval>Go</Btnval>
                      <BtnImg folder='index2/GameList' className='game' src='arrow.png' />
                    </CityBottom> */}
                  </GameListTextBox>

                  <CityImgBox
                    onMouseEnter={() => {
                      mouseEnter(`city1`)
                    }}
                    onMouseLeave={() => {
                      mouseLeave(`city1`)
                    }}
                    style={{ borderRadius: '0 0 0 0' }}
                  >
                    <CityImg folder='index2/GameList' className='city1' src='city1.png' />
                    {/* {scrollH > changeVw && (
                      <video playsInline autoPlay muted loop ref={videoRef} className="back-video-style">
                        <source src={videoSource} type="video/mp4"></source>
                      </video>
                    )} */}
                  </CityImgBox>
                </GameListBox>
              </GamePage>
            </Box>
          </Wrap>
        ) : (
          <GameListH5></GameListH5>
        )
      }
    </Media>
  )
}

const Wrap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  .actived {
    width: 100vw !important;
  }
  .back-video-style {
    position: absolute;
    left: -1vw;
    top: -1vw;
    /* width: 54.688vw; */
    height: 35.542vw;
    /* width: 52.688vw; */
    width: auto;
  }
`

const Box = styled.div`
  height: 100%;
  width: 100%;
  background-color: #1e2020;
  // border-radius: 3.542vw;
  transition: width 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: width;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
`

const GamePage = styled.div<{ imgurl?: string }>`
  width: 100%;
  height: 100vh;
  background-image: url(${({ imgurl }) => imgurl});
  background-size: 100% 100%;
  position: relative;
`

const GameListBox = styled.div`
  width: 89.063vw;
  height: 33.542vw;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const GameListTextBox = styled.div`
  width: 31.823vw;
  min-height: 25.938vw;
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
`
const Game = styled.div`
  width: 13.438vw;
  height: 1.563vw;
  font-size: 2.083vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.563vw;
`
const BigTitleImg = styled(Image)`
  width: 14.5vw;
  height: 14.5vw;
  display: block;
  margin: 1.406vw 0 1.771vw 0;
`
const ThreeTitle = styled.div`
  width: 27.135vw;
  height: 9.375vw;
  font-size: 4.167vw;
  font-family: AvenueX-Regular, AvenueX;
  color: #ffffff;
  line-height: 4.688vw;
  margin: 1.406vw 0;
`
const FourTitle = styled.div`
  /* width: 22.552vw; */
  height: 9.063vw;

  font-size: 4.167vw;
  font-family: CRSL Arches-Regular, CRSL Arches;
  color: #ffffff;
  line-height: 9.063vw;
  margin: 1.406vw 0;
`
const FiveTitle = styled.div`
  width: 22.552vw;
  height: 9.063vw;
  font-size: 4.167vw;
  font-family: Chilling Sabrina-Regular, Chilling Sabrina;
  color: #ffffff;
  /* line-height: 9.063vw; */
  margin: 1.406vw 0;
`

const BigTitle = styled.div`
  width: 31.823vw;
  height: 5vw;
  font-size: 4.167vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #ffffff;
  line-height: 5vw;
  margin: 3.281vw 0 3.958vw 0;
`

const SmallTitle = styled.div`
  width: 31.823vw;
  min-height: 1.563vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.563vw;
`
const Tips = styled.div`
  width: 28vw;
  min-height: 7.813vw;
  font-size: 0.938vw;
  font-family: Albert Sans-Medium, Albert Sans;
  font-weight: 500;
  color: #b0abab;
  line-height: 1.563vw;
  margin-top: 0.625vw;
  div {
    /* margin-bottom: 0.938vw; */
  }
`

const CityImgBox = styled.div`
  width: 54.688vw;
  height: 33.542vw;
  overflow: hidden;
  position: relative;
`
const CityImg = styled(Image)`
  width: 50.688vw;
  height: 33.542vw;
  position: absolute;
`
const CityBottom = styled.div`
  width: 8vw;
  height: 3.438vw;
  background: #ffffff;
  border-radius: 3.438vw;
  padding: 0 1.51vw;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.302vw;
  cursor: pointer;
`
const Btnval = styled.div`
  width: 100%;
  color: #383938;
  font-size: 1.25vw;
  height: 3.438vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  line-height: 3.438vw;
  text-align: center;
`
const BtnImg = styled(Image)`
  width: 1.458vw;
  height: 0.625vw;
`
const UnStartBtn = styled.div`
  width: 10.417vw;
  height: 3.438vw;
  background: #313134;
  border-radius: 3.438vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #7b7b7b;
  line-height: 3.438vw;
  text-align: center;
  margin-top: 3.646vw;
`

// 下载按钮
const DownLoadBox = styled.div`
  width: 28.594vw;
  height: 4.375vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.302vw;
`
const LogoBox = styled.a`
  width: 9.125vw;
  height: 4.375vw;
  cursor: pointer;
`
const SaveButton = styled(Button)`
  width: 9.125vw;
  height: 4.375vw;
  background-color: rgba(92, 127, 255, 0);
  position: absolute;
  border-radius: 2.1vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
  }
`
const Logo = styled(Image)`
  width: 9.125vw;
  height: auto;
  position: absolute;
`

export default GameList
