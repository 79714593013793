import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import useSize from 'hooks/useSize'

interface MarqueeProps {
  images: string[]
  direction?: 'left' | 'right'
  speed?: number
}

const Marquee: React.FC<MarqueeProps> = ({ images, direction = 'left', speed = 1 }) => {
  const { pcSize } = useSize()

  const marqueeRef = useRef<any>(null)
  const [isPaused, setIsPaused] = useState(false)
  const [totalWidth, setTotalWidth] = useState(0)
  let animationFrameId: number | null = null

  useEffect(() => {
    const marquee = marqueeRef.current
    let totalWidth = 0
    for (const child of marquee.children) {
      totalWidth += (child as HTMLElement).offsetWidth
    }
    setTotalWidth(totalWidth)
    if (direction === 'right') {
      marquee.scrollLeft = totalWidth
    }
  }, [images, direction])

  const step = () => {
    if (!isPaused) {
      const marquee = marqueeRef.current
      const firstChild = marquee.firstChild as HTMLElement
      marquee.scrollLeft += direction === 'left' ? speed : -speed

      if (direction === 'left' && marquee.scrollLeft >= firstChild.offsetWidth) {
        marquee.appendChild(marquee.firstChild)
        marquee.scrollLeft -= firstChild.offsetWidth
      } else if (direction === 'right' && marquee.scrollLeft <= 0) {
        marquee.insertBefore(marquee.lastChild, marquee.firstChild)
        marquee.scrollLeft += firstChild.offsetWidth
      }
    }

    animationFrameId = requestAnimationFrame(step)
  }

  useEffect(() => {
    if (pcSize) {
      step()

      return () => {
        if (animationFrameId) {
          cancelAnimationFrame(animationFrameId)
        }
      }
    }
  }, [direction, speed, isPaused]) // eslint-disable-line

  return (
    <Wrap ref={marqueeRef}>
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt=""
          onLoad={() => {
            const marquee = marqueeRef.current
            let totalWidth = 0
            for (const child of marquee.children) {
              totalWidth += (child as HTMLElement).offsetWidth
            }
            setTotalWidth(totalWidth)
            if (direction === 'right') {
              marquee.scrollLeft = totalWidth
            }
          }}
          onMouseEnter={() => {
            setIsPaused(true)
            if (animationFrameId) {
              cancelAnimationFrame(animationFrameId)
              animationFrameId = null
            }
          }}
          onMouseLeave={() => {
            setIsPaused(false)
            if (!animationFrameId) {
              step()
            }
          }}
        />
      ))}
    </Wrap>
  )
}
export default Marquee

const Wrap = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  img {
    width: 14.167vw;
    height: 5.833vw;
    padding: 0.521vw 0.417vw;
    box-sizing: border-box;
  }
`
