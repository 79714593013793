import { baseURL } from 'api/apiList'

const configs = {
  CHAIN_CACHE_KEY: 'ACCESS_CHAIN',
  INIT_CHAIN: 256256,
  SUPPORTED_CHAINS: [256256],
  // SUPPORTED_CHAINS: [256256, 1],
  // SUPPORTED_CHAINS: [1, 256256, 512512, 5],
  CHAIN_INFO: [
    {
      chainId: 256256,
      chainName: 'CMP',
      chainNameShort: 'CMP',
      rpcUrl: 'https://mainnet.block.caduceus.foundation',
      explorerUrls: ['https://mainnet.scan.caduceus.foundation'],
      nativeCurrency: {
        name: 'CMP Token',
        symbol: 'CMP',
        decimals: 18,
      },
    },
    {
      chainId: 512512,
      chainName: 'CMP-Test',
      chainNameShort: 'CMP',
      rpcUrl: 'https://galaxy.block.caduceus.foundation',
      explorerUrls: ['https://galaxy.scan.caduceus.foundation'],
      nativeCurrency: {
        name: 'CMP Token',
        symbol: 'CMP',
        decimals: 18,
      },
    },
    // {
    //   chainId: 5,
    //   chainName: "以太坊测试网",
    //   chainNameShort: "ETH",
    //   rpcUrl: "https://goerli.infura.io/v3/",
    //   blockExplorerUrs: ["https://goerli.etherscan.io"],
    //   nativeCurrency: {
    //     name: "Binance Coin",
    //     symbol: "ETH",
    //     decimals: 18,
    //   },
    // },
    // {
    //   chainId: 1,
    //   chainName: "以太坊主网",
    //   chainNameShort: "ETH",
    //   rpcUrl: "https://mainnet.infura.io/v3/",
    //   blockExplorerUrs: ["https://etherscan.io"],
    //   nativeCurrency: {
    //     name: "Binance Coin",
    //     symbol: "ETH",
    //     decimals: 18,
    //   },
    // },
  ],
}

export default configs
