import React, { useEffect, useState } from 'react'
import FlexBox from 'components/FlexBox'
import Image from 'components/Image'
import styled from '@emotion/styled'
import { Link, useNavigate } from 'react-router-dom'
import { Box } from '@mui/material'
import useModal from 'hooks/useModal'
import LoginModal from './LoginModal'
import { getAuthCookie } from 'utils/user'
import { clearAuthCookie } from 'utils/user'
import { getPersonalFun } from 'api/request'
import useWalletAuth from 'hooks/useWalletAuth'
import { baseURL } from 'api/apiList'
import Setmedal from './Nav/Setmedal'
import { SwitchChainRequest, WalletCache } from 'utils/walletUtils'
import { UserList } from 'config/Nav'
import { resetRoute } from 'utils'
import HeaderH5 from './HeaderH5'
import Media from 'react-media'

const Header: React.FC<any> = () => {
  // 鼠标划到切链改变颜色
  const [MouseStatus, setMouseStatus] = useState<boolean>(false)
  // 展示登陆弹窗
  const [MouseLogin, setMouseLogin] = useState<boolean>(false)

  const { disconnect } = useWalletAuth()

  const [loginModalHandler] = useModal(<LoginModal />)

  const token = getAuthCookie()

  const [userInfo, setUserInfo] = useState<any>()

  const update = localStorage.getItem('update')

  const chainId = WalletCache.getChain()

  const [isShow, setIsShow] = useState<any>(false)
  const [Select, setSelect] = useState<any>('CMP')
  const [SelectInfo, setSelectInfo] = useState<any>([])

  const [isBlack, setIsBlack] = useState<any>(true)

  const ChangeChainId = () => {
    if (baseURL.includes('offical')) {
      if (chainId === 256256) {
        SwitchChainRequest(5).then((res) => {
          if (res) {
            setSelect('ETH')
          }
        })
      } else {
        SwitchChainRequest(256256).then((res) => {
          if (res) {
            setSelect('CMP')
          }
        })
      }
    } else {
      if (chainId === 256256) {
        SwitchChainRequest(1).then((res) => {
          if (res) {
            setSelect('ETH')
          }
        })
      } else {
        SwitchChainRequest(256256).then((res) => {
          if (res) {
            setSelect('CMP')
          }
        })
      }
    }
  }

  useEffect(() => {
    if (WalletCache.getChain() === 1 || WalletCache.getChain() === 5) {
      setSelect('ETH')
    } else {
      setSelect('CMP')
    }
  }, [chainId])

  useEffect(() => {
    if (token) {
      getPersonalFun()
        .then((res: any) => {
          localStorage.setItem('account', res.data.wallet_address)
          setUserInfo(res.data)
        })
        .catch((err: any) => {})
    }
  }, [token, update])

  const navigate = useNavigate()
  const handleSetting = (e: any) => {
    e.stopPropagation()
    navigate('/setting')
  }

  const handleDownload = () => {
    navigate('/download')
  }

  // function mousewheelHandler(e: any) {
  //   var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail))
  //   if (delta > 0 && isHeader === false) {
  //     setIsHeader(true)
  //   } else if (delta <= 0 && isHeader === true) {
  //     setIsHeader(false)
  //   }
  // }
  // document.addEventListener('mousewheel', mousewheelHandler, false)
  // document.addEventListener('DOMMouseScroll', mousewheelHandler, false)

  // useEffect(() => {
  //   if (isHeader) {
  //     gsap.to('.header-animation', { y: 0, yoyoEase: true, duration: 0.2 })
  //   } else if (!isHeader) {
  //     gsap.to('.header-animation', { y: '-15vw', yoyoEase: true, duration: 0.2 })
  //   }
  // }, [isHeader])

  window.addEventListener('scroll', function () {
    // 获取当前滚动高度
    var scrollHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

    const screenHeight = window.innerHeight
    const vh = screenHeight / 100

    // 检查滚动高度是否达到阈值
    if (scrollHeight >= 280 * vh && scrollHeight < 380 * vh) {
      if (isBlack) {
        setIsBlack(false)
      }
    } else if (scrollHeight >= 630 * vh && scrollHeight < 1130 * vh) {
      if (isBlack) {
        setIsBlack(false)
      }
    } else {
      if (!isBlack) {
        setIsBlack(true)
      }
    }
  })

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="header-animation">
            <FlexBox>
              {/* <NavBox>
          <NavImg src="nav.png" folder="index2/GameDownload" />
          <NavName>Manu</NavName>
        </NavBox> */}
              {/* <HeadA href="https://discord.com/invite/UC9ud4QzP7" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_dic@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_dic@2x.png" folder="index2/GameDownload" />}
              </HeadA>
              <HeadA href="https://twitter.com/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_tt@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_tt@2x.png" folder="index2/GameDownload" />}
              </HeadA>
              <HeadA href="https://medium.com/@SkylandNFTs" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_mdi@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_mdi@2x.png" folder="index2/GameDownload" />}
              </HeadA>
              <HeadA href="https://linktr.ee/SkytopiaLabs" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_lt@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_lt@2x.png" folder="index2/GameDownload" />}
              </HeadA> */}
              <HeadA href="" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_dic@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_dic@2x.png" folder="index2/GameDownload" />}
              </HeadA>
              <HeadA href="" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_tt@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_tt@2x.png" folder="index2/GameDownload" />}
              </HeadA>
              <HeadA href="" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_mdi@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_mdi@2x.png" folder="index2/GameDownload" />}
              </HeadA>
              <HeadA href="" target="_blank" rel="noopener noreferrer">
                {!isBlack ? <HeadRight src="Blackbtn_lt@2x.png" folder="index2/GameDownload" /> : <HeadRight src="Whitebtn_lt@2x.png" folder="index2/GameDownload" />}
              </HeadA>
            </FlexBox>
            {isBlack ? <LogoBtn src="marsxyz.png" onClick={resetRoute} folder="index2/GameDownload" /> : <LogoBtn src="marsxyz.png" onClick={resetRoute} folder="index2/GameDownload" />}

            <FlexBox endAlignCenter>
              {/* <ChainBtn
          onMouseEnter={() => {
            setMouseStatus(true)
          }}
          onMouseLeave={() => {
            setMouseStatus(false)
          }}
          onClick={() => {
            ChangeChainId()
          }}
        >
          <ChainTips>{Select}</ChainTips>
          {MouseStatus ? <ChainImg folder="index2/GameDownload" src="switchYellow.png" /> : <ChainImg folder="index2/GameDownload" src="switch.png" />}
        </ChainBtn> */}
              {
              <DownloadBtn onClick={() => {
                handleDownload()
              }}>
                
                {/* <CMPLogo folder="index2/GameDownload" src="Connectwallet.png" /> */}
                <DownloadTips>Download</DownloadTips>
              </DownloadBtn> 
              }
              {token !== '' ? (
                <LoginBtn
                  onMouseEnter={() => {
                    setMouseLogin(true)
                  }}
                  onMouseLeave={() => {
                    setMouseLogin(false)
                  }}
                >
                  <SignUpImg folder="index2/GameDownload" src="user.png" />
                  <Username>{userInfo?.username}</Username>

                  {MouseLogin && (
                    <SelectBox>
                      {UserList.map((childItem, index) => {
                        return (
                          <Link to={childItem.router} key={index} style={{ padding: '0.8vw 1.042vw', display: 'block', width: 'fit-content', maxWidth: '22vw' }}>
                            {childItem.name}
                          </Link>
                        )
                      })}
                      <LogOutBox
                        onClick={() => {
                          setMouseLogin(false)
                          clearAuthCookie()
                          disconnect()
                          navigate('/')
                        }}
                      >
                        Log out
                      </LogOutBox>
                    </SelectBox>
                  )}
                </LoginBtn>
              ) : (
                <LoginBtn
                  onClick={() => {
                    loginModalHandler()
                  }}
                >
                  <SignUpImg folder="index2/GameDownload" src="Connectwallet.png" />
                  <LoginTips>Connect Wallet</LoginTips>
                </LoginBtn>
              )}
            </FlexBox>
          </Wrap>
        ) : (
          <HeaderH5></HeaderH5>
        )
      }
    </Media>
  )
}

const Wrap = styled.div`
  width: 100vw;
  height: 5.573vw;
  /* background: #151516; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.333vw;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  a {
    border-bottom: none;
    text-decoration: none;
    /* width: fit-content; */
    color: #fff;
    &:hover {
      color: #eff11f;
    }
  }
`

const ChainBtn = styled.div`
  width: 5.521vw;
  height: 2.083vw;
  background: #313134;
  border-radius: 2.083vw;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.938vw 0;
  box-sizing: border-box;
  margin-right: 1.25vw;

  font-size: 0.938vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #afafaf;

  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    /* background: #313134;
    color: #eff11f; */
  }
`
const CMPLogo = styled(Image)`
  width: 1.146vw;
  height: 1.25vw;
`

const ChainTips = styled.div`
  width: 1.979vw;
  font-size: 0.938vw;
`
const ChainImg = styled(Image)`
  width: 1.094vw;
  height: 1.146vw;
`
const LoginBtn = styled.div`
  width: 11.51vw;
  height: 2.708vw;
  background: #eff11f;
  border-radius: 0.677vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.521vw 0 0.365vw;
  box-sizing: border-box;
  font-family: Albert Sans-Bold;
  color: #000000;
  position: relative;
  cursor: pointer;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #eff11f;
  }
`
const LoginTips = styled.div`
  width: 8.5vw;
  font-size: 1.146vw;
  text-align: right;
`
const SignUpImg = styled(Image)`
  width: 1.979vw;
  height: 1.979vw;
`

const SelectBox = styled.div`
  font-size: 1.146vw;
  width: fit-content;
  max-width: 22vw;
  min-height: 1vw;
  background: rgba(21, 21, 22, 0.9);
  border-radius: 0.313vw;
  z-index: 1;
  box-sizing: border-box;
  padding: 1.51vw 0.3vw 1.302vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 2.708vw;
  left: 1.342vw;
`
const LogOutBox = styled(Box)`
  font-size: 1.146vw;
  font-family: Albert Sans-Bold;
  color: #656667;
  padding: 0.8vw 1.042vw;
  display: block;
  width: fit-content;
  max-width: 22vw;
  &:hover {
    color: #eff11f;
  }
`

const Username = styled.div`
  font-size: 1.146vw;
  font-family: Albert Sans-Bold;
  color: #3d3d3d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 0.417vw;
  flex-grow: 1;
  text-align: center;
`
const HeadA = styled.a`
  width: 2.396vw;
  height: 2.396vw;
  display: block;
  position: relative;
  margin-right: 1.25vw;
`
const HeadRight = styled(Image)`
  width: 2.396vw;
  display: block;
  position: absolute;
`
const HeadBox = styled.div<{ isblack?: boolean }>`
  width: 2.396vw;
  height: 2.396vw;
  display: block;
  position: absolute;
  border-radius: 50%;
  background-color: ${({ isblack }) => (isblack ? '#000' : 'rgba(210, 211, 212, 1)')};
`
const LogoBtn = styled(Image)`
  width: 2.604vw;
  height: 2.604vw;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
`

const DownloadBtn = styled.div`
  width: 11.51vw;
  height: 2.708vw;
  background: #313134;
  border-radius: 0.677vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.521vw 0 0.365vw;
  box-sizing: border-box;
  font-family: Albert Sans-Bold;
  color: #afafaf;
  position: relative;
  cursor: pointer;
  margin-right: 1.25vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    /* background: #313134;
    color: #eff11f; */
  }
`

const DownloadTips = styled.div`
  width: 8.5vw;
  font-size: 1.146vw;
  text-align: right;
  color: white;
`

export default Header
