import Image from "components/Image"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { productListFun } from "api/request"
import FlexBox from "components/FlexBox"
import { useNavigate } from "react-router-dom"
import Media from "react-media"
import ProductH5 from "./ProductH5"

const Product = () => {
  const [productListInfo, setProductList] = useState<any>([])

  const navigate = useNavigate()
  const handleToDetails = (item: any) => {
    console.log(item)
    localStorage.setItem("productInfo", JSON.stringify(item))
    navigate("/store/details")
  }

  useEffect(() => {
    const getproductList = () => {
      productListFun()
        .then((res: any) => {
          setProductList(res.data.list)
        })
        .catch((err: any) => {})
    }
    getproductList()
  }, [])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="product-wrap">
            <Title>STORE</Title>
            <ProductList>
              {productListInfo?.map((item: any, key: any) => (
                <ProductBox onClick={() => item.status !== 3 && handleToDetails(item)} key={key}>
                  <ProductSaleImage folder="store" src="sale.png" />
                  <ProductImage src={item.image_url} alt="NFT"></ProductImage>
                  <ProductTitle>{item.name}</ProductTitle>
                  <FlexBox startCenter sx={{ height: "1vw", margin: "0.625vw 0 0 0.208vw" }}>
                    <ProductPriceImage folder="setting" src={item.pay_currency?.toUpperCase() + ".png"}></ProductPriceImage>
                    <ProductPrice>
                      {item.price}
                      {item.pay_currency}
                    </ProductPrice>
                  </FlexBox>
                  <ProductCountBox>
                    <ProductCount>
                      {item.balance}/{item.supply}
                    </ProductCount>
                  </ProductCountBox>
                  <ProductSource>{item.resource}</ProductSource>
                  {item.status === 3 && (
                    <ProductSold>
                      <ProductSoldImage folder="store" src="sold.png" />
                    </ProductSold>
                  )}
                </ProductBox>
              ))}
            </ProductList>
          </Wrap>
        ) : (
          <ProductH5></ProductH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh - 3.594vw);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
`
const Title = styled.div<{ marginVal?: string }>`
  width: 7.083vw;
  height: 1.719vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 2.604vw;
  margin: 11.25vw 0 2.083vw 9.635vw;
`
const ProductList = styled.div`
  min-height: 50vw;
  width: 79.167vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 10.469vw;
  flex-wrap: wrap;
`
const ProductBox = styled.div`
  width: 14.063vw;
  height: 23.229vw;
  background: #ffffff;
  border-radius: 1.042vw;
  margin-right: 2.135vw;
  margin-bottom: 1vw;
  position: relative;
  padding: 0.521vw;
  box-sizing: border-box;
  background: rgba(92, 127, 255, 0.8);
  cursor: pointer;
  :nth-of-type(5n + 0) {
    margin-right: 0;
  }
`
const ProductTitle = styled.div`
  width: 13.542vw;
  height: 3.229vw;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 1.146vw;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
`
const ProductImage = styled.img`
  width: 14.063vw;
  height: 14.063vw;
  border-radius: 1.042vw 1.042vw 0 0;
  position: relative;
  left: -0.521vw;
  top: -0.521vw;
`
const ProductPriceImage = styled(Image)`
  width: 1.458vw;
  height: 1.458vw;
  margin-right: 0.469vw;
`
const ProductSaleImage = styled(Image)`
  width: 5.677vw;
  height: 3.958vw;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
`
const ProductPrice = styled.div`
  min-width: 2.24vw;
  font-size: 1.302vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffe736;
`
const ProductCountBox = styled.div`
  width: 12.969vw;
  height: 1.667vw;
  background: #5784ff;
  border-radius: 0.417vw;
  margin: 0.208vw auto 0.052vw;
`
const ProductCount = styled.div`
  min-width: 12.708vw;
  height: 0.938vw;
  font-size: 1.042vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  line-height: 1.667vw;
`

const ProductSource = styled.div`
  font-size: 1.042vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #a9baff;
`
const ProductSold = styled.div`
  width: 14.063vw;
  height: 23.229vw;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 1.042vw;
  position: absolute;
  left: 0;
  top: 0;
`
const ProductSoldImage = styled(Image)`
  width: 11.406vw;
  height: 8.802vw;
  position: absolute;
  left: 1.61vw;
  top: 7.396vw;
`

export default Product
