import Image from "components/Image"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { orderListFun } from "api/request"
import FlexBox from "components/FlexBox"

const OrderH5 = () => {
  const [OrderListInfo, setOrderList] = useState<any>([])

  useEffect(() => {
    const getOrderList = () => {
      orderListFun()
        .then((res: any) => {
          setOrderList(res.data.list)
        })
        .catch((err: any) => {})
    }
    getOrderList()
  }, [])

  return (
    <Wrap className="Order-wrap">
      <Title>STORE</Title>
      <OrderList>
        {OrderListInfo?.length > 0 ? (
          OrderListInfo?.map((item: any, key: any) => (
            <OrderBox key={key}>
              <FlexBox between>
                <FlexBox sx={{ fontSize: "2.933vw", fontFamily: "Albert Sans-Bold, Albert Sans" }}>
                  <div style={{ marginRight: "1.333vw" }}>{item?.updated?.split("T")[0]} </div>
                  <div>Order Number : {item?.id}</div>
                </FlexBox>
                <Complete>Complete</Complete>
              </FlexBox>

              <FlexBox between>
                <FlexBox>
                  <OrderImage src={item?.image_url}></OrderImage>
                  <OrderName>{item?.name}</OrderName>
                </FlexBox>
                <div>
                  <FlexBox center sx={{ marginBottom: "1.333vw", marginRight: "1.333vw" }}>
                    {item?.price}
                    <ProductPriceImage folder="setting" src="CMP.png"></ProductPriceImage>
                  </FlexBox>
                  <div>X {item?.amount}</div>
                </div>
              </FlexBox>
              <FlexBox end sx={{ marginRight: "1.333vw" }}>
                <FlexBox center sx={{ fontSize: "4vw", fontFamily: "Albert Sans-Bold, Albert Sans" }}>
                  <span style={{ color: "#95ADD4", marginRight: "2.267vw" }}>Payments: </span>
                  <span style={{ marginRight: "1.467vw" }}></span>
                  {item?.cost}
                  <ProductPriceImage folder="setting" src="CMP.png"></ProductPriceImage>
                </FlexBox>
              </FlexBox>
            </OrderBox>
          ))
        ) : (
          <FlexBox column center sx={{ width: "100%" }}>
            <NoDataImg src="NoData.png" folder="setting"></NoDataImg>
            <Tips>No Data</Tips>
          </FlexBox>
        )}
      </OrderList>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh - 3.594vw);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
`
const Title = styled.div`
  width: 21.067vw;
  height: 5.2vw;
  font-size: 7.467vw;
  line-height: 5.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin: 16.267vw 0 7.2vw 5.2vw;
`
const OrderList = styled.div`
  min-height: 50vw;
  width: 89.467vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5.333vw;
  flex-wrap: wrap;
`

const OrderBox = styled.div`
  width: 89.467vw;
  border-radius: 2vw;
  margin-bottom: 4vw;
  padding: 1.333vw;
  box-sizing: border-box;
  background: rgba(139, 164, 255, 0.5);
  height: 24.533vw;
  box-sizing: border-box;
`
const Complete = styled.div`
  width: fit-content;
  font-size: 2.933vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #5c7fff;
`
const OrderImage = styled.img`
  width: 13.333vw;
  height: 13.333vw;
  border-radius: 1.333vw;
  margin-right: 1.333vw;
`
const OrderName = styled.div`
  width: 43.6vw;
  height: 8.4vw;
  font-size: 3.467vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 400;
  color: #ffffff;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  
`
const ProductPriceImage = styled(Image)`
  width: 5.067vw;
  height: 5.067vw;
  margin-left: 0.533vw;
`
const NoDataImg = styled(Image)`
  width: 30.133vw;
  height: 30.133vw;
  margin: 16.267vw auto 2vw;
`
const Tips = styled.div`
  font-size: 4.267vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b2c1dd;
  text-align: center;
`
export default OrderH5
