import Cookies from 'js-cookie'

export const getAuthCookie = (): string => {
  return Cookies.get('accessToken') || ''
}

export const setAuthCookie = (token: string, expires: number = 7) => {
  Cookies.set('accessToken', token, { expires })
}

export const clearAuthCookie = () => {
  Cookies.remove('accessToken')
}

export const isValidPassword = (password: string) => {
  const regex = /^[A-Za-z0-9]+$/
  return regex.test(password)
}
