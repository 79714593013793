/* eslint-disable */
import { useCallback } from "react"
import { useWeb3React } from "@web3-react/core"
import { Contract } from "web3-eth-contract"
import { useERC20 } from "hooks/useContract"
import { approve } from "utils/web3Utils"

// stake授权
export const useSousApprove = (lpContract: Contract,  contractAddress: any) => {
  //获取钱包地址
  const { account } = useWeb3React()
  // 要授权给谁的
  const sousChefContract = useERC20(contractAddress)

  const handleApprove = useCallback(async () => {
    try {
      const tx = await approve(lpContract, sousChefContract, account)
      return tx
    } catch (e) {
      return false
    }
  }, [account, lpContract, sousChefContract, contractAddress])

  return { onApprove: handleApprove }
}
/* eslint-disable */
