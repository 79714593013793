import styled from "@emotion/styled"
import useSize from "hooks/useSize"
import { useRef, useState, useEffect } from "react"
import Image from "components/Image"
import { Button } from "@mui/material"
import { useWeb3React } from "@web3-react/core"
import useWalletAuth from "hooks/useWalletAuth"
import { useLandSaleWithETHAndDAI, useEthLandSaleWithETHAndDAI } from "hooks/useContract"
import { buyLandWithETH } from "utils/BuyNFT"
import message from "utils/message"
import { getproofFun } from "api/request"
import { getAuthCookie } from "utils/user"
import LoginModal from "components/LoginModal"
import useModal from "hooks/useModal"
import { WalletCache } from "utils/walletUtils"
interface IProps {
  timeStamp: any
  getCountdown?: any
  countdownType?: number
  mintPlanType?: number
}

const CountDowns = (props: IProps) => {
  const { timeStamp, getCountdown, countdownType, mintPlanType } = props
  const intervalRef = useRef<any>(null)
  const { pcSize } = useSize()
  const token = getAuthCookie()
  const [mint, setMint] = useState<boolean>(false)

  const [loading, setLoading] = useState(false)

  const { connect } = useWalletAuth()
  var { account } = useWeb3React()

  const now: any = Math.round(new Date().getTime() / 1000)
  const end: any = Number(timeStamp)

  const [d, setDays] = useState<any>("")
  const [h, setHours] = useState<any>("")
  const [m, setMinutes] = useState<any>("")
  const [s, setSeconds] = useState<any>("")
  const LandSaleWithETHAndDAIContract = useLandSaleWithETHAndDAI(localStorage.getItem("MarketContract"))
  const EthLandSaleWithETHAndDAIContract = useEthLandSaleWithETHAndDAI(localStorage.getItem("MarketContract"))

  const [loginModalHandler] = useModal(<LoginModal />)
  const handleWalletConnect = () => {
    if (token) {
      if (localStorage.getItem("ethereumNum") === "2") {
        window.ethereum = window.bitkeep.ethereum
        connect()
      } else if (localStorage.getItem("ethereumNum") === "1") {
        connect()
      } else {
        loginModalHandler()
      }
    } else {
      loginModalHandler()
    }
  }

  return (
    <Wrap>
      {account ? (
        <Buttonvalue
          onClick={async () => {
            if (account?.toLocaleLowerCase() !== localStorage.getItem("account")?.toLocaleLowerCase()) {
              message.warn("Connected address does not match. Please switch to your binding address.")
              return false
            }
            var list: any = []
            try {
              await getproofFun({ contract_address: localStorage.getItem("NftContract") }).then(async (res: any) => {
                if (res.code === 0) {
                  list = res.data
                }
              })
            } catch (error) {
              return false
            }

            try {
              if (list.length === 0) {
                return false
              }
              setLoading(true)
              setMint(true)
              WalletCache.getChain() === 1 || WalletCache.getChain() === 5
                ? await buyLandWithETH(EthLandSaleWithETHAndDAIContract, account, list.token_id, list.price, list.salt, list.proofs)
                : await buyLandWithETH(LandSaleWithETHAndDAIContract, account, list.token_id, list.price, list.salt, list.proofs)
              // const hash = await buyLandWithETH(LandSaleWithETHAndDAIContract, account, list.token_id, list.price, list.salt, list.proofs)
              message.success("Mint Success")
              setLoading(false)
              // setMint(false)
            } catch (error) {
              message.error("Mint Error.")
              setLoading(false)
              setMint(false)
            }
          }}
          disabled={countdownType === 0 || mint}
        >
          {loading && <Loading className="RotationBack" folder="components" src="rotate.png"></Loading>}
          {!loading && <span>MINT</span>}
        </Buttonvalue>
      ) : (
        <Buttonvalue onClick={handleWalletConnect}>Connect </Buttonvalue>
      )}
    </Wrap>
  )
}
const Wrap = styled.div`
  .Mui-disabled {
    color: #fff !important;
    background: rgba(0, 0, 0, 0.2);
  }
  .MuiButton-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: #ffd824;
  }
  position: relative;
`
const CountDownBox = styled.div`
  display: flex;
  align-items: center;
  width: 43.438vw;
  height: 10.938vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.563vw;
  padding-left: 3.281vw;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    width: 88.267vw;
    height: 24.533vw;
    border-radius: 4vw;
    padding-left: 2.133vw;
  }
`
const NumberBox = styled.div`
  width: 8.021vw;
  height: 8.021vw;
  position: relative;
  @media (max-width: 1000px) {
    width: 18.933vw;
    height: 18.933vw;
    position: relative;
  }
`
const NumberImage = styled(Image)`
  width: 8.021vw;
  height: 8.021vw;
  position: absolute;
  left: 0;
  top: 0;
  @media (max-width: 1000px) {
    width: 18.933vw;
    height: 18.933vw;
  }
`
const DivisionImage = styled(Image)`
  width: 1.563vw;
  height: 2.604vw;
  @media (max-width: 1000px) {
    width: 2.667vw;
    height: 6.533vw;
  }
`

const NumberCount = styled.div`
  text-align: center;
  font-size: 4.792vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  height: 3.333vw;
  line-height: 3.3vw;
  position: absolute;
  left: 1.5vw;
  top: 2vw;
  @media (max-width: 1000px) {
    font-size: 8.667vw;
    height: 6vw;
    line-height: 6vw;
    position: absolute;
    left: 4.8vw;
    top: 5.467vw;
  }
`
const Title = styled.div`
  width: 8vw;
  text-align: center;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.25vw;
  position: absolute;
  bottom: -1vw;
  left: 50%;
  margin-left: -4vw;
  @media (max-width: 1000px) {
    width: 18vw;
    font-size: 3.067vw;
    line-height: 3.067vw;
    text-align: center;
    position: absolute;
    bottom: -2vw;
    left: 50%;
    margin-left: -9vw;
  }
`
const Buttonvalue = styled(Button)`
  width: 14.063vw;
  height: 5.208vw;
  background: #ffd824;
  border-radius: 1.042vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  text-align: center;
  color: #ffffff;
  line-height: 5.208vw;
  margin: 2.448vw 0 0 13.8vw;
  border: 0;
  position: relative;
  &:hover {
    border: 0;
  }

  @keyframes rotationback {
    from {
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0deg);
    }
  }
  .RotationBack {
    transform: rotate(360deg);
    animation: rotationback 3s linear infinite;
  }

  @media (max-width: 1000px) {
    width: 32vw;
    height: 11.867vw;
    border-radius: 2.667vw;
    font-size: 5.733vw;
    line-height: 5.208vw;
    margin: 6.133vw 0 0 28.267vw;
  }
`
const Loading = styled(Image)`
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -1.25vw;
  margin-left: -1.25vw;
  width: 2.5vw;
  height: 2.5vw;
  @media (max-width: 1000px) {
    width: 8vw;
    height: 8vw;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -4vw;
    margin-left: -4vw;
  }
`
const Tips = styled.div`
  width: 36.5vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin-bottom: 1.042vw;
  margin-left: 6.563vw;
  @media (max-width: 1000px) {
    width: 77.33vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 3.2vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #7893be;
    margin-bottom: 1.6vw;
    margin-left: 5.6vw;
  }
`

export default CountDowns
