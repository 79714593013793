import styled from "@emotion/styled"
import Image from "components/Image"
import { gsap } from "gsap"
import { ImgInfo } from "../config/Team"
import FlexBox from "components/FlexBox"
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import useSize from "hooks/useSize"

const TeamH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".team-wrap", start: "top-=800" } })
      tl.fromTo(".team-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 0.5 })
    }
  }, [pcSize])
  const [hover, setHover] = useState(0)
  return (
    <Wrap className="team-wrap">
      <Title className="team-title-style">team</Title>
      <TeamBox>
        {ImgInfo.map((item, key) => {
          return (
            <div key={key}>
              <Box key={key} className={`${item?.class} buildings-img-style-box`}>
                <ImgActive
                  onClick={() => {
                    setHover(key)
                  }}
                  folder="about"
                  src={item.url}
                  hovers={hover === key ? "1" : "0.3"}
                ></ImgActive>
                <SkytopiaVal className="team-name" style={{ opacity: hover === key ? "1" : "0.4" }}>
                  {item.name}
                </SkytopiaVal>
              </Box>
              <Content style={{ display: key === hover ? "block" : "none", backgroundColor: item.color }}>
                <ContentTitle>{item?.Title}</ContentTitle>
                <ContentTips dangerouslySetInnerHTML={{ __html: item.content }}></ContentTips>
              </Content>
            </div>
          )
        })}
      </TeamBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  min-height: 125.6vw;
  position: relative;
`
const ImgActive = styled(Image)<{ hovers?: string }>`
  position: absolute;
  width: 24.293vw;
  height: 23.693vw;
  opacity: ${({ hovers }) => (hovers === "1" ? 1 : 0.3)};
`
const Title = styled.div`
  text-align: center;
  height: 5.067vw;
  font-size: 7.467vw;
  color: #7893be;
  line-height: 5.733vw;
  margin-bottom: 8.133vw;
`
const TeamBox = styled.div`
  width: 92.133vw;
  height: 52.933vw;
  position: relative;
  left: 4.133vw;
  .buildings-img-style-box {
    width: 24.293vw;
    height: 23.693vw;
    margin: 0 auto;
    position: absolute;
  }
  .team-img-style-one {
    top: 0;
    left: 0;
    .team-name {
      left: 7.333vw;
    }
  }
  .team-img-style-two {
    top: 0;
    left: 23.2vw;
    .team-name {
      left: 6.667vw;
    }
  }
  .team-img-style-three {
    top: 0;
    left: 46vw;
    .team-name {
      left: 6.533vw;
    }
  }
  .team-img-style-four {
    top: 0;
    left: 67.867vw;
    .team-name {
      left: 8.133vw;
    }
  }

  .team-img-style-five {
    top: 29.333vw;
    left: 0;
    .team-name {
      left: 6.533vw;
    }
  }
  .team-img-style-six {
    top: 29.333vw;
    left: 23.2vw;
    .team-name {
      left: 9.733vw;
    }
  }
  .team-img-style-serve {
    top: 29.333vw;
    left: 46vw;
    .team-name {
      left: 7.733vw;
    }
  }
  .team-img-style-eight {
    top: 29.333vw;
    left: 67.867vw;
    .team-name {
      left: 7.867vw;
    }
  }
`

const Content = styled.div`
  width: 88vw;
  height: 35.467vw;
  position: absolute;
  top: 58.8vw;
  left: 1.867vw;
  background-color: rgb(35, 202, 143);
  padding: 4.933vw 10vw 0 11.733vw;
  border-radius: 2vw;
  box-sizing: border-box;
`

const ContentTitle = styled.div<{ tops?: string }>`
  height: 4.133vw;
  font-size: 4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  line-height: 4.667vw;
  margin-bottom: 3.733vw;
`

const ContentTips = styled.div<{ tops?: string }>`
  /* width: 58.533vw; */
  min-height: 16.267vw;
  font-size: 2.8vw;
  font-family: Albert Sans-Bold, Albert Sans;
  line-height: 4.667vw;
`

const SkytopiaVal = styled.div`
  font-size: 3.067vw;
  color: #7893be;
  line-height: 3.467vw;
  width: fit-content;
  position: absolute;
  bottom: -2.667vw;
  z-index: 1;
`
export default TeamH5
