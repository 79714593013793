import React, { useContext, useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Button } from '@mui/material'
import { change_pwdFun } from 'api/request'
import message from 'utils/message'
import { isValidPassword } from 'utils/user'

const EditPasswordModelH5: React.FC<any> = ({ mobile_or_email }) => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const ChangePsd = () => {
    if (newPassword.length < 8) {
      message.error('Passwords must have at least 8 characters.')
      return false
    }
    if (newPassword !== repeatPassword) {
      message.error('Passwords do not match.')
      return false
    }

    // if (!isValidPassword(password) || !isValidPassword(newPassword)) {
    //   message.error('Support upper and lower case letters only.')
    //   return false
    // }
    change_pwdFun({ old_password: password, new_password: newPassword, mobile_or_email: mobile_or_email })
      .then((res: any) => {
        if (res.code === 0) {
          onClose()
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const { onClose } = useContext(ModalContext)

  const title = 'edit Password'

  const content = (
    <Warp>
      <Title>Current Password:</Title>
      <input
        type="password"
        value={password}
        onChange={(evt: any) => {
          setPassword(evt.target.value || '')
        }}
        style={{
          background: password ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>New Password:</Title>
      <input
        type="password"
        value={newPassword}
        onChange={(evt: any) => {
          setNewPassword(evt.target.value || '')
        }}
        style={{
          background: newPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>Confirm New Password:</Title>
      <input
        type="password"
        value={repeatPassword}
        onChange={(evt: any) => {
          setRepeatPassword(evt.target.value || '')
        }}
        style={{
          background: repeatPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
          marginBottom: '2.667vw',
        }}
      />

      <Signin
        disabled={password.length === 0 || newPassword.length === 0 || repeatPassword.length === 0}
        onClick={() => {
          ChangePsd()
        }}
      >
        Submit
      </Signin>
    </Warp>
  )
  return (
    <Modal
      title={title}
      content={content}
      sx={{
        width: '78.667vw',
        minHeight: '70vw',
      }}
    />
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4.267vw 5.467vw 4vw;
  box-sizing: border-box;
  position: relative;
  input {
    width: 67.733vw;
    height: 7.2vw;
    border-radius: 2vw;
    background: rgba(92, 127, 255, 0.5);
    font-family: Albert Sans-Bold, Albert Sans;
    font-size: 3.733vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    display: block;
    margin-bottom: 6.666vw;
    box-sizing: border-box;
    padding-left: 2.8vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  background: #5c7fff;
  font-size: 2.933vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 0 auto;
  display: block;
  box-sizing: border-box;
  width: 67.6vw;
  height: 7.2vw;
  padding: 0;
  border-radius: 3.6vw;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-bottom: 1.042vw;
  font-size: 2.667vw;
  margin-left: 1.733vw;
`
export default EditPasswordModelH5
