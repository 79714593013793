import Image from "components/Image"
import styled from "@emotion/styled"
import { gsap } from "gsap"
import { useEffect } from "react"
import useSize from "hooks/useSize"

const AboutHeaderH5 = () => {
  const { pcSize } = useSize()
  useEffect(() => {
    if (pcSize) {
      var tl = gsap.timeline({ scrollTrigger: { trigger: ".about-wrap", start: "top-=800" } })
      tl.fromTo(".about-title-Tips", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 1 })
      tl.fromTo(".about-title-style", { opacity: 0, x: -30 }, { x: 0, opacity: 1, duration: 1 })
    }
  }, [pcSize])

  return (
    <Wrap className="about-wrap">
      <HeaderImage folder="about_h5" src="headerImg.png" />
      <Tips className="about-title-Tips">join us to build a truly generational</Tips>
      <Title className="about-title-style">metaverse</Title>
    </Wrap>
  )
}
const Wrap = styled.div`
  height: 85.2vw;
  width: 100%;
  position: relative;
  overflow: hidden;
`
const HeaderImage = styled(Image)`
  height: 50.667vw;
  width: 90vw;
  margin: 18.667vw auto 0;
`

const Title = styled.div`
  text-align: center;
  width: 40vw;
  height: 5.2vw;
  font-size: 7.467vw;
  line-height: 6.667vw;
  position: absolute;
  left: 50%;
  top: 45.6vw;
  margin-left: -20vw;
`
const Tips = styled.div`
  width: 80vw;
  text-align: center;
  height: 1.771vw;
  font-size: 4.133vw;
  line-height: 4.133vw;
  position: absolute;
  left: 50%;
  top: 40.533vw;
  margin-left: -40vw;
`
export default AboutHeaderH5
