import React, { useContext, useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Button } from '@mui/material'
import FlexBox from 'components/FlexBox'
import { change_pwdFun } from 'api/request'
import message from 'utils/message'
import Media from 'react-media'
import EditPasswordModelH5 from './EditPasswordModelH5'
import { isValidPassword } from 'utils/user'

const EditPasswordModel: React.FC<any> = ({ mobile_or_email }) => {
  const [password, setPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')

  const ChangePsd = () => {
    if (newPassword.length < 8) {
      message.error('Passwords must have at least 8 characters.')
      return false
    }
    if (newPassword !== repeatPassword) {
      message.error('Passwords do not match.')
      return false
    }

    // if (!isValidPassword(newPassword) || !isValidPassword(password)) {
    //   message.error('Support upper and lower case letters only.')
    //   return false
    // }

    change_pwdFun({ old_password: password, new_password: newPassword, mobile_or_email: mobile_or_email })
      .then((res: any) => {
        if (res.code === 0) {
          onClose()
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const { onClose } = useContext(ModalContext)

  const title = 'edit Password'

  const content = (
    <Warp>
      <Title>Current Password:</Title>
      <input
        type="password"
        value={password}
        onChange={(evt: any) => {
          setPassword(evt.target.value || '')
        }}
        style={{
          background: password ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>New Password:</Title>
      <input
        type="password"
        value={newPassword}
        onChange={(evt: any) => {
          setNewPassword(evt.target.value || '')
        }}
        style={{
          background: newPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
        }}
      />

      <Title>Confirm New Password:</Title>
      <input
        type="password"
        value={repeatPassword}
        onChange={(evt: any) => {
          setRepeatPassword(evt.target.value || '')
        }}
        style={{
          background: repeatPassword ? 'rgba(92, 127, 255, 0.6)' : 'rgba(92, 127, 255, 0.3)',
          marginBottom: 0,
        }}
      />

      <Signin
        disabled={password.length === 0 || newPassword.length === 0 || repeatPassword.length === 0}
        onClick={() => {
          ChangePsd()
        }}
      >
        Submit
      </Signin>
    </Warp>
  )
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Modal
            title={title}
            content={content}
            sx={{
              width: '28.646vw',
              minHeight: '40vw',
            }}
          />
        ) : (
          <EditPasswordModelH5 mobile_or_email={mobile_or_email} />
        )
      }
    </Media>
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.563vw 1.979vw 1.563vw;
  position: relative;
  input {
    width: 24.635vw;
    height: 2.604vw;
    background: rgba(92, 127, 255, 0.5);
    border-radius: 1.042vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-size: 1.354vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    line-height: 1.406vw;
    display: block;
    margin-bottom: 49.997px;
    box-sizing: border-box;
    padding-left: 0.781vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  width: 24.635vw;
  height: 3.438vw;
  background: #5c7fff;
  border-radius: 1.719vw;
  font-size: 1.354vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 1.042vw auto 0;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  font-size: 1.354vw;
  height: 1.094vw;
  line-height: 1vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-bottom: 1.042vw;
`
export default EditPasswordModel
