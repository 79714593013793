import styled from '@emotion/styled'
import Image from 'components/Image'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useState, useEffect, useRef } from 'react'
import PlayOwnH5 from './PlayOwnH5'
import Media from 'react-media'
const PlayOwn = () => {
  gsap.registerPlugin(ScrollTrigger)

  const [scrollH, setScrollH] = useState(0)
  const [changeVw, setChangeVw] = useState(0)

  // 监听覆盖下一页
  useEffect(() => {
    let trigger = ScrollTrigger.create({
      trigger: '.TestPinSpacing',
      start: 'top top',
      end: 'buttom bottom',
      pin: true,
      pinSpacing: false,
    })
    return () => {
      trigger.kill()
    }
  }, [])

  // 鼠标滚动的高度
  useEffect(() => {
    const handleScroll = () => {
      setScrollH(window.scrollY)

      const screenHeight = window.innerHeight
      const vh = screenHeight / 100
      const changeWidth = 200 * vh
      setChangeVw(changeWidth)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          var tl = gsap.timeline({ scrollTrigger: { trigger: '.playown-wrap', start: 'top-=700' } })
          tl.fromTo('.playown-game', { opacity: 0, y: 200 }, { y: 0, opacity: 1, duration: 0.5 })
          tl.fromTo('.playown-companion', { opacity: 0, y: 200 }, { y: 0, opacity: 1, duration: 0.5 })
          tl.fromTo('.playown-token', { opacity: 0, y: 200 }, { y: 0, opacity: 1, duration: 0.5 })
          tl.fromTo('.playown-governance', { opacity: 0, y: 200 }, { y: 0, opacity: 1, duration: 0.5 })
        }
      })
    })

    const elements = document.querySelectorAll('.PlayownContent')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="playown-wrap">
            <Box className={scrollH > changeVw ? 'actived' : ''}>
              <PlayownContent className="PlayownContent">
                <GameBox className="playown-game">
                  <GameImg folder="index2/PlayOwn" className="game" src="Game.png" />
                  <Title>Play</Title>
                  <Content>Users can explore a fantasy world, enjoy different types of gameplay, interacting with cute characters and environments to obtain an immersive gaming experience in the Skytopia Universe.</Content>
                </GameBox>

                <GameBox className="playown-companion">
                  <GameImg folder="index2/PlayOwn" className="token" src="Companion.png" />
                  <Title className="tokenlabel">Own</Title>
                  <Content>In Skytopia, game assets are owned by players. Therefore, players can trade, transfer, and manage these assets within the game, thereby gaining a better gaming experience and economic benefits.</Content>
                </GameBox>

                <GameBox className="playown-token">
                  <GameImg folder="index2/PlayOwn" className="token" src="Token.png" />
                  <Title className="tokenlabel">Create</Title>
                  <Content>Skytopia combines AI and other technologies to provide the community with powerful content creator tools. Users can express their ideas and feelings, and together create a rich and diverse content ecosystem.</Content>
                </GameBox>

                <GameBox className="playown-governance">
                  <GameImg folder="index2/PlayOwn" className="token" src="Governance.png" />
                  <Title className="tokenlabel">Socialize</Title>
                  <Content>Players can establish game communities and teams in Skytopia. The rich social features will bring players a broader and deeper gaming experience, while enhancing the interactivity and playability.</Content>
                </GameBox>
              </PlayownContent>
            </Box>
          </Wrap>
        ) : (
          <PlayOwnH5></PlayOwnH5>
        )
      }
    </Media>
  )
}

const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .actived {
    width: 100vw !important;
  }
`

const Box = styled.div`
  height: 100vh;
  width: 90vw;
  background-color: #1e2020;
  border-radius: 3.542vw;
  transition: width 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: width;
  margin: 0 auto;
  position: relative;
`

const PlayownContent = styled.div`
  width: 73.542vw;
  height: 26.875vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const GameBox = styled.div`
  width: 15.833vw;
  height: 26.875vw;
`
const GameImg = styled(Image)`
  width: 8.75vw;
  height: 8.75vw;
  display: block;
  margin: 0.521vw 0 0.469vw 0.208vw;
`

const Title = styled.div`
  width: 100%;
  height: 3.073vw;
  font-size: 1.875vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #b0abab;
  line-height: 2.24vw;
  margin-bottom: 0.208vw;
`
const Content = styled.div`
  width: 100%;
  min-height: 12.708vw;
  font-size: 0.938vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #ffffff;
  line-height: 1.563vw;
`

export default PlayOwn
