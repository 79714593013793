export const ImgInfo = [
  {
    url: "01-1@2x",
    urlBg: "01@2x",
    class: "small-01",
  },
  {
    url: "02-1@2x",
    urlBg: "02@2x",
    class: "small-02",
  },

  {
    url: "03-1@2x",
    urlBg: "03@2x",
    class: "small-03",
  },
  {
    url: "04-1@2x",
    urlBg: "04@2x",
    class: "small-04",
  },
  {
    url: "07-1@2x",
    urlBg: "07@2x",
    class: "small-05",
  },

  {
    url: "06-1@2x",
    urlBg: "06@2x",
    class: "small-06",
  },
  {
    url: "05-1@2x",
    urlBg: "05@2x",
    class: "small-07",
  },
  {
    url: "08-1@2x",
    urlBg: "08@2x",
    class: "small-08",
  },
  {
    url: "09-1@2x",
    urlBg: "09@2x",
    class: "small-09",
  },
  {
    url: "10-1@2x",
    urlBg: "10@2x",
    class: "small-10",
  },
]
export const H5ImgInfo = [
  {
    url: "01@2x",
    class: "small-01",
  },
  {
    url: "02@2x",
    class: "small-02",
  },
  {
    url: "03@2x",
    class: "small-03",
  },
  {
    url: "04@2x",
    class: "small-04",
  },
  {
    url: "05@2x",
    class: "small-05",
  },
  {
    url: "06@2x",
    class: "small-06",
  },
  {
    url: "07@2x",
    class: "small-07",
  },
  {
    url: "08@2x",
    class: "small-08",
  },
  {
    url: "09@2x",
    class: "small-09",
  },
  {
    url: "10@2x",
    class: "small-10",
  },
]
