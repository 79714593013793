import styled from '@emotion/styled'
import { gsap } from 'gsap'
import { useEffect, useRef, useState } from 'react'

interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: any
  progress?: Number
}
const GameMap: React.FC<PreloaderProps> = ({ imageUrls, videoUrls }) => {
  const [di_guanggao1, di_guanggao2, di_guanggao3, di_guanggao4, di_guanggao5, di_guanggao6, di_hongsedi, WordWar, ChaosCourseImg] = imageUrls
  const [FugitiveFinder, Mushroom, candyChaos, Football, WordWarVideo, ArcticAssault, ChaosCourse] = videoUrls
  const [autoPlay, setAutoPlay] = useState(999)

  const GameInfo = [
    {
      url: di_guanggao1,
      class: 'Game1',
      videoUrl: candyChaos,
      name: 'CANDY CHAOS',
    },
    {
      url: di_guanggao2,
      class: 'Game2',
      videoUrl: Mushroom,
      name: 'MUSHROOM MADNESS',
    },
    {
      url: di_guanggao3,
      class: 'Game3',
      videoUrl: Football,
      name: 'FOOTBALL FRENZY',
    },
    {
      url: ChaosCourseImg,
      class: 'Game7',
      videoUrl: ChaosCourse,
      name: 'CHAOS COURSE',
    },
    {
      url: di_guanggao4,
      class: 'Game4',
      videoUrl: FugitiveFinder,
      name: 'FUGITIVE  FINDER',
    },
    {
      url: WordWar,
      class: ' ',
      videoUrl: WordWarVideo,
      name: 'WORD WAR',
    },
    {
      url: di_guanggao5,
      class: 'Game6',
      videoUrl: ArcticAssault,
      name: 'ARCTIC ASSAULT',
    },
   
    {
      url: di_guanggao6,
      class: 'Game6',
      videoUrl: '',
      name: 'STAY TUNED',
    },
  ]

  const scrollContainerRef: any = useRef(null)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startX, setStartX] = useState<any>()
  const [scrollLeft, setScrollLeft] = useState(0)

  const handleMouseDown = (e: { pageX: number }) => {
    setIsMouseDown(true)
    console.log(e.pageX)

    setStartX(e.pageX - scrollContainerRef.current.offsetLeft)
    setScrollLeft(scrollContainerRef.current.scrollLeft)
    scrollContainerRef.current.style.cursor = 'grabbing'
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
    scrollContainerRef.current.style.cursor = 'grab'
  }

  const handleMouseMove = (e: { preventDefault: () => void; pageX: number }) => {
    if (!isMouseDown) return
    e.preventDefault()
    const x = e.pageX - scrollContainerRef.current.offsetLeft
    const scrollX = (x - startX) * 2
    scrollContainerRef.current.scrollLeft = scrollLeft - scrollX
  }

  useEffect(() => {
    scrollContainerRef.current.scrollLeft = 680
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.fromTo(entry.target, { opacity: 0, x: 1000 }, { opacity: 1, x: 0, duration: 1 })
        }
      })
    })

    const elements = document.querySelectorAll('.roadmap')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  return (
    <Wrap style={{ backgroundImage: `url(${di_hongsedi})` }}>
      <RoadMapBox ref={scrollContainerRef} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove} onMouseLeave={handleMouseUp}>
        <RoadMap className="roadmap">
          {GameInfo.map((item, key) => (
            <GameBox
              key={key}
              onMouseEnter={() => {
                setAutoPlay(key + 1)
              }}
              onMouseLeave={() => {
                setAutoPlay(999)
              }}
            >
              <GameName>{item.name}</GameName>
              <GameImg src={`${item.url}`} />
              {item.videoUrl && (
                <video
                  style={{
                    width: '100%',
                    height: 'auto',
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    borderRadius: '31px',
                    display: autoPlay === key + 1 ? 'block' : 'none',
                  }}
                  muted
                  playsInline
                  autoPlay
                  loop={true}
                  controls
                  src={item.videoUrl}
                ></video>
              )}
            </GameBox>
          ))}
        </RoadMap>
      </RoadMapBox>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 620px;
  position: relative;
  background-size: 100% 100%;
  video::-webkit-media-controls-enclosure {
    display: none;
  }
`

const RoadMapBox = styled.div`
  width: 100%;
  min-height: 474px;
  position: absolute;
  left: 0;
  top: 57px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`
const RoadMap = styled.div`
  width: 267.994px;
  min-height: 474px;
  display: flex;
  align-items: center;
  margin: 0 380px;
`

const GameImg = styled.img`
  width: 268px;
  display: block;
  height: 474px;
`
const GameBox = styled.div`
  width: 268px;
  height: 474px;
  margin: 0 30px;
  position: relative;
  /* overflow: hidden; */
`
const GameName = styled.div`
  width: 268px;
  text-align: center;
  height: 22px;
  font-size: 29px;
  font-family: Milky Nice;
  color: #ffffff;
  line-height: 31px;
  position: absolute;
  top: 194px;
  text-align: center;
  /* white-space: nowrap; */
`
export default GameMap
