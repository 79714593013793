import { useMediaQuery } from '@mui/material'

const useSize = () => {
  const pcSize = useMediaQuery('(min-width: 1000px)')
  const phoneSize = useMediaQuery('(max-width: 768px)')
  const smallSize = useMediaQuery('(max-width: 540px)')
  const miniSize = useMediaQuery('(max-width: 320px)')
  return { pcSize, phoneSize, smallSize, miniSize }
}

export default useSize