import React, { useContext, useState } from 'react'
import Modal from 'components/Modal/NewModal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Button } from '@mui/material'
import FlexBox from 'components/FlexBox'
import Btn from './CodeCountdown'
import { reset_pwdFun } from 'api/request'
import message from 'utils/message'
import Media from 'react-media'
import ForgotPasswordModelH5 from './ForgotPasswordModelH5'
import useModal from 'hooks/useModal'
import LoginModal from 'components/LoginModal'

interface NewForgotPasswordModelProps {
  mobile_or_email?: string
  isAuthenticated?: boolean
}

const NewForgotPasswordModel: React.FC<NewForgotPasswordModelProps> = ({ mobile_or_email, isAuthenticated = true }) => {
  const [Emailcode, setEmailcode] = useState('')

  const [email, setEmail] = useState(mobile_or_email || '')

  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const { onClose } = useContext(ModalContext)

  const [loginModalHandler] = useModal(<LoginModal />)
  const ChangePsd = () => {
    if (newPassword.length < 8) {
      message.error('Passwords must have at least 8 characters.')
      return false
    }
    if (email === undefined) {
      message.error('Please provide a valid email address')
      return false
    }
    if (newPassword !== repeatPassword) {
      message.error('Passwords do not match.')
      return false
    }
    // if (!isValidPassword(newPassword)) {
    //   message.error('Support upper and lower case letters only.')
    //   return false
    // }
    reset_pwdFun({ new_password: newPassword, mobile_or_email: email, code: Emailcode })
      .then((res: any) => {
        message.success('success')
        // onClose()
        loginModalHandler()
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const title = 'forgot password'

  const content = (
    <Warp>
      <input
        type="text"
        value={email}
        placeholder="Enter your email"
        onChange={(evt: any) => {
          setEmail(evt.target.value || '')
        }}
      />

      <FlexBox startBetween sx={{ width: '100%' }}>
        <input
          type="text"
          value={Emailcode}
          onChange={(evt: any) => {
            setEmailcode(evt.target.value || '')
          }}
          maxLength={6}
          placeholder="Enter your verification code"
          style={{
            width: '20.625vw',
          }}
        />
        <Btn isable={email} isAuthenticated={isAuthenticated}></Btn>
      </FlexBox>

      <input
        type="password"
        value={newPassword}
        placeholder="Enter your new password"
        onChange={(evt: any) => {
          setNewPassword(evt.target.value || '')
        }}
      />

      <input
        type="password"
        placeholder="Confirm password"
        value={repeatPassword}
        onChange={(evt: any) => {
          setRepeatPassword(evt.target.value || '')
        }}
        style={{
          marginBottom: 0,
        }}
      />

      <Tips>· 8+ characters, must contain at least one letter or one number</Tips>

      <FlexBox between style={{ width: '27.396vw' }}>
        <Signin onClick={loginModalHandler}>Cancel</Signin>
        <Signin
          disabled={Emailcode.length !== 6 || newPassword.length === 0 || repeatPassword.length === 0}
          onClick={() => {
            ChangePsd()
          }}
        >
          Reset
        </Signin>
      </FlexBox>
    </Warp>
  )
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Modal
            title={title}
            content={content}
            sx={{
              width: '32.708vw',
              minHeight: '20vw',
            }}
          />
        ) : (
          <ForgotPasswordModelH5 mobile_or_email={mobile_or_email} />
        )
      }
    </Media>
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2.656vw;
  position: relative;
  input {
    width: 27.396vw;
    height: 2.604vw;
    background: #d8d8d8;
    border-radius: 0.938vw;
    font-size: 1.042vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-weight: 700;
    color: #3d3d3d;
    line-height: 1.25vw;
    outline: none;
    border: 0;
    display: block;
    /* margin-bottom: 1.875vw; */
    box-sizing: border-box;
    padding-left: 1.198vw;
    margin: 0 auto 1.875vw;
  }
  input::-webkit-input-placeholder {
    color: #b0b0b0;
    font-size: 1.198vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: #c2c6cd;
  }
`
const Signin = styled(Button)`
  width: 11.979vw;
  height: 2.813vw;
  background: #383938;
  border-radius: 2.188vw;
  font-size: 1.042vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #ffffff;
  line-height: 1.563vw;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #383938;
  }
`
const Tips = styled.div`
  width: 21.51vw;
  height: 0.729vw;
  font-size: 0.729vw;
  font-family: Albert Sans-Black, Albert Sans;
  font-weight: 900;
  color: #46a2ff;
  line-height: 0.729vw;
  margin: 0.26vw 0 2.5vw;
`

export default NewForgotPasswordModel
