import styled from '@emotion/styled'
import Button, { ButtonProps } from '@mui/material/Button/Button'
import { getUnLoginsend_smsFun, getsend_smsFun } from 'api/request'
import { useState, useEffect } from 'react'

var timeChange: string | number | NodeJS.Timeout | undefined
interface BtnProps {
  isable: any
  isAuthenticated?: boolean
}
// const Btn = ({ isable }: any) => {
const Btn: React.FC<BtnProps> = ({ isable, isAuthenticated = true }) => {
  console.log(isAuthenticated, '=======isAuthenticated=====')

  const [time, setTime] = useState(60)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [btnContent, setBtnContent] = useState('SEND')
  useEffect(() => {
    clearInterval(timeChange)
    return () => clearInterval(timeChange)
  }, [])

  useEffect(() => {
    if (time > 0 && time < 60) {
      setBtnContent(`${time}s`)
    } else {
      clearInterval(timeChange)
      setBtnDisabled(false)
      setTime(60)
      setBtnContent('SEND')
    }
  }, [time])

  const startInterval = () => {
    if (!localStorage.getItem('isreCAPTCHA')) {
      return false
    }
    isAuthenticated ? GetSendSmsFun() : UnLoginGetSendSmsFun()
  }

  // 登录时获取邮件
  const GetSendSmsFun = () => {
    getsend_smsFun({ mobile_or_email: isable })
      .then((res: any) => {
        timeChange = setInterval(() => setTime((t) => --t), 1000)
        setBtnDisabled(true)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  // 未登录时获取邮件
  const UnLoginGetSendSmsFun = () => {
    getUnLoginsend_smsFun({ mobile_or_email: isable })
      .then((res: any) => {
        timeChange = setInterval(() => setTime((t) => --t), 1000)
        setBtnDisabled(true)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  return (
    <>
      <SendBtn isAuthenticated={isAuthenticated} disabled={btnDisabled || isable?.length === 0} onClick={() => startInterval()}>
        {btnContent}
      </SendBtn>
    </>
  )
}
// const SendBtn = styled(Button)<{ isAuthenticated?: Boolean }>`

const SendBtn = styled(({ isAuthenticated, ...props }: { isAuthenticated?: boolean } & ButtonProps) => <Button {...props} />)<{ isAuthenticated?: Boolean }>`
  width: 6.25vw;
  height: 2.604vw;
  background: ${({ isAuthenticated }) => (isAuthenticated ? '#5c7fff' : '#383938')};
  border-radius: 1.302vw;
  box-sizing: border-box;
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: ${({ isAuthenticated }) => (isAuthenticated ? '#5c7fff' : '#383938')};
  }
  @media (max-width: 1000px) {
    width: ${({ isAuthenticated }) => (isAuthenticated ? '18.533vw' : '25.333vw')};
    height: ${({ isAuthenticated }) => (isAuthenticated ? '7.2vw' : '11.2vw')};
    border-radius: ${({ isAuthenticated }) => (isAuthenticated ? '3.333vw' : '11.2vw')};
    font-size: ${({ isAuthenticated }) => (isAuthenticated ? '3.2vw' : '5.333vw')};
  }
`
export default Btn
