/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'components/Modal'
import styled from '@emotion/styled'
import { ModalContext } from 'context/ModalProvider'
import { Button } from '@mui/material'
import FlexBox from 'components/FlexBox'
import { useNavigate } from 'react-router-dom'

const Navigation: React.FC<any> = () => {
  const { onClose } = useContext(ModalContext)

  const title = 'purchase succeeded'
  const navigate = useNavigate()
  const handleToDetails = (e: any) => {
    e.stopPropagation()
    onClose()
    navigate('/store')
  }
  useEffect(() => {
    const interval = setInterval(async () => {
      onClose()
      navigate('/store')
    }, 3000)
    return () => clearInterval(interval)
  }, [])
  const content = (
    <Warp>
      <Title>Return to the Store(3S)</Title>
      <FlexBox between sx={{ width: '100%' }}>
        <Signin
          onClick={() => {
            onClose()
          }}
        >
          cancel
        </Signin>
        <Signin onClick={handleToDetails}>store</Signin>
      </FlexBox>
    </Warp>
  )
  return (
    <Modal
      title={title}
      content={content}
      sx={{
        width: '28.646vw',
        minHeight: '4.896vw',
      }}
    />
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.563vw 1.979vw 1.563vw;
  position: relative;
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  width: 10.938vw;
  height: 3.438vw;
  background: #5c7fff;
  border-radius: 1.042vw;
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  height: 1.771vw;
  font-size: 1.979vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin: 0 auto 2.083vw;
`

export default Navigation
/* eslint-disable */
