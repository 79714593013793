import styled from '@emotion/styled'
import { gsap } from 'gsap'
import Image from 'components/Image'

import Marquee from './Marquee'

import FooterNotification from './FooterNotification'
import { useEffect } from 'react'
import Footer from './Footer'
import PartnerH5 from './PartnerH5'
import Media from 'react-media'
import { S3Url } from "api/apiList";

const Partner = () => {
  const a1 = `${S3Url}/images/index2/partners/a1.png`
  const a2 = `${S3Url}/images/index2/partners/a2.png`
  const a3 = `${S3Url}/images/index2/partners/a3.png`
  const a4 = `${S3Url}/images/index2/partners/a4.png`
  const a5 = `${S3Url}/images/index2/partners/a5.png`
  const a6 = `${S3Url}/images/index2/partners/a6.png`
  const a7 = `${S3Url}/images/index2/partners/a7.png`

  const b1 = `${S3Url}/images/index2/partners/b1.png`
  const b2 = `${S3Url}/images/index2/partners/b2.png`
  const b3 = `${S3Url}/images/index2/partners/b3.png`
  const b4 = `${S3Url}/images/index2/partners/b4.png`
  const b5 = `${S3Url}/images/index2/partners/b5.png`
  const b6 = `${S3Url}/images/index2/partners/b6.png`
  const b7 = `${S3Url}/images/index2/partners/b7.png`

  const c1 = `${S3Url}/images/index2/partners/c1.png`
  const c2 = `${S3Url}/images/index2/partners/c2.png`
  const c3 = `${S3Url}/images/index2/partners/c3.png`
  const c4 = `${S3Url}/images/index2/partners/c4.png`
  const c5 = `${S3Url}/images/index2/partners/c5.png`
  const c6 = `${S3Url}/images/index2/partners/c6.png`
  const c7 = `${S3Url}/images/index2/partners/c7.png`

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          gsap.fromTo(entry.target, { opacity: 0, y: 100 }, { opacity: 1, y: 0, duration: 1 })
        }
      })
    })

    const elements = document.querySelectorAll('.Partner')
    elements.forEach((el) => {
      observer.observe(el)
    })

    // 当组件卸载时停止观察
    return () => {
      elements.forEach((el) => {
        observer.unobserve(el)
      })
    }
  }, [])

  const ImgOne = [a1, a2, a3, a4, a5, a6, a7, a1, a2, a3, a4, a5, a6, a7]
  const ImgTwo = [b1, b2, b3, b4, b5, b6, b7, b1, b2, b3, b4, b5, b6, b7]
  const ImgThree = [c1, c2, c3, c4, c5, c6, c7, c1, c2, c3, c4, c5, c6, c7]
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap className="Partner-wrap">
            <BottomBox>
              <Footer />
            </BottomBox>
            <Box className="Partner">
              <Partners>PARTNERS</Partners>
              <Marquee images={ImgOne} direction="left" speed={1} />
              <Marquee images={ImgTwo} direction="right" speed={1} />
              <Marquee images={ImgThree} direction="left" speed={1} />

              <FooterNotification></FooterNotification>
            </Box>
          </Wrap>
        ) : (
          <PartnerH5></PartnerH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 88.229vw;
  position: relative;
  padding-top: 0.052vw;
  box-sizing: border-box;
  input {
    width: 38.854vw;
    height: 4.792vw;
    background: #ffffff;
    border-radius: 0.938vw;

    font-size: 1.146vw;
    font-family: Albert Sans-Medium, Albert Sans;

    color: rgba(61, 61, 61, 1);
    line-height: 4.792vw;
    border: 0;
    padding-left: 1.25vw;
    outline: none;
    margin-right: 1.667vw;
    position: relative;
    z-index: 2;
  }

  input::-webkit-input-placeholder {
    color: rgba(229, 248, 252, 0.16);
  }
`
const Box = styled.div`
  position: relative;
  width: 100vw;
  height: 56.563vw;
  background: #ebebeb;
  border-radius: 3.542vw;
  padding-top: 0.052vw;
  box-sizing: border-box;
`
const Partners = styled.div`
  height: 1.563vw;
  font-size: 2.083vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #000000;
  line-height: 1.563vw;
  margin: 7.708vw 0 2.552vw 13.229vw;
`
const BottomBox = styled.div`
  width: 100%;
  height: 39.219vw;
  background: rgba(30, 32, 32, 1);
  position: absolute;
  bottom: 0;
  left: 0;
`
export default Partner
