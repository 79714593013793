import styled from "@emotion/styled"

const Content: React.FC<any> = ({ children }) => {
  return <Title>{children}</Title>
}
const Title = styled.div`
  width: 49.479vw;
  min-height: 1.563vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  line-height: 1.719vw;
  @media (max-width: 1000px) {
    width: 81.2vw;
    min-height: 3.733vw;
    font-size: 3.733vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: #7893be;
    line-height: 1.198vw;
  }
`
export default Content
