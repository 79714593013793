/* eslint-disable */
import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import Image from 'components/Image'
import FlexBox from 'components/FlexBox'
import { useWeb3React } from '@web3-react/core'
import Media from 'react-media'
import ReferralH5 from './ReferralH5'
import { inviteRewardFun } from 'api/request'
import { CopyAddress } from 'utils'
import message from 'utils/message'

const Referral = () => {
  var { account } = useWeb3React()
  const [inviteListInfo, setInviteListInfo] = useState<any>([])
  const [inviteLink, setInviteLink] = useState('')
  const [RefernalId, setRefernalId] = useState('')
  const [Referrals, setReferrals] = useState('')
  const [Reward, setReward] = useState('')

  useEffect(() => {
    const getinviteReward = async () => {
      inviteRewardFun()
        .then((res: any) => {
          setInviteListInfo(res.data?.UserInfoList)
          setInviteLink(res.data?.RefernalLink)
          setRefernalId(res.data?.RefernalId)
          setReward(res.data?.Reward)
          setReferrals(res.data?.Referrals)
        })
        .catch((err: any) => {})
    }
    account && getinviteReward()
  }, [account])

  const setToastHanlder = () => {
    message.success('Copied')
  }
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Wrap>
            <Title>Referral</Title>
            <ReferralBox>
              <SmallTitle>You can earn 75 $DPM for each valid referral. </SmallTitle>
              <SmallTitle>Valid Referral: The invitee must finish at least one game in Party Planet. </SmallTitle>
              <FlexBox sx={{ width: '52.083vw', margin: '2.396vw auto 0.677vw' }} between>
                <FlexBox center column>
                  <FlexBox sx={{ height: '2vw', width: 'fit-content' }}>
                    <Invitation>{RefernalId}</Invitation>
                    <CopyImage
                      folder="setting"
                      src="copy@2x.png"
                      onClick={() => {
                        CopyAddress(`${RefernalId}`, setToastHanlder)
                      }}
                    ></CopyImage>
                  </FlexBox>
                  <ReferralLink>Referral Code</ReferralLink>
                </FlexBox>
                <FlexBox center column>
                  <FlexBox sx={{ height: '2vw', maxWidth: '24.792vw', width: 'fit-content' }}>
                    <Invitation>{inviteLink}</Invitation>
                    <CopyImage
                      folder="setting"
                      src="copy@2x.png"
                      onClick={() => {
                        CopyAddress(`${inviteLink}`, setToastHanlder)
                      }}
                    ></CopyImage>
                  </FlexBox>
                  <ReferralLink>Referral Link</ReferralLink>
                </FlexBox>
              </FlexBox>

              {/* <FlexBox sx={{ width: '49.219vw', margin: '0 auto 1.771vw' }} between> */}
              <TotalKey>
                Total Referrals: <span>{Referrals || 0}</span>
              </TotalKey>
              <TotalKey style={{ margin: '0.521vw 0 2.865vw 0' }}>
                Total Rewards: <span style={{ marginLeft: '0.4vw' }}>{Reward || 0} $DPM</span>
              </TotalKey>
              {/* </FlexBox> */}
              <FlexBox sx={{ marginBottom: '0.677vw' }}>
                <TableTitle style={{ margin: '0 17.188vw 0 1.042vw' }}>Name</TableTitle>
                <TableTitle style={{ marginRight: '14.688vw' }}>Reward</TableTitle>
                <TableTitle style={{ marginRight: '15.99vw' }}>Date</TableTitle>
                <TableTitle>Status</TableTitle>
              </FlexBox>

              {inviteListInfo?.length > 0 ? (
                inviteListInfo?.map((item: any, i: any) => (
                  <TableBodyBox key={i}>
                    <TableBody
                      style={{
                        width: '17.578vw',
                        boxSizing: 'border-box',
                        paddingLeft: '1.042vw',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {item?.name}
                    </TableBody>
                    <TableBody style={{ width: '11vw', textAlign: 'center' }}> {item?.status === 0 ? 0 : item?.reward} $DPM</TableBody>
                    <TableBody style={{ width: '25vw', textAlign: 'center' }}>{item?.updated}</TableBody>
                    <TableBody style={{ marginLeft: '4.5vw' }}>{item?.status === 0 ? 'Pending' : 'Done'}</TableBody>
                  </TableBodyBox>
                ))
              ) : (
                <>
                  <NoDataImg src="NoData.png" folder="setting"></NoDataImg>
                  <Tips>No Data</Tips>
                </>
              )}
            </ReferralBox>
          </Wrap>
        ) : (
          <ReferralH5></ReferralH5>
        )
      }
    </Media>
  )
}
const Wrap = styled.div`
  min-height: 94vh;
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 4vw;
`

const Title = styled.div`
  width: 30vw;
  font-size: 2.5vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  line-height: 1.8vw;
  text-align: center;
  margin: 6.927vw auto 2.083vw;
`

const ReferralBox = styled.div`
  width: 77.708vw;
  min-height: 29.635vw;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.4);
  margin-left: 16.563vw;
  padding: 2.083vw 3.75vw;
  border-radius: 1.042vw;
  position: relative;
`
const SmallTitle = styled.div`
  height: 2.5vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
`
const Invitation = styled.div`
  width: fit-content;
  font-size: 1.54vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #8ba4ff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
const CopyImage = styled(Image)`
  width: 1.51vw;
  height: 1.458vw;
  margin-left: 0.521vw;
  cursor: pointer;
`
const ReferralLink = styled.div`
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #95add4;
  text-align: center;
  margin: 0 auto 4.948vw; ;
`
const TableTitle = styled.div`
  font-size: 1.042vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(149, 173, 212, 0.5);
`
const TableBodyBox = styled.div`
  width: 70.313vw;
  height: 2.604vw;
  margin-bottom: 0.521vw;
  background: rgba(139, 164, 255, 0.5);
  border-radius: 0.781vw;
  display: flex;
  align-items: center;
`
const TableBody = styled.div`
  font-size: 1.146vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
`
const NoDataImg = styled(Image)`
  width: 7vw;
  height: 7vw;
  margin: 7vw auto 0.5vw;
`
const Tips = styled.div`
  height: 1.615vw;
  font-size: 1.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #b2c1dd;
  text-align: center;
`
const TotalKey = styled.div`
  height: 1.042vw;
  font-size: 1.25vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(149, 173, 212, 0.7);
  span {
    color: rgba(152, 174, 255, 0.7);
  }
`

export default Referral
/* eslint-disable */
