import React, { useContext, useState } from "react"
import Modal from "components/Modal"
import styled from "@emotion/styled"
import { ModalContext } from "context/ModalProvider"
import { Button } from "@mui/material"
import FlexBox from "components/FlexBox"
import Btn from "./CodeCountdown"
import { modify_emailFun } from "api/request"
import message from "utils/message"
import { emailReg } from "utils"
import Media from "react-media"
import EditemallModelH5 from "./EditemallModelH5"
import { isValidPassword } from "utils/user"

const Editemall: React.FC<any> = ({ setUpdate, mobile_or_email }) => {
  const [newEmailAddress, setNewEmailAddress] = useState("")
  const [Emailcode, setEmailcode] = useState("")
  const [newPassword, setNewPassword] = useState("")
  var reg = emailReg()

  const ChangePsd = () => {
    if (!reg.test(newEmailAddress)) {
      message.error("Invalid email address.")
      return false
    }
    if (newPassword.length < 8) {
      message.error("Passwords must have at least 8 characters.")
      return false
    }
        // if (!isValidPassword(newPassword)) {
    //   message.error('Support upper and lower case letters only.')
    //   return false
    // }
    
    modify_emailFun({ new_mobile_or_email: newEmailAddress, platform: "web", code: Emailcode, password: newPassword })
      .then((res: any) => {
        if (res.code === 0) {
          setUpdate(new Date())
          onClose()
        } else {
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const { onClose } = useContext(ModalContext)

  const title = "edit email address"

  const content = (
    <Warp>
      {/* <Error></Error> */}
      <Title>Current Email Address:</Title>
      <input
        type="text"
        value={mobile_or_email}
        disabled
        style={{
          background: mobile_or_email ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
        }}
      />

      <Title>New Email Address:</Title>
      <input
        type="text"
        value={newEmailAddress}
        onChange={(evt: any) => {
          setNewEmailAddress(evt.target.value || "")
        }}
        style={{
          background: newEmailAddress ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
        }}
      />

      <Title>Verification Code:</Title>
      <FlexBox startBetween sx={{ width: "100%" }}>
        <input
          type="text"
          value={Emailcode}
          onChange={(evt: any) => {
            setEmailcode(evt.target.value || "")
          }}
          maxLength={6}
          style={{
            background: Emailcode ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
            width: "17.865vw",
          }}
        />
        <Btn isable={newEmailAddress}></Btn>
      </FlexBox>

      <Title>Password:</Title>
      <input
        type="password"
        value={newPassword}
        onChange={(evt: any) => {
          setNewPassword(evt.target.value || "")
        }}
        style={{
          background: newPassword ? "rgba(92, 127, 255, 0.6)" : "rgba(92, 127, 255, 0.3)",
          marginBottom: "0",
        }}
      />

      <Signin
        disabled={newEmailAddress.length === 0 || Emailcode.length !== 6 || newPassword.length === 0}
        onClick={() => {
          ChangePsd()
        }}
      >
        save
      </Signin>
    </Warp>
  )
  return (
    <Media query={{ maxWidth: 1000 }}>
      {(matches) =>
        !matches ? (
          <Modal
            title={title}
            content={content}
            sx={{
              width: "28.646vw",
              minHeight: "40vw",
            }}
          />
        ) : (
          <EditemallModelH5 setUpdate={setUpdate} mobile_or_email={mobile_or_email} />
        )
      }
    </Media>
  )
}
const Warp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1.563vw 1.979vw 1.563vw;
  position: relative;
  input {
    width: 24.635vw;
    height: 2.604vw;
    background: rgba(92, 127, 255, 0.5);
    border-radius: 1.042vw;
    font-family: Albert Sans-Bold, Albert Sans;
    font-size: 1.354vw;
    font-family: Albert Sans-Bold, Albert Sans;
    color: rgba(255, 255, 255, 1);
    outline: none;
    border: 0;
    line-height: 1.406vw;
    display: block;
    margin-bottom: 49.997px;
    box-sizing: border-box;
    padding-left: 0.781vw;
  }
  input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.198vw;
  }
  .Mui-disabled {
    color: #fff !important;
    background: rgba(92, 127, 255, 0.3);
  }
`
const Signin = styled(Button)`
  width: 24.635vw;
  height: 3.438vw;
  background: #5c7fff;
  border-radius: 1.719vw;
  font-size: 1.354vw;
  color: #fff;
  font-family: Albert Sans-Bold, Albert Sans;
  margin: 1.042vw auto 0;
  display: block;
  box-sizing: border-box;
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: #5c7fff;
  }
`
const Title = styled.div`
  font-size: 1.354vw;
  height: 1.094vw;
  line-height: 1vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: rgba(92, 127, 255, 0.7);
  margin-bottom: 1.042vw;
`

export default Editemall
