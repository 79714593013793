import styled from '@emotion/styled'
import Image from 'components/Image'
const PlayOwnH5 = () => {

  return (
    <Wrap className="playownH5-wrap">
      <Box>
        <GameBox className="exit-animation">
          <GameImg folder="index2/PlayOwnH5" src="Game.png" />
          <Title>Play</Title>
          <Content>Users can explore a fantasy world, enjoy different types of gameplay, interacting with cute characters and environments to obtain an immersive gaming experience in the Skytopia Universe.</Content>
        </GameBox>

        <SmallBox className="exit-animation">
          <GameImg folder="index2/PlayOwnH5" src="Companion.png" />
          <Title>Own</Title>
          <SmallContent>In Skytopia, game assets are owned by players. Therefore, players can trade, transfer, and manage these assets within the game, thereby gaining a better gaming experience and economic benefits.</SmallContent>
        </SmallBox>

        <GameBox className="exit-animation">
          <GameImg folder="index2/PlayOwnH5" src="Token.png" />
          <Title>Create</Title>
          <Content>Skytopia combines AI and other technologies to provide the community with powerful content creator tools. Users can express their ideas and feelings, and together create a rich and diverse content ecosystem.</Content>
        </GameBox>

        <SmallBox className="exit-animation">
          <GameImg folder="index2/PlayOwnH5" src="Governance.png" />
          <Title>Socialize</Title>
          <SmallContent>Players can establish game communities and teams in Skytopia. The rich social features will bring players a broader and deeper gaming experience, while enhancing the interactivity and playability.</SmallContent>
        </SmallBox>
      </Box>
    </Wrap>
  )
}

const Wrap = styled.div`
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: 341.6vw;
  background: #1e2020;
  border-radius: 9.067vw;
`

const Box = styled.div`
  width: 100vw;
  height: 341.6vw;
  background: #1e2020;
  border-radius: 9.067vw;
  margin: 0 auto;
  position: relative;
  padding: 32.267vw 0 8.8vw 0;
  box-sizing: border-box;
`

const GameBox = styled.div`
  width: 83.733vw;
  height: 73.6vw;
  margin: 2vw auto;
`

const SmallBox = styled.div`
  width: 65.2vw;
  height: 73.6vw;
  margin: 2vw 8.133vw 2vw 26.667vw;
`

const GameImg = styled(Image)`
  width: 17.867vw;
  height: 17.867vw;
  display: block;
  margin-left: 0.667vw;
`

const Title = styled.div`
  width: 65.2vw;
  height: 12.533vw;
  font-size: 10.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #a3aaaf;
  line-height: 12.8vw;
  margin: 2.933vw 0 1.333vw 0;
`
const Content = styled.div`
  width: 83.733vw;
  min-height: 33.067vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #ffffff;
  line-height: 5.067vw;
`
const SmallContent = styled.div`
  width: 65.2vw;
  min-height: 33.067vw;
  font-size: 3.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  font-weight: 700;
  color: #ffffff;
  line-height: 5.067vw;
`

export default PlayOwnH5
