import styled from '@emotion/styled'
import { useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { S3Url } from "api/apiList";

interface PreloaderProps {
  imageUrls: string[]
  videoUrls?: string[]
  progress: number
}
const Loading: React.FC<PreloaderProps> = ({ progress, imageUrls }) => {
  let [di_loadingi, di_w_dog, di_w_su, di_w_yu, di_loadingzi, DMP, DMP1, FPU, loading_donghua] = imageUrls
  gsap.registerPlugin(ScrollTrigger)

  const isAnimationPlayed = useRef(false)

  useEffect(() => {
    if (progress >= 96 && !isAnimationPlayed.current) {
      isAnimationPlayed.current = true;
      setTimeout(() => {
        gsap.fromTo('.loading-Wrap', { y: '0%' }, { y: '-100%', duration: 1.5 });
      }, 2000);
    }
  }, [progress]);

  useEffect(() => {
    const oneAnimation = lottie.loadAnimation({
      container: document.getElementsByClassName('one')[0],
      renderer: 'svg',
      loop: true,
      autoplay: true,
      // animationData: one,
      path: `${S3Url}/json/loading_data.json`,
    })

    var tl = gsap.timeline({ scrollTrigger: { trigger: '.loading-Wrap', start: 'top top+=100' } })
    tl.fromTo('.crocodile', { opacity: 0, y: 60 }, { y: 0, opacity: 1, duration: 0.3 })
    tl.fromTo('.dog', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.3 })
    tl.fromTo('.groundhog', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.3 })

    tl.fromTo('.fpu', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.3 })
    tl.fromTo('.dmp', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.3 })
    tl.fromTo('.dmp1', { opacity: 0, y: -60 }, { y: 0, opacity: 1, duration: 0.3 })
    return () => {
      tl.kill()
    }
  }, [])

  return (
    <Wrap className="loading-Wrap">
      <DMPImg src={DMP} alt="dmp" className="dmp" />
      <DMP1Img src={DMP1} alt="dmp" className="dmp1" />
      <FPUImg src={FPU} alt="fpu" className="fpu" />
      <LoadingBox>
        <Leaf>
          <LeafText>{progress}%</LeafText>
          <LeafImg src={di_loadingi} alt=""></LeafImg>
        </Leaf>
        {/* <LoadingImg src={loading_donghua} alt=""></LoadingImg> */}
        <div className="one"></div>
      </LoadingBox>
      <LoadingLine src={di_loadingzi} alt="" />

      <img className="groundhog" src={di_w_su} alt="" />
      <img className="crocodile" src={di_w_yu} alt="" />
      <img className="dog" src={di_w_dog} alt="" />
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 100vh;
  background-color: RGBA(50, 103, 217, 1);
  position: absolute;
  z-index: 10;
  overflow: hidden;
  .crocodile {
    width: 17.031vw;
    height: auto;
    position: absolute;
    bottom: 0vw;
    right: 13.438vw;
  }
  .groundhog {
    width: 20.104vw;
    height: auto;
    position: absolute;
    bottom: 9.063vw;
    right: 8.021vw;
  }
  .dog {
    width: 19.219vw;
    height: auto;
    position: absolute;
    bottom: 0vw;
    right: 0vw;
  }
  .one {
    width: 19.479vw;
    height: 6.563vw;
    transform: translateY(-2.083vw);
  }
`
const DMPImg = styled.img`
  width: 6.042vw;
  height: auto;
  position: absolute;
  top: 11.563vw;
  left: 5.208vw;
`
const DMP1Img = styled.img`
  width: 4.219vw;
  height: auto;
  position: absolute;
  top: 4.74vw;
  left: 1.406vw;
`

const FPUImg = styled.img`
  width: 8.854vw;
  height: auto;
  position: absolute;
  top: 3.125vw;
  left: 9.063vw;
`

const LoadingLine = styled.img`
  width: auto;
  height: calc(100vh - 2.917vw);
  margin: 1.458vw auto;
  display: block;
`

// const LoadingImg = styled.img`
//   width: 14.635vw;
//   height: auto;
//   margin: 0 auto;
// `

// const Team = styled.div`
//   width: auto;
//   height: 37.813vw;
//   position: absolute;
//   bottom: 0;
//   right: 2.5vw;
// `

const LoadingBox = styled.div`
  height: 12.24vw;
  width: 19.479vw;
  /* background-color: #000; */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
const Leaf = styled.div`
  width: 8.646vw;
  height: 7.604vw;
  margin: 0 auto 1.406vw;
  position: relative;
`
const LeafText = styled.div`
  width: 8.646vw;
  height: 7.604vw;
  line-height: 7.604vw;
  position: absolute;
  text-align: center;
  font-size: 2.604vw;
  font-family: Milky Nice;
  color: #fad121;
  margin-left: 0.26vw;
`

const LeafImg = styled.img`
  width: 8.646vw;
  height: 7.604vw;
`
export default Loading
