import { gsap } from 'gsap'

// Mouse in
export const mouseEnter = (value: string, scale: number = 1.05, duration: number = 0.25) => {
  gsap.to(value, { scale: scale, duration: duration })
}

// Mouse removal
export const mouseLeave = (value: string, duration: number = 0.25) => {
  gsap.to(value, { scale: 1.0, duration: duration })
}

export const mouseEnterTopMove = (value: string, scale: number = 1.05, duration: number = 0.25) => {
  gsap.to(value, { scale: scale, y: -20, duration: duration })
}

export const mouseLeaveTopMove = (value: string, duration: number = 0.25) => {
  gsap.to(value, { scale: 1.0, y: 0, duration: duration })
}
