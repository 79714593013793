import Box from "@mui/material/Box"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import Button from "@mui/material/Button"
import { LeftList } from "config/Nav"

const LeftTabs: React.FC = () => {
  return (
    <Wrap>
      {LeftList.map((item, i) =>
        item.name !== "REFERRAL" ? (
          <Link to={item.router} key={i}>
            <Buttonvalue className={window.location.href.includes(item.router) ? "Selected" : ""}>{item.name}</Buttonvalue>
          </Link>
        ) : (
          localStorage.getItem("inviteSwitch") === "1" && (
            <Link to={item.router} key={i}>
              <Buttonvalue className={window.location.href.includes(item.router) ? "Selected" : ""}>{item.name}</Buttonvalue>
            </Link>
          )
        )
      )}
    </Wrap>
  )
}
const Wrap = styled(Box)`
  width: 11.771vw;
  height: 29.688vw;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 1.042vw;
  display: flex;
  align-items: center;
  position: absolute;
  left: 2.448vw;
  top: 10.625vw;
  flex-direction: column;
  z-index: 2;
  padding: 0;
  @media (max-width: 1000px) {
    width: 100%;
    padding: 0 2vw;
    height: 10.4vw;
    border-radius: 0;
    position: absolute;
    left: 2.448vw;
    top: 33.467vw;
    left: 0;
    flex-direction: row;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
  }
  a {
    border-bottom: none;
    text-decoration: none;
    min-width: fit-content;
  }
  .Selected {
    background: rgba(92, 127, 255, 0.7);
    color: #fff;
    @media (max-width: 1000px) {
      font-size: 3.2vw;
    }
    &:hover {
      -webkit-text-decoration: none;
      text-decoration: none;
      background: rgba(92, 127, 255);
    }
  }
`
const Buttonvalue = styled(Button)`
  width: 9.792vw;
  height: 2.604vw;
  border-radius: 0.521vw;
  font-family: Albert Sans-Bold, Albert Sans;
  text-align: center;
  font-size: 1.25vw;
  color: #95add4;
  margin: 1.042vw auto 0;
  @media (max-width: 1000px) {
    /* width: 17.867vw; */
    width: fit-content;
    height: 6.667vw;
    border-radius: 1.333vw;
    font-size: 3.467vw;
  }
  &:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background: transparent;
  }
`

export default LeftTabs
