import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
const DownLoad: React.FC = () => {
  return (
    <Wrap>
      <Foes>STRIVING FOR $DPM(DOPAMINE)</Foes>
      <Modes>Compete and cooperate </Modes>
    </Wrap>
  )
}
const Wrap = styled.div`
  width: 100%;
  height: 11.979vw;
  position: relative;
  background-color: rgb(235, 81, 93);
  overflow: hidden;
`
const Foes = styled.div`
  height: 2.396vw;
  font-size: 3.281vw;
  font-family: Milky Nice;
  font-weight: 400;
  color: #49f0ff;
  margin: 1.76vw 0 1.5vw 3.438vw;
`
const Modes = styled.div`
  height: 3.542vw;
  font-size: 4.896vw;
  font-family: Milky Nice;
  font-weight: 400;
  color: #f7f41f;
  margin-left: 3.438vw;
`

export default DownLoad
