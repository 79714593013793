import copy from "copy-to-clipboard"
export const resetRoute = () => (window.location.href = window.location.origin)

export const getShortHash = (hash: any, startLength: number = 4, endLength: number = 4) => {
  return hash ? hash.slice(0, startLength) + "..." + hash.slice(hash.length - endLength, hash.length) : ""
}

export const emailReg = () => {
  return /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
}

export const CopyAddress = (str: string, Fn?: Function) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(str)
    copy(str)
    Fn?.(true)
    setTimeout(() => {
      Fn?.(false)
    }, 1000)
    return Promise.resolve(() => {})
  } else {
    copy(str)
    Fn?.(true)
    setTimeout(() => {
      Fn?.(false)
    }, 1000)
    return Promise.resolve(() => {})
  }
}

export const openNewTab = (url: string | URL | undefined) => {
  window.open(url, '_blank', "noopener,noreferrer")
}