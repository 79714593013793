import Image from "components/Image"
import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { productListFun } from "api/request"
import FlexBox from "components/FlexBox"
import { useNavigate } from "react-router-dom"

const ProductH5 = () => {
  const [productListInfo, setProductList] = useState<any>([])

  const navigate = useNavigate()
  const handleToDetails = (item: any) => {
    console.log(item)
    localStorage.setItem("productInfo", JSON.stringify(item))
    navigate("/store/details")
  }

  useEffect(() => {
    const getproductList = () => {
      productListFun()
        .then((res: any) => {
          setProductList(res.data.list)
        })
        .catch((err: any) => {})
    }
    getproductList()
  }, [])

  return (
    <Wrap className="product-wrap">
      <Title>STORE</Title>
      <ProductList>
        {productListInfo?.map((item: any, key: any) => (
          <ProductBox onClick={() => item.status !== 3 && handleToDetails(item)} key={key}>
            <ProductSaleImage folder="store" src="sale.png" />
            <ProductImage src={item.image_url} alt="NFT"></ProductImage>
            <ProductTitle>{item.name}</ProductTitle>
            <FlexBox startCenter sx={{ margin: "0 0 0 1.067vw" }}>
              <ProductPriceImage folder="setting" src={item.pay_currency?.toUpperCase() + ".png"}></ProductPriceImage>
              <ProductPrice>
                {item.price}
                {item.pay_currency}
              </ProductPrice>
            </FlexBox>
            <ProductCountBox>
              <ProductCount>
                {item.balance}/{item.supply}
              </ProductCount>
            </ProductCountBox>
            <ProductSource>{item.resource}</ProductSource>
            {item.status === 3 && (
              <ProductSold>
                <ProductSoldImage folder="store" src="sold.png" />
              </ProductSold>
            )}
          </ProductBox>
        ))}
      </ProductList>
    </Wrap>
  )
}
const Wrap = styled.div`
  min-height: calc(100vh - 3.594vw);
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 9vw;
`
const Title = styled.div`
  width: 21.067vw;
  height: 5.2vw;
  font-size: 7.467vw;
  line-height: 5.733vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #7893be;
  margin: 16.267vw 0 7.2vw 5.2vw;
`
const ProductList = styled.div`
  min-height: 50vw;
  width: 89.467vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 5.333vw;
  flex-wrap: wrap;
`
const ProductBox = styled.div`
  width: 28.8vw;
  height: 47.6vw;
  background: #ffffff;
  border-radius: 2.667vw;
  margin-right: 1.467vw;
  position: relative;
  padding: 0.8vw;
  box-sizing: border-box;
  background: rgba(92, 127, 255, 0.8);
  cursor: pointer;
  margin-bottom: 1.333vw;
  :nth-of-type(3n + 0) {
    margin-right: 0;
  }
`
const ProductTitle = styled.div`
  height: 6.2vw;
  font-size: 2.4vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffffff;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`
const ProductImage = styled.img`
  width: 28.933vw;
  height: 28.933vw;
  border-radius: 2.667vw 2.667vw 0 0;
  position: relative;
  left: -0.8vw;
  top: -0.8vw;
`
const ProductPriceImage = styled(Image)`
  width: 2.933vw;
  height: 2.933vw;
  margin-right: 0.933vw;
`
const ProductSaleImage = styled(Image)`
  width: 11.733vw;
  height: 8.133vw;
  position: absolute;
  left: 0;
  top: 0;
`
const ProductPrice = styled.div`
  min-width: 4.667vw;
  font-size: 2.667vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #ffe736;
`
const ProductCountBox = styled.div`
  width: 26.533vw;
  height: 3.467vw;
  background: #5784ff;
  border-radius: 1.067vw;
  margin: 0.4vw auto 0.133vw;
  line-height: 3.467vw;
`

const ProductCount = styled.div`
  min-width: 26.133vw;
  font-size: 2.133vw;
  color: #ffffff;
  font-family: Albert Sans-Bold, Albert Sans;
`

const ProductSource = styled.div`
  font-size: 2.133vw;
  font-family: Albert Sans-Bold, Albert Sans;
  color: #a9baff;
  height: 2.133vw;
  line-height: 2.133vw;
  margin-left: 1.067vw;
`
const ProductSold = styled.div`
  width: 28.8vw;
  height: 47.6vw;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 2.667vw;
  position: absolute;
  left: 0;
  top: 0;
`
const ProductSoldImage = styled(Image)`
  width: 23.333vw;
  height: 18vw;
  position: absolute;
  left: 2.933vw;
  top: 10.4vw;
`

export default ProductH5
